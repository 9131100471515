const pageLabel = 'main_page.my_ads.create_ad.house_details.photographs';

const getPropertyAreasCharacteristics = (t) => {
  return {
    toBeDefined: {title: t(pageLabel + '.areas.toBeDefined'), maxPhotos: 0, hideDropzone: true},
    kitchen: {title: t(pageLabel + '.areas.kitchen'), minPhotos: 1},
    livingRoom: {title: t(pageLabel + '.areas.livingRoom'), minPhotos: 1},
    bathroom: {title: t(pageLabel + '.areas.bathroom'), minPhotos: 0},
    otherSpaces: {
      title: t(pageLabel + '.areas.otherSpaces.title'),
      info: t(pageLabel + '.areas.otherSpaces.info')
    },
    exteriorSpaces: {
      title: t(pageLabel + '.areas.exteriorSpaces.title'),
      info: t(pageLabel + '.areas.exteriorSpaces.info')
    }
  }
}

export default getPropertyAreasCharacteristics;
