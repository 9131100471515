import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import {useTranslation} from "react-i18next";
import MetaTags from "react-meta-tags";
import Static from "../../pages/Static";

const PagesSwitch = ({
                         path,
                         pages,
                         renderLinks,
                         containerClass = false,
                         pageHeader,
                         boxShadow,
                         navBarButton,
                         basicContainer,
                         extraRoutes,
                         type = 'main',
                         ...props
                     }) => {


    path = path !== '/' ? path + '/' : path;

    const {t} = useTranslation();

    return (
        <div id="horizontalbar_content"
             className={`${containerClass ? 'container' : null} ${basicContainer ? 'basic_container' : ''}`}>
            <Switch>
                {pages &&
                pages.map((p) => {
                    return <Route key={p.path} path={path + p.path}
                                  render={() => <p.component {...p} {...props} path={path + p.path} pages={pages}/>}/>;
                })}
                {extraRoutes}
                {type === "main" ? <>
                    <Route exact path="/" render={()=><><MetaTags><meta id="metaviewport" name="viewport" content="width=device-width, initial-scale=1"/></MetaTags><Static/></>}/>
                    <Route exact path="/how-to" render={()=><><MetaTags><meta id="metaviewport" name="viewport" content="width=device-width, initial-scale=1"/></MetaTags><Static page={"/como-funciona-landlords"}/></>}/>
                </> : <Redirect to={path + pages[0].path}/>}
                <Route path="/" render={() => <>
                    <MetaTags>
                        {/*<meta id="metaviewport" name="viewport" content="width=1280, initial-scale=0.1"/>*/}
                        <meta name="title" content={t('inlife_title_default')}/>
                        <title>{t('inlife_title_default')}</title>
                    </MetaTags>
                </>}/>
            </Switch>
        </div>
    );
};

export default PagesSwitch;
