
import {IoMdCheckmark} from 'react-icons/io';
import React from 'react';

import './style.less'


const CheckBox = ({ generalClassName, generalStyle, value, onChange, label, checked,refCheckBox}) => {

  if(!refCheckBox) checked=value;

  return (<label  style={generalStyle} className={'CheckBox '+generalClassName} onClick={onChange}>
    {/*<input type="checkbox" value={value} onChange={onChange}*/}
    {/*       name={name} ref={register} />*/}
    <div className={`check_mark ${checked ? "checked" : null}`}>{checked ? <IoMdCheckmark /> : null}</div>
    <label className={`checkbox__label`}>{label}</label>
  </label>)
}

export default CheckBox;

