import React from 'react';
import InternetIcon from '../components/svg/Bills/Internet';
import CableTVIcon from '../components/svg/Bills/CableTV';
import WaterIcon from '../components/svg/Bills/Water';
import ElectricityIcon from '../components/svg/Bills/Electricity';
import GasIcon from '../components/svg/Bills/Gas';
import CondominiumIcon from '../components/svg/Bills/Condominium';

const PROPERTY_BILLS = {
    internet: { icon: <InternetIcon />, translationKey: "bills.internet" },
    tv: { icon: <CableTVIcon />, translationKey: "bills.tv" },
    water: { icon: <WaterIcon />, translationKey: "bills.water" },
    electricity: { icon: <ElectricityIcon />, translationKey: "bills.electricity" },
    gas: { icon: <GasIcon />, translationKey: "bills.gas" },
    condominium: { icon: <CondominiumIcon />, translationKey: "bills.condo" },
};

export default PROPERTY_BILLS;
