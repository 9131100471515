import React from 'react';
import {useTranslation} from 'react-i18next';
import './style.less';
import star from "./images/star.svg";
import block from "./images/block_black_18dp.svg";
import calendar from "./images/calendar.svg";
import check from "./images/check-circle.svg";
import percentage from "./images/percentage.svg";
import tag from "./images/tag.svg";

//possible types :
// potentials
// reservations
// visits
// ads
// events
//favorites

//grey: true / false -> for grey empty states

//TODO grey is not working 100% for the icon color

const EmptyState = ({type, grey}) => {

    const {t} = useTranslation();

    const icons = {
        potentials: percentage,
        reservations: check,
        visits: block,
        ads: tag,
        offers: tag,
        events: calendar,
        favorites: star
    }


    let translationPath = "empty_state." + type + ".";

    return (
        <div className={"EmptyState"}>
            <div className={`icon-container ${type} ${grey ? "grey" : ""}`}><img src={icons[type]} /></div>
            <div className={"text-info"}>
                <p>{t(translationPath + "label")}</p>
                <p>{t(translationPath + "info")}</p>
            </div>
        </div>
    )
}

export default EmptyState;