import {Trans, useTranslation} from 'react-i18next';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import moment from 'moment';
import DatePicker, {registerLocale} from 'react-datepicker';
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state';
import {Box, Button, ClickAwayListener, makeStyles, Popper} from '@material-ui/core';
import ReactMoment from 'react-moment';

import pt from 'date-fns/locale/pt';
import {propertyServices} from '../../../services';
import verifyAttributeExistenceToString from '../functions/verifyAttributeExistenceToString';
import pencilIconGrey from '../images/pencilIconGrey.svg';
import saveIconWhite from '../images/saveIconWhite.svg';
import PriceComponent from '../../../pages/MainPage/containers/MyAds/containers/Ad/components/PriceComponent';
import ResumeButtons from '../components';
import MUIMenu, {StyledMenuItem} from '../../MUIMenu';

import './style.less';
import {clampText} from "../../../utils/stringFunctions";
import {calcFirstAvailability} from "../../../utils/firstAvailability";
import ActionDialog from "../../ActionDialog";

registerLocale('pt', pt);

const useStyles = makeStyles({
  root: {
    '&hover': {
      backgroundColor: 'transparent'
    }
  }
})

const RoomResume = ({
                      room,
                      changePage,
                      roomName,
                      property,
                      type,
                      onDelete,
                      onEnable,
                      onDisable,
                      setOpen,
                      currentPage,
                      ...props
                    }) => {
  const {t} = useTranslation();
  const locale = localStorage.getItem('i18nextLng')?.split('-')?.[0];
  const roomNumber = parseInt(roomName?.split('_')?.[1]);
  const roomRef = useRef(null);

  const [actionDialog, setActionDialog] = useState(null || false);

  useEffect(() => {
    if (props.setRoomsRef) {
      props.setRoomsRef(property.id + "_" + roomName?.split('_')?.[1], roomRef);
    }
  }, [])

  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(property.accommodation === 'apartment' && roomName !== 'room_1' ? property.rooms.room_1 : room);
  const updateRoom = useMemo(() => property.accommodation === 'apartment' && roomName !== 'room_1' ? 'room_1' : roomName, [])

  const handlePropertyFieldChange = (field, value) => {
    setCurrentRoom(prevState => ({...prevState, [field]: value}))
  }

  useEffect(() => {
    setCurrentRoom(property.accommodation === 'apartment' && roomName !== 'room_1' ? property.rooms.room_1 : room);
  }, [property])

  const SimpleDatePicker = () => {


    const getExcludedDates = (() => {
      let allUnavailabilities = []
      if (currentRoom?.unavailability)
        allUnavailabilities = currentRoom.unavailability.filter(unavailability => unavailability.added == 2 || !unavailability.added)
      if (currentRoom?.importedUnavailability)
        allUnavailabilities.push(...currentRoom.importedUnavailability.filter(u => !u.removed));

      return allUnavailabilities.reduce((acc, unavalability) => {
        let dateArray = [];
        let currentDate = moment(unavalability.start);
        let stopDate = moment(unavalability.end);
        while (currentDate <= stopDate) {
          dateArray.push(currentDate.toDate())
          currentDate.add(1, 'days');
        }
        return [...acc, ...dateArray];
      }, [])
    })();

    const handleChange = async (date) => {
      try {
        let {firstAvailability} = await propertyServices.updateAvailableFrom(property.id, updateRoom?.split('_')?.[1], date)
        handlePropertyFieldChange('firstAvailability', firstAvailability);
        setDatePickerOpened(false);
        setActionDialog("availability_updated")
      } catch (e) {
        console.log("error changing firstAvailability", e)
      }
    }

    return (<div className={'CustomDatePicker'}>
      <DatePicker
        excludeDates={getExcludedDates}
        onChange={async (date) => {
          await handleChange(date);
        }}
        disabledKeyboardNavigation
        selected={currentRoom?.firstAvailability && new Date(currentRoom.firstAvailability) > Date.now() ? moment(currentRoom.firstAvailability).toDate() : new Date()}
        open={datePickerOpened}
        showPreviousMonths={false}
        locale={localStorage.getItem('i18nextLng')?.split('-')?.[0]}
        dateFormat={'dd MMM yyyy'}
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
        popperPlacement='bottom-start'
        dayClassName={date =>
          moment(date).isBefore(moment(currentRoom?.firstAvailability),'day')? 'passed_day' : undefined
        }
        minDate={new Date()}
        onClickOutside={() => setDatePickerOpened(false)}
        popperModifiers={{
          preventOverflow: {
            enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
          },
          hide: {
            enabled: false // turn off since needs preventOverflow to be enabled
          }
        }}
      />
    </div>)
  }

  const RentPriceComponent = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const [roomPrice, setRoomPrice] = useState(currentRoom?.rent);

    const getPricesInterval = useMemo(() => {
      let interval = propertyServices.getPricesInterval(currentRoom, property, true);
      return interval === "tbd" ? t('tbd') : interval
    }, [currentRoom]);

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleScroll = async() => {
      if (Boolean(anchorEl)) {
        setAnchorEl(null);
      }
    }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [anchorEl])

    const handle = async () => {
      try {
        handleClick();
        handlePropertyFieldChange('rent', roomPrice);
        await propertyServices.updateRent(property.id, updateRoom?.split('_')?.[1], roomPrice);
      } catch (e) {
        console.log(e)
      }
    }

    return (
        <>
        <div>
          <p className={'prices-interval'}>{getPricesInterval}</p>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div className={'PopupState'}>
                <Button variant="contained" disableElevation color="primary" {...bindTrigger(popupState)}>
                  {currentRoom?.rent && !property.disabled && type !== 'Advanced Edition' && (!property.integration_id || ["habyt", "homii"].includes(property.integration_platform)) &&
                   <img alt={"pencil icon"} src={pencilIconGrey} className={'RoomResume__pencilIcon'} {...bindTrigger(popupState)}
                        onClick={(event) => {
                          if (!currentRoom.fixedRent) {
                            if (setOpen)
                              setOpen(roomNumber);
                            else {
                              changePage('2.4', roomNumber)
                            }
                          } else {
                            handleClick(event);
                          }
                        }}/>}
                </Button>

                <Popper
                  {...bindPopover(popupState)}
                  style={{zIndex: 10000}}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  modifiers={{
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: 'scrollParent'
                    }
                  }}
                >
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Box p={2}>
                      <label>{t('property_resume.base_rent')}</label>
                      <PriceComponent
                          inputKey={'billsMax'}
                          name="price"
                          value={roomPrice}
                          watchValue={roomPrice}
                          onChange={setRoomPrice}
                          onBlur={handle}
                          autoFocus
                          onKeyDown={ e => {if (e.key === 'Enter') handle()}}
                      />
                      <button type={'button'} className={'btn btn_blue'} onClick={handle}><p>{t('buttons.save')}</p><img
                          src={saveIconWhite} alt={"save icon"}/></button>

                    </Box></ClickAwayListener>
                </Popper>
              </div>
            )}
          </PopupState>
          {type !== "Resume" && <p className={"bills-included"}>{property.billsIncluded ? t('property_resume.bills_included') : t('property_resume.bills_not_included')}</p>}
        </div>
          {type === "Resume" && <p className={"bills-included"}>{property.billsIncluded ? (property.billsSpecific && property.billsSpecific.length > 0 ? t('property_resume.bills_specific_included') : (property.billsMax && property.billsMax >= 1 ? t('property_resume.bills_max_included', {max: property.billsMax}) : t('property_resume.bills_included'))) : t('property_resume.bills_not_included')}</p>}
        </>
    )

  }

  const UnavailabilitiesComponent = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const arrowRef = useRef(null);
    const open = Boolean(anchorEl);

    const handleScroll = async () => {
      if (Boolean(anchorEl)) {
        setAnchorEl(null);
      }
    }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [anchorEl])

    const [anchorEl2, setAnchorEl2] = useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl2(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl2(null);
    };

    const open2 = Boolean(anchorEl2);

    let allUnavailabilities = []
    if (currentRoom?.unavailability)
      allUnavailabilities = currentRoom.unavailability.filter(unavailability => unavailability.added == 2 || !unavailability.added)
    if (currentRoom?.importedUnavailability)
      allUnavailabilities.push(...currentRoom.importedUnavailability);
    allUnavailabilities = allUnavailabilities.filter(u => !u.removed && moment(u.end).isAfter(moment()))


    return (
        <div style={{display: (allUnavailabilities.length ? "flex" : "none")}} >
          <p onMouseEnter={handlePopoverOpen}
             onMouseLeave={handlePopoverClose}
             >{t('property_resume.numUnavailabilities', {count: allUnavailabilities.length})}</p>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <div className={'PopupState'}>
                  <Button variant="contained" disableElevation color="primary" {...bindTrigger(popupState)}>
                    {currentRoom?.rent && !property.disabled && type !== 'Advanced Edition' && (!property.integration_id || ["habyt", "homii"].includes(property.integration_platform)) &&
                    <img alt={"pencil icon"} src={pencilIconGrey} className={'RoomResume__pencilIcon'} {...bindTrigger(popupState)}
                         onClick={() => {
                           if (setOpen) {
                             setOpen(roomNumber);
                           } else {
                             changePage('2.4', roomNumber)
                           }
                         }}/>}
                  </Button>
                </div>
            )}
          </PopupState>
          <Popper
              style={{zIndex: 10000}}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              id={open2 ? 'simple-popper' : undefined}
              open={open2}
              anchorEl={anchorEl2}
              modifiers={[{
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'scrollParent'
                }
              },
                {
                  name: 'arrow',
                  enabled: true,
                  options: {
                    element: arrowRef,
                  },
                }
              ]}
          >
            {/*<img src={arrowDownIcon} ref={arrowRef}/>*/}
            <div className={"unavailabilities-popper"}>
            {allUnavailabilities.map(unavailability => {
              return <span key={unavailability.id}>{moment(unavailability.start).format("DD/MM/YYYY") + " - " + moment(unavailability.end).format("DD/MM/YYYY")} </span>
              })}
            </div>
          </Popper>
        </div>
    )

  }

  const AvailabilityComponent = () => {
    let firstAvailability = currentRoom?.firstAvailability || calcFirstAvailability(currentRoom);
    return (
        <div className={'AvailabilityComponent'} onClick={() => {
          (!property.integration_id || ["habyt", "homii"].includes(property.integration_platform)) && !(property.accommodation === 'residence' && currentRoom?.openResidenceCalendar) && setDatePickerOpened(true);
        }}>
          {property.accommodation === 'residence' && currentRoom?.openResidenceCalendar ?
              <p className={'TypeResidence'}>{t('property_resume.residence_type_calendar')}</p> :
              <>{type === 'My_Ads' && <p>{`${t('property_resume.available_from')} `}</p>}
                {firstAvailability ?
                    <ReactMoment locale={locale}
                                 format={locale === 'en' ? 'MMM D yyyy' : 'D MMM yyyy'}>
                      {new Date(firstAvailability) < Date.now() ? Date.now() : firstAvailability}
                    </ReactMoment>
                    : <p>{t('tbd')}</p>}
                <SimpleDatePicker/>
                {firstAvailability && (property.accommodation === 'apartment' ? !property.disabled : !currentRoom?.disabled) && type !== 'Advanced Edition' && (!property.integration_id || ["habyt", "homii"].includes(property.integration_platform)) &&
                <img alt={"pencil icon"} src={pencilIconGrey} className={'RoomResume__pencilIcon'}/>}
              </>}
        </div>)
  }

  const PriceAndAvailabilityComponent = () => {
    if (type === 'Resume') {
      return (<div className={'PriceAndAvailabilityComponent'}>
        <div className={'RoomResume__price'}>
          <label>{t('price')}</label>
          <div className={'RoomResume__innerContainer'}>
            <RentPriceComponent/>
          </div>
        </div>
        <div className={'RoomResume__available'}>
          <label>{t('property_resume.available_from')}</label>
          <div className={'RoomResume__innerContainer'}>
            <AvailabilityComponent/>
          </div>
        </div>
      </div>)
    } else return (
      <div className={'PriceAndAvailabilityComponent MyAds'}>
        <div style={{justifyContent: "space-between"}}>
          <RentPriceComponent/>
          <AvailabilityComponent/>
          <UnavailabilitiesComponent/>
        </div>
      </div>)
  }

  const getRoomBestAmenity = (room) => {
    let amenitiesOrder = ['privateBathroom', 'balcony', 'kitchenette', 'window'];
    for (let i = 0; i < amenitiesOrder.length; i++) {
      if (room[amenitiesOrder[i]]) return t('main_page.my_ads.create_ad.room_details.room_details.' + amenitiesOrder[i] + '.desc')
    }
    return '';
  };

  const RoomResumeHeader = useCallback(() => {
    return (<div className={'RoomResume__main'}>
      <div className={'RoomResume__photo'}
           style={{backgroundImage: 'url(' + (property.accommodation === 'apartment' ? property.photos?.featured_0?.[0]?.md : room.photos?.featured?.md) + ')'}}/>
      <div className={'RoomResume__main-content'}>
        {property.accommodation === 'apartment' ?
          <div className={'RoomResume__info'}>
            <div>
              <label>{clampText(verifyAttributeExistenceToString(property, 'internal_name'), 60)}</label>
              <p
                className={'RoomResume__info-title'}>{t('up_to') + ' ' + property.people + ' ' + t('person', {count: parseInt(property.people)})} {room?.integration_id && (" | "+room.integration_id)}{room?.homii_number && (" | " + room.homii_number)}</p>
              <p
                className={'RoomResume__info-title'}>{property.bedrooms + ' ' + t('room', {count: parseInt(property.bedrooms)}) + ' | ' + property.numBathroom + ' ' + t('bathroom', {count: parseInt(property.numBathroom)})}</p>
              {!property.title
                ? <p
                  className={'RoomResume__info-title'}>{`${t('main_page.my_ads.create_ad.adjectives.' + (room.adjectives?.length > 0 ? room.adjectives[0] : 'new'))} ${t('accommodation_type.apartment').toLowerCase()} ${property.neighborhood !== 'Other' ? (t('near_by') + ' ' + property.neighborhood) : ''}`}</p>
                : <p className={'RoomResume__info-title'}>{property.title?.[locale]}</p>
              }
            </div>
          </div> :
          <div className={'RoomResume__info'}>
            <div>
              <label>{clampText(verifyAttributeExistenceToString(room, 'internalName'), 51)}
                <small> ({`${(property.accommodation === 'residence' && roomName?.split('_')?.[1] > property.bedroom_types) ? (t('studio') + ' ' + (roomName.split('_')[1] - property.bedroom_types)) : (t('room') + ' ' + roomName.split('_')[1])}`})</small></label>
              <p
                className={'RoomResume__info-title'}>{t('up_to') + ' ' + room.numPeople + ' ' + t('person', {count: parseInt(room.numPeople)})} {room.integration_id && (" | "+room.integration_id)}{room?.homii_number && (" | " + room.homii_number)}</p>
              <p className={'RoomResume__info-title'}>{getRoomBestAmenity(room)}</p>
              {!room.title
                ? <p
                  className={'RoomResume__info-title'}>{`${t('main_page.my_ads.create_ad.adjectives.' + (room.adjectives?.length > 0 ? room.adjectives[0] : 'new'))} ${t('room').toLowerCase()} ${property.neighborhood !== 'Other' ? (t('near_by') + ' ' + property.neighborhood) : ''}`}</p>
                : <p className={'RoomResume__info-title'}>{room.title?.[locale]}</p>
              }
            </div>
          </div>}
      </div>
    </div>)
  }, [])

  //Menu
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const menuRef = useRef(null);

  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  }

  if (type === 'Resume') {
    return (
      <div className={`RoomResume type_Resume`}>
        <RoomResumeHeader/>
        <div
          className={`RoomResume__complementary type_Resume ${property.accommodation === 'apartment' && roomName !== 'room_1' ? 'no_info' : ''}`}>
          {!(property.accommodation === 'apartment' && roomName !== 'room_1') && <PriceAndAvailabilityComponent/>}
        </div>
      </div>
    )
  }

  return (
    <div className={`RoomResume`} ref={roomRef}>
      <RoomResumeHeader/>
      <div
        className={`RoomResume__complementary ${property.accommodation === 'apartment' && roomName !== 'room_1' ? 'no_info' : ''}`}>
        {!(property.accommodation === 'apartment' && roomName !== 'room_1') && <PriceAndAvailabilityComponent/>}


        {type === 'My_Ads' &&
         <ResumeButtons
             onDelete={onDelete}
             property={property}
             changePage={(value) => changePage(value, roomNumber)}
             onEnable={onEnable}
             roomNumber={roomNumber}
             setOpen={setOpen}
             filter={props.filter}
             currentPage={currentPage}
         />}

        {type === 'My_Ads' && property.finished && props.filter !== 'disabled' &&
         <MUIMenu anchorEl={anchorRef} open={menuOpen} menuRef={menuRef} handleClick={handleClick}
                  handleClose={handleClose}>
           {/*<StyledMenuItem onClick={async () => {
             handleClose();
             await onDisable(roomNumber);
           }}>{t('property_resume.disable_ad')}</StyledMenuItem>*/}
           <StyledMenuItem zeropadding={"true"} onClick={(e) => {
             e.stopPropagation();
             handleClose();
           }}>
             <a target="_blank" className={"full_link"}
                href={propertyServices.generateStudentUrl(property.id, property.accommodation !== "apartment" && property.rooms[roomName].id)}>
               {t('property_resume.preview')}
             </a>
           </StyledMenuItem>
         </MUIMenu>}

      </div>
      <ActionDialog type={actionDialog} open={actionDialog} setOpen={setActionDialog}
                    title={t("action_dialog." + actionDialog)}
                    message={<Trans i18nKey={"action_dialog." + actionDialog + "-message"}
                                    values={{
                                      availability: moment(currentRoom?.firstAvailability || calcFirstAvailability(currentRoom)).locale(localStorage.getItem('i18nextLng').split('-')[0] || "en").format("DD MMM, YYYY")
                                    }}
                                    components={{blue: <span style={{color: "#57B4F2"}}/>}}
                      />
                    }/>
    </div>)
}

export default RoomResume;
