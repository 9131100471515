import * as React from "react";

function SvgGt(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M170.667 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h161.839v335.449z"
          fill="#82AFFF"
        />
        <path fill="#F5F5F5" d="M170.67.276h170.67v335.448H170.67z" />
        <path
          d="M503.172 335.724H341.333V.276h161.839A8.829 8.829 0 01512 9.104v317.793a8.828 8.828 0 01-8.828 8.827z"
          fill="#82AFFF"
        />
        <path fill="#FFC855" d="M253.773 124.11l-5.062 2.531 4.657 1.923z" />
        <g fill="#73AF00">
          <path d="M256 217.652c-28.135 0-51.023-22.89-51.023-51.023 0-17.478 8.797-33.548 23.531-42.989l1.31 2.046c-14.034 8.993-22.411 24.299-22.411 40.943 0 26.795 21.8 48.594 48.594 48.594l-.001 2.429z" />
          <path d="M226.798 220.836c1.944.396 4.943-.117 8.023-1.551 3.081-1.436 5.402-3.402 6.349-5.145-1.944-.396-4.943.117-8.023 1.551-3.079 1.436-5.401 3.402-6.349 5.145zm14.374-6.696c.396-1.944-.117-4.943-1.551-8.023-1.436-3.081-3.402-5.402-5.145-6.349-.396 1.944.117 4.943 1.551 8.023 1.435 3.079 3.401 5.401 5.145 6.349zm-24.539.456c1.865.678 4.907.61 8.164-.356 3.257-.967 5.843-2.572 7.036-4.157-1.865-.678-4.907-.61-8.164.356-3.257.967-5.843 2.572-7.036 4.157zm15.201-4.514c.678-1.865.61-4.907-.356-8.164-.968-3.258-2.572-5.843-4.157-7.036-.678 1.865-.61 4.907.356 8.164.967 3.258 2.572 5.843 4.157 7.036zm-24.603-3.994c1.713 1.001 4.717 1.482 8.096 1.116 3.379-.366 6.21-1.481 7.668-2.826-1.713-1.001-4.717-1.482-8.096-1.116-3.379.367-6.21 1.481-7.668 2.826zm15.764-1.711c1.001-1.713 1.482-4.717 1.116-8.096-.366-3.379-1.481-6.21-2.826-7.668-1.001 1.713-1.482 4.717-1.116 8.096.366 3.379 1.481 6.211 2.826 7.668zm-23.537-10.038c1.508 1.289 4.38 2.294 7.77 2.531 3.39.237 6.375-.358 8.047-1.423-1.508-1.289-4.38-2.294-7.77-2.531-3.39-.237-6.374.357-8.047 1.423zm15.818 1.108c1.289-1.508 2.294-4.38 2.531-7.77.237-3.39-.358-6.375-1.423-8.047-1.289 1.508-2.294 4.38-2.531 7.77-.238 3.39.357 6.374 1.423 8.047zm-20.368-14.05c1.204 1.577 3.799 3.164 7.064 4.109 3.265.945 6.306.991 8.167.301-1.204-1.577-3.799-3.164-7.064-4.109-3.265-.945-6.307-.991-8.167-.301z" />
          <path d="M210.138 185.807c1.577-1.204 3.164-3.799 4.109-7.064.946-3.265.991-6.306.301-8.167-1.577 1.204-3.164 3.799-4.109 7.064-.945 3.266-.99 6.307-.301 8.167zm-17.156-19.521c.758 1.834 2.858 4.034 5.77 5.788 2.912 1.753 5.839 2.58 7.815 2.391-.758-1.834-2.858-4.034-5.77-5.788-2.911-1.753-5.84-2.579-7.815-2.391zm13.585 8.179c1.834-.758 4.034-2.858 5.788-5.77 1.753-2.912 2.58-5.839 2.391-7.815-1.834.758-4.034 2.858-5.788 5.77-1.752 2.912-2.58 5.84-2.391 7.815zm-11.76-22.446c.356 1.952 1.949 4.544 4.428 6.869s5.17 3.746 7.139 3.977c-.356-1.952-1.949-4.544-4.428-6.869s-5.168-3.747-7.139-3.977zm11.569 10.846c1.952-.356 4.544-1.949 6.869-4.428s3.746-5.17 3.977-7.139c-1.952.356-4.544 1.949-6.869 4.428s-3.748 5.168-3.977 7.139zm-4.401-26.283c-.408 1.941.085 4.943 1.501 8.033s3.368 5.423 5.105 6.382c.408-1.941-.085-4.943-1.501-8.033-1.416-3.091-3.368-5.425-5.105-6.382z" />
          <path d="M208.58 150.997c1.941.408 4.943-.085 8.033-1.501s5.423-3.368 6.382-5.105c-1.941-.408-4.943.085-8.033 1.501s-5.424 3.368-6.382 5.105zm1.966-25.848c-.915 1.76-1.247 4.785-.714 8.141.533 3.357 1.785 6.13 3.201 7.519.915-1.76 1.247-4.785.714-8.141-.533-3.357-1.786-6.129-3.201-7.519zm2.488 15.66c1.76.915 4.785 1.247 8.141.714 3.357-.533 6.13-1.785 7.519-3.201-1.76-.915-4.785-1.247-8.141-.714-3.357.533-6.13 1.785-7.519 3.201zm5.917-23.617c-1.164 1.607-1.938 4.548-1.905 7.947.033 3.399.864 6.325 2.059 7.908 1.164-1.607 1.938-4.548 1.905-7.947-.033-3.398-.863-6.326-2.059-7.908zm.154 15.855c1.607 1.164 4.548 1.938 7.947 1.905 3.399-.033 6.325-.864 7.908-2.059-1.607-1.164-4.548-1.938-7.947-1.905-3.398.033-6.324.864-7.908 2.059zm4.078-4.169c1.977.16 4.894-.707 7.78-2.502 2.887-1.794 4.956-4.024 5.688-5.868-1.977-.16-4.894.707-7.78 2.502-2.886 1.794-4.955 4.024-5.688 5.868zM256 217.652v-2.43c26.794 0 48.594-21.799 48.594-48.594 0-16.644-8.378-31.951-22.411-40.943l1.31-2.046c14.734 9.441 23.531 25.512 23.531 42.989-.001 28.134-22.889 51.024-51.024 51.024z" />
          <path d="M285.202 220.836c-1.944.396-4.943-.117-8.023-1.551-3.081-1.436-5.402-3.402-6.349-5.145 1.944-.396 4.943.117 8.023 1.551 3.079 1.436 5.401 3.402 6.349 5.145zm-14.374-6.696c-.396-1.944.117-4.943 1.551-8.023 1.436-3.081 3.402-5.402 5.145-6.349.396 1.944-.117 4.943-1.551 8.023-1.435 3.079-3.401 5.401-5.145 6.349zm24.539.456c-1.865.678-4.907.61-8.164-.356-3.257-.967-5.843-2.572-7.036-4.157 1.865-.678 4.907-.61 8.164.356 3.257.967 5.843 2.572 7.036 4.157zm-15.202-4.514c-.677-1.865-.61-4.907.356-8.164.968-3.258 2.572-5.843 4.157-7.036.678 1.865.61 4.907-.356 8.164-.966 3.258-2.571 5.843-4.157 7.036zm24.604-3.994c-1.713 1.001-4.717 1.482-8.096 1.116-3.379-.366-6.21-1.481-7.668-2.826 1.713-1.001 4.717-1.482 8.096-1.116 3.379.367 6.21 1.481 7.668 2.826zm-15.764-1.711c-1.001-1.713-1.482-4.717-1.116-8.096.366-3.379 1.481-6.21 2.826-7.668 1.001 1.713 1.482 4.717 1.116 8.096-.366 3.379-1.481 6.211-2.826 7.668zm23.537-10.038c-1.508 1.289-4.38 2.294-7.77 2.531-3.39.237-6.374-.358-8.047-1.423 1.508-1.289 4.38-2.294 7.77-2.531 3.39-.237 6.374.357 8.047 1.423zm-15.818 1.108c-1.289-1.508-2.294-4.38-2.531-7.77-.237-3.39.358-6.375 1.423-8.047 1.289 1.508 2.294 4.38 2.531 7.77.238 3.391-.357 6.374-1.423 8.047zm20.368-14.05c-1.204 1.577-3.799 3.164-7.064 4.109-3.264.946-6.306.991-8.167.301 1.204-1.577 3.799-3.164 7.064-4.109 3.265-.945 6.307-.991 8.167-.301z" />
          <path d="M301.862 185.807c-1.577-1.204-3.164-3.799-4.109-7.064-.946-3.265-.991-6.306-.301-8.167 1.577 1.204 3.164 3.799 4.109 7.064.945 3.266.99 6.307.301 8.167zm17.156-19.521c-.758 1.834-2.858 4.034-5.77 5.788-2.912 1.753-5.839 2.58-7.815 2.391.758-1.834 2.858-4.034 5.77-5.788 2.911-1.753 5.84-2.579 7.815-2.391zm-13.585 8.179c-1.834-.758-4.034-2.858-5.788-5.77-1.753-2.912-2.58-5.839-2.391-7.815 1.834.758 4.034 2.858 5.788 5.77 1.754 2.912 2.58 5.84 2.391 7.815zm11.76-22.446c-.356 1.952-1.949 4.544-4.428 6.869s-5.17 3.746-7.139 3.977c.356-1.952 1.949-4.544 4.428-6.869s5.168-3.747 7.139-3.977zm-11.569 10.846c-1.952-.356-4.544-1.949-6.869-4.428s-3.746-5.17-3.977-7.139c1.952.356 4.544 1.949 6.869 4.428s3.748 5.168 3.977 7.139zm4.401-26.283c.408 1.941-.085 4.943-1.501 8.033s-3.368 5.423-5.105 6.382c-.408-1.941.085-4.943 1.501-8.033 1.416-3.091 3.368-5.425 5.105-6.382z" />
          <path d="M303.42 150.997c-1.941.408-4.943-.085-8.033-1.501s-5.423-3.368-6.382-5.105c1.941-.408 4.943.085 8.033 1.501s5.424 3.368 6.382 5.105zm-1.966-25.848c.915 1.76 1.247 4.785.714 8.141-.533 3.357-1.785 6.13-3.201 7.519-.915-1.76-1.247-4.785-.714-8.141.533-3.356 1.786-6.129 3.201-7.519zm-2.488 15.66c-1.76.915-4.785 1.247-8.141.714-3.357-.533-6.13-1.785-7.519-3.201 1.76-.915 4.785-1.247 8.141-.714 3.357.533 6.13 1.785 7.519 3.201zm-5.917-23.617c1.164 1.607 1.938 4.548 1.905 7.947-.033 3.399-.864 6.325-2.059 7.908-1.164-1.607-1.938-4.548-1.905-7.947.033-3.398.863-6.326 2.059-7.908z" />
          <path d="M292.895 133.047c-1.607 1.164-4.548 1.938-7.947 1.905-3.399-.033-6.325-.864-7.908-2.059 1.607-1.164 4.548-1.938 7.947-1.905 3.399.033 6.324.864 7.908 2.059zm-4.078-4.169c-1.977.16-4.894-.707-7.78-2.502-2.887-1.794-4.956-4.024-5.688-5.868 1.977-.16 4.894.707 7.78 2.502 2.886 1.794 4.955 4.024 5.688 5.868z" />
        </g>
        <path
          d="M228.146 206.351l-8.844 8.844-4.361-8.723 13.084-13.084 4.361 4.361-1.26 3.779a12.33 12.33 0 01-2.98 4.823z"
          fill="#966446"
        />
        <path
          d="M275.997 141.056l-47.972 47.972a3.084 3.084 0 000 4.361l2.18 2.18 8.723-8.723 34.889-39.251 4.361-4.361-2.181-2.178z"
          fill="#B9B9B9"
        />
        <path
          d="M239.83 181.583a3.086 3.086 0 00-.904 2.18v3.084l34.889-39.251-33.985 33.987z"
          fill="#966446"
        />
        <path
          fill="#A0A0A0"
          d="M278.177 143.236l-2.18-2.18-2.181 2.18 2.181 2.182 2.18 2.18 23.987-28.347z"
        />
        <path
          d="M286.284 206.351l8.844 8.844 4.361-8.723-13.084-13.084-4.361 4.361 1.26 3.779a12.33 12.33 0 002.98 4.823z"
          fill="#966446"
        />
        <path
          d="M238.433 141.056l47.972 47.972a3.084 3.084 0 010 4.361l-2.18 2.18-8.723-8.723-34.889-39.251-4.361-4.361 2.181-2.178z"
          fill="#B9B9B9"
        />
        <path
          d="M274.6 181.583c.578.578.904 1.363.904 2.18v3.084l-34.889-39.251 33.985 33.987z"
          fill="#966446"
        />
        <path
          fill="#A0A0A0"
          d="M236.253 143.236l2.18-2.18 2.181 2.18-2.181 2.182-2.18 2.18-23.987-28.347z"
        />
        <path
          d="M239.315 204.537l2.167 1.976c7.906-8.668 21.741-23.837 43.673-43.529.001.001-18.169 11.216-45.84 41.553z"
          fill="#B9B9B9"
        />
        <path
          d="M233.184 213.434a1.908 1.908 0 01-.124-2.694l4.644-5.092a1.908 1.908 0 012.818 2.57l-4.644 5.092a1.906 1.906 0 01-2.694.124z"
          fill="#FFE15A"
        />
        <path
          d="M237.556 202.933l-1.258.561c-1.119.557-4.063 2.222-4.57 4.595-.242 1.124.099 2.219.972 3.255.083-.214.196-.422.361-.601l.585-.641c-.395-.574-.605-1.138-.486-1.704.318-1.484 2.298-2.809 3.542-3.466l5.564 5.075 1.384-1.517-6.094-5.557z"
          fill="#FFC855"
        />
        <path
          d="M275.093 206.936l-2.167 1.976c-7.906-8.668-21.741-23.837-43.673-43.529-.001.001 18.169 11.216 45.84 41.553z"
          fill="#B9B9B9"
        />
        <path
          d="M281.224 215.835c.778-.71.833-1.916.124-2.694l-4.644-5.092a1.908 1.908 0 00-2.818 2.57l4.644 5.092c.71.778 1.916.833 2.694.124z"
          fill="#FFE15A"
        />
        <path
          d="M276.852 205.332l1.258.561c1.119.557 4.063 2.222 4.57 4.595.242 1.124-.099 2.219-.972 3.255a1.882 1.882 0 00-.361-.601l-.585-.641c.395-.574.605-1.138.486-1.704-.318-1.484-2.298-2.809-3.542-3.466l-5.564 5.075-1.384-1.517 6.094-5.557z"
          fill="#FFC855"
        />
        <path
          d="M258.161 126.686a3.63 3.63 0 00.269-1.362 3.645 3.645 0 00-7.29 0 3.63 3.63 0 001.482 2.919c-1.318 10.585 9.119 11.394 13.096 12.874 6.034 2.244 13.603 6.787 13.127 19.484-.462 12.342-8.267 24.251-8.267 24.251l6.479-8.808c-2.369 10.02-7.901 22.784-21.057 37.963 0 0 8.995-8.799 16.501-20.146-5.523 12.993-11.642 25.005-11.642 25.005s10.833-14.072 19.59-38.419c10.616-29.517-11.716-47.251-22.288-53.761z"
          fill="#69B469"
        />
        <path fill="#F4E08F" d="M238.996 170.273h9.719v4.86h-9.719z" />
        <g transform="translate(234 138)" fill="#ECD077">
          <circle cx={4.996} cy={37.128} r={4.86} />
          <circle cx={4.996} cy={5.548} r={4.86} />
        </g>
        <path
          d="M268.148 148.406a4.86 4.86 0 000-9.72h-29.156a4.86 4.86 0 11-4.86 4.86c0 8.157 6.826 18.019 9.027 26.727 0 0 .692 2.176.692 4.86a4.86 4.86 0 01-4.86 4.86h29.156a4.86 4.86 0 004.86-4.86c0-8.157-6.826-18.019-9.027-26.727h4.168z"
          fill="#FCF0A8"
        />
        <path
          d="M243.852 143.548a4.86 4.86 0 01-4.86 4.86h29.156a4.86 4.86 0 004.86-4.86h-29.156zm0 31.585a4.86 4.86 0 01-4.86 4.86h29.156a4.86 4.86 0 004.86-4.86h-29.156z"
          fill="#F4E08F"
        />
      </g>
    </svg>
  );
}

export default SvgGt;
