import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import './style.less';
import Buttons from '../../../../../components/Buttons';
import {useForm, useWatch} from 'react-hook-form';
import StyledSwitch from '../../../../../components/StyledSwitch';
import RadioButtons from '../../../../../components/RadioButtons';
import {FieldSetCheckBoxes} from '../../../../../components/FieldSetCheckBoxes';
import PriceComponent from '../../../../../components/PriceComponent';
import {updatePropertyActions} from "../../../../../../../../../../../redux/actions/updatePropertyActions";

const pageLabel = 'main_page.my_ads.create_ad.house_details.landlord_rules.level2';

const SwitchContainer = ({children, value, setValue, condition, id, label, className}) => {

  return (
    <div className={className}>
      <div className="SwitchContainer">
        <div className="SwitchContainer-label">
          <label htmlFor={id}>{label}</label>
        </div>
        <StyledSwitch id={id} onChange={(value) => setValue(value)} checked={value}/>
      </div>
      {condition && (children)}
    </div>)
};

const LandlordRules2 = ({updateProperty, t, currentLevel, property, changePage, defineUpdateFunc}) => {
  const onSubmit = async (values) => {
    if(values.overnightGuests !== "occasionallyWithCost") {
      values.overnightGuestsCost = 0;
    }
    values.overnightGuests= values.overnightGuests !== 'no';

    if (currentProperty.landlordLives) {
      values.landlordWorker = currentProperty.landlordProfile.includes('landlordWorker');
      values.landlordStudent = currentProperty.landlordProfile.includes('landlordStudent');
    }

    if (values.acceptWorkersAndStudents) {
      values[values.acceptWorkersAndStudents] = true;
    } else {
      values.onlyWorkers = false;
      values.onlyStudents = false;
    }

    if (values.acceptBothGenders) {
      values[values.acceptBothGenders] = true;
    } else {
      values.onlyFemales = false;
      values.onlyMales = false;
    }

    values.pets=values.pets==='true';
    values.smoking=values.smoking==='true';
    values.smokingBalcony=values.smokingBalcony==='true';
    values.landlordAlone=values.landlordAlone==='true';
    values.landlordPets=values.landlordPets==='true';
    values.landlordSmoker=values.landlordSmoker==='true';

    values.landlordLives = currentProperty.landlordLives;

    await updateProperty(values, currentLevel);
  };

  //Initial values
  const [currentProperty, setCurrentProperty] = useState(() => {
    return {
      ...property,
      //Landlord Profile
      landlordLives: property.hasOwnProperty('landlordLives') ? property.landlordLives : false,
      landlordAlone: property.hasOwnProperty('landlordAlone') ? property.landlordAlone.toString() : 'false',
      landlordGender: property.landlordGender ? property.landlordGender : 'male',
      landlordProfile: [property.landlordWorker === 'true' ? 'landlordWorker' : undefined
        , property.landlordStudent === 'true' ? 'landlordStudent' : undefined],
      landlordAgeGroup: property.landlordAgeGroup ? property.landlordAgeGroup : 'young',
      landlordSmoker: property.hasOwnProperty('landlordSmoker') ? property.landlordSmoker.toString() : 'true',
      landlordPets: property.hasOwnProperty('landlordPets') ? property.landlordPets.toString() : 'true',

      acceptBothGendersSwitch: (!(property.onlyMales || property.onlyFemales)),
      acceptBothGenders: property.onlyFemales ? 'onlyFemales' : 'onlyMales',

      acceptWorkersAndStudentsSwitch: (!(property.onlyWorkers || property.onlyStudents)),
      acceptWorkersAndStudents: property.onlyStudents ? 'onlyStudents' : 'onlyWorkers',

      overnightGuests: property.hasOwnProperty('overnightGuests') ? ((property.overnightGuests && property.overnightGuestsCost) ? 'occasionallyWithCost' : (property.overnightGuests ? 'yes' : 'no')) : 'yes',

      pets: property.hasOwnProperty('pets') ? property.pets.toString() : 'false',

      smoking: property.hasOwnProperty('smoking') ? property.smoking.toString() : 'false',
      smokingBalcony: property.hasOwnProperty('smokingBalcony') ? property.smokingBalcony.toString() : 'false'
    }
  });

  const handlePropertyFieldChange = (field, value) => {
    setCurrentProperty(prevState => ({...prevState, [field]: value}))
  };

  const {handleSubmit, register, errors, control, watch, formState} = useForm({shouldFocusError: true});

  useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
    if(!formState.isDirty) return resolve();
    await handleSubmit(async (values) => {
      await onSubmit(values);
      resolve()
    }, resolve)()
  })), [currentProperty, formState.isDirty]);

  const radioButtons = {
    //landlordLives
    landlordLives: [{
      id: 'landlord_lives',
      buttonValue: 'false',
      label: pageLabel + '.landlordLives.landlord_lives_with'
    },
      {id: 'landlord_dont_lives', buttonValue: 'true', label: pageLabel + '.landlordLives.landlordAlone'}],
    //landlordGender
    landlordGender: [
      {id: 'landlord_male', buttonValue: 'male', label: 'male'},
      {id: 'landlord_female', buttonValue: 'female', label: 'female'}],
    //landlordSmoker
    landlordSmoker: [
      {id: 'landlord_smoker', buttonValue: 'true', label: 'yes'},
      {id: 'landlord_non_smoker', buttonValue: 'false', label: 'no'}],
    //landlordPets
    landlordPets: [
      {id: 'landlord_pets', buttonValue: 'true', label: 'yes'},
      {id: 'landlord_no_pets', buttonValue: 'false', label: 'no'}],
    //bothGenders
    acceptBothGenders: [
      {id: 'just_males', buttonValue: 'onlyMales', label: pageLabel + '.accept_both_genders.onlyMales'},
      {id: 'just_females', buttonValue: 'onlyFemales', label: pageLabel + '.accept_both_genders.onlyFemales'}],
    //acceptWorkersStudents
    acceptWorkersStudents: [
      {id: 'just_workers', buttonValue: 'onlyWorkers', label: pageLabel + '.accept_studens_workers.onlyWorkers'},
      {id: 'onlyStudents', buttonValue: 'onlyStudents', label: pageLabel + '.accept_studens_workers.onlyStudents'}],
    //acceptOvernightGuest
    overnightGuests: [
      {id: 'guests_yes', buttonValue: 'yes', label: 'yes'},
      {id: 'guests_no', buttonValue: 'no', label: 'no'},
      {
        id: 'guest_with_cost',
        buttonValue: 'occasionallyWithCost',
        label: pageLabel + '.overnightGuests.ocasionallyWithCosts'
      }],
    //pets
    pets: [
      {id: 'pets', buttonValue: 'true', label: 'yes'},
      {id: 'no_pets', buttonValue: 'false', label: 'no'}],
    //smoking
    smoking: [
      {id: 'smoking', buttonValue: 'true', label: 'yes'},
      {id: 'no_smoking', buttonValue: 'false', label: 'no'}],
    //smokingBalcony
    smokingBalcony: [
      {id: 'no_balcony', buttonValue: 'false', label: pageLabel + '.smokingBalcony.false'},
      {id: 'balcony', buttonValue: 'true', label: pageLabel + '.smokingBalcony.true'}],
    //landlordAgeGroupOptions
    landlordAgeGroup: [
      {id: 'young', buttonValue: 'young', label: pageLabel + '.landlordAgeGroup.young'},
      {id: 'youngadult', buttonValue: 'youngadult', label: pageLabel + '.landlordAgeGroup.youngadult'},
      {id: 'adult', buttonValue: 'adult', label: pageLabel + '.landlordAgeGroup.adult'},
      {id: 'old', buttonValue: 'old', label: pageLabel + '.landlordAgeGroup.old'}]
  };

  const landlordProfileOptions = ['landlordWorker', 'landlordStudent'];

  const watchFields = watch(['overnightGuests', 'smoking', 'overnightGuestsCost']);

  return (
    <div className={'LandlordRules2'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'scrollable'}>
          <SwitchContainer
            className={'LandlordRules_container'}
            value={currentProperty.landlordLives}
            setValue={(value) => handlePropertyFieldChange('landlordLives', value)}
            condition={currentProperty.landlordLives} id={'landlordLives'} label={t(pageLabel + '.landlordLives.label')}
          >
            <div>
              <div className={'input_container dashed'}>
                <RadioButtons radioButtons={radioButtons.landlordLives} name={'landlordAlone'}
                              value={currentProperty.landlordAlone} t={t} className={'double'}
                              control={control} rules={{required: true}}/>
              </div>

              <div className={'input_container'}>
                <label htmlFor={'landlordGender'}>{`${t(pageLabel + '.landlordGender.label')}`}</label>
                <RadioButtons radioButtons={radioButtons.landlordGender} name={'landlordGender'}
                              value={currentProperty.landlordGender} t={t} className={'double margin_bottom'}
                              control={control} rules={{required: true}}/>
                <FieldSetCheckBoxes checkBoxesNames={landlordProfileOptions} name={'landlordProfile'}
                                    values={currentProperty.landlordProfile}
                                    type={'transparent two-in-line'}
                                    setValues={(value) => handlePropertyFieldChange('landlordProfile', value)}
                                    register={register} amountInLine={'two-in-line'}
                                    defaultObjectLabel={(value) => `${pageLabel}.${value}.label`}/>
              </div>

              <div className={'input_container  dashed'}>
                <label htmlFor={'landlordAgeGroup'}>{`${t(pageLabel + '.landlordAgeGroup.label')}`}</label>
                <RadioButtons radioButtons={radioButtons.landlordAgeGroup} name={'landlordAgeGroup'}
                              value={currentProperty.landlordAgeGroup} t={t} className={'double'}
                              control={control} rules={{required: true}}/>
              </div>


              <div className={'input_container'}>
                <label htmlFor={'landlordSmoker'}>{`${t(pageLabel + '.landlordSmoker.label')}`}</label>
                <RadioButtons radioButtons={radioButtons.landlordSmoker} name={'landlordSmoker'}
                              value={currentProperty.landlordSmoker} t={t} className={'double'}
                              control={control} rules={{required: true}}/>
              </div>

              <div className={'input_container'}>
                <label htmlFor={'landlordPets'}>{`${t(pageLabel + '.landlordPets.label')}`}</label>
                <RadioButtons radioButtons={radioButtons.landlordPets} name={'landlordPets'}
                              value={currentProperty.landlordPets} t={t} className={'double'} control={control}
                              rules={{required: true}}/>
              </div>
            </div>
          </SwitchContainer>

          <div className="LandlordRules_container">
            <SwitchContainer value={currentProperty.acceptBothGendersSwitch}
                             setValue={(value) => handlePropertyFieldChange('acceptBothGendersSwitch', value)}
                             condition={!currentProperty.acceptBothGendersSwitch} id={'acceptsBothGenders'}
                             label={t(pageLabel + '.accept_both_genders.label')}
                             className={'input_container'} register={register}>
              <RadioButtons radioButtons={radioButtons.acceptBothGenders} name={'acceptBothGenders'}
                            value={currentProperty.acceptBothGenders} t={t} className={'double'}
                            control={control} rules={{required: true}}/>
            </SwitchContainer>

            <SwitchContainer value={currentProperty.acceptWorkersAndStudentsSwitch}
                             setValue={(value) => handlePropertyFieldChange('acceptWorkersAndStudentsSwitch', value)}
                             condition={!currentProperty.acceptWorkersAndStudentsSwitch}
                             id={'acceptsWorkersAndStudents'}
                             label={t(pageLabel + '.accept_studens_workers.label')}
                             className={'input_container'}>
              <RadioButtons radioButtons={radioButtons.acceptWorkersStudents} name={'acceptWorkersAndStudents'}
                            value={currentProperty.acceptWorkersAndStudents} t={t} className={'double'}
                            control={control} rules={{required: true}}/>
            </SwitchContainer>
          </div>

          <div className="LandlordRules_container">
            <div className={'input_container'}>
              <label htmlFor={'overnightGuests'}>{`${t(pageLabel + '.overnightGuests.label')}`}</label>
              <RadioButtons radioButtons={radioButtons.overnightGuests} name={'overnightGuests'}
                            value={currentProperty.overnightGuests}
                            setValue={handlePropertyFieldChange} t={t} className={'double'}
                            control={control} rules={{required: true}}/>
              {watchFields.overnightGuests === 'occasionallyWithCost' &&
               <PriceComponent
                 name="overnightGuestsCost"
                 value={currentProperty.overnightGuestsCost}
                 error={errors.overnightGuestsCost}
                 watchValue={watchFields.overnightGuestsCost}
                 register={register}
                 rules={{
                   required: {value: true, message: t(pageLabel + '.overnightGuests.error')},
                   valueAsNumber: true
                 }}/>}
            </div>
          </div>

          <div className="LandlordRules_container LandlordRules_container_last">
            <div className={'input_container'}>
              <label htmlFor={'pets'}>{`${t(pageLabel + '.pets.label')}`}</label>
              <RadioButtons radioButtons={radioButtons.pets} name={'pets'} value={currentProperty.pets}
                            t={t} className={'double'} control={control} rules={{required: true}}/>
            </div>


            <div className={'input_container'}>
              <label htmlFor={'smoking'}>{`${t(pageLabel + '.smoking.label')}`}</label>
              <RadioButtons radioButtons={radioButtons.smoking} name={'smoking'}
                            value={currentProperty.smoking}
                            t={t} className={'double'} control={control} rules={{required: true}}
                            setValue={handlePropertyFieldChange}/>
              {watchFields.smoking === 'true' &&
               <RadioButtons id={'smokingBalcony'} radioButtons={radioButtons.smokingBalcony} name={'smokingBalcony'}
                             value={currentProperty.smokingBalcony} t={t}
                             className={'double second_input_container'} control={control} rules={{required: true}}/>}
            </div>
          </div>
        </div>

        <Buttons saveAndExit={handleSubmit(v=>onSubmit({...v, saveAndExit: true}))} changePage={changePage}/>
      </form>
    </div>
  );
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(LandlordRules2);
