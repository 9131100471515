import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {connect} from 'react-redux';

import './style.less'
import Ad from '../../pages/MainPage/containers/MyAds/containers/Ad';
import {useTranslation} from 'react-i18next';
import isAreaNotUnique from "../../pages/MainPage/containers/MyAds/containers/Ad/functions/isAreaNotUnique";
import Loading from '../Loading';
import getAreasNamesFromNums from "../../pages/MainPage/containers/MyAds/containers/Ad/functions/getAreasNamesFromNums";
import {DialogTitle} from '../MUIDialogComponents';


const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    borderTop: "1px solid #ededed",
    zIndex: 2
  }
}))(MuiDialogActions);

const DialogNav = ({editLevel, setEditLevel, open, setOpen, property, saveChanges}) => {

  const {t} = useTranslation();

  const isPropertyAvailable = (property) => {
    if (property.accommodation === 'apartment' && property.disabled) return !property.disabled
    if (property.accommodation === 'apartment') return !property.rooms?.['room_1']?.disabled
    return Object.values(property.rooms || {}).some(room => !room.disabled);
  }

  let propertyNavigation =
    ['1.1.3', '1.1.2', '1.2.1', '1.2.2', '1.3.1', '1.3.2', '1.4'];

  if(property.accommodation === "apartment") {
    propertyNavigation = [...propertyNavigation, "2.4", "rooms"];
  }
  let roomNavigation = ['2.4', '2.2', '2.3', '2.1'];
  if (isPropertyAvailable(property)){
    propertyNavigation = [...propertyNavigation, "advanced", '1.1.5'];
    roomNavigation = [...roomNavigation, 'advanced', '1.1.5'];
  }
  const rulesNavigation = ['1.2.2', '1.2.3', '1.2.4', '1.2.5'];
  const propertyCommonAreas = ["editAreas", ...getAreasNamesFromNums(property, true)];

  const mainShortcutActive = (navLabel) => {
    return editLevel === navLabel ||
        (navLabel === "1.2.2" && rulesNavigation.includes(editLevel)) ||
        (navLabel === "1.3.1" && editLevel==="1.5") ||
        (navLabel === "rooms" && ["2.1", "2.2", "2.3"].includes(editLevel))
  }

  return (
    <div className={'DialogNav'}>
      <div className={'DialogNav__container'}>
        {(open.isRoom ? roomNavigation : propertyNavigation).map((navLabel) =>
          (<div key={navLabel}
                className={`DialogNav__shortcut ${mainShortcutActive(navLabel)?'active':''}`}
                onClick={async (e) => {
            if(!await saveChanges()) {
              if (navLabel === "rooms") {
                setEditLevel("2.2");
                setOpen({...open, room_id: 1});
              } else {
                setEditLevel(navLabel);
              }
            }
          }}>
            <label>{t('main_page.my_ads.modal_edit.shortcuts.' + navLabel.replace(/\./g, '_'))}</label>
          </div>))
        }
      </div>
      {!open.isRoom && <div className={'DialogNav__container'}>
        {(editLevel === "1.3.1" || editLevel === "1.5") ? (propertyCommonAreas.map((sectionName, idx) => {
          if(sectionName === "editAreas") {
            return <div key={sectionName} className={`DialogNav__shortcut ${editLevel==="1.3.1"?'active':''}`} onClick={async (e) => {
              if(!await saveChanges()) {
                setEditLevel("1.3.1");
              }
            }}>
              <label>{t('main_page.my_ads.modal_edit.shortcuts.editAreas')}</label>
            </div>
          }
          let sectionSplit = sectionName.split('_');
          let sectionTrans = t(`main_page.my_ads.create_ad.house_details.common_areas_details.${sectionSplit[0]}.label_nav`) + (sectionSplit.length === 2 && isAreaNotUnique(sectionName, property) ? ' ' + (+sectionSplit[1] + 1) : '');
          return (<div key={sectionName}
                       className={`DialogNav__shortcut ${editLevel==="1.5"&&open.room_id===idx?'active':''}`}
                       onClick={async () => {
                         if(!await saveChanges()) {
                           if (editLevel !== "1.5") setEditLevel("1.5");
                           setOpen({...open, room_id: idx});
                         }
                       }}>
                <label>{sectionTrans}</label>
          </div>)
        })) : ((editLevel === "1.2.2" || rulesNavigation.includes(editLevel)) ? rulesNavigation.map(navLabel =>
            (<div key={navLabel} className={`DialogNav__shortcut ${editLevel===navLabel?'active':''}`} onClick={async (e) => {
              if(!await saveChanges()) {
                setEditLevel(navLabel);
              }
            }}>
              <label>{t('main_page.my_ads.modal_edit.shortcuts.' + (navLabel === "1.2.2" ? "generalRules" : navLabel.replace(/\./g, '_')))}</label>
            </div>)
        ) : (["2.1", "2.2", "2.3"].includes(editLevel) && <>{Object.keys(property.rooms).map(room_id => {
              let room_num = parseInt(room_id?.split("_")?.[1]);
              return <div key={room_id} className={`DialogNav__shortcut ${open.room_id === room_num ? 'active' : ''}`} onClick={async (e) => {
                if (!await saveChanges()) {
                  if (editLevel !== "2.2") setEditLevel("2.2");
                  setOpen({...open, room_id: room_num});
                }
              }}>
                <label>{t('room') + " " + room_num}</label>
                <div className="shortcut-sub-container">
                  {["2.2", "2.3"].map(roomLevel => (
                    <div key={roomLevel}
                         className={"shortcut-sub" + (editLevel === roomLevel ? ' active' : '')}
                         onClick={async () => {
                           if (!await saveChanges()) {
                             if (roomLevel !== editLevel) setEditLevel(roomLevel);
                           }
                         }}>
                      <label>{t("main_page.my_ads.modal_edit.shortcuts." + roomLevel.replace(/\./g, '_'))}</label>
                    </div>
                  ))}
                </div>
              </div>
            }
        )}<div className={`DialogNav__shortcut ${editLevel === "2.1" ? 'active' : ''}`} onClick={async (e) => {
          if (!await saveChanges()) {
            if (editLevel !== "2.1") setEditLevel("2.1");
          }
        }}>
          <label>{t('photo_plural')}</label>
        </div></>))
        }
      </div>}
    </div>
  )
}

function EditAdDialog({open, setOpen, updatePropertyFunction, updatePropertyList}) {

  const {t} = useTranslation();

  const [editLevel, setEditLevel] = useState('1.1.3');
  const [property, setProperty] = useState(null);
  const [loading,setLoading] = useState(false);

  const handleClose = () => {
    setOpen({});
    setProperty(null);
  };

  const isDoubleNav = () => {
    return !open.isRoom &&
        ['1.2.2', '1.2.3', '1.2.4', '1.2.5', '1.3.1', '2.2', '2.3', '2.1'].includes(editLevel);
  }

  useEffect(() => {
    setEditLevel(open.isRoom?'2.4':'1.1.3');
  },[open.isRoom]);

  useEffect(() => {
    if(open.property_id) {
      document.documentElement.oldScrollTop = document.documentElement.scrollTop;
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = null;
    }
  }, [open.property_id])

  const saveChanges = async(close) => {
    setLoading(true);
    const aux = await updatePropertyFunction.updateProperty();
    setLoading(false);
    if(close && !aux) {
      updatePropertyList(open.property_id);
      handleClose();
    }
    return aux;
  };

  //useEffect(() => {if(open.editLevel)setEditLevel(open.editLevel)},[open.editLevel])
  let title_t = 'editing';
  if(open.isRoom && property) {
    title_t += (property.accommodation === 'residence' && open.room_id > property.bedroom_types) ? '_studio' : '_room';
  }
  useEffect(() => {
    setHandleClose(handleClose);
    setUpdatePropertyList(updatePropertyList);
  }, []);

  return (
    <div>
      <Dialog onClose={async() => await saveChanges(true)} aria-labelledby="customized-dialog-title" open={!!open.property_id}>
        {property && <DialogTitle className="DialogHeader" onClose={handleClose}>
          <h4 className="DialogTitle">{t(title_t)} <span>{(open.isRoom?property.rooms["room_" + open.room_id]?.internalName:property.internal_name)||t('tbd')}</span></h4>
          <DialogNav editLevel={editLevel} setEditLevel={setEditLevel} open={open} setOpen={setOpen} property={property} saveChanges={async() => await saveChanges(false)}/>
        </DialogTitle>}
        <div className={"DialogContent" + (isDoubleNav() ? ' double-nav' : '')}>
          {open.property_id && <Ad property_id={open.property_id} simple={true} setProperty={setProperty} editLevel={editLevel} editRoom={open.room_id}/>}
          {loading && <Loading/>}
        </div>
        {property && <DialogActions>
          <button type={"button"} onClick={handleClose} className={"btn btn_white"}>
            {t('buttons.cancel')}
          </button>
          <button type={"button"} onClick={async() => await saveChanges(false)} className={"btn btn_white"}>
            {t('buttons.apply')}
          </button>
          <button type={"button"} onClick={async() => await saveChanges(true)} className={"btn btn_blue"}>
            {t('buttons.save_exit')}
          </button>
        </DialogActions>}
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    updatePropertyFunction: state.updatePropertyFunction,
  };
}

let handleClose;

export function getHandleClose() {
  handleClose();
}

export function setHandleClose(handle) {
  handleClose = handle;
}

let updatePropertyList;

export function getUpdatePropertyList() {
  updatePropertyList();
}

export function setUpdatePropertyList(propertyListFunction) {
  updatePropertyList = propertyListFunction;
}

export default connect(mapStateToProps, null)(EditAdDialog);
