import React from "react";

const Electricity = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#F0F3F6">
            <path d="M7.763 1.052c-2.874 0-5.21 2.372-5.21 5.29a5.34 5.34 0 0 0 .813 2.843 5.25 5.25 0 0 0 1.991 1.854l.253.963a.3.3 0 0 0 .29.226h3.695a.3.3 0 0 0 .288-.22l.277-.966a5.268 5.268 0 0 0 1.997-1.854 5.324 5.324 0 0 0 .817-2.842c0-2.918-2.337-5.294-5.211-5.294Zm-.348 10.55-.796-4.65h2.288l-.796 4.65h-.696Zm4.237-2.744a4.638 4.638 0 0 1-1.874 1.696.305.305 0 0 0-.156.189l-.25.875h-.65l.84-4.919a.314.314 0 0 0-.066-.25.296.296 0 0 0-.231-.11H6.26c-.09 0-.174.04-.23.11a.31.31 0 0 0-.067.25l.84 4.919H6.13l-.225-.869a.296.296 0 0 0-.16-.195 4.66 4.66 0 0 1-1.873-1.696 4.714 4.714 0 0 1-.721-2.512c.003-2.583 2.072-4.684 4.613-4.684 2.54 0 4.61 2.101 4.61 4.68a4.72 4.72 0 0 1-.72 2.516Zm-1.322 3.721H5.194c-.165 0-.3.137-.3.304 0 .168.135.305.3.305h.595c.126.992.96 1.76 1.973 1.76 1.012 0 1.847-.768 1.973-1.76h.595c.165 0 .3-.137.3-.305a.304.304 0 0 0-.3-.304ZM7.762 14.34a1.399 1.399 0 0 1-1.367-1.15h2.73a1.393 1.393 0 0 1-1.363 1.15Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 6.343C2.5 3.396 4.86 1 7.763 1c2.904 0 5.263 2.4 5.263 5.346a5.376 5.376 0 0 1-.825 2.87 5.32 5.32 0 0 1-1.997 1.861l-.27.945a.352.352 0 0 1-.339.258H5.901a.352.352 0 0 1-.342-.264l-.246-.942a5.303 5.303 0 0 1-1.991-1.862 5.392 5.392 0 0 1-.822-2.87Zm5.263-5.239c-2.845 0-5.159 2.349-5.159 5.239 0 1.002.28 1.975.806 2.814a5.2 5.2 0 0 0 1.972 1.836l.02.01.258.986c.03.111.127.187.24.187h3.695c.11 0 .208-.073.238-.181v-.001l.283-.988.02-.01a5.215 5.215 0 0 0 1.977-1.836c.53-.84.809-1.815.809-2.814 0-2.89-2.314-5.242-5.159-5.242Zm0 .61c-2.511 0-4.558 2.077-4.561 4.632 0 .883.246 1.743.713 2.485a4.608 4.608 0 0 0 1.852 1.676.345.345 0 0 1 .187.23l.215.829h.574l-.83-4.858a.364.364 0 0 1 .077-.291.348.348 0 0 1 .271-.13h3.004c.105 0 .204.047.271.13a.366.366 0 0 1 .077.291l-.83 4.858h.55l.239-.837a.358.358 0 0 1 .183-.222 4.586 4.586 0 0 0 1.853-1.676 4.665 4.665 0 0 0 .713-2.488c0-2.552-2.047-4.629-4.558-4.629ZM3.098 6.346C3.1 3.735 5.193 1.61 7.763 1.61s4.662 2.125 4.662 4.733a4.77 4.77 0 0 1-.728 2.543A4.691 4.691 0 0 1 9.8 10.601a.254.254 0 0 0-.129.156l-.26.913H8.66l.852-4.98a.262.262 0 0 0-.055-.208.244.244 0 0 0-.191-.09H6.26a.244.244 0 0 0-.19.09.259.259 0 0 0-.056.209l.852 4.98h-.778l-.236-.909a.244.244 0 0 0-.13-.161h-.001a4.712 4.712 0 0 1-1.895-1.715 4.767 4.767 0 0 1-.73-2.54Zm3.459.554h2.412l-.814 4.755h-.784L6.557 6.9Zm.124.105.778 4.546h.608l.779-4.546H6.68Zm-1.84 5.878c0-.195.158-.356.353-.356h5.136c.195 0 .352.16.352.357a.356.356 0 0 1-.352.357h-.55A2.05 2.05 0 0 1 7.763 15c-1.024 0-1.87-.765-2.019-1.76h-.549a.356.356 0 0 1-.352-.357Zm.353-.252a.251.251 0 0 0-.248.252c0 .14.112.253.248.253h.64l.006.046c.123.966.937 1.714 1.922 1.714.985 0 1.799-.748 1.922-1.714l.005-.046h.641a.251.251 0 0 0 .248-.252.251.251 0 0 0-.248-.253H5.194Zm1.139.508h2.855l-.011.062a1.445 1.445 0 0 1-1.415 1.192 1.45 1.45 0 0 1-1.418-1.192l-.011-.062Zm.127.105a1.346 1.346 0 0 0 1.302 1.045 1.34 1.34 0 0 0 1.3-1.045H6.46Z"
            />
        </g>
    </svg>
);

export default Electricity;
