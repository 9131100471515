import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import './style.less'
import notificationBell from "./images/notifications_blue_24px.svg";
import deleteIcon from "./images/delete_black_18dp.svg";
import moment from 'moment';
import momenttz from 'moment-timezone';
import {dashboardServices} from "../../services";
import Loading from "../Loading";
moment().locale(localStorage.getItem('i18nextLng')?.split('-')[0])


const LandlordNotification = ({notification, notifications, setNotifications, close}) => {

    const {t} = useTranslation();

    let history = useHistory();

    let tenantName = notification.options.tenant_name?.split(" ")[0];
    let landlordName = notification.options.landlord_name?.split(" ")[0];
    let propertyTitle = notification.options.property_title;


    let detailsLink = () => {
        let type = notification.type;
        if (["reservation_request", "reservation_request_reminder", "offer_accepted", "reservation_property_accepted"].includes(type)) {
            return "/reservations/" + notification.options.reservation_id + "/details/requests"
        } else if(type === "reservation_confirmed") {
            return "/reservations/" + notification.options.reservation_id + "/details/confirmed"
        } else if(type === "reservation_cancelled") {
            return "/reservations/" + notification.options.reservation_id + "/details/cancelled"
        }else if (type.includes("reservation")) {
            return "/reservations/" + notification.options.reservation_id + "/details"
        } else if (type === "visit_request" || type === "visit_suggestion_accepted") {
            return "/my_visits/requests"
        } else if (type === "visit_cancelled") {
            return "/my_visits/cancelled"
        } else if (type.includes("visit")) {
            return "/my_visits/scheduled"
        } else if (type === "inlife_billingInfo") {
            return "/my_account/billing_details"
        } else if (["inlife_checkUp", "inlife_propertyVerification", "inlife_availability"].includes(type)) {
            return "/my_ads/published?id=" + notification.options.property_id
        } else if(type === "offer_potential") {
            return "/radar/" + notification.options.tenant_id + "/details";
        } else if(type.includes("offer_rejected")) {
            return "/radar/declined";
        } else {
            return "/dashboard";
        }
    }

    const deleteNotification = (id) => {
        dashboardServices.deleteNotification(notification.id)
        setNotifications(prev => {
            return prev.filter((n) => n.id !== id);
        });
    }

    let visit_timezone = notification.options.visitTimezone || "Europe/Lisbon";

    return(
        <div className={"NotificationItem"} onClick={close}>
            <div className={"NotificationItem-title"}>
                <div className={"bell-container" + (notification.read ? " read" : "")}><img src={notificationBell}/></div>
                <span>{t("notifications." + notification.type + ".title", {tenantName})}</span>
                <div className={"delete-icon"} onClick={(e) => {
                    e.stopPropagation();
                    deleteNotification(notification.id)
                }}>
                    <img src={deleteIcon}/>
                </div>
            </div>
            <div className={"NotificationItem-content"}>
                <div className={"description"}>
                    <Trans i18nKey={"notifications." + notification.type + ".description" + (notification.type === "visit_reminder" ? ("_" + notification.options.visitType) : "")}
                           values={{
                               tenantName,
                               landlordName,
                               propertyTitle,
                               percentageDiff : notification.options.percentageDiff,
                               suggestionType: notification.options.suggestionType,
                               moveIn: moment(notification.options.moveIn).format("DD MMM YYYY"),
                               moveOut: moment(notification.options.moveOut).format("DD MMM YYYY"),
                               visitDay: momenttz.tz(notification.options.visitMoment, visit_timezone).format("DD MMM YYYY"),
                               visitHour: momenttz.tz(notification.options.visitMoment, visit_timezone).format("HH:mm"),
                               timezone: visit_timezone,
                               visitType: notification.options.visitType && t("notifications.visitType." + notification.options.visitType),
                               visitTypeReminder: notification.options.visitType && t("notifications.visitTypeReminder." + notification.options.visitType),
                               visitLink: <a target="_blank" href={"/video/" + notification.options.visit_id}/> ,
                               acceptedType: notification.options.suggestionType && t("notifications.suggestionType." + notification.options.suggestionType, {propertyTitle, moveIn: moment(notification.options.moveIn).format("DD/MM/YYYY"),
                                   moveOut: moment(notification.options.moveOut).format("DD/MM/YYYY")}),
                               reminderType: notification.options.reminderType && t("notifications.reminderType." + notification.options.reminderType),
                               contractValue: notification.options.contractValue,
                               moreAdsTrans: t("notifications.moreAdsTrans." + notification.options.total_ads ? "yes" : "no", {totalAds: notification.options.total_ads || 0})
                           }}
                           components={{blue: <span style={{color: "#57B4F2"}}/>, link: <a />}}
                    />
                </div>
                <div>
                    <span className={"notification-date"}>{t("notifications.sent")} {moment(notification.date).format("DD MMM YYYY")}</span>
                    <span className={"link link_blue"} onClick={() => history.push(detailsLink())}>{t("notifications.see_detail")}</span>
                </div>
            </div>
        </div>
    )
}

export default LandlordNotification;
