import React, { useState } from "react";

import "./style.less";
import Buttons from "../../../../../components/Buttons";
import apartmentIcon from "../../../../../images/apartmentIcon.svg";
import bedIcon from "../../../../../images/bedIcon.svg";
import {useForm} from 'react-hook-form';
import RadioButtonsWithIcons from '../../../../../components/RadioButtonsWithIcons';
import {useTranslation} from 'react-i18next';
import {useAuth} from "../../../../../../../../../../../services/authServices";


const PropertyDetails0 = ({ updatePropertychangePage,currentLevel,updateProperty}) => {
  const {t} = useTranslation();
  const {user, setUser} = useAuth();

  const [accommodation,setAccommodation] = useState({accommodation:"rooms"})

  const radioButtons = [{icon:bedIcon,id:"rooms",buttonValue:"rooms",label:"main_page.my_ads.create_ad.house_details.property_details.level0.rooms"},
    {icon:apartmentIcon,id:"apartment",buttonValue:"apartment",label:"main_page.my_ads.create_ad.house_details.property_details.level0.apartment"}]


  const onSubmit = async (values) => {
    await updateProperty(values,currentLevel);
    setUser(prev => ({...prev, inProgressListings: prev.inProgressListings + 1}));
  }
  const { handleSubmit,control, setValue, watch} = useForm({ shouldFocusError: true });

  const watchAccommodation=watch("accommodation");

  return (
    <form className={"PropertyDetails0"} onSubmit={handleSubmit(onSubmit)}>
      <RadioButtonsWithIcons  radioButtons={radioButtons}
                              name={"accommodation"}
                              value={accommodation.accommodation}
                              setValue={(value) => {
                                setAccommodation({"accommodation": value});
                                setValue('accommodation', value)
                              }}
                              watchValue={watchAccommodation}
                              control={control} t={t} type={"fullImage double"}/>
      <Buttons/>
    </form>
  );
};



export default PropertyDetails0;
