import React from "react";

const NoMeetingRoom = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m2.975 1.565-1.41 1.41 4 4v11.46h-2v2h11v-4.46l6.46 6.46 1.41-1.41-19.46-19.46Zm9.59 2.87v3.88l2 2v-4.88h3v7.88l2 2V3.435h-5v-1h-7.88l2 2h3.88Zm-5 14h5v-4.46l-5-5v9.46Z"
        />
    </svg>
);

export default NoMeetingRoom;
