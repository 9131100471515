import * as React from "react";

function SvgPh(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M3.256 333.59L256 168 3.256 2.41C1.311 4.029 0 6.375 0 9.103v317.793c0 2.729 1.311 5.075 3.256 6.694z"
          fill="#F5F5F5"
        />
        <path
          d="M3.256 333.59c1.53 1.274 3.425 2.134 5.571 2.134h494.345a8.829 8.829 0 008.828-8.828V168H256L3.256 333.59z"
          fill="#FF4B55"
        />
        <path
          d="M3.256 2.41C4.786 1.136 6.681.276 8.827.276h494.345A8.829 8.829 0 01512 9.104V168H256L3.256 2.41z"
          fill="#41479B"
        />
        <g transform="translate(13 41)" fill="#FFE15A">
          <circle cx={79.69} cy={127} r={28.69} />
          <path d="M79.69 127L68.931 83.966 79.69 69.621l10.758 14.345zm0 0l10.758 43.034-10.758 14.345-10.759-14.345zm0 0l-43.035 10.759L22.31 127l14.345-10.759zm0 0l43.034-10.759L137.069 127l-14.345 10.759zm0 0l22.822-38.037 17.751-2.537-2.536 17.751zm0 0l-22.823 38.037-17.751 2.537 2.537-17.751zm0 0l-38.037-22.823-2.537-17.751 17.751 2.537zm0 0l38.037 22.823 2.536 17.751-17.751-2.537zM10.658 2.694l1.496 11.16-10.076 5.028c-1.304.651-1.03 2.588.405 2.85l11.076 2.026 1.668 11.136c.216 1.442 2.143 1.779 2.836.495l5.35-9.908 11.106 1.854c1.438.241 2.354-1.489 1.348-2.543l-7.77-8.15 5.195-9.99c.673-1.293-.689-2.699-2.003-2.068L21.137 9.456l-7.895-8.029c-1.02-1.038-2.777-.177-2.584 1.267zm4.444 217.212l-1.89 11.101-11.114 1.805c-1.439.234-1.752 2.165-.461 2.841l9.973 5.227-1.717 11.128c-.222 1.441 1.517 2.335 2.56 1.316l8.053-7.87 10.054 5.073c1.302.657 2.69-.722 2.044-2.028l-4.996-10.091 7.93-7.993c1.026-1.035.146-2.781-1.298-2.57l-11.142 1.633-5.152-10.013c-.667-1.295-2.601-.997-2.844.441zm174.632-109.351l-6.481 9.208-10.81-3.15c-1.399-.408-2.513 1.201-1.639 2.367l6.755 9.009-6.337 9.308c-.82 1.205.365 2.761 1.745 2.29l10.655-3.64 6.894 8.903c.893 1.153 2.739.505 2.717-.952l-.17-11.258 10.598-3.806c1.372-.493 1.327-2.449-.066-2.878l-10.761-3.318-.344-11.255c-.042-1.459-1.917-2.021-2.756-.828z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgPh;
