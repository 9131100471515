import React, { useState } from "react";
import {Trans, useTranslation} from "react-i18next";
import { useHistory } from 'react-router-dom';
import { NavLink } from "react-router-dom";

import { userServices } from "../../../../services";

import "./style.less";
import useQuery from "../../../../utils/query";

import EmailSent from "../../../../components/svg/Actions/EmailSent";
import PasswordChanged from "../../../../components/svg/Actions/PasswordChanged";
import ArrowBackwardIcon from "../../../../components/svg/Navigation/ArrowBackward";


const RecoverContainer = (props) => {
    const { t } = useTranslation();

    const query = useQuery();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [sending, setSending] = useState(null);
    const [status, setStatus] = useState(null);
    const [showActionDialog, setShowActionDialog] = useState(null);

    const checkValidEmail = (email) => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    }

    const sendRecoverEmail = async () => {
        try {
            setSending(true);
            await userServices.recoverPassword(email);
            setStatus("sent");
            setShowActionDialog("email_sent");
        } catch (e) {
            if(e.error?.message === "notFound") setShowActionDialog("email_not_found");
            setStatus(e.error?.message);
        }
        setSending(false);
    }

    const resetPassword = async () => {
        try {
            setSending(true);
            await userServices.resetPassword(query.token, password);
            setStatus("reset");
            setShowActionDialog("new_password");
            setTimeout(()=>{
                history.push('/start/login');
            }, 5000)
        } catch (e) {
            setStatus(e.error?.message);
        }
        setSending(false);
    }
    console.log("show", showActionDialog)
    let reset = query.token;

    return (
        <div className={"RecoverContainer"}>

            {showActionDialog === "email_sent" && <div className="action">
                <EmailSent />
                <h3>{t("start.recover.emailSent")}</h3>
                <p><Trans i18nKey={"start.recover.emailSentDescription"} components={{ br: <br /> }} /></p>
                <p className="backToLogin" onClick={() => history.push('/start/login')}>
                    <ArrowBackwardIcon />
                    {t("start.recover.backToLogin")}
                </p>
            </div>}

            {showActionDialog === "new_password" && <div className="action">
                <PasswordChanged />
                <h3>{t("start.reset.passwordChanged")}</h3>
                <p>{t("start.reset.passwordChangedDescription")}</p>
                <div className="btn-container">
                    <NavLink to="login" className="btn btn-primary">{t("start.login.button")}</NavLink>
                </div>
            </div>}

            {!showActionDialog && <>
                <h3>{t("start." + (reset ? 'reset' : 'recover') + ".label")}</h3>
                <p><Trans i18nKey={"start." + (reset ? 'reset' : 'recover') + ".description"} components={{ strong: <strong /> }} /></p>
            {reset ? <div className={"base-info"}>
                    <div className="input-container">
                    <label htmlFor={"password"}>{`${t("start.signup.password")}`}</label>
                    <input
                        name="password" type={"password"} value={password}
                        required disabled={status==="reset"}
                        placeholder={`${t("start.signup.password_placeholder")}`}
                        className={"input_field"} onChange={e=>{
                            setStatus(null);
                            setPassword(e.target.value)
                        }}
                    />
                </div>
            </div> : <div className={"base-info"}>
                        <div className="input-container">
                    <label htmlFor={"email"}>{`${t("start.signup.email")}`}</label>
                    <input
                        name="email" type={"email"} value={email}
                        required disabled={status==="sent"}
                        placeholder={`${t("start.signup.email_placeholder")}`}
                        className={"input_field"} onChange={e=>{
                            setStatus(null);
                            setEmail(e.target.value)
                        }}
                    />
                </div>
            </div>}
            {status && <p className={"status-"+((status==="sent"||status==="reset")?'success':'error')}>
                {t('start.recover.status.' + status)}
                </p>}
                {reset ? <button type="submit" onClick={resetPassword}
                        disabled={!password||password.length<6||sending||status} className={"btn btn_blue"}>
                    {t("start.reset.button")}
                </button> : <button type="submit" onClick={sendRecoverEmail}
                    disabled={!email||!checkValidEmail(email)||sending||status} className={"btn btn_blue"}>
                {t("start.recover.button")}
                </button>}

                {!reset && <div id="actions">
                    <p>{t("start.recover.rememberDetails")}</p>
                    <NavLink to="login" className={"blue-link-label"}>{t("start.recover.login")}</NavLink>
                </div>}
            </>}
        </div>
    )
}

export default RecoverContainer;
