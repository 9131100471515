import React, { useState, useEffect } from 'react';

const ExpirationCountdown = ({ targetTimestamp }) => {
    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime(targetTimestamp));

    function calculateRemainingTime(targetTimestamp) {
        const now = new Date().getTime();
        const timeDifference = targetTimestamp - now;

        if (timeDifference < 0) {
            return { hours: 0, minutes: 0 };
        }

        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        return { hours, minutes };
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const time = calculateRemainingTime(targetTimestamp);
            setRemainingTime(time);
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, [targetTimestamp]);

    const { hours, minutes } = remainingTime;

    return (
        <span>
            {/* {`{hours > 0 ? `${hours}h` : ''} {minutes}m`} */}
            {`${hours > 0 ? `${hours}h` : ""} ${minutes > 0 ? `${minutes}m` : ""}`}
        </span>
    );
};

export default ExpirationCountdown;
