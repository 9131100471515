import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import {Handle, Range} from 'rc-slider';
import PriceRangeChart from "./chart";

import './style.less';
import 'rc-slider/assets/index.css';

const PriceRange = (props) => {
    const [value, setValue] = useState(props.value);

    const change = (val) => {
        setValue(val);
        performChange(val);
    };

    const changeTimeout = useRef(null);
    const performChange = (val) => {
        if(!props.onChange) return;
        window.clearTimeout(changeTimeout.current);
        changeTimeout.current = setTimeout(() => {
            props.onChange(val)
        }, 300);
    };

    const inputChange = input => ({target}) => {
        let v = [...value];
        let newValue = parseInt(target.value.replace(/[^0-9.]/g, ''));
        if (!isNaN(newValue) || !newValue)
            v[input === "min" ? 0 : 1] = isNaN(newValue) ? "" : newValue;
        change(v);
    };

    const limitValue = () => {
        let v = [...value];
        if (v[0] && v[1]) {
            v.sort((a, b) => a - b);
            if (v[0] < props.range[0])
                v[0] = props.range[0];
            if (v[1] > props.range[1])
                v[1] = props.range[1];
            change(v);
        }
    };

    return <div className={"PriceRange"}>
        {props.chartData && <PriceRangeChart
            data={props.chartData}
            min={props.range[0]}
            max={props.range[1]}
            value={value}
            step={props.barStep}/>}
        <Range
            min={props.range[0]}
            max={props.range[1]}
            step={props.step}
            value={[value[0] - (props.range[0] > 0 ? props.step : 0), value[1]]}
            onChange={(v) => {
                change([v[0] + (props.range[0] > 0 && v[0] !== props.range[0] ? props.step : ""), v[1]]);
            }}
            handle={(handleProps) => <Handle key={"handle_"+handleProps.index} {...{...handleProps, dragging: (""+handleProps.dragging)}}><span className={"handle-bar"}/></Handle>}
        />
        <div className={"inputs"}>
            <input value={"€" + value[0]} onChange={inputChange("min")} onBlur={limitValue}/>
            <input value={"€" + value[1]} onChange={inputChange("max")} onBlur={limitValue}/>
        </div>
    </div>
};

PriceRange.propTypes = {
    range: PropTypes.array.isRequired,
    step: PropTypes.number.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    chartData: PropTypes.object,
};

export default PriceRange;
