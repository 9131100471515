import React from "react";

const ArrowForward = (props) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="arrow_forward" clipPath="url(#clip0_2344_9604)">
      <path
        id="Vector"
        d="M24 8L21.18 10.82L32.34 22H8V26H32.34L21.18 37.18L24 40L40 24L24 8Z"
        fill="#434A5F"
      />
    </g>
    <defs>
      <clipPath id="clip0_2344_9604">
        <rect width={48} height={48} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowForward;
