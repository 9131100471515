import React, { useState } from 'react';
import './style.less'

import { useTranslation } from 'react-i18next';

const ClampedText = ({ text, maxLength = 300 }) => {
    const { t } = useTranslation();
    const [showingAllText, setShowingAllText] = useState(false);

    const MAX_LENGTH = maxLength;
    const showMore = text?.length > MAX_LENGTH;
    const twoThirdsPosition = Math.min(Math.floor(text?.length * (2 / 3)), MAX_LENGTH);

    return (
        <p className="clamped-text">
            {showingAllText ? text : showMore ? `${text.substring(0, twoThirdsPosition)}...` : text}
            {showMore && (
                <span className="clamped-text-link" onClick={() => setShowingAllText(!showingAllText)}>
                    {!showingAllText ? t("showMore") : t("showLess")}
                </span>
            )}
        </p>
    );
}

export default ClampedText;
