import * as React from "react";

function SvgEc(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M512.001 251.862H0v75.034a8.829 8.829 0 008.828 8.828h494.345a8.829 8.829 0 008.828-8.828v-75.034z"
          fill="#FF4B55"
        />
        <path
          d="M512.001 168H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828V168z"
          fill="#FFE15A"
        />
        <path fill="#41479B" d="M0 168h512v83.862H0z" />
        <path
          d="M246.788 172.953l64.48-55.268v79.987c0 13.481-10.929 24.408-24.408 24.408a24.413 24.413 0 01-16.329-6.265l-23.743-21.368v-21.494z"
          fill="#FFE15A"
        />
        <path
          d="M280.801 204.404l-18.66-16.794v-27.815l33.775-28.95v66.828c0 4.993-4.063 9.056-9.056 9.056a9.045 9.045 0 01-6.059-2.325z"
          fill="#FF4B55"
        />
        <path
          d="M271.352 151.9v31.617l15.352 13.862v-58.637l9.212-7.896v66.828c0 4.993-4.063 9.056-9.056 9.056a9.04 9.04 0 01-6.059-2.325l-18.66-16.794v-27.815l9.211-7.896z"
          fill="#41479B"
        />
        <path
          d="M265.212 172.953l-64.48-55.268v79.987c0 13.481 10.929 24.408 24.408 24.408 6.03 0 11.847-2.232 16.329-6.265l23.743-21.368v-21.494z"
          fill="#FFE15A"
        />
        <path
          d="M231.199 204.404l18.66-16.794v-27.815l-33.775-28.95v66.828c0 4.993 4.063 9.056 9.056 9.056a9.045 9.045 0 006.059-2.325z"
          fill="#FF4B55"
        />
        <path
          d="M240.648 151.9v31.617l-15.352 13.862v-58.637l-9.212-7.896v66.828c0 4.993 4.063 9.056 9.056 9.056a9.04 9.04 0 006.059-2.325l18.66-16.794v-27.815l-9.211-7.896zm68.395-33.941h4.414v26.449h-4.414zm-110.533 0h4.414v26.449h-4.414z"
          fill="#41479B"
        />
        <g fill="#5A4646">
          <path d="M246.788 105.404s6.141-29.17-15.352-25.332c-21.493 3.838-21.493 9.978-46.057 22.261 0 0 27.634 6.141 39.916 3.071 0 0 9.212 12.281 18.423 15.352h9.212l-6.142-15.352zm18.424 0s-6.141-29.17 15.352-25.332c21.493 3.838 21.493 9.978 46.057 22.261 0 0-27.634 6.141-39.916 3.071 0 0-9.212 12.281-18.423 15.352h-9.212l6.142-15.352z" />
          <path d="M265.212 120.756h-18.423V105.29a9.098 9.098 0 019.098-9.098h.227a9.098 9.098 0 019.098 9.098v15.466z" />
        </g>
        <circle fill="#E6E1CD" cx={256} cy={105.401} r={6.141} />
        <path
          d="M242.823 101.438l11.546 6.166a2.494 2.494 0 002.937-3.964l-7.448-7.448h-1.791a5.245 5.245 0 00-5.244 5.246z"
          fill="#FFD0AF"
        />
        <path
          d="M246.827 102.333h-9.249l4.047-4.738a4.002 4.002 0 013.042-1.402h5.192v3.108a3.033 3.033 0 01-3.032 3.032z"
          fill="#FFAF94"
        />
        <path
          d="M282.186 120.764c-2.072-2.458-2.514-5.717-1.504-8.541 2.954-.516 6.091.471 8.162 2.931 1.77 2.101 2.283 4.772 1.784 7.272a88.745 88.745 0 012.189 3.964c.041-.225.032-.448.096-.673.724-2.546 2.743-4.348 5.135-4.958 1.713 1.778 2.483 4.373 1.759 6.918-.622 2.19-2.228 3.777-4.18 4.581a86.865 86.865 0 012.247 5.69c.041-.118.044-.238.092-.355 1-2.451 3.206-4.019 5.65-4.362 1.506 1.955 1.986 4.619.987 7.07-.875 2.144-2.69 3.558-4.764 4.121 1.068 4.213 1.775 8.683 1.775 13.183h-3.071c0-3.922-.602-7.85-1.511-11.608-1.895.612-4.012.481-5.845-.641-2.236-1.37-3.416-3.778-3.361-6.223 2.154-1.16 4.835-1.203 7.071.168.081.049.135.121.213.173a83.574 83.574 0 00-2.077-5.365c-1.843.871-4.012 1.005-6.002.071-2.374-1.116-3.811-3.379-4.026-5.815 2.006-1.386 4.657-1.721 7.023-.62a88.6 88.6 0 00-2.206-4.002c-2.115-.244-4.152-1.218-5.636-2.979zm-52.372 0c2.072-2.458 2.514-5.717 1.504-8.541-2.954-.516-6.091.471-8.162 2.931-1.77 2.101-2.283 4.772-1.784 7.272a88.745 88.745 0 00-2.189 3.964c-.041-.225-.032-.448-.096-.673-.724-2.546-2.743-4.348-5.135-4.958-1.713 1.778-2.483 4.373-1.759 6.918.622 2.19 2.228 3.777 4.18 4.581a86.865 86.865 0 00-2.247 5.69c-.041-.118-.044-.238-.092-.355-1-2.451-3.206-4.019-5.65-4.362-1.506 1.955-1.986 4.619-.986 7.07.875 2.144 2.69 3.558 4.764 4.121-1.068 4.213-1.775 8.683-1.775 13.183h3.071c0-3.922.602-7.85 1.511-11.608 1.895.612 4.012.481 5.845-.641 2.237-1.37 3.416-3.778 3.361-6.223-2.154-1.16-4.835-1.203-7.071.168-.081.049-.135.121-.213.173a83.574 83.574 0 012.077-5.365c1.843.871 4.012 1.005 6.002.071 2.374-1.116 3.811-3.379 4.026-5.815-2.006-1.386-4.657-1.721-7.023-.62a88.6 88.6 0 012.206-4.002c2.114-.244 4.151-1.218 5.635-2.979z"
          fill="#8CB400"
        />
        <path
          d="M256 188.306l-64.48-55.268v79.987c0 13.481 10.929 24.408 24.408 24.408 6.03 0 11.847-2.232 16.329-6.265L256 209.8v-21.494z"
          fill="#FFE15A"
        />
        <path
          d="M221.987 219.756l18.66-16.794v-27.815l-33.775-28.95v66.828c0 4.993 4.063 9.056 9.056 9.056a9.039 9.039 0 006.059-2.325z"
          fill="#FF4B55"
        />
        <path
          d="M231.436 167.252v31.617l-15.352 13.862v-58.637l-9.212-7.896v66.828c0 4.993 4.063 9.056 9.056 9.056a9.04 9.04 0 006.059-2.325l18.66-16.794v-27.815l-9.211-7.896z"
          fill="#41479B"
        />
        <path
          d="M256 188.306l64.48-55.268v79.987c0 13.481-10.929 24.408-24.408 24.408a24.413 24.413 0 01-16.329-6.265L256 209.8v-21.494z"
          fill="#FFE15A"
        />
        <path
          d="M290.013 219.756l-18.66-16.794v-27.815l33.775-28.95v66.828c0 4.993-4.063 9.056-9.056 9.056a9.037 9.037 0 01-6.059-2.325z"
          fill="#FF4B55"
        />
        <path
          d="M280.564 167.252v31.617l15.352 13.862v-58.637l9.212-7.896v66.828c0 4.993-4.063 9.056-9.056 9.056a9.04 9.04 0 01-6.059-2.325l-18.66-16.794v-27.815l9.211-7.896z"
          fill="#41479B"
        />
        <ellipse fill="#69C3FF" cx={256} cy={166.808} rx={30.705} ry={46.057} />
        <path
          d="M228.366 179.095h55.268l3.071-12.281-13.993 2.333a9.617 9.617 0 01-7.839-2.184l-15.884-13.615a7.831 7.831 0 00-10.635.408l-9.988 9.988v15.351z"
          fill="#009B64"
        />
        <path
          d="M253.087 164.75l5.274-3.37-9.374-8.034a7.831 7.831 0 00-10.635.408l-9.988 9.988v3.071l15.352-3.071 2.714 1.357a6.753 6.753 0 006.657-.349z"
          fill="#F5F5F5"
        />
        <path
          fill="#5AA0E6"
          d="M228.366 179.095l3.07 18.422 15.352 15.353H256l12.281-3.07 12.283-12.283 3.07-18.422z"
        />
        <path
          d="M256 215.94c-18.624 0-33.775-22.039-33.775-49.128s15.151-49.128 33.775-49.128 33.775 22.039 33.775 49.128S274.624 215.94 256 215.94zm0-92.113c-15.236 0-27.634 19.284-27.634 42.987 0 23.703 12.397 42.987 27.634 42.987s27.634-19.284 27.634-42.987c0-23.703-12.398-42.987-27.634-42.987z"
          fill="#FFC88C"
        />
        <g fill="#E1E1E1">
          <path d="M234.339 212.594c-2.574-3.554-6.406-5.865-10.746-5.865-4.34 0-8.172 2.311-10.746 5.865 4.39 1.217 7.982 4.781 9.683 9.479a18.053 18.053 0 011.064 6.15c-.001-7.596 4.588-13.922 10.745-15.629z" />
          <path d="M226.031 222.281s-.239 4.094-2.339 6.013l-.088-.072c-2.099-1.919-2.339-6.013-2.339-6.013l4.766.072z" />
        </g>
        <path
          d="M294.4 223.616h-76.8a1.516 1.516 0 01-1.516-1.516v-.038c0-.838.679-1.516 1.516-1.516h76.8c.838 0 1.516.679 1.516 1.516v.038c0 .837-.678 1.516-1.516 1.516z"
          fill="#966446"
        />
        <path
          d="M271.428 228.222h-30.856a6.066 6.066 0 01-6.066-6.066v-.151a6.066 6.066 0 016.066-6.066h30.856a6.066 6.066 0 016.066 6.066v.151a6.067 6.067 0 01-6.066 6.066z"
          fill="#BE915A"
        />
        <path
          d="M228.366 220.546H217.62a1.535 1.535 0 000 3.07h10.746v-3.07z"
          fill="#B9B9B9"
        />
        <path
          fill="#FF4B55"
          d="M256 228.222v28.054l-12.281-3.49 6.14-24.564z"
        />
        <path
          fill="#41479B"
          d="M252.929 228.222l-6.141 21.494-12.281-3.071 9.212-18.423z"
        />
        <path
          fill="#FFE15A"
          d="M240.648 228.222l-9.212 12.282 9.212 3.07 6.14-15.352z"
        />
        <path fill="#FF4B55" d="M256 228.222v28.054l12.281-3.49-6.14-24.564z" />
        <path
          fill="#41479B"
          d="M259.071 228.222l6.141 21.494 12.281-3.071-9.212-18.423z"
        />
        <path
          fill="#FFE15A"
          d="M271.352 228.222l9.212 12.282-9.212 3.07-6.14-15.352z"
        />
        <path
          d="M193.728 159.482l-4.414-4.448v-21.999h4.414zm128.918-4.448l-4.414 4.448v-26.447h4.414z"
          fill="#41479B"
        />
      </g>
    </svg>
  );
}

export default SvgEc;
