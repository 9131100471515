import React from "react";

const ChevronRight = (props) => (
    <svg
        width={8}
        height={12}
        viewBox="0 0 8 12"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M2 0 .59 1.41 5.17 6 .59 10.59 2 12l6-6-6-6Z" />
    </svg>
);

export default ChevronRight;
