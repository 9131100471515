import * as React from "react";

function SvgSt(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#73AF00"
        />
        <path fill="#FFE15A" d="M0 79.724h511.999v176.551H0z" />
        <path
          d="M2.666 333.192L176.551 168 2.666 2.809C1.027 4.411 0 6.631 0 9.104v317.792c0 2.473 1.027 4.693 2.666 6.296z"
          fill="#FF4B55"
        />
        <path
          d="M278.298 127.767l9.047 27.126 28.594.222c3.702.029 5.237 4.753 2.259 6.953l-23.002 16.986 8.626 27.263c1.117 3.53-2.902 6.45-5.914 4.297l-23.263-16.628-23.263 16.628c-3.011 2.153-7.031-.767-5.914-4.297l8.626-27.263-23.002-16.986c-2.978-2.199-1.443-6.924 2.259-6.953l28.594-.222 9.047-27.126c1.167-3.512 6.135-3.512 7.306 0zm132.001 0l9.047 27.126 28.594.222c3.702.029 5.237 4.753 2.259 6.953l-23.002 16.986 8.626 27.263c1.117 3.53-2.902 6.45-5.914 4.297l-23.263-16.628-23.263 16.628c-3.011 2.153-7.031-.767-5.914-4.297l8.626-27.263-23.002-16.986c-2.978-2.199-1.443-6.924 2.259-6.953l28.594-.222 9.047-27.126c1.166-3.512 6.134-3.512 7.306 0z"
          fill="#464655"
        />
      </g>
    </svg>
  );
}

export default SvgSt;
