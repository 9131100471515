import moment from "moment";

const calculateTenantAge = (birthday, t) => {
    if (birthday) {
        let split = birthday.split("-");
        return (split && split.length > 0) ? `${(moment().year() - split[split.length - 1])} ${t('years')}` : "";
    }
}

const calculateTenantAgeGroup = (age, t) => {
    let ageGroup = { 'kid': '<18', 'young': '19-25', 'youngadult': '26-35', 'adult': '36-49', 'old': '50+' }[age];

    return `${ageGroup} ${t('years')}`
}

export { calculateTenantAge, calculateTenantAgeGroup }
