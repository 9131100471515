import React from 'react'
import { useTranslation } from 'react-i18next';

import './style.less'
import SearchIcon from '../svg/Search';

const SearchFilter = ({ placeholder, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="SearchFilter">
      <SearchIcon />
      <input
        type="text"
        placeholder={t("list_filter_input_container." + placeholder)}
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={(event) => onChange(event.target.value)}
      />
    </div>
  )
}

export default SearchFilter;
