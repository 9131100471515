import React from "react";

const Timeline = (props) => (
    <svg
        width={22}
        height={12}
        viewBox="0 0 22 12"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M22 2c0 1.1-.9 2-2 2a1.7 1.7 0 0 1-.51-.07l-3.56 3.55c.05.16.07.34.07.52 0 1.1-.9 2-2 2s-2-.9-2-2c0-.18.02-.36.07-.52L9.52 4.93C9.36 4.98 9.18 5 9 5s-.36-.02-.52-.07L3.93 9.49c.05.16.07.33.07.51 0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.18 0 .35.02.51.07l4.56-4.55C7.02 3.36 7 3.18 7 3c0-1.1.9-2 2-2s2 .9 2 2c0 .18-.02.36-.07.52l2.55 2.55c.16-.05.34-.07.52-.07s.36.02.52.07l3.55-3.56A1.7 1.7 0 0 1 18 2c0-1.1.9-2 2-2s2 .9 2 2Z"
        />
    </svg>
);

export default Timeline;
