import * as React from "react";

function SvgMz(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M512.001 112.092H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v102.989z"
          fill="#5AA587"
        />
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V223.908h512v102.988a8.828 8.828 0 01-8.828 8.828z"
          fill="#FFE15A"
        />
        <path fill="#464655" d="M0 112.088h512V223.9H0z" />
        <path
          d="M0 102.157h512v13.942H0zm0 117.738h512v13.942H0z"
          fill="#F5F5F5"
        />
        <path
          d="M3.256 333.59l202.841-161.015c2.946-2.338 2.946-6.812 0-9.151L3.256 2.41C1.311 4.029 0 6.375 0 9.103v317.793c0 2.729 1.311 5.075 3.256 6.694z"
          fill="#FF4B55"
        />
        <path
          d="M81.814 110.577l12.905 38.695 40.789.317c2.281.018 3.226 2.929 1.391 4.284l-32.813 24.231 12.303 38.891c.687 2.175-1.788 3.974-3.644 2.647l-33.185-23.72-33.185 23.72c-1.856 1.326-4.332-.472-3.644-2.647l12.303-38.891-32.812-24.231c-1.835-1.355-.889-4.266 1.391-4.284l40.789-.317 12.905-38.695c.724-2.164 3.785-2.164 4.507 0z"
          fill="#FFE15A"
        />
        <g fill="#F5F5F5">
          <path d="M77.196 182.446l-3.19 3.21H47.328l7.281-26.717 2.975-1.487-4.776 23.82c-.04.294.02.587.215.822a.91.91 0 00.744.352h23.429zm6.4 4.444a6.753 6.753 0 01-4.032 1.351 6.746 6.746 0 01-4.032-1.351l4.032-4.032 4.032 4.032zm28.205-1.233H85.123l-3.19-3.21h23.428a.91.91 0 00.744-.352 1.04 1.04 0 00.215-.822l-4.776-23.82 2.975 1.487 7.282 26.717z" />
          <path d="M104.168 180.49H80.543v-24.369c3.954-3.347 14.386-1.057 18.809.313l4.816 24.056zm-25.583-24.369v24.369H54.961l4.815-24.055c4.424-1.371 14.856-3.66 18.809-.314z" />
        </g>
        <g fill="#464655">
          <path d="M114.033 186.381L106.301 158a.977.977 0 00-.509-.607l-5.167-2.583c-.02-.02-.04-.02-.078-.02-.02 0-.02-.02-.039-.04-.627-.215-14.934-4.874-20.943-.313-6.009-4.561-20.317.098-20.943.313l-.039.04c-.04 0-.058 0-.078.02l-5.167 2.583a.978.978 0 00-.509.607l-7.732 28.381a.984.984 0 00.157.842c.195.235.489.392.783.392h27.402c1.624 1.624 3.817 2.583 6.126 2.583 2.31 0 4.502-.959 6.126-2.583h27.402c.294 0 .587-.157.783-.392a.987.987 0 00.157-.842zm-33.49-30.26c3.954-3.347 14.386-1.057 18.809.313l4.815 24.055H80.543v-24.368zm-20.767.314c4.424-1.37 14.856-3.66 18.809-.313v24.369H54.961l4.815-24.056zm-12.448 29.222l7.281-26.717 2.975-1.487-4.776 23.82c-.04.294.02.587.215.822a.91.91 0 00.744.352h23.428l-3.19 3.21H47.328zm32.236 2.584a6.746 6.746 0 01-4.032-1.351l4.032-4.032 4.032 4.032a6.748 6.748 0 01-4.032 1.351zm5.559-2.584l-3.19-3.21h23.428a.91.91 0 00.744-.352 1.04 1.04 0 00.215-.822l-4.776-23.82 2.975 1.487 7.281 26.717H85.123z" />
          <path d="M104.034 130.558a2.51 2.51 0 01-3.211-.285l-1.302-1.302v3.187l-7.967 7.967h-3.187l-12.746 12.747h-3.187L47.926 177.38a2.252 2.252 0 00-.544 2.306l1.153 3.459-4.78 4.78h-3.187l-9.743 9.743a1.994 1.994 0 000 2.819l6.43 6.43c.928.928 2.45.87 3.304-.127l14.35-17.272 1.593-4.78 3.187-3.187 6.374 9.56 4.78-4.78-5.099-7.648 1.27-1.27a3.16 3.16 0 000-4.471l-.951-.951.895-.895c6.801 5.828 14.967 7.335 22.742 4.002l-2.663-6.214c-6.424 2.753-11.67.45-15.319-2.549l13.464-13.464-.531-2.656 3.718-3.718h3.187l23.901-23.901-11.423 7.962z" />
          <path d="M56.501 127.378l-3.187 3.187a2.255 2.255 0 000 3.187l.531.531-5.311 1.063-14.34 14.34 7.967 7.967 14.34-14.34 1.062-5.312 2.125 2.125 1.593-1.593 53 53 3.187-3.187-53-53 1.593-1.593-6.374-6.374a2.252 2.252 0 00-3.186-.001z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgMz;
