import React, {useEffect, useState} from 'react';
import ReactMoment from 'react-moment';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import {Trans, useTranslation} from 'react-i18next';

import CustomDatePicker from '../../../../../../../../../../../components/CustomDatePicker';
import AmountButtonsInput from '../../../../../components/AmountButtonsInput';
import OverviewCalendar from './components/OverviewCalendar';
import RadioButtons from '../../../../../components/RadioButtons';
import RentByMonth from './components/RentByMonth';
import PriceComponent from '../../../../../components/PriceComponent';
import RoomDetailsHeader from '../../../components/RoomDetailsHeader';
import Buttons from '../../../../../components/Buttons';
import UnavailabilityPeriodTable from './components/UnavailabilityPeriodTable';
import binIcon from './images/binIcon.svg';
import restoreIcon from './images/binIconRestore.svg';
import {propertyServices} from '../../../../../../../../../../../services';
import {updatePropertyActions} from "../../../../../../../../../../../redux/actions/updatePropertyActions";
import {calcFirstAvailability} from "../../../../../../../../../../../utils/firstAvailability";

import './style.less';
import infoIcon from "./components/OverviewCalendar/images/infoIcon.svg";
import CheckBox from "../../../../../../../../../../../components/CheckBox";
import moment from "moment";
import AvailabilitySlots from './components/AvailabilitySlots';
import OverviewSlots from './components/OverviewSlots';
import {transformAvailabilityPeriods} from "../../../../../../../../../../../utils/transformers/availabilityPeriods"
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import {useAuth} from "../../../../../../../../../../../services/authServices";

const pageLabel = 'main_page.my_ads.create_ad.room_details.room_prices';
const pageLabel2 = 'main_page.my_ads.create_ad.house_details.landlord_rules.level4';
const pageLabel3 = 'main_page.my_ads.create_ad.house_details.landlord_rules.level5';

const securityDepositInitialValue = (room, buttons) => {
    if (room?.securityDeposit) {
        let option = buttons.find(option => option.buttonValue === room.securityDeposit);
        if (option) return option.buttonValue;
        else return 'specific_value';
    }
};

const AddExternalUnavailabilities = ({propertyId, roomName, addImportedUnavailability}) => {
    const {t} = useTranslation();
    const componentLabel = pageLabel + '.calendars_sync.content';

    const [unavailabilitiesURL, setUnavailavilitiesURL] = useState('');
    const [importUrlError, setImportUrlError] = useState(null);

    const importUnavailabilities = async () => {
        setImportUrlError(null)
        try {
            const {events: unavailabilities} = await propertyServices.parselCal({url: unavailabilitiesURL});
            addImportedUnavailability(unavailabilities, unavailabilitiesURL);
            setUnavailavilitiesURL('');
        } catch (e) {
            setImportUrlError(e.error && e.error.message || e.message);
        }
    };

    const exportURL = `https://landlord.inlifehousing.com/api/property/exportCalendar/${propertyId}/${roomName}`;

    const copyUrl = async () => {
        await navigator.clipboard.writeText(exportURL)
    };

    return (
        <div className={'AddExternalUnavailabilities basic_container'}>
            <label>{t(componentLabel + '.import_label')}</label>
            <div>
                <div className={'label_as_placeholder'}>
                    <input id={'unavailabilities'} name="unavailabilities" value={unavailabilitiesURL}
                        onChange={value => setUnavailavilitiesURL(value.target.value)}
                           className={`basic_container input_field`} placeholder={t(componentLabel + '.import_label')}/>
                    <label htmlFor="unavailabilities"><Trans
                        i18nKey={componentLabel + '.placeholder'} // optional -> fallbacks to defaults if not provided
                        components={{strong: <b/>}}
                    /></label>
                </div>
                <button type={'button'} className={'btn btn_blue'}
                        onClick={importUnavailabilities}>{t('buttons.add')}</button>
            </div>
            {importUrlError && <p className={'error'}>{t(componentLabel + '.error')}</p>}
            <label>{t(componentLabel + '.export_label')}</label>
            <div>
                <input value={exportURL}
                       className={`basic_container input_field`}
                       id={'export_input'}
                       readOnly={true}
                       onClick={(e) => {
                           e.target.focus();
                           e.target.select();
                       }}/>
                <button type={'button'}
                        className={`btn btn_blue export_button_${localStorage.getItem('i18nextLng')?.split('-')?.[0]}`}
                        onClick={copyUrl}>{t('buttons.copy')}</button>
            </div>
            <p className={'info'}>{t(componentLabel + '.info')}</p>
        </div>
    )
};

const UnavailabilityDatesTable = ({datesArray, t, removeUnavailability, restoreUnavailability, removeEvent, eventUrls}) => {
    const locale = localStorage.getItem('i18nextLng')?.split('-')?.[0];

    if ((!datesArray || datesArray.length === 0) && (!eventUrls || eventUrls.length === 0)) {
        return null;
    }

    return (
        <div className={'input_container'}>
            <label htmlFor={'imported_dates'}>{`${t(pageLabel + '.imported_dates.label')}`}</label>
            <div className={"UnavailabilityDatesTable basic_container"}>
                {eventUrls.map(eventUrl => (<>
                    {<div className={"UnavailabilityEventUrlContainer"}>
                        <p key={eventUrl + "title"} className={"UnavailabilityEventUrl"}>{eventUrl}</p>
                        <img src={binIcon} onClick={() => removeEvent(eventUrl)} alt={"bin icon"}/>
                    </div>}
                    <table key={eventUrl + "table"} cellSpacing="0" cellPadding="0">
                        <tbody>
                        {datesArray.filter(d => d.eventUrl === eventUrl).map(date => (
                            <tr key={date.eventUrl + date.end + date.start} className={(date.removed ? "removed-date" : "")}>
                                <td colSpan="1.8">{<ReactMoment locale={locale}
                                                                format="MMM DD yyyy">{date.start}</ReactMoment>} - {
                                    <ReactMoment
                                        locale={locale} format="MMM DD yyyy">{date.end}</ReactMoment>}</td>
                                <td colSpan="1.8">{date.reason}</td>
                                <td colSpan="1" title={date.domain} className="event-domain">{date.domain}</td>
                                <td colSpan="1">
                                    {date.removed ? <div className={'UnavailabilityDatesTable__delete'}
                                         onClick={() => restoreUnavailability(date)}>
                                        <p>{t('buttons.restore')}</p><img src={restoreIcon} alt={"bin icon"}/>
                                    </div> : <div className={'UnavailabilityDatesTable__delete'}
                                         onClick={() => removeUnavailability(date)}>
                                        <p>{t('buttons.remove')}</p><img src={binIcon} alt={"bin icon"}/>
                                    </div>}
                                </td>
                            </tr>))}
                        </tbody>
                    </table>
                </>))}
            </div>
        </div>)
};

const RoomPrices = ({
                        currentRoomName,
                        currentRoomIndx,
                        updateRoomLevel,
                        currentPageLevel,
                        goToNextRoom,
                        property,
                        goToPreviousRoom,
                        currentRoomHeader,
                        setCurrentRoomIndx,
                        cloneRoomHandler,
                        maxRoom,
                        setMaxRoom,
                        title,
                        changeInfo,
                        cloneValue,
                        defineUpdateFunc
}) => {

    const { t } = useTranslation();

    const {setUser} = useAuth();

    const onSubmit = async (values) => {
        let shouldGoToNextRoom = !values.saveAndExit;
        values.fixedRent = values.fixedRent === 'true';
        if (!values.discount) values.discount = 0;
        if (!values.extraTenantsPrice) values.extraTenantsPrice = 0;
        if (!values.periodStayMin) values.periodStayMin = 0;
        if (!values.periodStayMax) values.periodStayMax = 0;

        values.rentMonths = !values.fixedRent ? currentRoom.rentMonths : {};

        values.unavailability = (values.unavailability || [])
            .filter(item => +item.added === 2 && !!item.start)
            .map(item => ({ ...item, reason: item.reason.value }));

        values.availability_periods = (values.availability_periods || [])
            .filter(item => +item.added === 2 && !!item.move_in && !!item.move_out)

        values.availability_periods = transformAvailabilityPeriods(values.availability_periods);
        currentRoom.availability_periods = values.availability_periods;

        values.hasAvailability = values.availability_periods.length > 0;
        values.importedUnavailability = currentRoom.importedUnavailability;
        values.eventUrls = currentRoom.eventUrls;
        values.openResidenceCalendar = currentRoom.openResidenceCalendar;

        if(!values.availableFrom) {
            values.availableFrom = currentRoom.availableFrom || new Date();
        }

        values.currentLevel = currentPageLevel;
        if (currentRoom.integration_id) {
            values["rent"] = currentRoom.rent;
        }
        if (values.securityDeposit === "specific_value") {
            values.securityDeposit = values.securityDepositAmount;
        }
        if (values.securityDepositSwitch === 'false') {
            values.securityDeposit = null;
        }
        if (values.extraAdministrativeSwitch) {
            values.extraAdministrativeFeeType = values.extraAdministrativeSwitch === 'true' ? (values.extraAdministrativeFeeType || "specific_value") : "no";
            delete values.extraAdministrativeSwitch;
        }

        delete values.securityDepositSwitch;
        delete values.securityDepositAmount;

        try {
            if (!(await updateRoomLevel(values, currentPageLevel, currentRoomIndx) && shouldGoToNextRoom && property.accommodation !== 'apartment')){
                goToNextRoom();
            } else{
                setUser(prev => ({...prev, inProgressListings: (prev.inProgressListings - 1 > 0? prev.inProgressListings - 1 : 0), activeListings: prev.activeListings + 1}));
            }
            setForcedSave(false);
        } catch (e) {
            console.log("error submitting:", e);
        }
    };

    const SpecificValuePriceComponent = () => (
        <PriceComponent
            name={'securityDepositAmount'}
            disabled={currentRoom.securityDeposit !== 'specific_value'}
            value={!isNaN(Number(property.rooms[currentRoomName].securityDeposit)) ? parseInt(property.rooms[currentRoomName].securityDeposit) : ''}
            error={errors.securityDepositAmount}
            watchValue={watchFields.securityDepositAmount}
            register={register}
            rules={currentRoom.securityDeposit === 'specific_value' ? {
                required: {
                    value: currentRoom.securityDeposit === 'specific_value',
                    message: t(pageLabel2 + '.securityDepositAmount.error')
                },
                validate: value => {
                    if (currentRoom.securityDeposit === 'specific_value')
                        return (value > 0 || t(pageLabel2 + '.securityDepositAmount.validation'));
                    else return true;
                }
            } : undefined} />
    );

    const radioButtons = {
        fixedRent: [{id: 'fixedRent', buttonValue: 'true', label: 'yes'},
            {id: 'no_fixedRent', buttonValue: 'false', label: 'no'}],
        extraTenantsPriceBoolean: [{id: 'extratenantsPriceBoolean', buttonValue: 'true', label: 'yes'},
            {id: 'no_extratenantsPriceBoolean', buttonValue: 'false', label: 'no'}],
        mealsIncluded: [{id: 'mealsIncluded', buttonValue: 'yes', label: 'yes'},
            {id: 'price_mealsIncluded', buttonValue: 'price', label: pageLabel + '.mealsIncluded.hasPrice'},
            {id: 'no_mealsIncluded', buttonValue: 'no', label: pageLabel + '.mealsIncluded.no'}
        ],
        securityDepositSwitch: [{id: 'SecurityDepositSwitch', buttonValue: 'true', label: 'yes'},
            {id: 'no_SecurityDepositSwitch', buttonValue: 'false', label: 'no'}],

        securityDeposit: [{id: '1/2month', buttonValue: '1/2month', label: pageLabel2 + '.securityDeposit.1/2month'},
            {id: '1month', buttonValue: '1month', label: pageLabel2 + '.securityDeposit.1month'},
            {id: '3/2months', buttonValue: '3/2months', label: pageLabel2 + '.securityDeposit.3/2months'},
            {id: '2months', buttonValue: '2months', label: pageLabel2 + '.securityDeposit.2months'},
            {id: '5/2months', buttonValue: '5/2months', label: pageLabel2 + '.securityDeposit.5/2months'},
            {id: '3months', buttonValue: '3months', label: pageLabel2 + '.securityDeposit.3months'},
            {
                id: 'specific_value',
                buttonValue: 'specific_value',
                label: pageLabel2 + '.securityDeposit.specific_value',
                component: SpecificValuePriceComponent,
                className: 'inline'
            }],
        periodStayMax: [{id: 'periodStayMax', buttonValue: 'true', label: 'yes'},
            { id: 'no_periodStayMax', buttonValue: 'false', label: 'no' }]
    };

    const initialRoomValue = () => {
        const currentRoomFinal = property.rooms[currentRoomName];
        return {
            ...currentRoomFinal,
            fixedRent: currentRoomFinal.fixedRent?.toString() ? currentRoomFinal.fixedRent?.toString() : 'true',
            rentMonths: currentRoomFinal.rentMonths ? currentRoomFinal.rentMonths : {},
            availableFrom: currentRoomFinal.availableFrom ? new Date(currentRoomFinal.availableFrom) : new Date(),
            firstAvailability: new Date(currentRoomFinal.firstAvailability || currentRoomFinal.availableFrom),
            openResidenceCalendar: currentRoomFinal.openResidenceCalendar,
            extraTenantsPriceBoolean: currentRoomFinal.extraTenantsPrice > 0 ? 'true' : 'false',
            periodStayMaxBoolean: currentRoomFinal.periodStayMax > 0 ? 'true' : 'false',
            unavailability: currentRoomFinal.unavailability ? currentRoomFinal.unavailability : [],
            importedUnavailability: currentRoomFinal.importedUnavailability ? currentRoomFinal.importedUnavailability : [],
            eventUrls: currentRoomFinal.eventUrls ? currentRoomFinal.eventUrls : [],
            securityDepositSwitch: currentRoomFinal.securityDeposit ? (!!currentRoomFinal.securityDeposit).toString() : 'false',
            securityDeposit: securityDepositInitialValue(currentRoomFinal, radioButtons.securityDeposit),
            extraAdministrativeFeeType: currentRoomFinal.hasOwnProperty("extraAdministrativeFeeType") ? currentRoomFinal.extraAdministrativeFeeType : (currentRoomFinal.extraAdministrativeFee > 0 ? "specific_value" : "no"),
            extraAdministrativeFee: currentRoomFinal.hasOwnProperty('extraAdministrativeFee') ? currentRoomFinal.extraAdministrativeFee : 0,
            extraAdministrativeFeeSwitch: currentRoomFinal.hasOwnProperty("extraAdministrativeFeeType") ? (currentRoomFinal.extraAdministrativeFeeType !== "no").toString() : "false",
            hasAvailability: currentRoomFinal.hasOwnProperty('hasAvailability') ? currentRoomFinal.hasAvailability : false,
            availability_periods: currentRoomFinal.availability_periods ? currentRoomFinal.availability_periods : [],
        }
    };

    let [currentRoom, setCurrentRoom] = useState(initialRoomValue());

    useEffect(() => {
        setCurrentRoom(initialRoomValue())
    }, [currentRoomName]);

    const [extraFee, setExtraFee] = useState(currentRoom.extraAdministrativeFee);
    const [extraAdministrativeFeeSwitch, setExtraAdministrativeFeeSwitch] = useState(currentRoom.extraAdministrativeFeeSwitch);
    const [hasAvailability, setHasAvailability] = useState(currentRoom.hasAvailability);
    const [showDeleteAvailabilitiesModal, setShowDeleteAvailabilitiesModal] = useState(false);

    const {
        handleSubmit,
        register,
        errors,
        watch,
        getValues,
        control,
        setValue,
        setError,
        clearErrors,
        formState
    } = useForm({
        shouldFocusError: true,
        mode: 'onChanges',
        reValidateMode: 'onChange'
    });

    const handleRoomFieldChange = (field, value) => {
        setForcedSave(true);
        if (field === "extraAdministrativeFeeType" && (value === "no" || value === "specific_value")) {
            setExtraFee(null);
            setValue("extraAdministrativeFee", null);
        }

        setCurrentRoom(prevState => {
            const newState = {
                ...prevState,
                "extraAdministrativeFee": field === "extraAdministrativeFeeType" &&
                    value === "no" ? 0 : prevState.extraAdministrativeFee,
                [field]: value
            };

            if (field === "availableFrom") {
                const firstAvailability = calcFirstAvailability({
                    ...prevState,
                    availableFrom: value
                });

                return { ...newState, firstAvailability };
            }

            return newState;
        })
    };

    const addImportedUnavailability = (unavailabilitiesArray, eventUrl) => {
        if (currentRoom.eventUrls.includes(eventUrl)) return;
        setForcedSave(true);
        setCurrentRoom(prevState => ({
            ...prevState,
            importedUnavailability: (prevState.importedUnavailability ?
                [...prevState.importedUnavailability, ...unavailabilitiesArray] :
                [unavailabilitiesArray]),
            eventUrls: prevState.eventUrls ? [...prevState.eventUrls, eventUrl] : [eventUrl]
        }))
    };

    const removeEventUrl = (eventUrl) => {
        if (!currentRoom.eventUrls.includes(eventUrl)) return;
        setForcedSave(true);
        setCurrentRoom(prevState => {
            let nextEventUrls = prevState.eventUrls.filter(e => e !== eventUrl);
            let nextImportedUnavailability = prevState.importedUnavailability.filter(unavailability => unavailability.eventUrl !== eventUrl);
            return {
                ...prevState,
                importedUnavailability: nextImportedUnavailability,
                eventUrls: nextEventUrls
            };
        });
    };

    const removeImportedUnavailability = (unavailabilityToRemove) => {
        setForcedSave(true);
        setCurrentRoom(prevState => {
            return {
                ...prevState,
                importedUnavailability: prevState.importedUnavailability.map(unavailability => {
                    if (unavailability === unavailabilityToRemove) {
                        return {...unavailability, removed: true};
                    }
                    return unavailability;
                })
            }
        })
    };

    const restoreImportedUnavailability = (unavailabilityToRestore) => {
        setForcedSave(true);
        setCurrentRoom(prevState => {
            return {
                ...prevState,
                importedUnavailability: prevState.importedUnavailability.map(unavailability => {
                    if (unavailability === unavailabilityToRestore) {
                        return {...unavailability, removed: false};
                    }
                    return unavailability;
                })
            }
        })
    }

    const clearUnavailabilities = () => {
        setForcedSave(true);
        setCurrentRoom(prevState => {
            return {
                ...prevState,
                unavailability: [],
                importedUnavailability: [],
                eventUrls: []
            }
        });
    };

    const clearAvailabilities = () => {
        setForcedSave(true);
        setCurrentRoom(prevState => {
            return {
                ...prevState,
                availability_periods: []
            }
        });
    };

    const watchFields = watch([
        'rent',
        'extraTenantsPriceBoolean',
        'periodStayMaxBoolean',
        'fixedRent',
        'unavailability',
        'availableFrom',
        'extraTenantsPrice',
        'mealsIncluded',
        'mealsAveragePrice',
        'securityDepositSwitch',
        'securityDeposit',
        'securityDepositAmount',
        'extraAdministrativeFeeType',
        'extraAdministrativeSwitch',
        'hasAvailability',
        'availability_periods',
    ]);

    const [forcedSave, setForcedSave] = useState(false);

    useEffect(() => {
        if (
            (!watchFields.unavailability ||
                watchFields.unavailability?.filter(
                    (unavailabilities) => unavailabilities.added === 2 && !unavailabilities.removed
            ).length === currentRoom.unavailability?.filter(
                    (unavailabilities) => unavailabilities.added === 2 && !unavailabilities.removed
                ).length) &&
            (!watchFields.availability_periods ||
                watchFields.availability_periods?.filter((availabilities) =>
                    availabilities.added === 2).length === currentRoom.availability_periods?.filter((availabilities) =>
                        availabilities.added === 2).length)
        ) {
            return;
        }

        setCurrentRoom((prevState) => {
            let firstAvailability = calcFirstAvailability({
                ...prevState,
                unavailability: watchFields.unavailability,
                availability_periods: transformAvailabilityPeriods(watchFields.availability_periods)
            });
            return {
                ...prevState,
                firstAvailability,
                unavailability: watchFields.unavailability,
                availability_periods: transformAvailabilityPeriods(watchFields.availability_periods)
            };
        });
    }, [
        currentRoom.unavailability,
        currentRoom.importedUnavailability,
        currentRoom.availableFrom,
        currentRoom.availability_periods,
        watchFields.unavailability,
        watchFields.availability_periods
    ]);

    useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
        if (!forcedSave && (!formState.isDirty || (Object.keys(formState.dirtyFields).length === 1 && formState.dirtyFields.periodStayMax && watchFields.periodStayMaxBoolean === "false"))) return resolve();
        await handleSubmit(async (values) => {
            await onSubmit(values);
            resolve()
        }, resolve)()
    })), [currentRoom, formState.isDirty, formState.dirtyFields, currentRoom.rentMonths, watchFields.periodStayMaxBoolean, forcedSave]);

    useEffect(() => {
        if (errors) {
            const anchor = document.querySelector(`#${Object.keys(errors)[0]}`);
            if (anchor) anchor.scrollIntoView({behavior: 'smooth', block: 'center'})
        }
    }, [errors]);

    const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

    const handleAdminFeeSwitch = (field, value) => {
        setExtraAdministrativeFeeSwitch(value)
        if (!value) {
            handleRoomFieldChange("extraAdministrativeFeeType", "no");
            handleRoomFieldChange("extraAdministrativeFee", 0); //ISSUE 3078
        }
    };

    const radioButtonsExtraAdministrativeSwitch = [{id: 'extra_administrative_yes', buttonValue: 'true', label: 'yes'},
        {id: 'extra_administrative_no', buttonValue: 'false', label: 'no'}];

    const radioButtonsExtraAdministrativeFee = ["1/2month", "1month", "3/2months", "2months", "5/2months", "3months", "specific_value"].map(type => {
        let label = 'main_page.my_ads.create_ad.house_details.landlord_rules.level4';
        return {
            id: type,
            buttonValue: type,
            label: type === "no" ? type : label + ".securityDeposit." + type
        };
    });

    const handleAvailabilityCheckboxChange = () => {
        if (!hasAvailability) {
            setHasAvailability(true);
            handleRoomFieldChange("hasAvailability", true);
        } else if (hasAvailability && !watchFields.availability_periods) {
            setHasAvailability(false);
            handleRoomFieldChange("hasAvailability", false);
        } else {
            setShowDeleteAvailabilitiesModal(true);
        }
    }

    const handleDeleteAvailabilitiesModalClose = () => {
        clearAvailabilities();
        setShowDeleteAvailabilitiesModal(false);
        setHasAvailability(false);
        handleRoomFieldChange("hasAvailability", false);
    }

    return (
        <>
        <div className={'RoomPrices'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'scrollable'}>
                    {property.accommodation === 'apartment'
                        ? <h4 className={'title'}>{title}</h4>
                        : <RoomDetailsHeader key={currentPageLevel + currentRoom.idx} currentRoom={currentRoomHeader}
                                             setCurrentRoomIndx={setCurrentRoomIndx} property={property}
                                             cloneRoomHandler={cloneRoomHandler} t={t}
                                             maxRoom={maxRoom} setMaxRoom={setMaxRoom} rooms={property.rooms}
                                             currentPageLevel={currentPageLevel}
                                             title={title}
                                             changeInfo={changeInfo}
                                             cloneValue={cloneValue}/>}

                    <div className={'input_container'}>
                        <label htmlFor={'fixedRent'}>{`${t(pageLabel + '.fixedRent.label')}`}</label>
                        <RadioButtons radioButtons={radioButtons.fixedRent} name={'fixedRent'}
                                      value={currentRoom.fixedRent}
                                      className={'double'} control={control} rules={{required: true}}
                                      setValue={handleRoomFieldChange}/>
                    </div>

                    <div className={'input_container_double input_info'}>
                        <div className={'input_container'}>
                            <label htmlFor={'rent'}>{t(pageLabel + '.rent.label')}</label>
                            <PriceComponent name="rent" type="number"
                                            value={currentRoom.rent ? currentRoom.rent : ''}
                                            onChange={(value) => {
                                                handleRoomFieldChange('rent', value);
                                                let changedRentMonths = {};
                                                months.forEach(month => changedRentMonths[month] = value);
                                                handleRoomFieldChange('rentMonths', changedRentMonths);
                                            }}
                                            onWheel={(e) => e.target.blur()}
                                            placeholder={`€400`}
                                            watchValue={watchFields.rent}
                                            register={register}
                                            integrationKey={["habyt", "homii"].includes(property.integration_platform) ? null : currentRoom.integration_id}
                                            rules={{required: `${t(pageLabel + '.rent.error')}`, valueAsNumber: true}}
                                            className={`${errors.rent ? 'input_error' : null} input_field  basic_container`}/>
                            {errors.rent && <p className={'error_message'}>{errors.rent.message}</p>}
                        </div>


                        <div className={'input_container'}>
                            <label htmlFor={'discount'}>{`${t(pageLabel + '.discount.label')}`}</label>
                            <AmountButtonsInput name={'discount'} value={currentRoom.discount} placeholder={'0%'}
                                                min={0}
                                                id={'discount'}
                                                watchValue={watch('discount')} setValue={setValue}
                                                amount={1}
                                                className={`${errors.discount ? 'input_error' : null} input_field`}
                                                register={register({valueAsNumber: true})}/>
                            {errors.discount && <p className={'error_message'}>{errors.discount.message}</p>}
                        </div>
                        {<p className={'info'}>{t(pageLabel + '.rent.info')}</p>}
                    </div>


                    {watchFields.fixedRent === 'false' &&
                        <RentByMonth fixedPrice={watchFields.fixedRent} rent={currentRoom.rent} t={t}
                                     register={register} errors={errors}
                                     rentMonths={currentRoom.rentMonths}
                                     setRentMonths={(value) => handleRoomFieldChange('rentMonths', value)}
                                     pageLabel={pageLabel}/>}


                    {/*ExtraTenantsPrice*/}
                    <div>
                        <div className={'input_container'}>
                            <label
                                htmlFor={'extraTenantsPriceBoolean'}>{`${t(pageLabel + '.extraTenantsPrice.label')}`}</label>
                            <RadioButtons radioButtons={radioButtons.extraTenantsPriceBoolean}
                                          name={'extraTenantsPriceBoolean'}
                                          value={currentRoom.extraTenantsPriceBoolean}
                                          control={control}
                                          setValue={handleRoomFieldChange} className={'double'}/>
                        </div>

                        {watchFields.extraTenantsPriceBoolean === 'true' &&
                            <div className={'input_container half_input_container'}>
                                <label
                                    htmlFor={'extraTenantsPrice'}>{t(pageLabel + '.extraTenantsPrice.value_label')}</label>
                                <PriceComponent
                                    name={'extraTenantsPrice'}
                                    disabled={watchFields.extraTenantsPriceBoolean === 'false'}
                                    value={currentRoom.extraTenantsPrice ? currentRoom.extraTenantsPrice : ''}
                                    error={errors.extraTenantsPrice}
                                    watchValue={watchFields.extraTenantsPrice}
                                    register={register}
                                    rules={watchFields.extraTenantsPriceBoolean === 'true' ? {
                                        required: t(pageLabel + '.extraTenantsPrice.required'),
                                        validate: value => value > 0 || t(pageLabel + '.extraTenantsPrice.required')
                                    } : undefined}/>
                            </div>}
                    </div>

                    {/*Meals Included: yes / price / no*/}
                    <div>
                        <div className={'input_container'}>
                            <label htmlFor={'mealsIncluded'}>{`${t(pageLabel + '.mealsIncluded.label')}`}</label>
                            <RadioButtons radioButtons={radioButtons.mealsIncluded} name={'mealsIncluded'}
                                          value={currentRoom.mealsIncluded || "no"}
                                          control={control}
                                          setValue={handleRoomFieldChange} className={'double'}/>
                        </div>
                        {watchFields.mealsIncluded === 'price' &&
                            <div className={'input_container half_input_container'}>
                                <label
                                    htmlFor={'mealsAveragePrice'}>{t(pageLabel + '.mealsIncluded.value_label')}</label>
                                <PriceComponent
                                    name={'mealsAveragePrice'}
                                    disabled={["yes", "no"].includes(watchFields.mealsIncluded)}
                                    value={currentRoom.mealsAveragePrice ? currentRoom.mealsAveragePrice : ''}
                                    error={errors.mealsAveragePrice}
                                    watchValue={watchFields.mealsAveragePrice}
                                    register={register}
                                    rules={watchFields.mealsIncluded === 'price' ? {
                                        required: t(pageLabel + '.mealsIncluded.required'),
                                        validate: value => value > 0 || t(pageLabel + '.mealsIncluded.required')
                                    } : undefined}/>
                            </div>}
                    </div>

                    {/*Security Deposit*/}
                    <div>
                        <div className={'input_container'}>
                            <label
                                htmlFor={'securityDepositSwitch'}>{`${t(pageLabel + '.securityDeposit.label')}`}</label>
                            <RadioButtons radioButtons={radioButtons.securityDepositSwitch}
                                          name={'securityDepositSwitch'}
                                          value={currentRoom.securityDepositSwitch || "no"}
                                          control={control}
                                          setValue={handleRoomFieldChange} className={'double'}/>
                            {currentRoom?.securityDepositSwitch === 'true' &&
                                <RadioButtons id={'securityDeposit'} radioButtons={radioButtons.securityDeposit}
                                              name={'securityDeposit'}
                                              value={currentRoom.securityDeposit} t={t}
                                              className={'double second_input_container'}
                                              watchValue={watchFields.securityDeposit}
                                              control={control}
                                              rules={{required: t(pageLabel + '.securityDeposit.required')}}
                                              setValue={handleRoomFieldChange}/>}
                        </div>
                    </div>

                    {/*Extra Admin Fee*/}
                    <div>
                        <div className={'input_container'}>
                            <label
                                htmlFor={'extraAdministrativeFeeSwitch'}>{`${t(pageLabel3 + '.extraAdministrativeFee.label')}`}</label>
                            <RadioButtons id={"extraAdministrativeSwitch"}
                                          radioButtons={radioButtonsExtraAdministrativeSwitch}
                                          name={'extraAdministrativeSwitch'}
                                          value={currentRoom.extraAdministrativeFeeSwitch}
                                          setValue={handleAdminFeeSwitch} t={t} className={'triple'}
                                          control={control} rules={{required: true}}/>
                            {extraAdministrativeFeeSwitch === "true" &&
                                <RadioButtons radioButtons={radioButtonsExtraAdministrativeFee}
                                              name={'extraAdministrativeFeeType'}
                                              value={currentRoom.extraAdministrativeFeeType}
                                              setValue={handleRoomFieldChange} t={t} className={'triple'}
                                              control={control} rules={{required: true}}/>}
                            {extraAdministrativeFeeSwitch === "true" &&
                                <>
                                    <label
                                        style={{marginTop: "5px"}}>{t('main_page.my_ads.create_ad.house_details.landlord_rules.' + (watchFields.extraAdministrativeFeeType === "specific_value" ? 'level4.securityDeposit.specific_value' : 'additional_value')) + (!["no", "specific_value"].includes(watchFields.extraAdministrativeFeeType || "no") ? " (+ " + t('main_page.my_ads.create_ad.house_details.landlord_rules.level4.securityDeposit.' + watchFields.extraAdministrativeFeeType).toLowerCase() + ")" : "") + ":"}</label>

                                    <PriceComponent
                                        name="extraAdministrativeFee"
                                        value={currentRoom.extraAdministrativeFee}
                                        disabled={watchFields.extraAdministrativeFeeType === "no"}
                                        error={errors.extraAdministrativeFee}
                                        placeholder={watchFields.extraAdministrativeFeeType === "specific_value" ?
                                            t('main_page.my_ads.create_ad.house_details.landlord_rules.level4.securityDeposit.specific_value') :
                                            "0"
                                        }
                                        forceShowTag={watchFields.extraAdministrativeFeeType !== "specific_value"}
                                        watchValue={extraFee}
                                        register={register}
                                        onChange={value => setExtraFee(value)}
                                        rules={watchFields.extraAdministrativeFeeType === 'specific_value' ? {
                                            required: watchFields.extraAdministrativeFeeType === "specific_value" ? {value: true, message: t(pageLabel3 + '.extraAdministrativeFee.error')} : undefined,
                                            validate: value => value > 0 || t(pageLabel3 + '.extraAdministrativeFee.error')
                                        } : undefined}/>
                                </>}
                        </div>
                    </div>

                    {/*Available from*/}
                    <div id={'availabilities'}>
                        <div id={'availabilitiesInfo'}>
                            <div className={'input_container_double input_info'}>
                                <div className={'input_container'}>
                                    <label htmlFor={'availableFrom'}>{t(pageLabel + '.availableFrom.label')}</label>
                                    <CustomDatePicker
                                        selected={currentRoom.availableFrom ? moment(currentRoom.availableFrom).toDate() : new Date()}
                                        showIcon={true} minDate={new Date()}
                                        disabled={
                                            currentRoom.openResidenceCalendar ||
                                            (["habyt", "homii"].includes(property.integration_platform) ? false : currentRoom.integration_id)
                                        }
                                        className={`${errors.availableFrom ? 'input_error' : null} input_field basic_container`}
                                        control={control} name={'availableFrom'} onChange={(date) => {
                                        handleRoomFieldChange("availableFrom", date);
                                    }}
                                        rules={{required: t(pageLabel + '.availableFrom.required')}}
                                        placeholder={'1 Jan 2021'}/>
                                    {errors.availableFrom &&
                                        <p className={'error_message'}>{errors.availableFrom.message}</p>}
                                    {property.accommodation === "residence" && <div>
                                        <CheckBox value={currentRoom.openResidenceCalendar}
                                                  onChange={() => {
                                                      if (currentRoom.openResidenceCalendar) {
                                                          clearUnavailabilities()
                                                      }
                                                      handleRoomFieldChange("openResidenceCalendar", !currentRoom.openResidenceCalendar);
                                                  }}
                                                  generalClassName={'openResidenceCalendar transparent'}
                                                  label={t(pageLabel + '.openResidenceCalendar.label')}/>
                                    </div>}
                                </div>

                                <div className={'input_container'}>
                                    <label
                                        htmlFor={'periodStayMin'}>{`${t(pageLabel + '.periodStayMin.label')}`}</label>
                                    <AmountButtonsInput name={'periodStayMin'}
                                        value={currentRoom.periodStayMin ? currentRoom.periodStayMin : 1}
                                        placeholder={'1'}
                                        min={1}
                                        amount={1}
                                        watchValue={watch('periodStayMin')} setValue={setValue}
                                        className={`${errors.periodStayMin ? 'input_error' : null} input_field`}
                                        register={register({
                                            required: `${t(pageLabel + '.periodStayMin.required')}`,
                                            valueAsNumber: true,
                                            max: currentRoom.periodStayMaxBoolean && watch('periodStayMax') ? watch('periodStayMax') : 99
                                        })}
                                            disabled={hasAvailability} />
                                    {errors.periodStayMin &&
                                        <p className={'error_message'}>{errors.periodStayMin.message}</p>}
                                </div>
                            </div>

                            <div className='input_container label_bold checkbox'>
                                    <CheckBox value={hasAvailability}
                                    onChange={() => {
                                        handleAvailabilityCheckboxChange();
                                    }}
                                    generalClassName={'transparent fit noPadding'}
                                    label={t(pageLabel + '.availabilitySlots.checkbox')}
                                />
                                <div className="infoContainerWithIcon">
                                    <img src={infoIcon} className="infoIcon" alt={"info icon"} />
                                    <p className='infoText'>
                                        <Trans t={t} i18nKey={pageLabel + '.availabilitySlots.info'} />
                                    </p>
                                </div>
                            </div>

                                {(currentRoom.hasAvailability || hasAvailability) && <div>
                                    <div className={'input_container'}>
                                        <label htmlFor={'availability_periods'}>
                                            {t(pageLabel + '.availabilitySlots.label')}
                                        </label>
                                        <AvailabilitySlots errors={errors}
                                            control={control}
                                            watch={watch}
                                            pageLabel={pageLabel}
                                            name={'availability_periods'}
                                            value={currentRoom.availability_periods}
                                            t={t}
                                            getValues={getValues}
                                            setValue={setValue}
                                            register={register}
                                            setError={setError}
                                            setForcedSave={setForcedSave}
                                            clearErrors={clearErrors}
                                            integrationKey={["habyt", "homii"].includes(property.integration_platform) ? false : !!currentRoom.integration_id}
                                        />
                                    </div>
                                </div>}

                            <div className={'input_container'}>
                                <label
                                    htmlFor={'periodStayMaxBoolean'}>{`${t(pageLabel + '.periodStayMax.label')}`}</label>
                                <RadioButtons radioButtons={radioButtons.periodStayMax} name={'periodStayMaxBoolean'}
                                              value={currentRoom.periodStayMaxBoolean}
                                              setValue={handleRoomFieldChange} t={t} className={'double'}
                                              control={control} rules={{required: true}}/>
                            </div>

                            {watchFields.periodStayMaxBoolean !== 'false' &&
                                <div className={'input_container_double input_info'}>
                                    <div className={'input_container'}>
                                        <label
                                            htmlFor={'periodStayMax'}>{`${t(pageLabel + '.periodStayMax.value_label')}`}</label>
                                        <AmountButtonsInput name={'periodStayMax'}
                                                            value={currentRoom.periodStayMax || currentRoom.periodStayMin + 1}
                                                            placeholder={'1'}
                                                            watchValue={watch('periodStayMax')}
                                                            amount={1}
                                                            className={`${errors.periodStayMax ? 'input_error' : null} input_field`}
                                                            register={register({
                                                                required: {
                                                                    value: watchFields.periodStayMaxBoolean === 'true',
                                                                    message: `${t(pageLabel + '.periodStayMax.required')}`
                                                                },
                                                                valueAsNumber: true,
                                                                max: 99,
                                                                min: {
                                                                    value: watchFields.periodStayMaxBoolean === 'true' && watch('periodStayMin') ? watch('periodStayMin') : 1,
                                                                    message: `${t(pageLabel + '.periodStayMax.required')}`
                                                                }
                                                            })}
                                                            disabled={watchFields.periodStayMaxBoolean === 'false'}
                                                            setValue={setValue}/>
                                        {errors.periodStayMax &&
                                            <p className={'error_message'}>{errors.periodStayMax.message}</p>}
                                    </div>
                                </div>}

                            {!currentRoom.openResidenceCalendar && <>
                                <div className={'input_container'}>
                                    <label
                                        htmlFor={'unavailability'}>{`${t(pageLabel + '.unavailability.label')}`}</label>
                                    {property.accommodation === "residence" &&
                                        <small className={"unavailability-residence-warning"}>
                                            <img src={infoIcon}
                                                 alt={"info icon"}/> {t(pageLabel + '.unavailability.residence_warning')}
                                        </small>}
                                    <UnavailabilityPeriodTable errors={errors} control={control} pageLabel={pageLabel}
                                                               name={'unavailability'}
                                                               value={currentRoom.unavailability?.filter(item => item.end && moment(item.end).isSameOrAfter(moment()))}
                                                               t={t} watch={watch}
                                                               getValues={getValues} setValue={setValue}
                                                               register={register}
                                                               setError={setError} setForcedSave={setForcedSave}
                                                               clearErrors={clearErrors}
                                                               integrationKey={["habyt", "homii"].includes(property.integration_platform) ? false : !!currentRoom.integration_id}/>
                                </div>

                                <div className={'input_container'}>
                                    <label
                                        htmlFor={'calendars_sync'}>{`${t(pageLabel + '.calendars_sync.label')}`}</label>
                                    <AddExternalUnavailabilities addImportedUnavailability={addImportedUnavailability}
                                                                 roomName={currentRoomName}
                                                                 isApartment={property.accommodation === 'apartment'}
                                                                 propertyId={property.id}/>
                                </div>


                                <UnavailabilityDatesTable datesArray={currentRoom.importedUnavailability}
                                                          eventUrls={currentRoom.eventUrls} removeEvent={removeEventUrl}
                                                          restoreUnavailability={restoreImportedUnavailability}
                                                          removeUnavailability={removeImportedUnavailability} t={t}/>
                            </>}
                        </div>

                            {!hasAvailability ?
                                <OverviewCalendar
                                    t={t}
                                    watchUnavailability={watchFields.unavailability}
                                    openResidenceCalendar={currentRoom.openResidenceCalendar}
                                    datesArray={currentRoom.importedUnavailability}
                                    availableFrom={currentRoom.firstAvailability}
                                    pageLabel={pageLabel}
                                />
                                :
                                <OverviewSlots
                                    t={t}
                                    watchAvailability={watchFields.availability_periods}
                                    watchUnavailability={watchFields.unavailability}
                                    availableFrom={currentRoom.availableFrom}
                                    pageLabel={pageLabel}
                                />
                            }
                    </div>
                </div>

                {/*Clean the unavailability erros before submiting the form*/}
                <Buttons executeOnAdvance={() => clearErrors('unavailability')}
                         saveAndExit={handleSubmit(v => onSubmit({...v, saveAndExit: true}))}
                         clickBack={goToPreviousRoom}/>
            </form>
        </div>
            <Dialog open={showDeleteAvailabilitiesModal}
                className="delete-availabilities-modal"
            >
                <DialogTitle>
                    {t(pageLabel + '.availabilitySlots.modal.title')}
                </DialogTitle>
                <DialogContent className="delete-availabilities-modal__content">
                    <p>{t(pageLabel + '.availabilitySlots.modal.infoText')}</p>
                    <h5>{t(pageLabel + '.availabilitySlots.modal.question')}</h5>
                    <div className="delete-availabilities-modal__buttons">
                        <button className="btn btn-secondary" onClick={() => setShowDeleteAvailabilitiesModal(false)}>
                            {t(pageLabel + '.availabilitySlots.modal.cancel')}
                        </button>
                        <button className="btn btn-primary" onClick={handleDeleteAvailabilitiesModalClose}>
                            {t(pageLabel + '.availabilitySlots.modal.confirm')}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

const actionCreator = {
    defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(RoomPrices);
