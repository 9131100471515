import {IoMdCheckmark} from 'react-icons/io';
import React from 'react';
import {toggleObjects} from '../../../../../../../../utils/toogleObjects';

import CheckBox from '../../../../../../../../components/CheckBox';

import './style.less'
import {useTranslation} from 'react-i18next';

const FieldSetCheckBoxes = ({
                              checkBoxesNames,
                              checkBoxesNamesMapped,
                              name,
                              register,
                              amountInLine,
                              type,
                              values,
                              setValues,
                              defaultObjectLabel
                            }) => {

  const {t} = useTranslation();

  let checkBoxesOptions = []

  if (checkBoxesNamesMapped) checkBoxesOptions = checkBoxesNamesMapped;
  else checkBoxesOptions = checkBoxesNames.map(adjective => ({
    value: adjective,
    label: t(defaultObjectLabel(adjective))
  }))

  return (<div className={'FieldSetCheckBoxes'}>
    <fieldset style={{float: 'left'}} className={`${amountInLine}`} ref={register} name={name}>
      {checkBoxesOptions.map((option, i) => (
        <CheckBox key={option.value}
                  generalClassName={type}
                  generalStyle={option.styleLabel}
                  value={option.value}
                  onChange={() => toggleObjects(option.value, values, setValues)}
                  register={register}
                  name={option.value}
                  label={option.label}
                  checked={values?.find((value) => value === option.value)}
                  refCheckBox={true}/>
      ))}
    </fieldset>
  </div>)
}

const getInitialFieldSetValues = (valuesObject, options) => {
  let finalValues = [];
  options.map(comoditie => {
    if (valuesObject?.[comoditie] === true) finalValues.push(comoditie)
  })
  return finalValues;
}

const getFinalValues = (values, optionsArray, finalSelectedArray) => {
  let valuesObject = {...values}
  optionsArray.map(option => {
    if (finalSelectedArray.includes(option)) valuesObject[option] = true;
    else valuesObject[option] = false;
  })

  return valuesObject;
}

module.exports = {FieldSetCheckBoxes, getInitialFieldSetValues, getFinalValues}