import * as React from "react";

function SvgUg(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M512.001 56.184H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v47.081z"
          fill="#464655"
        />
        <path fill="#FFE15A" d="M0 56.188h512v55.908H0z" />
        <path fill="#FF4B55" d="M0 112.088h512v55.908H0z" />
        <path fill="#464655" d="M0 168h512v55.908H0z" />
        <path fill="#FFE15A" d="M0 223.912h512v55.908H0z" />
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896v-47.081h512v47.081a8.828 8.828 0 01-8.828 8.828z"
          fill="#FF4B55"
        />
        <circle fill="#F5F5F5" cx={256} cy={168} r={52.966} />
        <path
          d="M259.194 135.753l5.217-2.087c.756-.302 1.122-1.17.787-1.911-1.17-2.594-4.305-7.962-10.263-8.124-3.773-.102-6.594.748-8.186 1.405a1.439 1.439 0 00-.696 2.062l3.148 5.378 9.993 3.277z"
          fill="#FF4B55"
        />
        <path
          d="M254.935 123.63c-.434-.012-.825.013-1.234.025l-.568 8.329 3.03 1.678 6.554-5.93c-1.725-2.087-4.255-4.007-7.782-4.102z"
          fill="#FFE15A"
        />
        <path
          d="M277.377 184.732l-23.588-27.52c0-5.696 2.058-10.347 3.196-12.486 3.247-1.45 5.341-5.021 4.469-8.962-.633-2.867-2.947-5.206-5.809-5.86-5.15-1.177-9.719 2.702-9.719 7.65 0 .186.042.361.055.544-5.088 1.342-6.034 2.978-6.034 2.978s3.335-.887 6.229-1.645c.058.236.136.463.214.691l-5.707 1.938 6.232-.758a7.974 7.974 0 002.957 3.046c-4.658 3.752-11.81 10.803-11.81 18.763 0 7.278 5.391 12.128 12.181 15.352 2.374 1.128 4.555 2.615 6.349 4.535l.067.072.59 9.035h-13.29c-4.915 0-6.265 3.194-6.265 8.477l2.334-3.317 1.965 3.686.246-4.178 2.089 2.702s-.021-3.53 2.642-3.993c1.412-.246 6.307-.553 10.465-.553l.881 13.464c-5.639-.026-12.021 2.63-12.021 2.63 1.965-.369 6.758.369 6.758.369l-.799 2.334c5.528-3.01 15.665-3.072 15.665-3.072s-2.211-1.508-7.371-2.098l.119-13.628h8.111l1.228-3.194s-4.185-5.553-4.185-8.493c6.489 1.232 11.556 1.491 11.556 1.491zm-16.686 7.372l.041-4.674 4.361 4.674h-4.402z"
          fill="#464655"
        />
        <path
          d="M277.377 188.663c-1.965-7.863-10.58-10.28-19.657-15.726-9.828-5.897-3.932-15.726-3.932-15.726s32.926 9.665 35.383 23.588c1.913 10.844-5.897 17.692-9.828 19.657l1.412-3.932h-7.31s7.74-6.02 7.863-11.795l-3.931 3.934z"
          fill="#696E7D"
        />
        <path
          d="M289.172 180.8c-1.643-9.309-16.893-16.707-26.829-20.594-3.18-.354-5.974 4.991 2.749 10.765 11.735 7.768 12.286 17.692 12.286 17.692l3.932-3.932c-.122 5.774-7.863 11.795-7.863 11.795h7.31l-1.412 3.932c3.93-1.966 11.74-8.813 9.827-19.658z"
          fill="#FFFCFF"
        />
        <path
          d="M289.172 180.8c-1.089-6.169-8.158-11.492-15.693-15.517-3.629-1.085-6.79-.946-6.79-.946 2.211 1.352 3.194 4.791 3.194 4.791-1.597-.983-5.16-.491-5.16-.491.991.85 1.93 2.582 2.558 3.911 9.609 7.469 10.097 16.116 10.097 16.116l3.932-3.932c-.122 5.774-7.863 11.795-7.863 11.795h7.31l-1.412 3.932c3.93-1.967 11.74-8.814 9.827-19.659z"
          fill="#FF4B55"
        />
        <circle fill="#F5F5F5" cx={250.593} cy={136.817} r={1.311} />
        <path
          d="M247.155 141.813s-2.867 3.358-.983 4.341c1.884.983 3.701-1.809 3.701-1.809l-2.718-2.532z"
          fill="#FF4B55"
        />
      </g>
    </svg>
  );
}

export default SvgUg;
