import React, { useMemo } from "react";
import "./style.less";

import moment from "moment";
import "moment/locale/pt";
import { useTranslation } from 'react-i18next';
import EmptyCalendarIcon from "../../../../../../../../../../../../../components/svg/EmptyCalendar";
import InfoCircleIcon from "../../../../../../../../../../../../../components/svg/InfoCircle";
import CompareArrowsIcon from "../../../../../../../../../../../../../components/svg/CompareArrows";
import CircleDownArrowIcon from "../../../../../../../../../../../../../components/svg/CircleDownArrow";
import { transformAvailabilityPeriods } from "../../../../../../../../../../../../../utils/transformers/availabilityPeriods";

moment().locale(localStorage.getItem("i18nextLng")?.split("-")?.[0]);

const OverviewSlots = ({
  pageLabel,
  watchUnavailability: unavailabilities,
  watchAvailability: availabilities,
  availableFrom
}) => {
  const { t, i18n } = useTranslation();

  const filteredUnavailabilities =
    unavailabilities?.filter((item) => +item.added === 2 && !!item.start && !!item.end) ?? [];

  const sortedUnavailabilities = useMemo(() => {
    return filteredUnavailabilities.sort((a, b) => {
      const startDateA = Array.isArray(a.start) ? new Date(a.start[0]) : new Date(a.start);
      const startDateB = Array.isArray(b.start) ? new Date(b.start[0]) : new Date(b.start);
      return startDateA - startDateB;
    });
  }, [unavailabilities]);

  const filteredAvailabilities =
    availabilities?.filter((item) => +item.added === 2 && !!item.move_in && !!item.move_out) ?? [];

  const sortedAvailabilities = useMemo(() => {
    return filteredAvailabilities?.sort((slot1, slot2) => {
      const slot1moveInStart = new Date(slot1.move_in[0]);
      const slot2moveInStart = new Date(slot2.move_in[0]);
      let slot1moveInEnd = new Date(slot1.move_in[1]);
      let slot2moveInEnd = new Date(slot2.move_in[1]);
      slot1moveInStart.setFullYear(availableFrom.getFullYear());
      slot2moveInStart.setFullYear(availableFrom.getFullYear());
      slot1moveInEnd.setFullYear(availableFrom.getFullYear());
      slot2moveInEnd.setFullYear(availableFrom.getFullYear());
      while(slot1moveInEnd < slot1moveInStart) slot1moveInEnd.setFullYear(slot1moveInEnd.getFullYear() + 1)
      while(slot2moveInEnd < slot2moveInStart) slot2moveInEnd.setFullYear(slot2moveInEnd.getFullYear() + 1)
      let betweenSlot1 = availableFrom >= slot1moveInStart && availableFrom <= slot1moveInEnd;
      let betweenSlot2 = availableFrom >= slot2moveInStart && availableFrom <= slot2moveInEnd;
      if (betweenSlot1 && !betweenSlot2) {
        return -1;
      } else if (!betweenSlot1 && betweenSlot2) {
        return 1;
      } else if (betweenSlot1 && betweenSlot2) {
        return slot1moveInEnd - slot2moveInEnd;
      }
      if(slot1.temporary_year) slot1moveInStart.setFullYear(slot1.temporary_year-1);
      if(slot2.temporary_year) slot2moveInStart.setFullYear(slot2.temporary_year-1);
      while (slot1moveInStart < availableFrom) slot1moveInStart.setFullYear(slot1moveInStart.getFullYear() + 1);
      while (slot2moveInStart < availableFrom) slot2moveInStart.setFullYear(slot2moveInStart.getFullYear() + 1);
      return slot1moveInStart - slot2moveInStart;
    });
  }, [availabilities])

  const transformedAvailabilities = useMemo(() => {
    return transformAvailabilityPeriods(sortedAvailabilities);
  }, [availabilities]);

  const combinedArray = useMemo(() => {
    return transformedAvailabilities.concat(sortedUnavailabilities);
  }, [unavailabilities, availabilities]);

  const transformDay = (date) => {
    return String(date).padStart(2, "0");
  }

  return (
    <>
      <div className="overview-slots">
        <label htmlFor="periods_overview" className="overview-slots__title">
          {t(pageLabel + ".overviewSlots.label")}
        </label>
        <div className="overview-slots__info">
          <InfoCircleIcon />
          <p>{t(pageLabel + ".overviewSlots.info")}</p>
        </div>

        <div className="overview-slots__slots">
          <div className="overview-slots__slots__title">
            <label htmlFor="available_periods">{t(pageLabel + ".overviewSlots.periodLabel")}</label>
          </div>

          <div className="overview-slots__slots__container">
            {combinedArray.length > 0 ? (
              combinedArray
                .map((slot, index) => {
                  return (
                    <div key={index} className={`overview-slots__slots__container__slot-wrapper ${slot.reason ? "unavailable" : "available"} ${slot.temporary_year ? "automatic" : ""}`}>
                      <span className={"slow-wrapper-title"}>
                        {slot.temporary_year && t(pageLabel + ".overviewSlots.temporaryYear", { year: slot.temporary_year })}
                      </span>
                      <div className={"overview-slots__slots__container__slot"}>
                        <div className="overview-slots__slots__container__slot__info">
                          <h5>{t(pageLabel + ".overviewSlots.moveIn")}</h5>
                          {slot.start && <p>{new Date(slot.start).toLocaleDateString()}</p>}
                          {slot.move_in && <>
                            <p>{`${transformDay(slot.move_in?.start?.day)}/${transformDay(slot.move_in?.start?.month)}`}</p>
                            <CircleDownArrowIcon />
                            <p>{`${transformDay(slot.move_in?.end?.day)}/${transformDay(slot.move_in?.end?.month)}`}</p>
                          </>}
                        </div>

                        <div className="overview-slots__slots__container__slot__separator">
                          <div className="overview-slots__slots__container__slot__separator__line">
                            &nbsp;
                          </div>
                          <div className="overview-slots__slots__container__slot__separator__icon-container">
                            <CompareArrowsIcon className="icon" />
                          </div>
                          <div className="overview-slots__slots__container__slot__separator__line">
                            &nbsp;
                          </div>
                        </div>

                        <div className="overview-slots__slots__container__slot__info">
                          <h5>{t(pageLabel + ".overviewSlots.moveOut")}</h5>
                          {slot.end && <p>{new Date(slot.end).toLocaleDateString()}</p>}
                          {slot.move_out && <>
                            <p>{`${transformDay(slot.move_out?.start?.day)}/${transformDay(slot.move_out?.start?.month)}`}</p>
                            <CircleDownArrowIcon />
                            <p>{`${transformDay(slot.move_out?.end?.day)}/${transformDay(slot.move_out?.end?.month)}`}</p>
                          </>}
                        </div>
                      </div>
                    </div>
                  )
                })
            ) : (
              <div className="overview-slots__no-slots">
                <span className="overview-slots__no-slots__icon-container">
                  <EmptyCalendarIcon className="icon" />
                </span>

                <div className="overview-slots__no-slots__icon-container__info">
                    <h5>{t(pageLabel + ".overviewSlots.noSlotsAdded.label")}</h5>
                    <p>{t(pageLabel + ".overviewSlots.noSlotsAdded.info")}</p>
                </div>
              </div>
            )}
          </div>

          <div className="overview-slots__slots__info">
            <div className="overview-slots__slots__info--available">
              <div className="overview-slots__slots__info--available__icon">&nbsp;</div>
              <p>{t(pageLabel + ".overviewSlots.available")}</p>
            </div>
            <div className="overview-slots__slots__info--unavailable">
              <div className="overview-slots__slots__info--unavailable__icon">&nbsp;</div>
              <p>{t(pageLabel + ".overviewSlots.unavailable")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewSlots;
