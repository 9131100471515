import React from "react";

const PlaylistAddCheck = (props) => (
    <svg
        width={21}
        height={14}
        viewBox="0 0 21 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 4H0v2h12V4Zm0-4H0v2h12V0ZM0 10h8V8H0v2Zm19.5-4.5L21 7l-6.99 7L9.5 9.5 11 8l3.01 3 5.49-5.5Z"
            fill="#1D9AFA"
        />
    </svg>
);

export default PlaylistAddCheck;
