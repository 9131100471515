import React from "react";

const Water = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#F0F3F6">
            <path d="M7.897 1.088a.31.31 0 0 0-.22.118c-1.826 2.365-4.59 5.858-4.59 8.994 0 2.66 2.18 4.714 4.839 4.714 2.658 0 4.84-2.054 4.84-4.714 0-3.136-2.765-6.63-4.591-8.994a.312.312 0 0 0-.278-.118Zm.029.835C9.735 4.276 12.14 7.567 12.14 10.2c0 2.316-1.873 4.085-4.215 4.085-2.342 0-4.215-1.77-4.215-4.085 0-2.633 2.406-5.924 4.215-8.277ZM5.082 9.88a.312.312 0 0 0-.278.32c0 1.731 1.401 3.141 3.122 3.141a.31.31 0 0 0 .293-.192.316.316 0 0 0-.172-.413.31.31 0 0 0-.121-.023A2.501 2.501 0 0 1 5.428 10.2a.316.316 0 0 0-.215-.305.31.31 0 0 0-.131-.014Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.889 1.002a.397.397 0 0 1 .354.15v.001l.142.184c.888 1.148 1.969 2.547 2.845 4.033.921 1.565 1.622 3.239 1.622 4.83 0 2.71-2.223 4.8-4.926 4.8C5.223 15 3 12.91 3 10.2c0-1.59.7-3.265 1.622-4.83.876-1.486 1.957-2.885 2.845-4.033l.142-.184a.4.4 0 0 1 .28-.15Zm.008.086.007.086a.224.224 0 0 0-.159.085l-.14.182c-.889 1.15-1.964 2.54-2.834 4.017-.917 1.556-1.598 3.198-1.598 4.742 0 2.609 2.14 4.627 4.753 4.627 2.614 0 4.753-2.018 4.753-4.627 0-1.544-.682-3.185-1.598-4.742C10.21 3.98 9.136 2.59 8.247 1.44l-.14-.182a.226.226 0 0 0-.201-.085l-.01-.086ZM5.215 6c-.826 1.442-1.418 2.907-1.418 4.2 0 2.265 1.831 3.999 4.129 3.999 2.298 0 4.129-1.734 4.129-3.999 0-1.293-.592-2.758-1.418-4.2-.804-1.405-1.822-2.777-2.711-3.935C7.037 3.223 6.019 4.595 5.215 6Zm2.71-4.077-.068-.053c-.904 1.177-1.961 2.592-2.792 4.044-.83 1.45-1.44 2.947-1.44 4.286 0 2.367 1.914 4.172 4.3 4.172 2.388 0 4.302-1.805 4.302-4.172 0-1.34-.61-2.837-1.44-4.286-.83-1.452-1.888-2.867-2.793-4.044l-.068.053ZM5.073 9.795a.397.397 0 0 1 .41.241.402.402 0 0 1 .033.165 2.415 2.415 0 0 0 2.41 2.427.396.396 0 0 1 .374.246.402.402 0 0 1-.374.555c-1.768 0-3.207-1.45-3.208-3.228a.402.402 0 0 1 .355-.406Zm0 0Zm.114.182a.224.224 0 0 0-.24.066.229.229 0 0 0-.056.155v.002c0 1.685 1.363 3.056 3.036 3.056h.001a.223.223 0 0 0 .212-.14.23.23 0 0 0-.124-.3.223.223 0 0 0-.088-.016h-.001a2.587 2.587 0 0 1-2.584-2.6v-.002a.23.23 0 0 0-.156-.221Z"
            />
        </g>
    </svg>
);

export default Water;
