export const dashboardServices = {
    getPotentialTenants,
    sendOffer,
    getOffers,
    archivePotentialTenant,
    getArchivedTenants,
    unarchivePotentialTenant,
    getDashboardInfo,
    getPotentialTenantsDetails,
    getPotentialTenantProperties,
    getIndicatorsInfo,
    readNotifications,
    deleteNotification,
    clearNotifications,
    createEvent,
    deleteEvent,
    getInvoiceHistory,
    sendOffers,
    getMontlyCalendar,
    getDailyCalendar
};

import {get, post } from "./index.js";

// Potential tenants / Offers

async function getPotentialTenants(filters){
    try {
        return await get('/offers/potentialTenants', filters);
    } catch (e) {
        throw e;
    }
}

async function getPotentialTenantsDetails(user_id) {
    try {
        return await get(`/offers/potentialTenantDetails/${user_id}`);
    } catch (e) {
        throw e;
    }
}

async function getArchivedTenants(){
    try {
        return await get('/offers/archivedTenants');
    } catch (e) {
        throw e;
    }
}

async function getPotentialTenantProperties(tenant_id){
    try {
        return await get(`/offers/potentialTenantProperties/${tenant_id}`);
    } catch (e) {
        throw e;
    }
}

async function getOffers(){
    try {
        return await get('/offers/getLandlordOffers');
    } catch (e) {
        throw e;
    }
}

async function sendOffer(offer){
    try {
        return await post('/offers/makeOffer', offer);
    } catch (e) {
        throw e;
    }
}


async function sendOffers(offers, user){
    try {
        return await post('/offers/makeOffers', {offers, user});
    } catch (e) {
        throw e;
    }
}

async function archivePotentialTenant(potential_tenant){
    try {
        return await post(`/offers/archivePotentialTenant/`+potential_tenant);
    } catch (e) {
        throw e;
    }
}

async function unarchivePotentialTenant(potential_tenant){
    try {
        return await post(`/offers/unarchivePotentialTenant/`+potential_tenant);
    } catch (e) {
        throw e;
    }
}

// Dashboard

async function getDashboardInfo(){
    try {
        return await get(`/landlord/dashboard`);
    } catch (e) {
        throw e;
    }
}

async function getIndicatorsInfo(){
    try {
        return await get(`/landlord/indicators`);
    } catch (e) {
        throw e;
    }
}

//notifications

async function readNotifications() {
    try {
        return await post("/landlord/readNotifications")
    } catch (e) {
        throw e;
    }
}

async function deleteNotification(notificationId) {
    try {
        return await post("/landlord/deleteNotification", {notification_id: notificationId})
    } catch (e) {
        throw e;
    }
}

async function clearNotifications() {
    try {
        return await post("/landlord/clearNotifications")
    } catch (e) {
        throw e;
    }
}

// Calendar

async function getMontlyCalendar(day){
    try {
        return await post('/landlord/monthlyCalendar', {date: day});
    } catch (e) {
        throw e;
    }
}

async function getDailyCalendar(day){
    try {
        return await post('/landlord/dailyCalendar', {date: day});
    } catch (e) {
        throw e;
    }
}

async function createEvent(event){
    try {
        return await post('/landlord/createEvent', event);
    } catch (e) {
        throw e;
    }
}

async function deleteEvent(event_id){
    try {
        return await post('/landlord/deleteEvent', {event_id: event_id});
    } catch (e) {
        throw e;
    }
}

// Invoice history

async function getInvoiceHistory(filters){
    try {
        return await get('/landlord/invoiceHistory', filters);
    } catch (e) {
        throw e;
    }
}

