import React from "react";

const ControlPointDuplicate = (props) => (
    <svg
        width={24}
        height={18}
        viewBox="0 0 24 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16 5h-2v3h-3v2h3v3h2v-3h3V8h-3V5ZM2 9c0-2.79 1.64-5.2 4.01-6.32V.52C2.52 1.76 0 5.09 0 9s2.52 7.24 6.01 8.48v-2.16A6.99 6.99 0 0 1 2 9Zm13-9c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9-4.04-9-9-9Zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7Z"
        />
    </svg>
);

export default ControlPointDuplicate;
