import React from "react";

const EmailSent = (props) => (
    <svg
        width={201}
        height={126}
        viewBox="0 0 201 126"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect x={0.5} width={194.689} height={110.715} rx={30} fill="#F0F3F6" />
        <path
            d="M35.6699 15.2451L91.8738 55.6724C95.3469 58.1706 100.024 58.1827 103.51 55.7025L160.373 15.2451"
            stroke="#9EB9CA"
            strokeOpacity={0.5}
            strokeWidth={6}
            strokeLinecap="round"
        />
        <rect
            opacity={0.4}
            x={26.6753}
            y={70.9038}
            width={26.3595}
            height={6.96187}
            rx={3}
            fill="#9EB9CA"
        />
        <rect
            opacity={0.4}
            x={26.6753}
            y={82.9314}
            width={19.586}
            height={6.96187}
            rx={3}
            fill="#9EB9CA"
        />
        <path
            d="M127.873 82.768C125.479 84.0237 125.578 85.8629 128.088 86.8559L135.523 89.7978C138.035 90.795 141.835 90.2509 143.964 88.5896L176.252 63.1266C178.375 61.457 178.602 61.7032 176.759 63.6769L151.234 90.9977C149.384 92.9652 149.93 95.3816 152.45 96.356L153.321 96.6953C155.841 97.6697 159.948 99.3061 162.451 100.324L170.697 103.682C173.203 104.7 175.88 103.5 176.591 100.893L189.159 54.6961C189.869 52.0894 188.491 50.9826 186.098 52.2363L127.873 82.768Z"
            fill="#9575CD"
        />
        <path
            d="M148.277 113.012C148.13 113.453 153.362 105.436 153.362 105.436C154.842 103.177 154.002 100.481 151.503 99.4592L145.797 97.1235C143.298 96.1015 142.1 97.3014 143.134 99.7985C143.134 99.7985 148.428 112.559 148.277 113.012Z"
            fill="#9575CD"
        />
    </svg>
);

export default EmailSent;