import React, {useEffect} from 'react';
import {GrPrevious} from 'react-icons/gr';
import {GrNext} from 'react-icons/gr';

import '../../../../../../../../../../services/i18n';
import './style.less';

import {useTranslation} from 'react-i18next';

import personPinIcon from './images/personPinIcon.svg';

const StepperBar = ({steps, activeStep = 1, setActiveStep, maxStep = 1, setMaxStep}) => {
  const {t, i18n} = useTranslation();

  const stepHandler = (valueToAdd) => {
    setActiveStep(activeStep+valueToAdd);
    if (activeStep + valueToAdd > maxStep) {
      setMaxStep(activeStep + valueToAdd)
    }
  }

  const goToStep = (step) => {
    setActiveStep(step)
  }

  useEffect(() => {
    const anchor = document.querySelectorAll(".StepperBar__bar-step");
    if (anchor?.[activeStep-1]) {
      anchor[activeStep - 1].scrollIntoView({
        block:    "end",
        inline:   "center"
      });
    }
  },[activeStep])



  return (
    <div className={'StepperBar'}>
      <div className={'StepperBar__button StepperBar__button-previous'}>
        <button disabled={activeStep === 1} type={'button'} onClick={() => stepHandler(-1)}>
          <GrPrevious/>
        </button>
      </div>

      <div className={'StepperBar__bar'}>
        {steps.map((step, index) => (
          <div key={index} id={step.title} tabIndex={-1}
               className={`StepperBar__bar-step ${activeStep === index + 1 ? 'active' : null} ${index + 1 <= maxStep ? 'completed' : null}`}>
            <div className={'StepperBar__bar-step__step'}>
              <div className={'StepperBar__bar-step__step__circle'}>
                {activeStep === index + 1 && (
                  <div className={'StepperBar__bar-step__step__circle-icon'}>
                    <img src={personPinIcon}/>
                  </div>
                )}
              </div>
              <div className={'StepperBar__bar-step__step__line'}></div>
            </div>
            <h6 className={'StepperBar__bar-step__title'}>{step.title}</h6>
            {activeStep !== index + 1
              ? (index + 1 > maxStep ? <p className={'StepperBar__bar-step__info small'}>{t('incomplete')}</p> : (
                <p className={'StepperBar__bar-step__info-edit small'}
                   onClick={() => goToStep(index + 1)}>{t('edit')}</p>))
              : <p className={'StepperBar__bar-step__info small'}>{t('you_are_here')}</p>}
          </div>
        ))}
      </div>

      <div className={'StepperBar__button StepperBar__button-next'}>
        <button disabled={activeStep >= maxStep} type={'button'} onClick={() => stepHandler(+1)}>
          <GrNext/>
        </button>
      </div>
    </div>
  )
}

export default StepperBar;

