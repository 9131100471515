import React from "react";

const ChevronLeft = (props) => (
    <svg
        width={8}
        height={12}
        viewBox="0 0 8 12"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.41 1.41 6 0 0 6l6 6 1.41-1.41L2.83 6l4.58-4.59Z"
        />
    </svg>
);

export default ChevronLeft;
