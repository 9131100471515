import React from "react";

const HourglassBottom = (props) => (
    <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="hourglass_bottom" clipPath="url(#clip0_606_44)">
            <g id="Group">
                <path
                    id="Vector"
                    d="M22.5 27.5L22.4875 20L17.5 15L22.4875 9.9875L22.5 2.5H7.5V10L12.5 15L7.5 19.9875V27.5H22.5ZM10 9.375V5H20V9.375L15 14.375L10 9.375Z"
                    fill="#F2B457"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_606_44">
                <rect width={30} height={30} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default HourglassBottom;
