import * as React from "react";

function SvgCr(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path fill="#FF4B55" d="M0 115.035h511.999v105.931H0z" />
        <path
          d="M8.828.276h494.345a8.829 8.829 0 018.828 8.828v48.552H0V9.104A8.829 8.829 0 018.828.276z"
          fill="#41479B"
        />
        <path fill="#F5F5F5" d="M0 57.655h511.999v57.379H0z" />
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896v-48.552h512v48.552a8.828 8.828 0 01-8.828 8.828z"
          fill="#41479B"
        />
        <g transform="translate(0 123)" fill="#F5F5F5">
          <path d="M0 97.965h511.999v57.379H0z" />
          <ellipse cx={138.394} cy={45} rx={41.29} ry={44.138} />
        </g>
        <path fill="#3CC8FF" d="M117.031 150.919h42.714v21.357h-42.714z" />
        <path
          fill="#FFE15A"
          d="M122.732 163.059l.488 4.711 1.918-4.329-.992 4.631 3.164-3.526-2.376 4.098 4.099-2.374-3.525 3.163 4.631-.992-4.33 1.919 4.712.488-4.712.488 4.33 1.919-4.631-.992 3.525 3.163-4.099-2.376 2.376 4.099-3.164-3.525.992 4.631-1.918-4.329-.488 4.71-.488-4.71-1.919 4.329.992-4.631-3.163 3.525 2.374-4.099-4.098 2.376 3.526-3.163-4.632.992 4.33-1.919-4.711-.488 4.711-.488-4.33-1.919 4.632.992-3.526-3.163 4.098 2.374-2.374-4.098 3.163 3.526-.992-4.631 1.919 4.329z"
        />
        <path
          fill="#41479B"
          d="M146.936 202.172h-17.085l-15.662-12.815v-18.509h49.833v18.509z"
        />
        <path
          d="M155.479 165.153h-.001a.71.71 0 00-1.422 0h-.002c0 2.471-1.432 5.889-3.188 8.337-2.452-3.096-3.374-6.686-3.722-8.947a1.604 1.604 0 00-2.307-1.188c-.47.235-.787.694-.867 1.213-1.09 6.983-4.382 9.95-6.514 11.17-3.191-3.085-4.243-8.078-4.589-11.03a1.66 1.66 0 00-2.397-1.29 1.675 1.675 0 00-.832.981c-4.466 13.613-16.875 14.993-16.875 14.993v4.271h49.833v-7.115c-5.693-1.429-7.117-11.395-7.117-11.395z"
          fill="#73AF00"
        />
        <g fill="#F0AA00">
          <path d="M162.598 152.339c-5.695-2.848-5.695-5.695-5.695-5.695s-7.119-2.848-12.814-2.848c0 0 1.423-2.848-1.423-2.848h-8.542c-2.848 0-1.423 2.848-1.423 2.848-5.695 0-12.814 2.848-12.814 2.848s0 2.848-5.695 5.695c-5.695 2.848-5.695 9.255-5.695 9.255a2.135 2.135 0 004.27 0c0-.244-.05-.473-.125-.691l.125-.021 14.238-9.966h22.782l14.238 9.966.125.021a2.11 2.11 0 00-.125.691 2.135 2.135 0 004.27 0c-.002-.001-.002-6.408-5.697-9.255z" />
          <path d="M166.87 169.425c-1.423 0-5.695 0-5.695-4.271s5.695-4.271 5.695-4.271l-8.543-2.848c-2.848 9.966 2.848 9.966 2.848 22.781 0 11.479-12.814 18.509-17.086 18.509-4.271 0-5.695 2.848-5.695 2.848s-1.423-2.848-5.695-2.848c-4.271 0-17.086-7.03-17.086-18.509 0-12.814 5.695-12.814 2.848-22.781l-8.543 2.848s5.695 0 5.695 4.271-4.271 4.271-5.695 4.271c-1.424 0-7.119 0-7.119 7.119s7.119 15.662 17.086 21.357c9.966 5.695 5.695 11.391 18.509 11.391s8.543-5.695 18.509-11.391c9.966-5.695 17.086-14.238 17.086-21.357s-5.696-7.119-7.119-7.119z" />
        </g>
        <g fill="#73AF00">
          <path d="M135.546 147.138l-3.444-1.548a2.843 2.843 0 00-1.816-.175c-2.22.513-7.727 2.2-11.826 6.923l4.271 1.423 12.815-6.623zm5.695 0l3.444-1.548a2.843 2.843 0 011.816-.175c2.22.513 7.727 2.2 11.826 6.923l-4.271 1.423-12.815-6.623z" />
          <path d="M138.897 148.067l2.596-2.596a.355.355 0 10-.503-.503l-2.596 2.596-2.596-2.596a.355.355 0 10-.503.503l2.596 2.596-2.596 2.596a.355.355 0 10.504.503l2.596-2.596 2.596 2.596a.355.355 0 10.504-.503l-2.598-2.596z" />
        </g>
        <path
          d="M166.87 160.882c0-2.422-2.289-3.469-4.271-3.924v-1.128c-2.68-.252-4.177-1.258-6.775-3.029a76.777 76.777 0 00-3.507-2.289c-5.006-3.041-8.854-3.48-11.075-3.374v2.353h-5.695v-2.353c-2.221-.106-6.069.333-11.075 3.374a76.486 76.486 0 00-3.507 2.289c-2.599 1.77-4.094 2.777-6.775 3.029v1.128c-1.983.454-4.271 1.501-4.271 3.924l4.271-1.423v.649c4.041-.298 6.351-1.848 9.18-3.775.959-.653 2.045-1.394 3.319-2.167 3.252-1.976 5.779-2.588 7.434-2.732v2.33h8.543v-2.33c1.655.145 4.182.756 7.434 2.732a72.771 72.771 0 013.319 2.167c2.828 1.927 5.139 3.478 9.18 3.775v-.649l4.271 1.423z"
          fill="#F5F5F5"
        />
        <path
          d="M155.479 138.439v-1.762h-3.966c.693-.595 1.119-1.295 1.119-2.135 0-3.934-8.95-4.983-14.238-4.983s-14.238 1.049-14.238 4.983c0 .84.426 1.54 1.119 2.135h-3.966v1.762c-3.486.778-6.335 2.648-6.485 2.749l1.579 2.369c.039-.026 3.957-2.609 7.753-2.609v-1.423h28.476v1.423c3.797 0 7.714 2.582 7.753 2.609l1.579-2.369c-.149-.101-2.999-1.971-6.485-2.749zm-11.39-2.156v-1.081c0-.697-.655-1.208-1.331-1.039l-4.364 1.091-4.364-1.091a1.071 1.071 0 00-1.331 1.039v1.081c-3.199-.47-5.09-1.252-5.606-1.741.77-.732 4.543-2.135 11.3-2.135 6.757 0 10.53 1.405 11.3 2.135-.514.488-2.406 1.27-5.604 1.741z"
          fill="#3CC8FF"
        />
        <path
          d="M138.572 154.3l.361 1.081 1.14.009c.148.001.209.19.09.277l-.917.678.344 1.087a.154.154 0 01-.236.171l-.928-.663-.928.663c-.12.086-.28-.031-.236-.171l.344-1.087-.917-.678c-.119-.087-.057-.276.09-.277l1.14-.009.361-1.081a.154.154 0 01.292 0zm-5.582.365l.472 1.038 1.135-.11a.154.154 0 01.119.266l-.841.77.456 1.045a.153.153 0 01-.216.195l-.992-.562-.853.757c-.11.098-.282-.001-.253-.146l.228-1.118-.983-.577a.154.154 0 01.061-.285l1.133-.128.245-1.113a.153.153 0 01.289-.032zm-5.732 1.526l.686.911 1.084-.354a.153.153 0 01.173.234l-.654.934.672.921a.153.153 0 01-.169.237l-1.09-.334-.669.924c-.086.119-.276.06-.278-.087l-.02-1.14-1.085-.351c-.14-.045-.142-.244-.002-.291l1.078-.371-.002-1.14c-.001-.147.188-.211.276-.093zm-5.744 2.422l.8.812 1.027-.494a.154.154 0 01.203.21l-.525 1.012.788.824a.154.154 0 01-.136.258l-1.126-.186-.541 1.004a.154.154 0 01-.287-.05l-.17-1.128-1.122-.204c-.146-.026-.173-.223-.041-.289l1.02-.51-.152-1.13a.154.154 0 01.262-.129zm22.348-3.948l-.472 1.038-1.135-.11a.154.154 0 00-.119.266l.841.77-.456 1.045a.153.153 0 00.216.195l.992-.562.853.757c.11.098.282-.001.253-.146l-.228-1.118.983-.577a.154.154 0 00-.061-.285l-1.133-.128-.245-1.113c-.031-.146-.229-.167-.289-.032zm5.731 1.526l-.686.911-1.084-.354a.153.153 0 00-.173.234l.654.934-.672.921a.153.153 0 00.169.237l1.09-.334.669.924c.086.119.276.06.278-.087l.02-1.14 1.085-.351c.14-.045.142-.244.002-.291l-1.078-.371.002-1.14c.001-.147-.188-.211-.276-.093zm5.745 2.422l-.8.812-1.027-.494a.154.154 0 00-.203.21l.525 1.012-.788.824a.154.154 0 00.136.258l1.126-.186.541 1.004c.07.13.265.096.287-.05l.17-1.128 1.122-.204c.146-.026.173-.223.041-.289l-1.02-.51.152-1.13c.019-.147-.159-.234-.262-.129z"
          fill="#F5F5F5"
        />
        <path
          fill="#8C5A28"
          d="M128.56 188.468l7.253 4.849h8.765l4.05-3.47-3.115 1.246H135.19z"
        />
        <path
          d="M133.499 182.209l-.7 7.06-3.186-1.216c0-.001 1.466-3.403 3.886-5.844z"
          fill="#F5F5F5"
        />
        <path
          d="M139.785 179.41h1v12.022h-1zm4.358 1.015h1v11.005h-1zm-8.695 0h1v11.005h-1z"
          fill="#8C5A28"
        />
        <path
          d="M141.462 190.292h-2.877a.542.542 0 01-.542-.542v-1.453c0-.299.243-.542.542-.542h2.877c.299 0 .542.243.542.542v1.453a.542.542 0 01-.542.542zm0-3.382h-2.877a.542.542 0 01-.542-.542v-1.453c0-.299.243-.542.542-.542h2.877c.299 0 .542.243.542.542v1.453c0 .3-.243.542-.542.542zm0-3.114h-2.877a.542.542 0 01-.542-.542v-1.453c0-.299.243-.542.542-.542h2.877c.299 0 .542.243.542.542v1.453a.542.542 0 01-.542.542zm4.089 6.496h-2.343a.542.542 0 01-.542-.542v-1.453c0-.299.243-.542.542-.542h2.343c.299 0 .542.243.542.542v1.453a.542.542 0 01-.542.542zm0-3.113h-2.343a.542.542 0 01-.542-.542v-1.055c0-.299.243-.542.542-.542h2.343c.299 0 .542.243.542.542v1.055a.542.542 0 01-.542.542zm0-3.086h-2.343a.542.542 0 01-.542-.542v-.712c0-.299.243-.542.542-.542h2.343c.299 0 .542.243.542.542v.712a.542.542 0 01-.542.542zm-8.699 6.199h-2.343a.542.542 0 01-.542-.542v-1.453c0-.299.243-.542.542-.542h2.343c.299 0 .542.243.542.542v1.453a.543.543 0 01-.542.542zm0-3.113h-2.343a.542.542 0 01-.542-.542v-1.055c0-.299.243-.542.542-.542h2.343c.299 0 .542.243.542.542v1.055a.543.543 0 01-.542.542zm0-3.086h-2.343a.542.542 0 01-.542-.542v-.712c0-.299.243-.542.542-.542h2.343c.299 0 .542.243.542.542v.712a.543.543 0 01-.542.542zm-.937-3.663v1.423l2.093-.725zm8.694 0v1.423l2.093-.725zm-4.356-1.021v1.422l2.094-.725z"
          fill="#F5F5F5"
        />
        <path
          fill="#8C5A28"
          d="M134.693 169.801l2.519 1.685h3.046l1.407-1.206-1.083.432h-3.586z"
        />
        <path
          d="M136.409 167.626l-.243 2.453-1.107-.423c-.001 0 .509-1.182 1.35-2.03z"
          fill="#F5F5F5"
        />
        <path
          d="M138.593 166.654h1v4.177h-1zm1.512.353h1v3.823h-1zm-3.024 0h1v3.823h-1z"
          fill="#8C5A28"
        />
        <path
          d="M139.174 170.434h-1a.189.189 0 01-.189-.189v-.504c0-.104.084-.189.189-.189h1c.104 0 .189.084.189.189v.504a.188.188 0 01-.189.189zm0-1.174h-1a.189.189 0 01-.189-.189v-.504c0-.104.084-.189.189-.189h1c.104 0 .189.084.189.189v.504a.189.189 0 01-.189.189zm0-1.082h-1a.189.189 0 01-.189-.189v-.504c0-.104.084-.189.189-.189h1c.104 0 .189.084.189.189v.504a.188.188 0 01-.189.189zm1.422 2.256h-.814a.189.189 0 01-.189-.189v-.504c0-.104.084-.189.189-.189h.814c.104 0 .189.084.189.189v.504a.19.19 0 01-.189.189zm0-1.081h-.814a.189.189 0 01-.189-.189v-.366c0-.104.084-.189.189-.189h.814c.104 0 .189.084.189.189v.366a.19.19 0 01-.189.189zm0-1.073h-.814a.189.189 0 01-.189-.189v-.247c0-.104.084-.189.189-.189h.814c.104 0 .189.084.189.189v.247a.19.19 0 01-.189.189zm-3.023 2.154h-.814a.189.189 0 01-.189-.189v-.504c0-.104.084-.189.189-.189h.814c.104 0 .189.084.189.189v.504a.19.19 0 01-.189.189zm0-1.081h-.814a.189.189 0 01-.189-.189v-.366c0-.104.084-.189.189-.189h.814c.104 0 .189.084.189.189v.366a.19.19 0 01-.189.189zm0-1.073h-.814a.189.189 0 01-.189-.189v-.247c0-.104.084-.189.189-.189h.814c.104 0 .189.084.189.189v.247a.19.19 0 01-.189.189zm-.325-1.272v.494l.727-.251zm3.02 0v.494l.727-.251zm-1.513-.354v.493l.727-.252zm-5.845-4.349h-1.848a1.216 1.216 0 01-1.212-1.212c0-.666.545-1.212 1.212-1.212h1.848c.666 0 1.212.545 1.212 1.212a1.214 1.214 0 01-1.212 1.212zm14.055 0h-1.219a1.212 1.212 0 010-2.424h1.219a1.212 1.212 0 010 2.424zm8.656 1.824h-1.074a.693.693 0 01-.693-.693v-.115c0-.383.31-.693.693-.693h1.074c.383 0 .693.31.693.693v.115c0 .383-.31.693-.693.693z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgCr;
