import React from "react";

const TrendingUp = (props) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="trending_up" clipPath="url(#clip0_2344_9699)">
      <path
        id="Vector"
        d="M32 12L36.58 16.58L26.82 26.34L18.82 18.34L4 33.18L6.82 36L18.82 24L26.82 32L39.42 19.42L44 24V12H32Z"
        fill="#434A5F"
      />
    </g>
    <defs>
      <clipPath id="clip0_2344_9699">
        <rect width={48} height={48} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TrendingUp;
