import * as React from "react";

function SvgVu(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#464655"
        />
        <path
          fill="#FFE15A"
          d="M512 159.172H249.444L0 21.545v20.169L228.897 168 0 294.286v20.17l249.444-137.628H512z"
        />
        <path
          d="M3.531 333.776c1.491 1.149 3.27 1.948 5.297 1.948h494.345a8.829 8.829 0 008.828-8.828V194.483H255.999L3.531 333.776z"
          fill="#73AF00"
        />
        <path
          d="M503.172.276H8.828c-2.027 0-3.807.8-5.297 1.949l252.468 139.292h255.999V9.104a8.826 8.826 0 00-8.826-8.828z"
          fill="#FF4B55"
        />
        <g fill="#FFE15A">
          <path d="M76.452 118.487c-25.205-7.929-44.234 10.386-48.583 19.812-3.683 7.987-10.525 44.167 23.581 49.936 34.104 5.77 33.72-26.412 33.72-26.412-1.538 5.129-4.296 19.745-28.914 19.745-9.234 0-22.501-13.719-20.001-28.848 2.353-14.236 15.07-21.518 24.682-21.924 9.102-.384 30.322 5.898 30.322 31.734 0 29.039-29.93 32.31-29.93 32.31v17.535c26.053-2.554 40.797-16.986 44.699-40.103 3.888-23.013-3.948-45.722-29.576-53.785z" />
          <path d="M48.544 161.549a4.405 4.405 0 00-.927 2.655l-.074 4.716-.918 1.601a1.214 1.214 0 102.107 1.206l.941-1.646 3.766-1.364a4.462 4.462 0 002.554-2.378c.984-2.197 3.097-6.231 6.521-10.44 3.424 4.209 5.537 8.243 6.521 10.44a4.46 4.46 0 002.553 2.378l3.767 1.364.941 1.646a1.214 1.214 0 002.107-1.206l-.918-1.601-.074-4.716a4.398 4.398 0 00-.927-2.655c-3.155-4.007-6.511-7.158-9.818-9.945 2.643-2.484 5.631-4.855 9.394-6.686a2.07 2.07 0 001.101-2.343 2.082 2.082 0 00-2.911-1.401c-2.522 1.175-6.859 3.49-11.737 7.047-4.879-3.556-9.215-5.87-11.737-7.047a2.084 2.084 0 00-2.911 1.401 2.073 2.073 0 001.1 2.343c3.764 1.831 6.752 4.203 9.395 6.686-3.305 2.787-6.661 5.939-9.816 9.945z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgVu;
