import {get, patch, post } from "./index.js";


let cachedCountries = null;
async function getCountries() {
    if(cachedCountries) return cachedCountries;
    try {
        cachedCountries = await get('/data/countries');
        return cachedCountries;
    } catch (e) {
        console.error("what", e);
        return [];
    }
}

function getCachedCountries() {
    return cachedCountries || [];
}

async function getTimezones() {
    try {
        return await get('/data/timezones');
    } catch (e) {
        return [];
    }
}

export const dataServices = {
    getCountries,
    getCachedCountries,
    getTimezones
};
