import * as React from "react";

function SvgLy(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M0 247.448h512v79.448a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.896v-79.448z"
          fill="#73AF00"
        />
        <path
          d="M8.828.276h494.345a8.829 8.829 0 018.828 8.828v79.448H0V9.103A8.828 8.828 0 018.828.276z"
          fill="#FF4B55"
        />
        <path fill="#464655" d="M0 88.552h512v158.897H0z" />
        <g fill="#F5F5F5">
          <path d="M306.058 139.432l13.286 17.379 20.745-6.944c1.16-.388 2.146.924 1.452 1.93l-12.424 18.006 13.014 17.583c.727.983-.215 2.327-1.387 1.977l-20.963-6.251-12.701 17.811c-.711.996-2.28.514-2.31-.708l-.533-21.869-20.864-6.575c-1.166-.367-1.194-2.008-.04-2.415l20.634-7.264-.193-21.875c-.011-1.224 1.541-1.757 2.284-.785z" />
          <path d="M270.001 210.898c-23.672 0-42.876-19.203-42.876-42.898 0-23.65 19.203-42.897 42.876-42.897 8.808 0 16.93 2.709 23.699 7.294 1.036.702 2.22-.66 1.352-1.562-10.116-10.517-24.434-16.934-40.309-16.392-27.274.93-49.852 22.797-51.594 50.031-1.996 31.194 22.703 57.118 53.455 57.118 15.148 0 28.762-6.333 38.485-16.459.858-.895-.327-2.236-1.354-1.54-6.776 4.592-14.911 7.305-23.734 7.305z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgLy;
