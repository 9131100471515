import React from "react";

const AlertNotification = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.75 2.292c0-.692.558-1.25 1.25-1.25s1.25.558 1.25 1.25v.975a5.828 5.828 0 0 1 4.583 5.691v5l1.667 1.667v.833h-15v-.833l1.667-1.667v-5A5.828 5.828 0 0 1 8.75 3.267v-.975Zm1.25 2.5c2.3 0 4.167 1.866 4.167 4.166v5.833H5.833V8.958c0-2.3 1.867-4.167 4.167-4.167ZM8.342 17.3a1.657 1.657 0 1 0 3.316 0H8.342Zm2.491-10.842v3.333H9.167V6.458h1.666Zm0 6.667v-1.667H9.167v1.667h1.666Z"
            fill="#284F83"
        />
    </svg>
);

export default AlertNotification;
