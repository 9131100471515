import React, {useEffect, useRef, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {RadioGroup, Radio, FormControlLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import './style.less'

import {BiInfoCircle} from 'react-icons/bi';
import {useTranslation} from 'react-i18next';

const radioButtonsStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'rgba(0,0,0,.1)',
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      background: 'rgba(24, 160, 251,.3)'
    },
    'input:disabled ~ &': {
      boxShadow: 'none'
    }
  },
  checkedIcon: {
    backgroundColor: '#18A0FB',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#18A0FB'
    }
  }
});

// Inspired by blueprintjs
export function StyledRadio(props) {
  const classes = radioButtonsStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
      icon={<span className={classes.icon}/>}
      {...props}
    />
  );
}

const RadioButtons = ({
                        radioButtons,
                        value,
                        rules,
                        name,
                        type = 'double',
                        error,
                        watchValue,
                        setValue,
                        inputRef,
                        control,
                        onChange,
                        labelClass
                      }) => {

  const {t,i18n} = useTranslation();

  const onChangeRadio = (e) => {
    if(onChange){
      onChange(e);
    }
      if(setValue){
          setValue(name,e.target.value);
      }
  }

  return (
    <div className={`RadioButtons ${type}`} ref={inputRef} id={name}>
      <Controller
        as={
          <RadioGroup aria-label="gender" >
            {radioButtons.map((button, index) => (
              <div key={button.label} className={`${(type==='info' && !button.info)?'':type} ${button.className}`}>
                <FormControlLabel
                  key={`${name}[${index}]`}
                  value={button.buttonValue}
                  className={labelClass}
                  control={<StyledRadio/>}
                  label={t(button.label)}
                  onChange={onChangeRadio}
                />
                {button.info &&
                 <div className={'RadioButton_info'} style={{flex: '1'}}><BiInfoCircle/><p>{t(button.info)}</p></div>}
                {button.component && button.component(button.rules)}
              </div>
            ))}
          </RadioGroup>}
        defaultValue={(value !== undefined && value !== null) ? value.toString() : ''}
        name={name}
        control={control}
        rules={rules}
      />
      {error && <p className={'error_message'}>{error.message}</p>}
    </div>)
};

export default RadioButtons;
