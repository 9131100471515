import React, { useEffect, useState } from "react";

import "./style.less";
import LandlordRules1 from "./levels/LandlordRules1";
import LandlordRules2 from "./levels/LandlordRules2";
import LandlordRules3 from "./levels/LandlordRules3";
import LandlordRules4 from "./levels/LandlordRules4";
import LandlordRules5 from "./levels/LandlordRules5";
import { connect } from "react-redux";
import {get, propertyServices} from '../../../../../../../../../services';
import CloneInformation from "../../../components/CloneInformation";


const pageLabel = "main_page.my_ads.create_ad.house_details.landlord_rules";

const pages = [
  {
    title: pageLabel+".level1.title",
    level:'1.2.1',
    clone_house:true,
    component:LandlordRules1,
  },
  {
    title: pageLabel+".level2.title",
    level:'1.2.2',
    component:LandlordRules2,
  },
  {
    title: pageLabel+".level3.title",
    level:'1.2.3',
    clone_house:true,
    component:LandlordRules3,
  },
  {
    title: pageLabel+".level4.title",
    level:'1.2.4',
    clone_house:true,
    component:LandlordRules4,
  },
  {
    title: pageLabel+".level5.title",
    level:'1.2.5',
    clone_house:true,
    component:LandlordRules5,
  }
];



const LandlordRules = ({ updateProperty,currentPageLevel,t,property,changePage}) => {

  const [currentPage,setCurrentPage]=useState(pages.find((page) => (currentPageLevel === page.level)));

  const [currentProperty,setCurrentProperty]=useState(property);
  const [housesOptions,setHousesOptions]=useState([])

  const cloneHouseHandler= ({value}) => {
    setCurrentProperty(value)
  }

  useEffect(() => {
    (async () => {
      //get the user houses
      setHousesOptions(property.isEditing ? [] : ((await propertyServices.getPropertiesList(0, null, 10000)).properties)
        .filter(option => option.internal_name !== property.internal_name && option.finished)
        .map(option => ({label:option.internal_name,value:option})));
      //set the options
    })();
    setCurrentPage(pages.find((page) => (currentPageLevel === page.level)))
    return () => {setHousesOptions([])}
  },[]);


  return (
    <div className={"LandlordRules"}>
      <div>
        <h4 className={"title"}>{t(currentPage.title)}</h4>
        {currentPage.clone_house && <CloneInformation changeInfo={cloneHouseHandler} options={housesOptions}
        title={t(pageLabel+'.clone_house.label')} placeholder={t(pageLabel+'.clone_house.placeholder')}/>}
        <div className={"section_content"}>
          <currentPage.component key={currentProperty.id} currentLevel={currentPage.level} updateProperty={updateProperty} t={t} property={currentProperty}
          changePage={changePage}/>
        </div>
      </div>
    </div>
  );
};


export default LandlordRules;
