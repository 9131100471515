import React from "react";

const InfoValidated = (props) => (
  <svg
    width={201}
    height={114}
    viewBox="0 0 201 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} width={200} height={113.733} rx={30} fill="#F0F3F6" />
    <path
      d="M13.712 73.127v-26.25H9.178l1.909-7.159h9.784v33.41h-7.16Zm12.898 0v-6.395c.89-.557 2.004-1.329 3.34-2.315a90.405 90.405 0 0 0 4.2-3.365 63.905 63.905 0 0 0 4.129-3.77c1.288-1.289 2.338-2.506 3.15-3.651.811-1.162 1.217-2.14 1.217-2.936 0-.811-.199-1.55-.597-2.219a4.468 4.468 0 0 0-1.599-1.623c-.652-.398-1.384-.596-2.195-.596a4.27 4.27 0 0 0-2.22.596 4.661 4.661 0 0 0-1.622 1.623 4.258 4.258 0 0 0-.597 2.22h-7.159c0-2.196.533-4.169 1.6-5.919a11.865 11.865 0 0 1 4.223-4.152c1.766-1.018 3.69-1.528 5.775-1.528 2.116 0 4.049.518 5.799 1.552a11.615 11.615 0 0 1 4.176 4.2c1.05 1.75 1.575 3.699 1.575 5.846a10.72 10.72 0 0 1-.43 3.055 12.752 12.752 0 0 1-1.264 2.84 21.146 21.146 0 0 1-2.029 2.84c-.811.97-1.75 1.996-2.816 3.078a244.885 244.885 0 0 1-3.484 3.46h10.023v7.16H26.61Zm38.513.621c-2.005 0-3.843-.494-5.513-1.48a11.319 11.319 0 0 1-4.01-3.985c-1.001-1.67-1.503-3.524-1.503-5.56h7.16c0 .7.175 1.344.525 1.933.35.588.819 1.058 1.408 1.408.588.35 1.233.525 1.933.525s1.344-.175 1.933-.525a3.979 3.979 0 0 0 1.407-1.408c.35-.589.526-1.233.526-1.933 0-.97-.27-1.774-.812-2.41-.54-.653-1.304-1.138-2.29-1.456-.971-.319-2.117-.477-3.437-.477v-5.442c1.75 0 3.126-.294 4.128-.882 1.003-.605 1.504-1.504 1.504-2.697 0-.573-.143-1.098-.43-1.575a3.097 3.097 0 0 0-1.121-1.145 2.881 2.881 0 0 0-1.552-.43c-.588 0-1.169.143-1.742.43a4.226 4.226 0 0 0-1.431 1.145 2.53 2.53 0 0 0-.55 1.575h-7.159a9.39 9.39 0 0 1 .86-3.985 10.667 10.667 0 0 1 2.386-3.294 11.194 11.194 0 0 1 3.46-2.219 10.553 10.553 0 0 1 4.081-.811c1.861 0 3.58.461 5.154 1.384a10.568 10.568 0 0 1 3.771 3.723c.954 1.559 1.432 3.293 1.432 5.202 0 1.114-.239 2.267-.716 3.46s-1.193 2.044-2.148 2.554c.827.318 1.52.883 2.076 1.694a8.908 8.908 0 0 1 1.265 2.72 10.68 10.68 0 0 1 .43 2.936c0 2.02-.501 3.866-1.504 5.536a11.318 11.318 0 0 1-3.985 4.01c-1.67.985-3.516 1.479-5.536 1.479Z"
      fill="#D0DCE4"
    />
    <rect
      x={142.369}
      y={56.134}
      width={40.463}
      height={40.463}
      rx={20.232}
      fill="#3EC28F"
      opacity={0.9}
    />
    <rect
      x={137.204}
      y={51.58}
      width={50.982}
      height={49.897}
      rx={24.948}
      fill="#3EC28F"
      opacity={0.3}
    />
    <path
      d="M171.098 70.077a1 1 0 0 0-1.417-.003l-9.695 9.695a1 1 0 0 1-1.413.002l-2.952-2.941a1 1 0 0 0-1.413.001l-.964.964a1 1 0 0 0 0 1.415l5.329 5.33a1 1 0 0 0 1.415 0l12.076-12.077a1 1 0 0 0 .002-1.411l-.968-.975Z"
      fill="#FEFEFE"
    />
    <rect
      x={84.023}
      y={37.965}
      width={53.504}
      height={13.867}
      rx={4}
      fill="#9DB5C6"
    />
    <rect
      opacity={0.4}
      x={84.023}
      y={61.922}
      width={39.755}
      height={13.867}
      rx={4}
      fill="#9DB5C6"
    />
  </svg>
);

export default InfoValidated;