import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import Reservations from './containers/Reservations';
import MakeOffers from './containers/MakeOffers';
import MyAds from './containers/MyAds';
import Dashboard from './containers/Dashboard';
import MyVisits from './containers/MyVisits';
import MyAccount from './containers/MyAccount';
import HorizontalNavBar from '../../components/HorizontalNavBar';
import Loading from '../../components/Loading';

import './style.less';
import {userActions} from '../../redux/actions';
import {useHistory, useLocation} from "react-router-dom";
import {isSm} from "../../utils/responsive";
import PagesSwitch from "../../components/PagesSwitch";
import {useAuth} from "../../services/authServices";
import MyCalendar from "./containers/MyCalendar";

const pages = [
   {
   label: "main_page.dashboard.label",
   path: "dashboard",
   component: Dashboard,
   },
   {
       label: "main_page.radar.label",
       path: "radar",
   component: MakeOffers,
   },
   {
   label: "main_page.reservations.label",
   path: "reservations",
   component: Reservations,
   },
  {
    label: 'main_page.my_ads.label',
    path: 'my_ads',
    component: MyAds
  },
  {
   label: "main_page.my_visits.label",
   path: "my_visits",
   component: MyVisits
  },
  /*{
   label: "main_page.my_calendar.label",
   path: "my_calendar",
   component: MyCalendar
  },*/
  {
    label: 'my_account.label',
    path: 'my_account',
    component: MyAccount
  }
];

const MainPage = ({displayFooter}) => {

    const auth = useAuth();
    const history = useHistory();
    let location = useLocation();

    const isSmallScreen = useMemo(() => {
        return isSm();
    }, []);

    useEffect(() => {
        (async () => {
            let user = await auth.onLoaded();
            if (!user && !(location.pathname === "/" || location.pathname === "/how-to")) {
                history.push('/start');
            }
        })();
    }, []);

    //if footer if show we need to limit the height
    const [limitedHeight, setLimitedHeight] = useState(!displayFooter.display);

    useEffect(() => {
        setLimitedHeight(!displayFooter.display);
    }, [displayFooter]);

    if (auth.loading) return <Loading/>;

    return (<div
        className={`MainPage${limitedHeight ? ' limit-height' : ''}${(location.pathname === "/" || location.pathname === "/how-to") ? ' static' : ''}`}>
        {isSmallScreen ? <PagesSwitch path={"/"}
                                      pages={pages}
                                      loggedIn={auth.user}
                                      pageHeader={true}/>
            : <HorizontalNavBar path={"/"}
                                pages={pages}
                                loggedIn={auth.user}
                                pageHeader={true}/>
        }

    </div>);
};

function mapStateToProps(state) {
    return {displayFooter: state.footerDisplay};
}

export default connect(mapStateToProps, null)(MainPage);
