import React, {forwardRef, useEffect, useMemo, useState} from "react";
import moment from "moment";
import "./style.less";
import {useTranslation} from "react-i18next";
import {propertyServices} from "../../../../../../../../../../../services";
import {usePopupDialog} from "../../../../../../../../../../../components/PopupDialog";
import {getOpenInformation} from "../../../../../../../../../../../../src/pages/MainPage/containers/MyAds/compnents/AdsList/index";
import {getHandleClose, getUpdatePropertyList} from "../../../../../../../../../../../components/EditAdDialog";
import pencilIcon from "../../../../../../../../MyAccount/containers/images/penciIconBlack.svg";
import greyPencil from "../../../../../../../../MyAccount/containers/images/penciIconGrey.svg";
import arrows from "../../../../../../../../../../../components/ListItem/images/compareArrowsIconWhite.svg";
import DatePicker from "react-datepicker";
import {dataServices as dataService} from "../../../../../../../../../../../services/dataServices";
import Select from "react-select";
import {FlagControl, FlagOption} from "../../../../../../../../../../../components/FlagControl";
import Loading from "../../../../../../../../../../../components/Loading";
import {HiPlusCircle} from 'react-icons/hi';
import defaultAvatar from "../../../../../../../../../../../components/MainNavBar/images/avatar_placeholder.svg";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../../../../../../../../../../services/authServices";


const AdvancedEdit = ({property}) => {

    const {user, setUser} = useAuth();

    const {t} = useTranslation();
    const transDirc = "main_page.my_ads.create_ad.house_details.advanced_edition.";
    let open = getOpenInformation();

    const history = useHistory();
    const popupDialog = usePopupDialog();
    const [residents, setResidents] = useState([]);
    const [editingResident, setEditingResident] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [openDatePicker, setOpenDatePicker] = useState(null);

    const [countries, setCountries] = useState([]);

    useEffect(() => {
        (async () => {
            let _countries = (await dataService.getCountries()).map(c => ({value: c.iso2, label: t('country.' + c.iso2)}));
            setCountries(_countries)
        })();
    }, [])

    useEffect(() => {
        if (countries) setCountries(_countries => _countries.map(c => ({value: c.value, label: t('country.' + c.value)})))
    }, [localStorage?.getItem('i18nextLng')])

    const inactivateAd = () => {
        let roomsNumber = Object.values(property.rooms).filter((room) => room.disabled !== true).length;
        popupDialog.openPopupDialog({
            title: t(`popup-dialog.disable_${open.isRoom ? 'room' : 'property'}.title`),
            message: t(`popup-dialog.disable_${open.isRoom ? 'room' : 'property'}.message`),
            request: async () => {
                if (open.isRoom) {
                    await propertyServices.disableRoom(property.id, open.room_id);
                    setUser(prev => ({
                        ...prev,
                        activeListings: prev.activeListings - 1 >= 0 ? prev.activeListings - 1 : 0,
                        inactiveListings: prev.inactiveListings + 1
                    }));
                } else {
                    if (property.accommodation === "apartment") {
                        await propertyServices.disableRoom(property.id, 1);
                        setUser(prev => ({
                            ...prev,
                            activeListings: prev.activeListings - 1 >= 0 ? prev.activeListings - 1 : 0,
                            inactiveListings: prev.inactiveListings + 1
                        }));
                    } else {
                        for (const [key, value] of Object.entries(property.rooms)) {
                            await propertyServices.disableRoom(property.id, key.split("room_")[1]);
                        }
                        setUser(prev => ({
                            ...prev,
                            activeListings: prev.activeListings - roomsNumber >= 0 ? prev.activeListings - roomsNumber : 0,
                            inactiveListings: prev.inactiveListings + roomsNumber
                        }));
                    }
                }
            },
            request_done: async () => {
                getUpdatePropertyList();
                getHandleClose();
            }
        })
    };

    useEffect(() => {
        (async () => {
            const {residents} = await propertyServices.getResidents(property.id, open.isApartment ? 1 : open.room_id);
            setResidents(residents);
            setLoading(false);
        })();
    }, [open.room_id, open.isApartment]);


    const CustomInput = forwardRef(({value, onClick}, ref) => (
        <div className={"CustomInput"} onClick={onClick} ref={ref}>
            {value}
            <img src={greyPencil} alt="pencil"/>
        </div>
    ));

    const handleCancelEditing = () => {
        if (editingResident.id === "new") {
            setResidents(_residents => _residents.filter(r => r.id !== "new"));
        }
        setEditingResident({})
    }

    const handleSaveResident = async () => {
        setLoadingEdit(true);
        let formData = new FormData();
        for (const [key, value] of Object.entries(editingResident)) {
            formData.append(key, value);
        }
        try {
            let roomNumber = open.isApartment ? 1 : open.room_id;
            let response = await propertyServices.saveResident(property.id, roomNumber, formData);
            if (response.status === "success") {
                delete response.status;
                setResidents(_residents => {
                    let _residents_ = _residents.filter(r => r.id !== editingResident.id);
                    _residents_.push(response);
                    return _residents_;
                });
            }
            setEditingResident({});
        } catch (e) {
            console.log("Error saving resident", e)
        }
        setLoadingEdit(false);
    }

    const handleDeleteResident = async () => {
        setLoadingEdit(true);
        try {
            let roomNumber = open.isApartment ? 1 : open.room_id;
            let response = await propertyServices.deleteResident(property.id, roomNumber, editingResident.id);
            if (response.status === "success") {
                setResidents(_residents => _residents.filter(r => r.id !== editingResident.id));
            }
            setEditingResident({});
        } catch (e) {
            console.log("Error deleting resident", e)
        }
        setLoadingEdit(false);
    }

    const editingPhoto = useMemo(() => {
        if (!editingResident.photo) {
            return defaultAvatar;
        } else if (editingResident.photo instanceof File) {
            return URL.createObjectURL(editingResident.photo);
        } else {
            return editingResident.photo;
        }

    }, [editingResident.photo])


    return (
        <div className={"Advanced"}>
            {(open.isRoom || open.isApartment) && <div className={"Advanced__section"}>
                <div className={"Advanced__section-title"}>
                    <span>{t(transDirc + "add_residents")}</span>
                    <span>{residents.length} {t(transDirc + "resident.label", {count: residents.length === 1 ? 1 : 2})}</span>
                </div>
                <div className={"Advanced__section-content"} id={"residents-container"}>
                    {loading ? <Loading relative/> : residents.map((resident, index) => {
                        if (editingResident.id === resident.id) {
                            return (
                                <div className={"Resident Resident__edit"} key={editingResident.id}>
                                    {loadingEdit && <Loading cover={0.3}/>}
                                    <div className={"Resident__edit-dates"}>
                                        <label>
                                            <div className={"Resident__photo"} style={{backgroundImage: `url(${editingPhoto})`}}>
                                                <input type={"file"}
                                                       accept={"image/*"}
                                                       onChange={(e) => {
                                                           let photo = e.target.files?.[0];
                                                           if (photo) setEditingResident(prev => ({...prev, ["photo"]: photo}))
                                                       }}/>
                                            </div>
                                        </label>


                                        {openDatePicker && <div className={"DatePickerBackground"} onClick={() => setOpenDatePicker(null)}/>}
                                        <div className={"Resident__edit-dates__inputs"}>
                                            {["moveIn", "moveOut"].map((date, index) => {
                                                return (
                                                    <>
                                                        {index === 1 && <div className={"arrows-container"}><img src={arrows} alt={"arrows"}/></div>}
                                                        <div className={"DatePickerLabel"} key={"date_input_" + date} onClick={() => {
                                                            if (openDatePicker !== date) setOpenDatePicker(date)
                                                        }} style={{cursor: "pointer"}}>
                                                            <label htmlFor={date}>{t(transDirc + "resident." + date)}</label>
                                                            <div className={"CustomDatePicker"} style={{width: "fit-content"}}>
                                                                <DatePicker className={"input_field"}
                                                                            name={date}
                                                                            open={openDatePicker === date}
                                                                            onChange={(day) => {
                                                                                setEditingResident(prev => ({...prev, [date]: day.toISOString()}))
                                                                                setOpenDatePicker(date === "moveIn" && !editingResident["moveOut"] ? "moveOut" : null)
                                                                            }}
                                                                            selected={editingResident[date] && new Date(editingResident[date])}
                                                                            locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                                                            dateFormat={"dd MMM yyyy"}
                                                                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                                                                            popperPlacement='bottom-start'
                                                                            minDate={date === "moveOut" && new Date(editingResident["moveIn"])}
                                                                            maxDate={date === "moveIn" && new Date(editingResident["moveOut"])}
                                                                            popperModifiers={{
                                                                                preventOverflow: {
                                                                                    enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                                },
                                                                                hide: {
                                                                                    enabled: false // turn off since needs preventOverflow to be enabled
                                                                                }
                                                                            }}
                                                                            customInput={<CustomInput/>}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>

                                    </div>
                                    <div className={"Resident__edit-info info-personal"}>
                                        <div className={"info"}>
                                            <label htmlFor={"name"}>{t("name")}</label>
                                            <input className={"input_field basic_container"} type={"text"} name={"name"} value={editingResident.name} onChange={(e) => {
                                                let name = e.target.value;
                                                setEditingResident(prev => ({...prev, name}))
                                            }}/>
                                        </div>
                                        <div className={"info"}>
                                            <label htmlFor={"birthdate"}>{t(transDirc + "resident.birthdate_label")}</label>
                                            <DatePicker className={"input_field basic_container"} name={"birthdate"}
                                                        onChange={(date) => setEditingResident(prev => ({...prev, birthdate: moment(date).format("MM-yyyy")}))}
                                                        selected={editingResident.birthdate ? new Date(editingResident.birthdate.split("-")[1], editingResident.birthdate.split("-")[0] - 1) : null}
                                                        locale={localStorage.getItem('i18nextLng').split('-')[0]} dateFormat={"MM/yyyy"}
                                                        popperPlacement='bottom-start'
                                                        showMonthYearPicker
                                                        showYearDropdown
                                            />
                                        </div>
                                        <div className={"info"}>
                                            <label htmlFor={"nationality"}>{t(transDirc + "resident.nationality")}</label>
                                            <Select searchable={true}
                                                    name={"nationality"}
                                                    defaultValue={countries.filter(c => c.value === editingResident["nationality"])}
                                                    value={countries.filter(c => c.value === editingResident["nationality"])}
                                                    onChange={(o) => {
                                                        setEditingResident(prev => ({...prev, "nationality": o.value}))
                                                    }}
                                                    options={countries}
                                                    placeholder={t("billing_details.country")}
                                                    searchPlaceholder={t("billing_details.country")}
                                                    className={'input-select basic_container'} classNamePrefix={'input-select'}
                                                    selectedSize={14} components={{Option: FlagOption, Control: FlagControl}}
                                            />
                                        </div>
                                    </div>
                                    <div style={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                                        <div className={"Resident__edit-info"} style={{minWidth: "fit-content", width: "unset"}}>
                                            <div className={"info"}>
                                                <label htmlFor={"occupation"}>{t("main_page.radar.offers_filters.occupation")}</label>
                                                <div className={"occupation-options"}>
                                                    {["student", "worker", "internship", "other"].map((occupation, index) => {
                                                        return (
                                                            <div key={"occupation_" + occupation} className={"basic_container" + (editingResident.occupation === occupation ? " selected" : "")} onClick={(e) => {
                                                                setEditingResident(prev => ({...prev, occupation: occupation}))
                                                            }}>{t('main_page.my_visits.visit_card.occupation.' + occupation)}</div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"Resident__edit-info"} style={{minWidth: "fit-content", width: "unset"}}>
                                            <div className={"info"}>
                                                <label htmlFor={"gender"}>{t("gender.label")}</label>
                                                <div className={"occupation-options"}>
                                                    {["male", "female", "other", "ratherNotSay"].map((gender, index) => {
                                                        return (
                                                            <div key={"gender" + gender} className={"basic_container" + (editingResident.gender === gender ? " selected" : "")} onClick={(e) => {
                                                                setEditingResident(prev => ({...prev, gender: gender}))
                                                            }}>{t("gender." + gender)}</div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"Resident__edit-buttons"}>
                                        <div className={"input-checkbox"}>
                                            <input type={"checkbox"} disabled={editingResident.reservation_id} id={"active"} name={"active"} checked={editingResident.active} onChange={() => setEditingResident(prev => ({...prev, active: !prev.active}))}/>
                                            <label htmlFor={"active"}>Show tenant on this listing</label>
                                        </div>
                                        <div className={"buttons"}>
                                            {editingResident.id !== "new" && <div className={"btn btn-delete"} onClick={handleDeleteResident}>{t("buttons.remove")}</div>}
                                            <div className={"btn btn_grey_soft"} onClick={handleCancelEditing}>{t("buttons.cancel")}</div>
                                            <button className={"btn btn_blue"} onClick={handleSaveResident} disabled={!(editingResident.name && editingResident.moveIn && editingResident.moveOut && editingResident.occupation && editingResident.birthdate && editingResident.nationality) || loadingEdit}>{t("buttons.save")}</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else return (
                            <div className={"Resident"} key={resident.id}>
                                <div className={"Resident__photo"} style={{backgroundImage: `url(${resident.photo || defaultAvatar})`}}/>
                                <div className={"Resident__info"}>
                                    <span className={"Resident__info-label"}>{resident.name}</span>
                                    <span className={"Resident__info-value"}>{t('main_page.my_visits.visit_card.occupation.' + resident.occupation)}</span>
                                </div>
                                {["moveIn", "moveOut", "birthdate", "nationality"].map((key, index) => {
                                    let value = resident[key];
                                    if (["moveIn", "moveOut"].includes(key)) value = moment(resident[key]).format("DD MMM YYYY");
                                    if (key === "nationality") value = t("country." + resident[key]);

                                    return (
                                        <div className={"Resident__info"} key={"resident-info_" + index}>
                                            <span className={"Resident__info-label"}>{t(transDirc + `resident.${key}`)}</span>
                                            <span className={"Resident__info-value"}>{value}</span>
                                        </div>
                                    )
                                })}
                                <div className={"Resident__actions"}>
                                    <button className={"Resident__button btn"} onClick={() => setEditingResident(resident)}><img src={pencilIcon} alt={"edit-icon"}/>{t("buttons.edit")}</button>
                                    {resident.reservation_id && <a className={"link link_grey"} onClick={() => history.push("/reservations/" + resident.reservation_id + "/details")}>{t(transDirc + "see_reservation")}</a>}
                                </div>

                            </div>
                        )
                    })}
                </div>
                {editingResident.id !== "new" && <div className={"Advanced__add-button"} onClick={() => {
                    setResidents(prev => [...prev, {id: "new", "active": true}]);
                    setEditingResident({id: "new", "active": true})
                    setTimeout(() => {
                        document.getElementById("residents-container")?.scroll({top: document.getElementById("residents-container")?.scrollHeight, behavior: 'smooth'});
                    }, 100)

                }}>{t(transDirc + "add_more_residents")} <HiPlusCircle/></div>}
            </div>}
            <div className={"Advanced__section"}>
                <div className={"Advanced__section-title"}><span>{t(transDirc + "ad_management")}</span></div>
                <div className={"Advanced__section-content"}>
                    <div className={"inactivate_container--btn"}>
                        <button className={"btn btn_blue"} onClick={inactivateAd}>
                            {t("main_page.my_ads.create_ad.house_details.advanced_edition.inactivate.label")}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};


export default AdvancedEdit;
