import React from "react";

const EmailSent = (props) => (
    <svg
        width={220}
        height={186}
        viewBox="0 0 220 186"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={194.689} height={110.715} rx={30} fill="#F0F3F6" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M162.318 13.5066C163.279 14.8567 162.963 16.7297 161.613 17.6903L104.751 58.1476C100.219 61.3719 94.1382 61.3562 89.6232 58.1086L33.4193 17.6813C32.0743 16.7138 31.7682 14.8391 32.7357 13.4941C33.7032 12.149 35.5779 11.8429 36.9229 12.8104L93.1267 53.2378C95.5579 54.9865 98.8322 54.9949 101.272 53.2588L158.135 12.8014C159.485 11.8409 161.358 12.1566 162.318 13.5066Z"
            fill="#9EB9CA"
            fillOpacity={0.5}
        />
        <rect
            opacity={0.4}
            x={26.1758}
            y={70.9038}
            width={26.3595}
            height={6.96187}
            rx={3}
            fill="#9EB9CA"
        />
        <rect
            opacity={0.4}
            x={26.1758}
            y={82.9312}
            width={19.586}
            height={6.96187}
            rx={3}
            fill="#9EB9CA"
        />
        <g filter="url(#filter0_d_406_19103)">
            <path
                d="M127.374 82.7682C124.98 84.024 125.079 85.8632 127.589 86.8562L135.024 89.7981C137.536 90.7952 141.336 90.2511 143.465 88.5899L175.753 63.1268C177.876 61.4573 178.103 61.7035 176.26 63.6771L150.735 90.998C148.885 92.9654 149.431 95.3818 151.951 96.3562L152.822 96.6955C155.342 97.6699 159.449 99.3064 161.952 100.324L170.198 103.682C172.704 104.7 175.381 103.5 176.092 100.893L188.66 54.6964C189.37 52.0897 187.992 50.9828 185.598 52.2365L127.374 82.7682Z"
                fill="#4BC596"
            />
            <path
                d="M147.779 113.013C147.632 113.453 152.864 105.437 152.864 105.437C154.343 103.177 153.503 100.482 151.004 99.4597L145.298 97.124C142.799 96.102 141.601 97.3019 142.636 99.799C142.636 99.799 147.93 112.559 147.779 113.013Z"
                fill="#4BC596"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_406_19103"
                x={94.4863}
                y={39.6177}
                width={125.514}
                height={145.514}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={40} />
                <feGaussianBlur stdDeviation={10} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.733333 0 0 0 0 0.711944 0 0 0 0 0.711944 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_406_19103"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_406_19103"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);

export default EmailSent;
