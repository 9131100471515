import * as React from "react";

function SvgKe(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M0 223.908h512v102.988a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.896V223.908z"
          fill="#73AF00"
        />
        <path
          d="M8.828.276h494.345a8.829 8.829 0 018.828 8.828v102.988H0V9.103A8.828 8.828 0 018.828.276z"
          fill="#464655"
        />
        <path fill="#C8414B" d="M0 112.088h512V223.9H0z" />
        <g fill="#F5F5F5">
          <path d="M0 106.207h512v17.655H0zm0 105.931h512v17.655H0z" />
          <path d="M303.268 93.618c7.108-14.657 14.525-48.61 14.525-48.61s-22.072 26.845-29.182 41.501c-1.49 3.072-.858 6.605 1.294 8.995l-80.967 166.934 5.496 2.666L295.401 98.17c3.212.211 6.378-1.48 7.867-4.552z" />
          <path d="M208.732 93.618c-7.108-14.657-14.525-48.61-14.525-48.61s22.072 26.845 29.182 41.501c1.49 3.072.858 6.605-1.294 8.995l80.967 166.934-5.496 2.666L216.599 98.17c-3.212.211-6.378-1.48-7.867-4.552z" />
        </g>
        <path
          d="M300.138 168c0 48.754-36.012 88.276-44.138 88.276-8.126 0-44.138-39.522-44.138-88.276S247.874 79.724 256 79.724c8.126 0 44.138 39.522 44.138 88.276z"
          fill="#C8414B"
        />
        <path
          d="M291.31 210.591c5.199-12.631 8.828-27.14 8.828-42.591s-3.628-29.959-8.828-42.591c-5.199 12.631-8.828 27.14-8.828 42.591s3.629 29.959 8.828 42.591zm-70.62-85.182c-5.199 12.631-8.828 27.14-8.828 42.591s3.628 29.959 8.828 42.591c5.199-12.631 8.828-27.14 8.828-42.591s-3.629-29.959-8.828-42.591z"
          fill="#464655"
        />
        <g transform="translate(247 79)" fill="#F5F5F5">
          <ellipse cx={9} cy={89} rx={8.828} ry={13.241} />
          <path d="M10.324.724v75.034C12.731 70.748 17 55.943 17 38.241 17 20.538 12.731 5.734 10.324.724zm-2.648 0C5.269 5.734 1 20.538 1 38.241c0 17.703 4.269 32.508 6.676 37.517V.724zm2.648 101.517v75.034C12.731 172.265 17 157.46 17 139.758c0-17.703-4.269-32.507-6.676-37.517zm-2.648 0C5.269 107.251 1 122.055 1 139.758c0 17.703 4.269 32.508 6.676 37.517v-75.034z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgKe;
