import * as React from "react";

function SvgAf(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M170.667 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h161.839v335.448z"
          fill="#464655"
        />
        <path fill="#FF4B55" d="M170.67.276h170.67v335.448H170.67z" />
        <path
          d="M503.172 335.724H341.333V.276h161.839A8.829 8.829 0 01512 9.104v317.792a8.828 8.828 0 01-8.828 8.828z"
          fill="#73AF00"
        />
        <g fill="#F5F5F5">
          <path d="M279.037 185.308h-46.075l2.56-5.12h40.955zm0-40.957h-46.075l2.56 5.12h40.955z" />
          <path d="M268.798 146.911h-25.597l5.12-5.119h15.358zm-33.279 25.602h7.679v5.12h-7.679zm0-7.68h7.679v5.12h-7.679zm0-12.8h7.679v5.12h-7.679zm0 7.68h7.679v2.56h-7.679zm33.28 12.8h7.679v5.12h-7.679zm0-7.68h7.679v5.12h-7.679zm0-12.8h7.679v5.12h-7.679zm0 7.68h7.679v2.56h-7.679zm-30.72-28.16h5.12v10.239h-5.12zm5.122-2.56h-5.12v-2.56a2.56 2.56 0 115.12 0v2.56zm25.598 2.56h5.12v10.239h-5.12zm5.119-2.56h-5.12v-2.56a2.56 2.56 0 115.12 0v2.56zm-17.919 23.038c-5.655 0-10.239 4.584-10.239 10.239v15.359h20.478V162.27c0-5.655-4.583-10.239-10.239-10.239z" />
          <path d="M239.053 168.157l-13.937-16.259c.09-.263.167-.533.167-.826a2.56 2.56 0 10-2.56 2.56c.154 0 .288-.062.434-.087l1.442 1.683-6.996 16.323 7.679 7.679 6.631-15.47 5.197 6.063 1.943-1.666zm-12.453 5.347l-6.127-6.127 2.764-6.451 6.127 6.127-2.764 6.451zm48.353-3.683l5.197-6.063 6.631 15.47 7.679-7.679-6.996-16.323 1.442-1.683c.147.026.28.087.434.087a2.56 2.56 0 10-2.56-2.56c0 .294.076.564.167.826l-13.937 16.259 1.943 1.666zm7.745-2.768l6.127-6.127 2.764 6.451-6.127 6.127-2.764-6.451zm-23.801-40.174c-.804-.237-1.522-.676-1.976-1.38a17.129 17.129 0 01-.921-1.625 17.129 17.129 0 01-.921 1.625c-.455.705-1.172 1.144-1.976 1.38-4.241 1.249-7.342 5.148-7.342 9.793 0 .889.149 1.737.362 2.56h19.754c.213-.823.362-1.671.362-2.56-.001-4.645-3.101-8.544-7.342-9.793zm-61.558 37.65a59.06 59.06 0 01-.214-4.819c0-3.288.298-6.522.826-9.695a47.353 47.353 0 012.314 1.704 56.462 56.462 0 00-.581 7.991c0 1.892.097 3.763.28 5.608l5.411 1.625c-.338-2.37-.572-4.772-.572-7.233 0-1.224.073-2.433.17-3.638 2.826 3.134 5.056 6.92 5.056 11.104 0 .107 8.213-12.159-6.719-25.704l-.107 5.759-8.6-.09a1.598 1.598 0 00-1.592 1.305 64.303 64.303 0 00-1.006 11.264c0 1.087.029 2.167.082 3.241l5.252 1.578z" />
          <path d="M220.052 113.099a2.559 2.559 0 10-3.13-4.05c-11.585 8.947-19.535 21.239-22.978 35.008l5.276.068c3.321-12.186 10.505-23.049 20.832-31.026z" />
          <path d="M216.623 119.484c-5.567 5.452-9.781 11.869-12.648 18.839a28.706 28.706 0 013.957 3.922c2.58-7.107 6.689-13.632 12.274-19.101a2.559 2.559 0 00.038-3.619 2.558 2.558 0 00-3.621-.041zm-8.896 73.786a58.983 58.983 0 01-8.498-18.116l2.976 1.094a56.543 56.543 0 008.292 16.516l5.65-1.034a51.606 51.606 0 01-7.727-13.198l3.34 1.227a48.827 48.827 0 007.255 11.447l5.879-1.077c-2.312-2.36-4.334-5.017-6.076-7.883l-3.406-6.812a8.525 8.525 0 00-5.178-4.355l-8.058-2.422-1.863-.559-2.682-.807-5.335-1.603-2.848-.856 1.762 5.288a5.206 5.206 0 002.188 2.766 63.69 63.69 0 008.8 21.399l5.529-1.015zm101.067-46.039l-.107-5.759c-14.932 13.545-6.719 25.811-6.719 25.704 0-4.184 2.23-7.97 5.056-11.104.097 1.205.17 2.415.17 3.638 0 2.462-.234 4.864-.572 7.233l5.411-1.625a56.79 56.79 0 00.28-5.608c0-2.701-.206-5.367-.582-7.991a48.52 48.52 0 012.314-1.704c.529 3.172.826 6.408.826 9.695a59.08 59.08 0 01-.214 4.819l5.251-1.578a64.52 64.52 0 00.082-3.241 64.4 64.4 0 00-1.006-11.264 1.598 1.598 0 00-1.592-1.305l-8.598.09z" />
          <path d="M312.779 144.126l5.276-.068c-3.444-13.769-11.393-26.061-22.978-35.008a2.56 2.56 0 00-3.13 4.05c10.327 7.976 17.511 18.839 20.832 31.026z" />
          <path d="M308.025 138.323c-2.868-6.97-7.081-13.387-12.648-18.839a2.556 2.556 0 00-3.619.04 2.558 2.558 0 00.038 3.619c5.585 5.469 9.694 11.993 12.274 19.101a28.569 28.569 0 013.955-3.921zm11.68 27.362l-5.335 1.603-2.682.807-1.863.559-8.058 2.422a8.528 8.528 0 00-5.178 4.355l-3.406 6.812c-1.741 2.866-3.764 5.523-6.076 7.883l5.879 1.077a48.774 48.774 0 007.255-11.447l3.34-1.227a51.647 51.647 0 01-7.727 13.198l5.65 1.034a56.506 56.506 0 008.292-16.516l2.976-1.094a58.972 58.972 0 01-8.498 18.116l5.529 1.012a63.736 63.736 0 008.8-21.399 5.214 5.214 0 002.188-2.766l1.762-5.288-2.848.859zm-37.028 28.301l-.132.117c-7.447 5.748-16.696 9.122-26.544 9.122-9.848 0-19.097-3.374-26.544-9.122l-.132-.117a4.082 4.082 0 00-3.828-1.255l-25.81 5.375 5.727 5.02a7.582 7.582 0 005.457 1.854 64.535 64.535 0 0015.723 11.522l-1.677 3.095a64.884 64.884 0 0031.084 7.887 64.887 64.887 0 0031.084-7.887l-1.677-3.095a64.561 64.561 0 0015.723-11.522 7.588 7.588 0 005.458-1.854l5.727-5.02-25.81-5.375a4.085 4.085 0 00-3.829 1.255zm-53.637 17.997c-4.64-2.414-8.888-5.463-12.711-8.97l2.485-1.185c3.471 3.072 7.289 5.762 11.438 7.915l-1.212 2.24zm66.629-8.968c-3.822 3.506-8.071 6.554-12.711 8.97l-1.213-2.239c4.149-2.153 7.966-4.843 11.438-7.915l2.486 1.184zm-39.67 7.889c-12.192 0-23.363-4.326-32.159-11.473l2.554-1.218c8.305 6.397 18.625 10.131 29.603 10.131 10.978 0 21.299-3.734 29.603-10.131l2.554 1.218c-8.79 7.149-19.963 11.473-32.155 11.473z" />
          <path d="M227.911 190.358c1.099.93 2.239 1.81 3.427 2.628h49.322a43.733 43.733 0 003.427-2.628l-2.49-2.49h-51.194l-2.492 2.49z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgAf;
