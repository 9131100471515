import React from 'react';

import "./style.less";
import Resume1 from './levels/Resume1';
import Resume2 from './levels/Resume2';

const pages =   {'3.1.1':Resume1,'3.1.2':Resume2,'3.1.3':Resume2};

const Resume = ({ updateProperty,currentPageLevel,t,property,changePage}) => {
  const CurrentPage = pages[currentPageLevel];

  return (
    <div className={"Resume section_content"}>
      <CurrentPage key={property.id} currentLevel={currentPageLevel} updateProperty={updateProperty} t={t} property={property}
          changePage={changePage}/>
    </div>
  );
};





export default Resume;
