import React, {useEffect, useMemo, useState} from 'react';
import {Route} from 'react-router-dom';

import './style.less';

import CreateNewAd from './containers/CreateNewAd';
import {propertyServices} from '../../../../services';
import AdsList from './compnents/AdsList';
import PublishedAds from './containers/PublishedAds';
import PagesSwitch from "../../../../components/PagesSwitch";
import {useAuth} from "../../../../services/authServices";
import IntegrationList from "./compnents/IntegrationList/index.js";


const pages = [
    {
        label: 'main_page.my_ads.published.label',
        header: 'main_page.my_ads.published.page_header',
        path: 'published',
        filter: 'published',
        component: PublishedAds
    },
    {
        label: 'main_page.my_ads.in_progress.label',
        header: 'main_page.my_ads.in_progress.page_header',
        path: 'in_progress',
        component: AdsList,
        filter: 'not_finished'
    },
    {
        label: 'main_page.my_ads.disabled.label',
        header: 'main_page.my_ads.disabled.page_header',
        path: 'disabled',
        component: AdsList,
        filter: 'disabled'
    }
]

const MyAds = () => {

    const path = "/my_ads";
    let {user} = useAuth();

    const [userProperties, setUserProperties] = useState(null);
    const [totalResults, setTotalResults] = useState(0);
    const [totalListings, setTotalListings] = useState(null);
    const [loading, setLoading] = useState(true);

    const updateUserPropertiesList = async (page = 0, filters) => {
        if (user.integration_landlord) {
            let _integrations = (await propertyServices.getLandlordIntegrations(page, filters, 20));
            setUserProperties(_integrations.integrations);
            setTotalResults(_integrations.total);
            setTotalListings(_integrations.total);
        } else {
            let {properties, total} = await propertyServices.getPropertiesList(page, filters, 20, false);
            setUserProperties(properties);
            setTotalResults(total);
            setTotalListings((await propertyServices.getLandlordListingsTotal())?.totalListings);
        }
    }

    useEffect(() => {
        if(user?.integration_landlord) {
            setLoading(false);
        } else {
            (async () => {
                setTotalListings((await propertyServices.getLandlordListingsTotal())?.totalListings);
            })();
            pages[0].num = user?.activeListings;
            pages[1].num = user?.inProgressListings;
            pages[2].num = user?.inactiveListings;
            setLoading(false);
        }
    }, [user, user?.activeListings, user?.inProgressListings, user?.inactiveListings, userProperties])

    return (<div className={'MyAds'}>
        {!loading && (user?.integration_landlord ? <IntegrationList
                totalListings={totalListings}
                totalResults={totalResults}
                userProperties={userProperties}
                pages={pages}
                updateUserPropertiesList={updateUserPropertiesList}/> :
        <PagesSwitch path={path}
                     pages={pages}
                     containerClass={true}
                     boxShadow={true}
                     navBarButton={true}
                     type={"secondary"}
                     userProperties={userProperties}
                     totalListings={totalListings}
                     updateUserPropertiesList={updateUserPropertiesList}
                     totalResults={totalResults}
                     extraRoutes={[
                         <Route key={`${path}/ad`} path={`${path}/ad`} component={CreateNewAd}/>
                     ]}/>)}
    </div>)
};

export default MyAds;
