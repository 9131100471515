import React from "react";

const ArrowBackward = (props) => (
    <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="arrow_back" clipPath="url(#clip0_2344_9627)">
            <path
                id="Vector"
                d="M40 22H15.66L26.84 10.82L24 8L8 24L24 40L26.82 37.18L15.66 26H40V22Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_2344_9627">
                <rect width={48} height={48} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default ArrowBackward;
