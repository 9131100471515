import React from "react";
import Switch from "react-switch";

import './style.less'
import {useTranslation} from 'react-i18next';

const StyledSwitch = ({id,onChange,checked}) => {

  const {t} = useTranslation();


  return (
  <Switch id={id} onChange={(value) => onChange(value)}
          checked={checked} onColor={'#57B4F2'} offColor={'#8C9BA5'}
          checkedIcon={<p>{t('yes')}</p>} uncheckedIcon={<p>{t('no')}</p>} className={'Switch'}
          width={62} height={26} activeBoxShadow={'0 0 0px 0px #3bf'} />)

}

export default StyledSwitch;