import React, {useMemo} from 'react';
import DatePicker from 'react-datepicker';
import ReactMoment from 'react-moment';
import moment from 'moment';

import 'moment/locale/pt';

moment().locale(localStorage.getItem('i18nextLng')?.split('-')?.[0])

import './style.less'
import InfoCircleIcon from "../../../../../../../../../../../../../components/svg/InfoCircle";

const OverviewCalendar = ({watchUnavailability, datesArray, t, availableFrom, pageLabel, openResidenceCalendar}) => {
  const locale = localStorage.getItem('i18nextLng')?.split('-')?.[0]

  const getDates = (startDate, endDate) => {
    let dateArray = [];
    let currentDate = moment(startDate);
    let stopDate = moment(endDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).toDate())
      currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }

  const getExcludedDates = useMemo(() => {
    let allUnavailabilities = [];
    if (watchUnavailability)
      allUnavailabilities = watchUnavailability.filter(unavailability => unavailability.added == 2 && !unavailability.removed);
    allUnavailabilities.push(...datesArray);

    let excludedDates = []
    allUnavailabilities.forEach(unavalability => excludedDates.push(...getDates(unavalability.start, unavalability.end)))
    return excludedDates

  }, [watchUnavailability, datesArray]);

  let isPassedDay = (date) => {
    let date_moment = moment(date);
    return date_moment.isBefore(moment(),'day') || (!openResidenceCalendar && (date_moment.isBefore(moment(availableFrom),'day') || getExcludedDates.some(ed => date_moment.isSame(ed, 'day'))));
  };

  return (
    <div className={'OverviewCalendar'}>
      <label htmlFor={'dates_overview'}>{t(pageLabel + '.dates_overview.label')}</label>
      <div className={'OverviewCalendar__info'}>
        <InfoCircleIcon />
        <p>{t(pageLabel + '.dates_overview.info') + ' '}
          {<ReactMoment locale={locale} format="LL">{(openResidenceCalendar || !availableFrom || new Date(availableFrom) < Date.now()) ? Date.now() : availableFrom}</ReactMoment>}</p>
      </div>
      <div className={'OverviewCalendar__Datepicker basic_container'}>
        <DatePicker
          //excludeDates={openResidenceCalendar ? null : getExcludedDates}
          inline
          readOnly
          locale={localStorage.getItem('i18nextLng')?.split('-')?.[0]}
          minDate={!openResidenceCalendar && availableFrom && moment(availableFrom).isAfter(moment()) ? moment(availableFrom).toDate() : new Date()}
          formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
          showPreviousMonths={true}
          openToDate={availableFrom && moment(availableFrom).isAfter(moment()) ? moment(availableFrom).toDate() : new Date()}
          dayClassName={date =>
            isPassedDay(date) ? 'passed_day' : undefined
          }
        />
        <div className={'OverviewCalendar__DatePicker__info'}>
          <div id={'OverviewCalendar__DatePicker__info--available'}>
            <div className={'OverviewCalendar__DatePicker__info--icon'}></div>
            <p>{t(pageLabel + '.dates_overview.available')}</p>
          </div>
          <div id={'OverviewCalendar__DatePicker__info--unavailable'}>
            <div className={'OverviewCalendar__DatePicker__info--icon'}></div>
            <p>{t(pageLabel + '.dates_overview.unavailable')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverviewCalendar;
