import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import Buttons from '../../../../../components/Buttons';
import AmountButtonsInputContainer
    from '../../../../../components/AmountButtonsInputContainer';
import RadioButtons from '../../../../../components/RadioButtons';
import {
    FieldSetCheckBoxes,
    getInitialFieldSetValues
} from '../../../../../components/FieldSetCheckBoxes';
import RoomDetailsHeader from '../../../components/RoomDetailsHeader';

import './style.less';
import {updatePropertyActions} from "../../../../../../../../../../../redux/actions/updatePropertyActions";

const pageLabel = 'main_page.my_ads.create_ad.room_details.room_details';

const RoomDetails = ({
                      currentRoomName,
                      currentRoomIndx,
                      updateRoomLevel,
                      currentPageLevel,
                      goToNextRoom,
                      property,
                      goToPreviousRoom,
                      currentRoomHeader,
                      setCurrentRoomIndx,
                      cloneRoomHandler,
                      maxRoom,
                      setMaxRoom,
                      title,
                      changeInfo,
                      cloneValue,
                      defineUpdateFunc
                     }) => {

    const {t} = useTranslation();

    let optionsNames = {
        privateBathroom: ['toilet', 'sink', 'shower', 'bathtub', 'mirror', 'dryer', 'wcWindow', 'wcHidro'],
        kitchenette: ['fridge', 'freezer', 'stove', 'oven', 'potsPans', 'cutlery', 'coffeeMachine', 'toaster', 'microwave', 'kettle', 'tables', 'chairs', 'juicer', 'blender', 'ventHood', 'kitchenWindow']
    };

    const initialRoomValue = () => {
        return {
            ...property.rooms[currentRoomName],
            privateBathroomEquipments: getInitialFieldSetValues(property.rooms[currentRoomName], optionsNames.privateBathroom),
            kitchenetteEquipments: getInitialFieldSetValues(property.rooms[currentRoomName], optionsNames.kitchenette),
            windowOptions: property.rooms[currentRoomName].externalWindow === true ? 'externalWindow' : property.rooms[currentRoomName].internalWindow === true ? 'internalWindow' : 'externalWindow'
        }
    };

    let [currentRoom, setCurrentRoom] = useState(initialRoomValue());

    useEffect(() => setCurrentRoom(initialRoomValue()), [currentRoomName]);

    const handleRoomFieldChange = (field, value) => {
        setCurrentRoom(prevState => ({...prevState, [field]: value}))
    };

    let amenitiesContainers = [
      {
          title: t(pageLabel + '.ammenities.doubleBed.label'),
          name: 'doubleBed',
          value: currentRoom.doubleBed,
          error: t(pageLabel + '.ammenities.doubleBed.error')
      },
      {
          title: t(pageLabel + '.ammenities.singleBed.label'),
          name: 'singleBed',
          value: currentRoom.singleBed,
          error: t(pageLabel + '.ammenities.singleBed.error')
      },
      {
          title: t(pageLabel + '.ammenities.bunkBed.label'),
          name: 'bunkBed',
          value: currentRoom.bunkBed,
          error: t(pageLabel + '.ammenities.bunkBed.error')
      },
      {
          title: t(pageLabel + '.ammenities.numPeople.label'),
          name: 'numPeople',
          value: currentRoom.numPeople,
          error: t(pageLabel + '.ammenities.numPeople.error')
        }
    ];

    const onSubmit = async (values) => {
        let shouldGoToNextRoom = !values.saveAndExit;
        values.window = values.window === 'true';
        values.balcony = values.balcony === 'true';
        values.couplesAllowed = values.couplesAllowed === 'true';
        values.kitchenette = values.kitchenette === 'true';
        values.privateBathroom = values.privateBathroom === 'true';

        optionsNames.kitchenette.map(value => {
            values[value] = currentRoom.kitchenetteEquipments.includes(value);
        });

        optionsNames.privateBathroom.map(value => {
            values[value] = currentRoom.privateBathroomEquipments.includes(value);
        });

        if (!values.doubleBed) values.doubleBed = 0;
        if (!values.singleBed) values.singleBed = 0;
        if (!values.bunkBed) values.bunkBed = 0;
        if (!values.numPeople) values.numPeople = 0;

        if (values.area === 0) delete values.area;

        if (values.windowOptions === 'externalWindow') {
            values.externalWindow = true;
            values.internalWindow = false;
        } else {
            values.externalWindow = false;
            values.internalWindow = true;
        }

        values.currentLevel = currentPageLevel;

        try {
            if(!await updateRoomLevel(values, currentPageLevel, currentRoomIndx) && shouldGoToNextRoom) {
                goToNextRoom();
            } else {
                reset();
            }
        } catch (e) {
            console.log(e)
        }
    };

    const radioButtons = {
        couplesAllowed: [{id: 'couples_allowed', buttonValue: 'true', label: 'yes'},
            {id: 'no_couples_allowed', buttonValue: 'false', label: 'no'}],
        privateBathroom: [{id: 'privateBathroom', buttonValue: 'true', label: 'yes'},
            {id: 'no_privateBathroom', buttonValue: 'false', label: 'no'}],
        kitchenette: [{id: 'kitchenette', buttonValue: 'true', label: 'yes'},
            {id: 'no_kitchenette', buttonValue: 'false', label: 'no'}],
        window: [{id: 'window', buttonValue: 'true', label: 'yes'},
            {id: 'no_window', buttonValue: 'false', label: 'no'}],
        windowOptions: [{
            id: 'externalWindow',
            buttonValue: 'externalWindow',
            label: `${pageLabel}.window.externalWindow`
        },
            {id: 'internalWindow', buttonValue: 'internalWindow', label: `${pageLabel}.window.internalWindow`}],
        balcony: [{id: 'balcony', buttonValue: 'true', label: 'yes'},
            {id: 'no_balcony', buttonValue: 'false', label: 'no'}]
    };

    const optionsNamesLabeled = (() => {
        let optionsNamesFinal = {};
        Object.keys(optionsNames).map(options => {
            optionsNamesFinal[options] = optionsNames[options].map(option => ({
                value: option,
                label: t(`${pageLabel}.${options}.${option}`)
            }))
        });
        return optionsNamesFinal;
    })();

    const {handleSubmit, register, errors, watch, setValue, control, formState, reset} = useForm({shouldFocusError: true});

    let watchFields = useWatch({
        control, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        name: ['privateBathroom', 'kitchenette', 'window'],
        defaultValue: 'default' // default value before the render
    });

    useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
        if(!formState.isDirty || Object.keys(formState.dirtyFields).length === 0) return resolve();
        await handleSubmit(async (values) => {
            await onSubmit(values);
            resolve()
        }, resolve)()
    })), [currentRoom, formState.isDirty, formState.dirtyFields]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} key={name} className={'RoomDetails'}>
            <div className={'scrollable'}>
                <RoomDetailsHeader currentRoom={currentRoomHeader} setCurrentRoomIndx={setCurrentRoomIndx}
                                   cloneRoomHandler={cloneRoomHandler} property={property}
                                   maxRoom={maxRoom} setMaxRoom={setMaxRoom} rooms={property.rooms}
                                   currentPageLevel={currentPageLevel}
                                   title={title}
                                   changeInfo={changeInfo}
                                   cloneValue={cloneValue}/>

                <div className={'input_containers'}>
                    <div>
                        <div className={'input_container'}>
                            <label htmlFor={'area'}>{t(pageLabel + '.area.label')}</label>
                            <input autoFocus={!property.isEditing} min="0" name="area" type="number" defaultValue={currentRoom.area}
                                   placeholder={`Ex: 70 m2`} className={`basic_container input_field`} onWheel={(e) => e.target.blur()}
                                   ref={register({valueAsNumber: true, maxLength: {value: 3, message: t(pageLabel + '.area.maxLength')}})}/>
                            {errors.area && <p className={'error_message'}>{errors.area.message}</p>}
                        </div>

                        <div className={'input_container input_info'}>
                            <label htmlFor={'internalName'}>{t(pageLabel + '.internalName.label')}</label>
                            <input name="internalName" placeholder={t(pageLabel + '.internalName.placeholder')}
                                   className={`basic_container ${errors.internalName ? 'input_error' : null} input_field`}
                                   defaultValue={currentRoom.internalName}
                                   ref={register({
                                       required: `${t(pageLabel + '.internalName.required')}`
                                   })}/>
                            {errors.internalName && <p className={'error_message'}>{errors.internalName.message}</p>}
                            {<p className={'info'}>{t(pageLabel + '.internalName.info')}</p>}
                        </div>
                    </div>

                    <div className="AmountButtonsInputContainers">
                        {amenitiesContainers.map((container, index) =>
                            <AmountButtonsInputContainer key={index} {...container} errors={errors} watch={watch}
                                                         setValue={setValue}
                                                         register={container.name === 'numPeople' ? register({
                                                             required: {
                                                                 value: true,
                                                                 message: container.error
                                                             }
                                                         }) : register}
                                                         type={'roomsInfo'}/>)}
                    </div>

                    <div className={'input_container'}>
                        <label htmlFor={'couplesAllowed'}>{`${t(pageLabel + '.couplesAllowed.label')}`}</label>
                        <RadioButtons radioButtons={radioButtons.couplesAllowed} name={'couplesAllowed'}
                                      value={currentRoom.hasOwnProperty('couplesAllowed') ? currentRoom.couplesAllowed.toString() : 'true'}
                                      className={'double'} control={control} rules={{required: true}}/>
                    </div>


                    <div className={'input_container'}>
                        <label htmlFor={'privateBathroom'}>{`${t(pageLabel + '.privateBathroom.label')}`}</label>
                        <RadioButtons radioButtons={radioButtons.privateBathroom} name={'privateBathroom'}
                                      value={currentRoom.hasOwnProperty('privateBathroom') ? currentRoom.privateBathroom.toString() : 'false'}
                                      className={'double'} control={control} rules={{required: true}}/>
                        {watchFields.privateBathroom === 'true' &&
                        <FieldSetCheckBoxes checkBoxesNamesMapped={optionsNamesLabeled.privateBathroom}
                                            name={'privateBathroomEquipments'}
                                            values={currentRoom.privateBathroomEquipments} register={register}
                                            type={'right transparent'}
                                            amountInLine={'two-in-line'}
                                            setValues={(value) => {
                                                setValue('privateBathroomEquipments', value, { shouldDirty: true });
                                                handleRoomFieldChange('privateBathroomEquipments', value)
                                            }}/>}
                    </div>


                    <div className={'input_container'}>
                        <label htmlFor={'kitchenette'}>{`${t(pageLabel + '.kitchenette.label')}`}</label>
                        <RadioButtons radioButtons={radioButtons.kitchenette} name={'kitchenette'}
                                      value={currentRoom.hasOwnProperty('kitchenette') ? currentRoom.kitchenette.toString() : 'false'}
                                      t={t} className={'double'} control={control} rules={{required: true}}/>
                        {watchFields.kitchenette === 'true' &&
                        <FieldSetCheckBoxes t={t} checkBoxesNamesMapped={optionsNamesLabeled.kitchenette}
                                            name={'kitchenetteEquipments'}
                                            values={currentRoom.kitchenetteEquipments} register={register}
                                            type={'right transparent'}
                                            amountInLine={'two-in-line'}
                                            setValues={(value) => {
                                                setValue('kitchenetteEquipments', value, { shouldDirty: true });
                                                handleRoomFieldChange('kitchenetteEquipments', value)
                                            }}/>}
                    </div>


                    <div className={'input_container'}>
                        <label htmlFor={'window'}>{`${t(pageLabel + '.window.label')}`}</label>
                        <RadioButtons radioButtons={radioButtons.window} name={'window'}
                                      value={currentRoom.hasOwnProperty('window') ? currentRoom.window.toString() : 'true'}
                                      className={'double'} control={control} rules={{required: true}}
                                      onChange={(value) => handleRoomFieldChange('window', value)}/>
                        {watchFields.window === 'true' &&
                        <RadioButtons radioButtons={radioButtons.windowOptions} name={'windowOptions'}
                                      value={currentRoom.windowOptions}
                                      t={t} type={'single'} control={control} rules={{required: true}}/>
                        }
                    </div>


                    <div className={'input_container'}>
                        <label htmlFor={'balcony'}>{`${t(pageLabel + '.balcony.label')}`}</label>
                        <RadioButtons radioButtons={radioButtons.balcony} name={'balcony'}
                                      value={currentRoom.hasOwnProperty('balcony') ? currentRoom.balcony.toString() : 'true'}
                                      className={'double'} control={control} rules={{required: true}}/>
                    </div>
                </div>
            </div>

            <Buttons clickBack={goToPreviousRoom} saveAndExit={handleSubmit(v => onSubmit({...v, saveAndExit: true}))}/>
        </form>
    )
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(RoomDetails);
