import * as React from "react";

function SvgDo(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#F5F5F5"
        />
        <path
          d="M211.862 132.69H8.828A8.829 8.829 0 010 123.862V9.103A8.829 8.829 0 018.828.275h203.034a8.829 8.829 0 018.828 8.828v114.759a8.829 8.829 0 01-8.828 8.828zm291.31 203.034H300.138a8.829 8.829 0 01-8.828-8.828V212.138a8.829 8.829 0 018.828-8.828h203.034a8.829 8.829 0 018.828 8.828v114.759a8.828 8.828 0 01-8.828 8.827z"
          fill="#41479B"
        />
        <path
          d="M503.172 132.69H300.138a8.829 8.829 0 01-8.828-8.828V9.103a8.829 8.829 0 018.828-8.828h203.034A8.829 8.829 0 01512 9.103v114.759a8.829 8.829 0 01-8.828 8.828zm-291.31 203.034H8.828A8.829 8.829 0 010 326.896V212.138a8.829 8.829 0 018.828-8.828h203.034a8.829 8.829 0 018.828 8.828v114.759a8.828 8.828 0 01-8.828 8.827z"
          fill="#FF4B55"
        />
        <g fill="#64B496">
          <path d="M250.932 193.171s5.274 1.931 4.586 8.552l2.132-.04s-1.345-7.651-2.457-8.568c-1.112-.917-4.261.056-4.261.056z" />
          <path d="M256.218 192.149c-2.237 0-6.147-.003-10.259-1.113.917-.954 2.184-2.876 2.184-6.227 0-5.248-3.616-1.9-3.616-1.9s-2.378 2.001-.94 7.35a20.733 20.733 0 01-4.119-2.109c.825-1.036 2.471-3.45 3.427-7.278 0 0-6.026-1.191-7.674 3.347a20.724 20.724 0 01-2.595-4.317c1.549.019 3.71-1.003 3.71-6.901 0 0-3.935.007-4.986 3.42a33.552 33.552 0 01-.961-4.572c1.571.456 5.633.783 5.633-8.032 0 0-4.877.009-6.006 4.329a52.456 52.456 0 01-.075-3.425c2.368-1.238 5.636-3.965 5.297-9.56 0 0-2.249.51-4.311 2.239.406-2.121.238-5.226-3.015-7.817 0 0-2.989 5.29 1.377 9.585a8.851 8.851 0 00-.984 1.817c-.981-1.494-2.554-2.883-5.088-3.727 0 0-1.166 7.085 5.449 10.049.157 4.138.804 7.794 1.916 10.973-1.895-1.669-3.428-3.967-3.428-3.967 0-7.872-6.453-8.071-6.453-8.071-1.205 8.985 5.439 9.348 5.439 9.348l3.638 3.97c-7.872-1.312-7.872 5.248-7.872 5.248 5.248 1.312 7.872-1.312 7.872-1.312l2.838 1.459c-5.034.147-6.774 5.101-6.774 5.101 6.327 1.055 10.953-.433 12.526-1.057 6.372 4.455 14.226 4.461 17.852 4.461v-1.311h-.002z" />
          <path d="M255.772 192.149c2.237 0 6.147-.003 10.259-1.113-.917-.954-2.184-2.876-2.184-6.227 0-5.248 3.616-1.9 3.616-1.9s2.378 2.001.94 7.35a20.733 20.733 0 004.119-2.109c-.825-1.036-2.471-3.45-3.427-7.278 0 0 6.026-1.191 7.674 3.347a20.724 20.724 0 002.595-4.317c-1.549.019-3.71-1.003-3.71-6.901 0 0 3.935.007 4.988 3.42.414-1.427.729-2.956.961-4.572-1.571.456-5.633.783-5.633-8.032 0 0 4.877.009 6.006 4.329.062-1.105.086-2.247.075-3.425-2.368-1.238-5.636-3.965-5.297-9.56 0 0 2.249.51 4.311 2.239-.406-2.121-.238-5.226 3.015-7.817 0 0 2.989 5.29-1.377 9.585.38.534.711 1.142.984 1.817.981-1.494 2.554-2.883 5.088-3.727 0 0 1.166 7.085-5.449 10.049-.157 4.138-.804 7.794-1.916 10.973 1.895-1.669 3.428-3.967 3.428-3.967 0-7.872 6.453-8.071 6.453-8.071 1.205 8.985-5.439 9.348-5.439 9.348l-3.638 3.97c7.872-1.312 7.872 5.248 7.872 5.248-5.248 1.312-7.872-1.312-7.872-1.312l-2.838 1.459c5.034.147 6.774 5.101 6.774 5.101-6.327 1.055-10.953-.433-12.526-1.057-6.372 4.455-14.226 4.461-17.852 4.461v-1.311z" />
        </g>
        <path
          d="M233.863 134.955a44.99 44.99 0 00-11.958 11.167l4.224 3.115a39.747 39.747 0 0110.551-9.856l-2.817-4.426z"
          fill="#41479B"
        />
        <path
          d="M256.014 169.066h18.367v10.496a5.247 5.247 0 01-5.248 5.248h-7.872c-2.156 0-4.052 1.054-5.248 2.66v-18.404h.001z"
          fill="#FF6B71"
        />
        <path
          fill="#5F64B9"
          d="M259.95 157.258v-14.432h19.68l-5.248 5.248v14.432H259.95z"
        />
        <path fill="#F5F5F5" d="M267.818 162.505h6.56v6.56h-6.56z" />
        <path
          d="M256.014 169.066h-18.367v10.496a5.247 5.247 0 005.248 5.248h7.872c2.156 0 4.052 1.054 5.248 2.66v-18.404h-.001z"
          fill="#FF6B71"
        />
        <path
          d="M252.735 185.126a6.586 6.586 0 013.25 2.307h.061a6.59 6.59 0 013.25-2.307v-10.813h-6.56v10.813h-.001z"
          fill="#F5F5F5"
        />
        <path
          fill="#5F64B9"
          d="M252.079 157.258v-14.432h-19.68l5.248 5.248v14.432h14.432z"
        />
        <path fill="#F5F5F5" d="M237.65 162.505h6.56v6.56h-6.56z" />
        <path fill="#41479B" d="M244.207 149.386v3.936l3.936 2.624v-2.624z" />
        <path fill="#F5F5F5" d="M248.143 153.322v3.936l5.248 2.624v-1.312z" />
        <path fill="#41479B" d="M267.822 149.386v3.936l-3.936 2.624v-2.624z" />
        <path fill="#F5F5F5" d="M263.886 153.322v3.936l-5.248 2.624v-1.312z" />
        <path
          d="M263.932 178.249h-15.829c-3.598 0-6.515-2.937-6.515-6.56v-20.291l14.525 9.795 14.333-9.795v20.291c0 3.624-2.916 6.56-6.514 6.56z"
          fill="#FF4B55"
        />
        <path
          d="M246.831 154.634l-5.248-3.936v14.432l5.248 2.624zm18.367 0l5.248-3.936v14.432l-5.248 2.624zm-17.055 13.123h15.744v6.56h-15.744z"
          fill="#41479B"
        />
        <path
          fill="#FF4B55"
          d="M248.143 155.946l7.871 3.936 7.872-3.936v11.808h-15.743z"
        />
        <g fill="#F5F5F5">
          <path d="M261.919 157.186v13.192a1.97 1.97 0 01-1.967 1.967h-7.872a1.97 1.97 0 01-1.967-1.967V157.24l-3.936-2.636v15.774a5.91 5.91 0 005.903 5.903h7.872a5.91 5.91 0 005.903-5.903v-15.863l-3.936 2.671z" />
          <path d="M263.293 166.35c-2.21.199-4.697.422-7.278 1.32-2.58-.898-5.068-1.121-7.278-1.32-3.698-.332-5.892-.655-7.148-2.886v3.787c1.929 1.254 4.372 1.484 6.913 1.713 2.281.205 4.639.416 7.049 1.329l.465.175.465-.175c2.409-.913 4.767-1.123 7.049-1.329 2.543-.228 4.99-.455 6.919-1.711v-3.798c-1.257 2.24-3.453 2.563-7.156 2.895z" />
        </g>
        <path
          fill="#FFE15A"
          d="M258.638 153.978h-1.967v-1.968h-1.312v1.968h-1.968v1.312h1.968v5.904h1.312v-5.904h1.967z"
        />
        <path
          fill="#464655"
          d="M255.335 159.555l-.72.72-11.528-11.529.72-.72z"
        />
        <path fill="#FFE15A" d="M241.278 146.22l1.441 2.882 1.441-1.441z" />
        <path fill="#464655" d="M256.04 159.338l-.824.6-9.6-13.18.824-.6z" />
        <path fill="#FFE15A" d="M244.227 143.983l.977 3.071 1.647-1.2z" />
        <path
          fill="#464655"
          d="M254.877 159.77l-.583.834-13.368-9.334.583-.835z"
        />
        <path fill="#FFE15A" d="M238.71 149.108l1.923 2.585 1.167-1.67z" />
        <path
          fill="#464655"
          d="M268.227 148.014l.72.72-11.529 11.528-.72-.72z"
        />
        <path fill="#FFE15A" d="M270.745 146.22l-1.441 2.882-1.441-1.441z" />
        <path fill="#464655" d="M265.585 146.146l.823.6-9.6 13.179-.823-.6z" />
        <path fill="#FFE15A" d="M267.796 143.983l-.977 3.071-1.646-1.2z" />
        <path
          fill="#464655"
          d="M270.532 150.443l.583.835-13.368 9.334-.582-.835z"
        />
        <path fill="#FFE15A" d="M273.313 149.108l-1.923 2.585-1.167-1.67z" />
        <path
          d="M255.349 184.436s-5.074-5.449-4.089-10.451c.791-4.023 3.192-8.458 5.071-9.537 0 0 2.657-.164 1.991 2.144-.666 2.308-1.478 2.903-1.24 5.178.409 3.938 1.044 8.604-1.733 12.666z"
          fill="#F5F5F5"
        />
        <path
          d="M256.888 164.477c-.317-.042-.558-.028-.558-.028-1.879 1.079-4.28 5.514-5.071 9.537-.448 2.275.361 4.638 1.373 6.543-.641-8.248 1.767-11.174 4.256-16.052z"
          fill="#41479B"
        />
        <path
          d="M257.08 171.772c-.237-2.275.574-2.87 1.24-5.178.467-1.615-.693-2.019-1.432-2.116 0 0-1.694 3.892-1.803 6.503-.096 2.297.492 11.644.264 13.458 2.777-4.065 2.142-8.731 1.731-12.667z"
          fill="#FF4B55"
        />
        <path
          d="M253.639 166.423a4.608 4.608 0 013.999-1.242l-2.07-6.664a4.616 4.616 0 00-3.999 1.242c.689 2.222 1.38 4.443 2.07 6.664z"
          fill="#F5F0F0"
        />
        <path
          d="M257.638 165.181a4.608 4.608 0 013.999-1.242l-2.07-6.664a4.616 4.616 0 00-3.999 1.242l2.07 6.664z"
          fill="#F5F5F5"
        />
        <path
          d="M250.022 136.354a39.786 39.786 0 00-20.37 5.07l-2.567-4.577a45.017 45.017 0 0123.058-5.74c-.041 1.749-.082 3.498-.121 5.247z"
          fill="#5F64B9"
        />
        <path
          d="M278.167 134.955a44.99 44.99 0 0111.958 11.167l-4.224 3.115a39.747 39.747 0 00-10.551-9.856l2.817-4.426z"
          fill="#41479B"
        />
        <path
          d="M262.008 136.354a39.786 39.786 0 0120.37 5.07l2.567-4.577a45.017 45.017 0 00-23.058-5.74c.041 1.749.082 3.498.121 5.247z"
          fill="#5F64B9"
        />
        <path
          d="M266.51 134.955a39.767 39.767 0 00-20.991 0l-1.385-5.062a45.037 45.037 0 0123.761 0l-1.385 5.062z"
          fill="#41479B"
        />
        <path
          d="M238.432 193.757a95.255 95.255 0 00-16.529 5.985l2.237 4.747a89.835 89.835 0 0115.96-5.742l-1.668-4.99zm35.166 0a95.255 95.255 0 0116.529 5.985l-2.237 4.747a89.835 89.835 0 00-15.96-5.742l1.668-4.99z"
          fill="#FF6B71"
        />
        <g fill="#FF4B55">
          <path d="M277.119 197.815a89.912 89.912 0 00-41.983 0l-1.225-5.102a95.168 95.168 0 0144.433 0c-.407 1.7-.816 3.402-1.225 5.102z" />
          <path d="M256.014 199.241l-3.618.905a1.312 1.312 0 01-1.63-1.272v-1.887c0-.853.802-1.48 1.63-1.272l3.618.905v2.621zm0-2.624l3.618-.905a1.312 1.312 0 011.63 1.272v1.887c0 .853-.802 1.48-1.63 1.272l-3.618-.905v-2.621z" />
        </g>
        <path
          d="M256.296 199.241h-.563a1.03 1.03 0 01-1.031-1.031v-.563a1.03 1.03 0 011.031-1.031h.563a1.03 1.03 0 011.031 1.031v.563a1.032 1.032 0 01-1.031 1.031z"
          fill="#FF6B71"
        />
      </g>
    </svg>
  );
}

export default SvgDo;
