import * as React from "react";

function SvgTz(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M0 256.276V9.103A8.829 8.829 0 018.828.275h379.586L0 256.276z"
          fill="#73AF00"
        />
        <path
          d="M512 79.724v247.172a8.829 8.829 0 01-8.828 8.828H123.586L512 79.724z"
          fill="#4173CD"
        />
        <path
          d="M512 84.702V9.103a8.829 8.829 0 00-8.828-8.828H383.139L0 251.298v75.598a8.829 8.829 0 008.828 8.828h120.033L512 84.702z"
          fill="#FFE15A"
        />
        <path
          d="M512 53.047V9.103a8.829 8.829 0 00-8.828-8.828h-71.718L0 282.953v43.944a8.829 8.829 0 008.828 8.828h71.718L512 53.047z"
          fill="#464655"
        />
      </g>
    </svg>
  );
}

export default SvgTz;
