import * as React from "react";

function SvgKn(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#73AF00"
        />
        <path
          d="M375.086.276L0 246.021v26.385L415.355.276zM512 89.979V63.594L96.644 335.724h40.269z"
          fill="#FFE15A"
        />
        <path
          d="M512 63.594V9.104a8.829 8.829 0 00-8.828-8.828h-87.817L0 272.406v54.49a8.829 8.829 0 008.828 8.828h87.817L512 63.594z"
          fill="#464655"
        />
        <path
          d="M156.018 187.9l18.119 14.59 19.67-12.42c2.547-1.608 5.675.951 4.602 3.766l-8.277 21.74 17.89 14.869c2.316 1.926.849 5.69-2.159 5.542l-23.234-1.154-8.613 21.61c-1.116 2.797-5.15 2.567-5.938-.341l-6.082-22.454-23.213-1.514c-3.006-.196-4.032-4.105-1.511-5.752l19.475-12.724-5.734-22.546c-.74-2.918 2.66-5.102 5.005-3.212zM323.007 78.29l18.119 14.59 19.67-12.42c2.547-1.608 5.675.951 4.602 3.766l-8.277 21.74 17.89 14.869c2.316 1.926.849 5.69-2.159 5.542l-23.234-1.154-8.613 21.61c-1.116 2.797-5.15 2.566-5.938-.341l-6.082-22.454-23.213-1.514c-3.006-.196-4.032-4.105-1.511-5.752l19.475-12.724-5.734-22.546c-.741-2.916 2.659-5.101 5.005-3.212z"
          fill="#F5F5F5"
        />
        <path
          d="M512 326.896V89.979L136.913 335.724h366.258a8.828 8.828 0 008.829-8.828z"
          fill="#FF4B55"
        />
      </g>
    </svg>
  );
}

export default SvgKn;
