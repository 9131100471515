import React from "react";

const CableTV = (props) => (
    <svg
        width={14}
        height={14}
        viewBox="0 0 14 14"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#F0F3F6">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 2.308C0 2.138.142 2 .318 2h13.364c.175 0 .318.138.318.308v8.005c0 .17-.143.307-.318.307H.318A.313.313 0 0 1 0 10.313V2.307Zm.636.308v7.389h12.728v-7.39H.636Zm3.182 8.928c0-.17.143-.308.318-.308h5.728c.175 0 .318.138.318.308 0 .17-.143.308-.318.308H4.136a.313.313 0 0 1-.318-.308Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.227 9.389c0-.17.143-.308.318-.308h1.91c.175 0 .318.138.318.308 0 .17-.143.308-.319.308H9.545a.313.313 0 0 1-.318-.308Z"
            />
            <path d="M12.727 9.697a.313.313 0 0 0 .319-.308.313.313 0 0 0-.319-.308.313.313 0 0 0-.318.308c0 .17.143.308.318.308Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.134 3.014a.3.3 0 0 1 0 .435l-.636.616a.326.326 0 0 1-.45 0 .3.3 0 0 1 0-.435l.636-.616a.326.326 0 0 1 .45 0Zm1.336 0a.3.3 0 0 1 0 .435L1.498 5.358a.326.326 0 0 1-.45 0 .3.3 0 0 1 0-.435l1.972-1.91a.326.326 0 0 1 .45 0Zm5.12 6.991c.177 0 .32.137.32.307v1.232c0 .17-.143.308-.32.308a.313.313 0 0 1-.317-.308v-1.232c0-.17.142-.307.318-.307Zm-3.18 0c.175 0 .317.137.317.307v1.232c0 .17-.142.308-.318.308a.313.313 0 0 1-.318-.308v-1.232c0-.17.142-.307.318-.307ZM7 4.77c-.879 0-1.59.69-1.59 1.54 0 .85.711 1.54 1.59 1.54.879 0 1.59-.69 1.59-1.54 0-.85-.711-1.54-1.59-1.54ZM4.773 6.31c0-1.19.997-2.155 2.227-2.155s2.227.965 2.227 2.155S8.23 8.465 7 8.465 4.773 7.5 4.773 6.31Z"
            />
        </g>
    </svg>
);

export default CableTV;
