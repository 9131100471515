import React from "react";

const ReasonMessage = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="M13.333 1.333H2.666c-.733 0-1.333.6-1.333 1.334v12L4 12h9.333c.733 0 1.333-.6 1.333-1.333v-8c0-.734-.6-1.334-1.333-1.334Zm0 9.334H4L2.666 12V2.667h10.667v8ZM4.666 6H6v1.333H4.666V6Zm2.667 0h1.333v1.333H7.333V6ZM10 6h1.333v1.333H10V6Z"
                fill="#434A5F"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default ReasonMessage;
