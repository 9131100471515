import React from "react";

const Upload = (props) => (
    <svg
        width={14}
        height={17}
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4 13H10V7H14L7 0L0 7H4V13ZM7 2.83L9.17 5H8V11H6V5H4.83L7 2.83ZM0 15H14V17H0V15Z"
            fill="#284F83"
        />
    </svg>
);

export default Upload;