import * as React from "react";

function SvgMt(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172.276H256v335.448h247.172a8.829 8.829 0 008.828-8.828V9.103a8.828 8.828 0 00-8.828-8.827z"
          fill="#FF4B55"
        />
        <path
          d="M8.828 335.724H256V.276H8.828A8.828 8.828 0 000 9.103v317.793a8.828 8.828 0 008.828 8.828z"
          fill="#F5F5F5"
        />
        <path
          d="M73.564 20.874v23.62c1.301.051 2.752.641 3.958 1.847 1.206 1.206 1.798 2.657 1.847 3.958h23.62v23.54H79.368c-.051 1.301-.641 2.752-1.847 3.958-1.206 1.206-2.657 1.796-3.958 1.847v23.62h-23.54v-23.62c-1.301-.051-2.752-.641-3.958-1.847-1.206-1.206-1.796-2.657-1.847-3.958h-23.62V50.3h23.62c.051-1.301.641-2.752 1.847-3.958 1.206-1.206 2.657-1.796 3.958-1.847v-23.62h23.541v-.001zm2.941-2.943H47.08v24.246a9.488 9.488 0 00-3.096 2.083 9.49 9.49 0 00-2.084 3.096H17.655v29.426h24.246a9.48 9.48 0 002.084 3.096 9.473 9.473 0 003.095 2.083v24.245h29.426V81.96a9.475 9.475 0 003.096-2.084 9.473 9.473 0 002.083-3.095h24.245V47.355H81.686a9.475 9.475 0 00-2.084-3.096 9.467 9.467 0 00-3.096-2.083V17.931h-.001z"
          fill="#FF4B55"
        />
        <path
          fill="#CDCDCD"
          d="M102.988 50.298H73.564V20.874H50.023v29.424H20.598v23.541h29.425v29.425h23.541V73.839h29.424z"
        />
        <g transform="translate(42 42)" fill="#B4A08C">
          <circle cx={19.793} cy={20.069} r={11.77} />
          <ellipse
            transform="rotate(-45 6.649 33.204)"
            cx={6.649}
            cy={33.204}
            rx={3.663}
            ry={5.234}
          />
          <ellipse
            transform="rotate(45 32.932 33.197)"
            cx={32.932}
            cy={33.197}
            rx={3.663}
            ry={5.234}
          />
          <ellipse
            transform="rotate(135 32.922 6.938)"
            cx={32.922}
            cy={6.938}
            rx={3.663}
            ry={5.234}
          />
          <ellipse
            transform="rotate(-135 6.647 6.937)"
            cx={6.647}
            cy={6.937}
            rx={3.663}
            ry={5.234}
          />
          <path d="M19.793 36.182c-8.885 0-16.113-7.226-16.113-16.113S10.908 3.956 19.793 3.956s16.113 7.228 16.113 16.113-7.228 16.113-16.113 16.113zm0-30.754c-8.072 0-14.641 6.569-14.641 14.641S11.721 34.71 19.793 34.71s14.641-6.569 14.641-14.641S27.865 5.428 19.793 5.428z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgMt;
