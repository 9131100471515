import React from "react";

const SendArrow = (props) => (
    <svg
        width={25}
        height={24}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="SendArrow" clipPath="url(#clip0_3460_32481)">
            <path
                id="Vector"
                d="M4.51 6.03L12.02 9.25L4.5 8.25L4.51 6.03ZM12.01 14.75L4.5 17.97V15.75L12.01 14.75ZM2.51 3L2.5 10L17.5 12L2.5 14L2.51 21L23.5 12L2.51 3Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_3460_32481">
                <rect width={24} height={24} fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
);

export default SendArrow;
