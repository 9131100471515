import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

import RoomDetailsHeader from '../../../components/RoomDetailsHeader';
import Buttons from '../../../../../components/Buttons';
import {
    FieldSetCheckBoxes,
    getInitialFieldSetValues,
    getFinalValues
} from '../../../../../components/FieldSetCheckBoxes';

import './style.less';
import {updatePropertyActions} from "../../../../../../../../../../../redux/actions/updatePropertyActions";

const pageLabel = 'main_page.my_ads.create_ad.room_details.room_amenities';

const RoomAmenities = ({
                           currentRoomName,
                           currentRoomIndx,
                           updateRoomLevel,
                           currentPageLevel,
                           goToNextRoom,
                           property,
                           goToPreviousRoom,
                           currentRoomHeader,
                           setCurrentRoomIndx,
                           cloneRoomHandler,
                           maxRoom,
                           setMaxRoom,
                           title,
                           changeInfo,
                           cloneValue,
                           defineUpdateFunc
                       }) => {

    const {t} = useTranslation();

    const adjectivesNames = ['luminous', 'spacious', 'modern', 'new', 'comfortable', 'bright', 'cozy', 'renovated'];
    const commoditiesNames = ['lockKey', 'bedding', 'chair', 'desk', 'closet', 'shelf', 'hangers', 'drawers', 'sofa', 'sofaBed', 'ac', 'fan', 'heating', 'electricHeating', 'tv', 'miniFridge', 'microwave'];

    const initialRoomValue = () => {
        return {
            ...property.rooms[currentRoomName],
            adjectives: property.rooms[currentRoomName].adjectives ? property.rooms[currentRoomName].adjectives : [],
            roomCommodities: getInitialFieldSetValues(property.rooms[currentRoomName], commoditiesNames)
        }
    };

    let [currentRoom, setCurrentRoom] = useState(initialRoomValue());
    let [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setIsDirty(false);
        setCurrentRoom(initialRoomValue())
    }, [currentRoomName]);

    const handleRoomFieldChange = (field, value) => {
        setIsDirty(true);
        setCurrentRoom(prevState => ({...prevState, [field]: value}))
    };

    const onSubmit = async (values) => {
        let shouldGoToNextRoom = !values.saveAndExit;
        values.adjectives = currentRoom.adjectives;
        values = getFinalValues(values, commoditiesNames, currentRoom.roomCommodities);
        values.currentLevel = currentPageLevel;

        try {
            if(!await updateRoomLevel(values, currentPageLevel, currentRoomIndx) && shouldGoToNextRoom) {
                goToNextRoom();
            }
        } catch (e) {
            console.log(e)
        }
    };

    const {handleSubmit, register} = useForm({shouldFocusError: true});

    useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
        if(!isDirty) return resolve();
        await handleSubmit(async (values) => {
            await onSubmit(values);
            resolve()
        }, resolve)()
    })), [currentRoom, isDirty]);

    return (

        <form onSubmit={handleSubmit(onSubmit)} className={'RoomAmenities'}>
            <div className={'scrollable'}>
                <RoomDetailsHeader currentRoom={currentRoomHeader} setCurrentRoomIndx={setCurrentRoomIndx}
                                   cloneRoomHandler={cloneRoomHandler} t={t} property={property}
                                   maxRoom={maxRoom} setMaxRoom={setMaxRoom} rooms={property.rooms}
                                   currentPageLevel={currentPageLevel}
                                   title={title}
                                   changeInfo={changeInfo}
                                   cloneValue={cloneValue}/>

                <h5>{t(pageLabel + '.label')}</h5>

                <div className={'input_container commodities_checkboxes'}>
                    <FieldSetCheckBoxes t={t} checkBoxesNames={commoditiesNames} name={'commonArea'}
                                        ref={register("roomCommodities")}
                                        register={register} type={'right label_bold transparent'}
                                        mainObject={currentRoom}
                                        amountInLine={'two-in-line'} values={currentRoom.roomCommodities}
                                        setValues={(value) => handleRoomFieldChange('roomCommodities', value)}
                                        defaultObjectLabel={(value) => pageLabel + '.room_commodities.' + value}
                    />
                </div>


                <div className={'input_checkboxes input_container'}>
                    <label htmlFor={'adjectives'}>{`${t(pageLabel + '.adjectives.label')}`}</label>
                    <FieldSetCheckBoxes t={t} checkBoxesNames={adjectivesNames} name={'adjectives'} register={register}
                                        type={'basic_container'} mainObject={currentRoom}
                                        amountInLine={'four-in-line'} values={currentRoom.adjectives}
                                        setValues={(value) => handleRoomFieldChange('adjectives', value)}
                                        defaultObjectLabel={(value) => ('main_page.my_ads.create_ad.adjectives.' + value)}/>
                </div>

            </div>

            <Buttons clickBack={goToPreviousRoom} saveAndExit={handleSubmit(v => onSubmit({...v, saveAndExit: true}))}/>
        </form>
    );
};

const actionCreator = {
    defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(RoomAmenities);
