import * as React from "react";

function SvgFj(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#82AFFF"
        />
        <path
          d="M247.172 168H8.828A8.829 8.829 0 010 159.172V9.103A8.829 8.829 0 018.828.275h238.345a8.829 8.829 0 018.828 8.828v150.069a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path
          d="M247.172.276h-15.334l-81.77 53.574V.276H105.93V53.85L24.16.276H8.828A8.828 8.828 0 000 9.103v7.002l70.155 45.963H0v44.138h70.155L0 152.17v7.002A8.829 8.829 0 008.828 168h15.334l81.77-53.574V168h44.138v-53.574L231.839 168h15.334a8.829 8.829 0 008.828-8.828v-7.002l-70.155-45.963H256V62.069h-70.155L256 16.106V9.104a8.828 8.828 0 00-8.828-8.828z"
          fill="#F5F5F5"
        />
        <g fill="#FF4B55">
          <path d="M256 70.897H141.241V.276h-26.482v70.621H0v26.482h114.759V168h26.482V97.379H256z" />
          <path d="M97.103 62.069L4.207 1.768C1.735 3.332 0 5.961 0 9.103v.458l80.889 52.508h16.214zm69.18 0l88.374-57.366c-1.536-2.599-4.246-4.427-7.485-4.427h-1.909l-95.194 61.793h16.214zm-76.809 44.138L1.269 163.463C2.79 166.125 5.542 168 8.828 168h1.665l95.196-61.793H89.474zm166.386 53.658l-82.666-53.658H156.98l93.874 60.935c2.812-1.305 4.741-4.04 5.006-7.277z" />
        </g>
        <path
          d="M322.034 96.617v96.858c0 39.281 55.178 59.469 61.902 61.782 6.717-2.313 61.902-22.534 61.902-61.782V96.617H322.034z"
          fill="#F5F5F5"
        />
        <path
          fill="#FF4B55"
          d="M448.022 141.041V94.432H319.849v46.609h55.348v43.695h-55.348v17.479h55.348v52.433l8.739 2.913 8.738-2.913v-52.433h55.348v-17.479h-55.348v-43.695z"
        />
        <circle fill="#F5F5F5" cx={334.223} cy={122.858} r={7.293} />
        <path
          d="M348.231 112.999c-2.334-1.283-6.535-4.726-8.226-4.142-1.692.584-2.07 1.78-4.437 1.962-2.306.178-5.948 2.428-4.43 4.922 1.973 3.246 8.149.722 8.557-.796 0 0 1.517.243 2.792-.061 0 0 2.537 3.54 5.687 3.307 3.15-.234 4.609-4.142 3.91-4.142-.699 0-3.853-1.05-3.853-1.05zm9.685 15.46c-.525.962-4.522 4.38-11.202 5.951-1.487.35-1.225 2.1-2.713 1.225-1.487-.875-5.601-1.576-9.277-1.75-3.676-.175-4.041-3.581-3.413-4.813.797-1.565 6.665-2.519 7.715-.594 1.05 1.926 1.837 1.524 4.45 2.081 1.619.346 1.663 1.138 1.663 1.138s2.976-2.976 5.514-3.851c2.538-.875 4.726-2.801 5.163-2.801.438 0 2.713.612 2.713.612l-.613 2.802zm9.714-10.327c3.851 1.4 15.228.962 27.392-2.976 12.164-3.938 15.666-4.638 21.617-2.451 5.951 2.187 8.527-4.725 4.619-5.894-6.138-1.837-25.124 9.194-38.871 3.004 0 0-.405 1.665.032 2.452 0 0-9.976.35-7.614-9.976 0 0 .68 3.77 5.397 3.337 8.921-.819 10.196 5.462 29.085-.362 10.487-3.233 18.635-1.646 18.707 3.456.087 6.143-8.381 7.571-8.381 7.571s3.296 6.739 9.948 3.763c6.652-2.976 7.001 2.158 7.585 3.91.584 1.751 1.692 3.443 3.15 3.617 0 0 1.05 3.15.642 3.559-.408.408-3.15 3.851-5.892 3.851h-8.693s-1.576-3.91 1.459-3.967c3.033-.058 6.71-.291 6.243-1.926-.467-1.633-2.859-2.392-2.801-4.201 0 0-7.06 5.134-11.435-.934l-.117 2.859s-5.31-.992-7.234-5.251c0 0 .525 4.785-4.025 4.084 0 0 .934 1.4 2.509 2.392 1.576.992-.059 3.15-3.092 4.959-3.033 1.809-12.369 7.06-16.628 1.692-4.259-5.367.934-8.46 2.217-8.11 1.283.35 4.959 6.009 7.468 5.251 2.509-.758 3.734-3.326 1.633-4.9-2.101-1.574-3.673-3.064-2.349-4.654 1.821-2.185 8.534-1.53 8.3-2.988-.233-1.459-6.476-1.05-10.56 3.033-4.084 4.084-21.821 13.478-28.881 7.527-7.059-5.951-1.43-11.727-1.43-11.727z"
          fill="#FFE15A"
        />
        <path
          d="M365.355 115.507s5.338 5.251 14.353 6.914c0 0-1.663 2.538-6.476 1.838 0 0 1.926 1.663 3.239 4.463 0 0-3.063.7-5.777-.788 0 0 .35 2.275 1.663 4.114 0 0-3.588 0-6.652-2.713l-1.225 3.5s-2.1-1.4-3.413-4.114c0 0-2.451 1.926-4.9 1.926 0 0 .875-2.625.875-4.114 0 0-3.5 2.1-6.389 1.313 0 0 .087-3.676 1.05-4.726 0 0-2.275.263-3.763-.525 0 0 .875-2.013 1.313-3.326 0 0-2.451.087-3.938-1.313 0 0 4.726-1.838 5.777-3.588l14.263 1.139z"
          fill="#FFD25A"
        />
        <path
          d="M356.749 100.804c1.05.584 2.742 1.867 3.443 4.025 0 0 1.926-2.801 5.251-3.326 0 0 1.459 1.633 1.05 5.892 0 0 3.676-1.05 5.66.875 0 0-3.91.875-5.718 4.142 0 0-.702 3.338.524 5.322 0 0-1.365.182-2.741-.42 0 0-.7 3.326 0 4.376 0 0-1.926-.234-2.976-2.275 0 0-1.576 2.392-1.459 3.851 0 0-2.1-1.225-2.217-3.033 0 0-1.459 2.742-3.033 2.976 0 0-.175-3.851-.875-5.31 0 0-1.342 1.459-3.092 1.4 0 0 0-2.976 1.459-4.376 0 0-2.451.758-3.793-1.926 0 0 4.025 1.166 5.017-1.283.992-2.451 1.283-3.209 2.684-4.551 1.4-1.341.816-6.359.816-6.359z"
          fill="#FFBE5A"
        />
        <path
          d="M365.355 112.094s-4.989 9.539-9.539 6.652c-4.985-3.165 1.225-10.59 2.013-11.377-.001-.001 3.675.962 7.526 4.725z"
          fill="#FFE15A"
        />
        <path
          d="M347.363 160.952s-.523-6.851-4.237-8.828c-3.492-1.858-5.555-2.787-5.955-5.169 0 0 3.034 1.394 6.241 2.265 2.898.787 5.726 5.692 4.867 11.209l-.916.523z"
          fill="#5ABEA0"
        />
        <path
          d="M335.639 166.585l.472.871.343 1.873-.687 2.221s2.729 1.085 3.35 4.791c.426 2.543 1.546 3.093 2.233 2.57.687-.523 1.103-1.141.386-3.921-.916-3.549-3.393-6.925-3.393-6.925l-1.932-2.962-.772 1.482z"
          fill="#B46446"
        />
        <g fill="#5ABEA0">
          <path d="M338.345 168.066c-2.49-3.223-9.277-9.931-10.564-.785 0 0-2.66-7.706 2.319-7.405 5.754.35 8.245 8.19 8.245 8.19z" />
          <path d="M336.111 167.457c-2.147-2.353-2.835-4.095-3.951-8.45-1.117-4.355-1.46-5.663-2.749-5.576-1.289.087-2.061 1.481-2.061 4.268 0 0-3.779-3.398-1.203-6.098 2.577-2.7 8.331-1.219 8.675 3.572.343 4.791.258 8.014 1.975 12.022l-.686.262z" />
          <path d="M336.713 165.366s-4.552-10.018 8.246-15.42c0 0-11.338-3.049-9.963 13.067l1.717 2.353zm1.461 3.572s-9.62-3.659-8.761 5.227c0 0 1.632-4.182 6.356-2.613l2.405-2.614z" />
        </g>
        <path
          d="M349.081 165.423c0 2.599 1.022 10.14-.429 11.093-.73.479-2.792.087-2.62-1.742.172-1.829.988-10.716.988-12.893 0-2.178 2.061 3.542 2.061 3.542z"
          fill="#B46446"
        />
        <g fill="#5ABEA0">
          <path d="M342.955 171.464c1.661-1.684 2.176-5.517 1.603-10.628 0 0 3.436 2.381 4.524 4.588 0 0 1.088-5.053-2.462-6.389-3.55-1.336-5.44-.58-6.07 1.045-.63 1.626 1.085 3.445 1.833 6.098.686 2.44.572 5.286.572 5.286z" />
          <path d="M348.853 164.088s.573-2.671 2.462-3.775c0 0 3.894 2.73 3.607 6.389 0 0 1.031-4.994-1.088-6.969-2.119-1.974-4.524-2.497-5.726-.755-1.203 1.741.745 5.11.745 5.11z" />
          <path d="M349.769 158.977s1.203-7.144 4.237-8.712c3.034-1.568 8.647-3.02 8.99-3.369 0 0-6.699.349-7.787.349s-7.101 4.355-7.101 10.803c0 6.446.573 2.033.745 1.974.171-.057.916-1.045.916-1.045z" />
        </g>
        <path
          d="M352.561 177.867c.687 1.438 2.319 1.785 2.663 1.045.343-.74 1.889-6.403 1.889-6.403l-2.19-1.045-2.362 6.403z"
          fill="#B46446"
        />
        <path
          d="M365.401 154.099c2.691-1.568 4.467 3.079 4.467 3.079 0-5.471-1.718-7.201-6.013-5.111-1.293.63-2.24 1.949-2.936 3.479-2.174-6.247-6.969-6.151-6.969-6.151l-1.088.755c3.926.755 6.175 5.46 7.126 8.026a30.27 30.27 0 00-.867 4.728c-2.573 2.667-4.198 8.559-4.198 8.559s1.847 1.089 2.19 1.045c0 0 1.203-3.702 2.706-3.615 1.503.087 3.264 2.483 3.307 4.008 0 0 2.233-4.53-.429-6.272-1-.654-1.8-.903-2.446-.915l1.974-2.221c3.221.61 5.454 3.659 5.454 3.659.065-5.444-2.339-6.507-4.874-6.051.453-2.192 1.45-6.334 2.596-7.002z"
          fill="#5ABEA0"
        />
        <path
          d="M418.42 161.638v15.439c0 .747-3.27 3.652-3.27 3.652h8.693s-2.792-3.238-2.792-3.652c0-.414.638-14.278.638-14.278l-3.269-1.161z"
          fill="#B46446"
        />
        <path
          d="M418.978 163.215c-2.073-.166-7.816 4.566-9.491 11.787 0 0-5.105-8.301 7.657-15.191 0 0-5.663-1.661-14.276 7.305-.465.484-.877-13.53 14.595-9.795 0 0-5.902-8.551-18.025-1.826 0 0 8.055-14.444 19.939-1.494 0 0 .079-11.207.16-10.045.081 1.162.957 6.308.957 6.308l2.313-4.067-1.834 8.301s10.607-14.112 19.62.582c0 0-11.645-4.814-17.227.581 0 0 11.964-3.238 11.006 10.625 0 0-4.705-8.218-12.362-7.139 0 0 9.013 4.15 6.141 15.357-.001.001-5.432-10.99-9.173-11.289zm-76.163 49.887a2.416 2.416 0 00-1.676-.966c.235-.81.392-1.18.329-1.77.186.046.364.13.555.153l.082-.666c-.708-.085-1.48-.403-2.273-.887a2.414 2.414 0 00.843-1.852c0-.857-.393-1.575-.952-1.982-.558.408-.952 1.126-.952 1.982 0 .643.264 1.159.61 1.572-.295-.204-.593-.44-.892-.694-.077-.527-.279-1.058-.747-1.484-.633-.577-1.428-.77-2.106-.632-.075.687.191 1.461.824 2.039.489.446 1.065.598 1.618.61.126.107.258.214.397.323a2.583 2.583 0 00-1.198.142c-.806.294-1.345.908-1.537 1.572.575.385 1.383.51 2.188.216a2.406 2.406 0 001.399-1.335c.471.301.988.552 1.523.753l-.085.011c-.018.483.014.682-.226 1.58a2.411 2.411 0 00-2.014-.286c-.822.242-1.399.822-1.633 1.473.55.42 1.348.596 2.171.353.618-.182 1.037-.579 1.336-1.03-.113.341-.255.693-.414 1.052-.483.223-.936.567-1.213 1.137-.375.77-.335 1.588-.011 2.199.681-.122 1.348-.596 1.724-1.366.289-.595.274-1.19.128-1.724.067-.151.132-.308.198-.472.063.377.216.759.474 1.109.509.69 1.25 1.034 1.942 1.031.206-.66.097-1.471-.412-2.161z"
          fill="#5ABEA0"
        />
        <path
          d="M338.874 210.951c2.911 0 6.719-.111 6.606 2.799-.111 2.911-3.359 12.766 4.143 15.789 7.502 3.023 8.398 2.575 8.398 2.575l3.359 7.726 8.174-2.911-7.839-6.271s-.672-4.255-2.575-7.167c0 0 4.815-2.464 6.495-4.591l-3.359-.224s4.367-2.799 6.382-5.934l-2.351.224s2.687-2.688 3.359-5.823c0 0-4.367 4.367-8.846 4.927 0 0 1.903-4.927 2.351-6.83 0 0-3.359 2.127-5.71 3.023-2.351.896-6.047 1.007-4.367 8.622 0 0-2.016-1.679-3.247-5.263-1.231-3.583-1.679-4.927-3.359-4.927-2.743 0-3.807 2.464-3.807 2.464l-3.807 1.792z"
          fill="#FFECDE"
        />
        <path
          d="M421.519 212.391c1.007-1.343 3.022-5.54 4.785-4.449 1.763 1.091 4.114 3.777 5.54 2.518 1.426-1.259 1.511-2.854-.672-3.609-2.183-.755-4.617-2.015-6.127-1.511-1.511.503-6.127 5.289-6.127 5.289l2.601 1.762z"
          fill="#B46446"
        />
        <path
          d="M408.509 216c-.084-1.931 1.219-10.313 6.464-9.234 2.854.588 4.258 3.613 7.975 5.036 3.945 1.511 6.464 2.434 5.708 5.036-.756 2.602-2.183 5.708-5.457 6.464-3.274.757-14.69-7.302-14.69-7.302z"
          fill="#FFE15A"
        />
        <g fill="#5ABEA0">
          <path d="M405.067 222.911c-1.119.224-5.26 6.268-.335 12.424 0 0 6.044 2.462 10.857-1.343s1.231-5.708 1.231-5.708l-11.753-5.373z" />
          <path d="M405.486 224.226c-.503-.756-.419-1.315-.419-1.315s-.252-8.674 3.358-8.842c3.61-.168 16.62 8.394 16.62 9.821 0 1.427-4.198 5.54-7.723 6.379-3.524.84-11.836-6.043-11.836-6.043z" />
        </g>
        <path
          d="M396.589 245.212s11.466-5.151 9.311-9.342c-1.555-3.023-5.05-1.086-5.603 1.223-.691 2.881-2.932 6.896-3.708 8.119z"
          fill="#B46446"
        />
        <path
          d="M383.936 259.854l-.663-.211c-.665-.213-16.494-5.311-32.583-15.916-21.605-14.238-33.026-31.617-33.026-50.252V92.247h132.543v101.228c0 18.636-11.421 36.014-33.026 50.252-16.088 10.605-31.917 15.703-32.583 15.916l-.662.211zM322.034 96.617v96.858c0 39.281 55.178 59.469 61.902 61.782 6.717-2.313 61.902-22.534 61.902-61.782V96.617H322.034z"
          fill="#464655"
        />
      </g>
    </svg>
  );
}

export default SvgFj;
