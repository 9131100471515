import React, {useCallback, useRef, useState} from 'react';
import {BiMinus, BiPlus} from 'react-icons/bi';

import './style.less'

const AmountButtonsInput = ({
                                amount = 1,
                                min = 1,
                                units,
                                name,
                                value,
                                register,
                                className,
                                disabled,
                                setValue,
                                watchValue,
                                ...input
                            }) => {
    const [inputFocused, setInputFocused] = useState(false);

    const onBlur = () => {
        setInputFocused(false);
    }

    const focusOnTheInput = useCallback(() => {
        const anchor = document.querySelector(`#${name}`);
        anchor.focus();
    }, [])

    const onFocus = (e) => {
        focusOnTheInput();
        setInputFocused(true);
    }

    watchValue = watchValue || value;

    const subtract = () => {
        if (!watchValue || watchValue <= min) {
            setValue(name, min, {
                shouldValidate: true,
                shouldDirty: true
            })
        } else {
            setValue(name, parseInt(watchValue) - amount, {
                shouldValidate: true,
                shouldDirty: true
            })
        }
    }

    const add = () => {
        setValue(name, watchValue ? parseInt(watchValue) + amount : amount, {
            shouldValidate: true
        })
    }

    function resizeInput() {
        return watchValue ? (watchValue.toString().length + 0.5) + 'ch' : '';
    }

    const componentRef = useRef();

    const maxWidth = componentRef.current ? (componentRef.current.offsetWidth / 3.3) + 'px' : undefined;

    return (
        <div
            className={`basic_container hover_focus_border AmountButtonsInput ${className} ${disabled ? 'disabled' : null} ${inputFocused ? 'focus' : ''}`}
            ref={componentRef} onClick={() => focusOnTheInput()}>
            <button type="button" disabled={watchValue === 0 || disabled} className={'center'}
                    onClick={() => subtract()}>
                <BiMinus/></button>
            <div>
                <input type="number" name={name} style={{maxWidth: maxWidth, width: resizeInput()}} defaultValue={value}
                       disabled={disabled} id={name} min={min} onWheel={(e) => e.target.blur()}
                       ref={register} {...input} onFocus={onFocus} onBlur={onBlur}/>
                {watchValue > 0 && units && <p>{units}</p>}
            </div>
            <button type="button" className={'center'} disabled={disabled} onClick={() => add()}><BiPlus/></button>
        </div>
    )
}

export default AmountButtonsInput;
