import React from 'react';

import './style.less'
import AdsList from '../../compnents/AdsList';

const PublishedAds = ({userProperties, updateUserPropertiesList, totalResults, totalListings, ...props}) => {
  return (<div className={'PublishedAds'}>
    <AdsList totalListings={totalListings} userProperties={userProperties} totalResults={totalResults} updateUserPropertiesList={updateUserPropertiesList} pages={props.pages} header={props.header}/>
  </div>)
}

export default PublishedAds;
