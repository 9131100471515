export const userServices = {
    login,
    logout,
    signup,
    getUser,
    saveAccountDetails,
    recoverPassword,
    resetPassword,
    saveProfilePicture,
    changePassword,
    changeEmail,
    saveFiscalInfo,
    saveBankInfo,
    addBankInfo,
    addFiscalInfo,
    deleteFiscalInfo,
    deleteBankInfo,
    makeDefaultBankInfo,
    makeDefaultFiscalInfo,
    validateVATNumber,
    verifyEmail,
    verifyPhone,
    sendPhoneVerification,
    resendVerifyEmail,
    updateUserLanguage,
    updateNotificationBlocks,
    subscribeMailchimp,
    unsubscribeMailchimp,
    deleteAccount,
    getUsers,
    addUser,
    changeUser,
    deleteUser,
    validateLandlord,
    removeVerificationDocument,
    removeBankInfoDocument,
    getFMCTokens
};

import {get, post} from "./index.js";

async function login(email, password) {
    try {
        return await post(`/auth/landlord/login`, {email, password});
    } catch (e) {
        throw e;
    }
}

async function signup(user) {
    try {
        return await post(`/auth/landlord/signup`, {...user});
    } catch (e) {
        throw e;
    }
}

async function logout() {
    try {
        let logoutRes = await get(`/auth/landlord/logout`);
        if (logoutRes.redirect) {
            return window.location.href = logoutRes.redirect;
        }
        return logoutRes;
    } catch (e) {
        throw e;
    }
}


async function getUser() {
    try {
        let user = await get(`/auth/landlord/me`);
        if ((!user.email_verified || !user.phone_verified || (!user.has_password && !user.googleId && !user.facebookId) || !user.phone) && !user.impersonateOriginal && !window.location.href.includes("/start/verification")) {
            return window.location.href = "/start/verification";
        }
        return user;
    } catch (e) {
        throw e;
    }
}

async function saveAccountDetails(accountDetails) {
    try {
        return await post(`/auth/landlord/save`, accountDetails);
    } catch (e) {
        throw e;
    }
}

async function changeEmail(email) {
    try {
        return await post(`/landlord/changeEmail`, {email});
    } catch (e) {
        throw e;
    }
}

async function updateUserLanguage(language) {
    try {
        return await post(`/landlord/updateLanguage`, {language});
    } catch (e) {
        throw e;
    }
}

async function updateNotificationBlocks(notificationBlocks) {
    try {
        return await post(`/landlord/updateNotificationBlocks`, {notificationBlocks});
    } catch (e) {
        throw e;
    }
}

async function saveFiscalInfo(fiscalInfo) {
    try {
        return await post(`/landlord/saveFiscalInfo`, {...fiscalInfo, fiscal_info_id: fiscalInfo.id});
    } catch (e) {
        throw e;
    }
}

async function addFiscalInfo() {
    try {
        return await post(`/landlord/addFiscalInfo`);
    } catch (e) {
        throw e;
    }
}

async function deleteFiscalInfo(id) {
    try {
        return await post(`/landlord/deleteFiscalInfo`, {fiscal_info_id: id});
    } catch (e) {
        throw e;
    }
}


async function saveBankInfo(form) {
    try {
        return await post(`/landlord/saveBankInfo`, form);
  } catch (e) {
    throw e;
  }
}

async function removeBankInfoDocument(bankInfo) {
  try {
    return await post(`/landlord/removeBankInfoDocument`, bankInfo);
    } catch (e) {
        throw e;
    }
}

async function addBankInfo() {
    try {
        return await post(`/landlord/addBankInfo`);
    } catch (e) {
        throw e;
    }
}

async function deleteBankInfo(id) {
    try {
        return await post(`/landlord/deleteBankInfo`, {bank_info_id: id});
    } catch (e) {
        throw e;
    }
}

async function makeDefaultBankInfo(id) {
  try {
    return await post(`/landlord/makeDefaultBankInfo`, {bank_info_id: id});
  } catch (e) {
    throw e;
  }
}

async function makeDefaultFiscalInfo(id) {
  try {
    return await post(`/landlord/makeDefaultFiscalInfo`, {fiscal_info_id: id});
  } catch (e) {
    throw e;
  }
}

async function validateVATNumber(id, vat, country) {
    try {
        return await post(`/landlord/validateVAT`, {fiscal_info_id: id, vat, country});
    } catch (e) {
        throw e;
    }
}


async function recoverPassword(email) {
    try {
        return await post(`/auth/landlord/recoverPassword`, {email});
    } catch (e) {
        throw e;
    }
}

async function resetPassword(token, password) {
    try {
        return await post(`/auth/landlord/resetPassword`, {token, password});
    } catch (e) {
        throw e;
    }
}

async function changePassword(password) {
    try {
        return await post(`/landlord/changePassword`, password);
    } catch (e) {
        throw e;
    }
}

async function saveProfilePicture(files) {
    try {
        return await post('/landlord/uploadUserPhoto', files);
    } catch (e) {
        throw e;
    }
}

async function verifyEmail(email, token) {
    try {
        return await post(`/auth/landlord/verifyEmail`, {"email": email, "token": token});
    } catch (e) {
        throw e;
    }
}

async function verifyPhone(token) {
    try {
        return await post(`/auth/landlord/verifyPhone`, {"token": token});
    } catch (e) {
        throw e;
    }
}

async function sendPhoneVerification() {
    try {
        return await post(`/auth/landlord/sendPhoneVerification`);
    } catch (e) {
        throw e;
    }
}

async function resendVerifyEmail(email) {
    try {
        return await post(`/auth/landlord/resendVerifyEmail`, {"email": email});
    } catch (e) {
        throw e;
    }
}

async function subscribeMailchimp() {
    try {
        return await post(`/landlord/subscribeMailchimp`);
    } catch (e) {
        throw e;
    }
}

async function unsubscribeMailchimp() {
    try {
        return await post(`/landlord/unsubscribeMailchimp`);
    } catch (e) {
        throw e;
    }
}

async function deleteAccount() {
    try {
        let logoutRes = await get(`/landlord/deleteLandlord`);
        if (logoutRes.redirect) {
            return window.location.href = logoutRes.redirect;
        }
        return logoutRes;
    } catch (e) {
        throw e;
    }
}


async function getUsers() {
    try {
        return await get(`/landlord/listUsers`);
    } catch (e) {
        throw e;
    }
}

async function addUser(user) {
    try {
        return await post(`/landlord/addUser`, user);
    } catch (e) {
        throw e;
    }
}

async function changeUser(permissions, landlord_user_id) {
    try {
        return await post(`/landlord/editUser`, {landlord_user_id, permissions});
    } catch (e) {
        throw e;
    }
}

async function deleteUser(userId) {
    try {
        return await post(`/landlord/deleteUser`, {landlord_user_id: userId});
    } catch (e) {
        throw e;
    }
}

async function validateLandlord(type, files) {
    try {
        return await post(`/landlord/uploadVerificationDocument/${type}`, files);
    } catch (e) {
        throw e;
    }
}

async function removeVerificationDocument(type) {
    try {
        return await get(`/landlord/removeVerificationDocument/${type}`);
    } catch (e) {
        throw e;
    }
}

async function getFMCTokens() {
    try {
        return await get(`/landlord/FMCTokens`);
    } catch (e) {
        throw e;
    }
}
