import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import './style.less';
import Buttons from '../../../components/Buttons';
import {serialize} from 'object-to-formdata';
import ChooseOnePhoto from '../../../components/ChooseOnePhoto';
import ChooseImages from '../../../components/ChooseImages';
import ProgressBarComponent from '../../../../../../../../../components/ProgressBarComponent';
import {updatePropertyActions} from "../../../../../../../../../redux/actions/updatePropertyActions";
import {DragDropContext} from 'react-beautiful-dnd';
import {move, movePhoto, reorder} from '../../../functions/beautifulDndFunctions';
import getPropertyAreasCharacteristics from '../../../functions/getPropertyAreasCharacteristics';
import getPropertyAreasToSend from '../../../functions/getPropertyAreasToSend';
import Loading from "../../../../../../../../../components/Loading";

const pageLabel = 'main_page.my_ads.create_ad.room_details.room_photos';

const getInitialRoomPhotos = (property, roomNames) => {
  let roomsPhotosFinal = {};

  // set the default photos
  roomNames.map((room, index) => {
    let _idx = 'room_' + (index + 1);
    roomsPhotosFinal[room] = property.rooms?.[_idx]?.photos ? Object.keys(property.rooms[_idx].photos).map((i) => {
      if (i !== 'featured') {
        return {
          id: property.rooms[_idx].photos[i].original,
          file: property.rooms[_idx].photos[i].original,
          src: property.rooms[_idx].photos[i].sm
        }
      }
    }).filter(item => item) : [];
  });

  return roomsPhotosFinal;
};

const getInitialFeaturedRoomPhotos = (property, roomNames) => {
  let roomsFeaturedPhotos = {};
  // set the default featured photos
  roomNames.map((room, index) => {
    let featuredPhotoFromServer = property.rooms?.['room_' + (index + 1)]?.photos?.featured;
    roomsFeaturedPhotos[room] = featuredPhotoFromServer ?
      ({id: featuredPhotoFromServer.original + '_featured', file: featuredPhotoFromServer.original, src: featuredPhotoFromServer.sm})
      : {};
  });
  return roomsFeaturedPhotos;
};

const RoomPhotosHeader = ({t}) => {

  return (
    <div className={'RoomPhotos__header'}>
      <h4 className={'title'} style={{width: '100%'}}>{t(pageLabel + '.title')}</h4>
      <div className={'textbox'}>
        <p>{t(pageLabel + '.text1')}</p>
      </div>

      <div className={'textbox'}>
        <p>{t(pageLabel + '.text2_1')}</p>
        <p>{t(pageLabel + '.text2_2')}</p>
      </div>

      <div className={'textbox'}>
        <p>{t(pageLabel + '.text3')}</p>
        <p>{t(pageLabel + '.text4')}</p>
      </div>
    </div>
  )
};

const RoomPhotos = ({
                      updateProperty,
                      t,
                      currentPageLevel,
                      property,
                      changePage,
                      defineUpdateFunc
                    }) => {
  const [submitted, setSubmitted] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  //getting the room names
  const roomNames = (() => {
    let roomNames = [];
    for (let i = 1; i <= property.renting; i++) {
      roomNames.push(`room_${i}`)
    }
    return roomNames;
  })();

  //the state that will store the photos
  const [roomsPhotos, setRoomsPhotos] = useState(getInitialRoomPhotos(property, roomNames));

  const [roomsFeaturedPhotos, setRoomsFeaturedPhotos] = useState(getInitialFeaturedRoomPhotos(property, roomNames));


  useEffect(() => {
    setRoomsPhotos(getInitialRoomPhotos(property, roomNames));
    setRoomsFeaturedPhotos(getInitialFeaturedRoomPhotos(property, roomNames))
    if(roomsFeaturedPhotos)
    Object.entries(roomsFeaturedPhotos).forEach(([key,value]) => {
      if(value && Object.values(value).length <= 0){
        setAsFeaturedPhoto({source: key, index: 0})
      }
    })
  }, [property.rooms]);


  const propertyAreasCharacteristics = useMemo(() =>{return getPropertyAreasCharacteristics(t)},[localStorage.getItem('i18nextLng')?.split('-')?.[0]]);

  //check if all the featured photos are uploaded
  const checkAllFeaturedPhotosUploaded = () => {
    let errors = false;
    Object.keys(roomsFeaturedPhotos).map(room => {
      if (Object.keys(roomsFeaturedPhotos[room]).length === 0) {
        errors = true;
      }
    });
    return !errors;
  };

  const deleteImages = (room, image) => {
    if(!image) {
      for(let i = roomsPhotos[room].length - 1; i >= 0; i--) {
        userActionsHandler({type: 'delete', field: `${room}[${i}]`});
        if((roomsPhotos[room][i].id + '_featured') === roomsFeaturedPhotos[room].id) {
          setRoomsFeaturedPhotos(prevState => ({...prevState, [room]: {}}));
        }
      }
      setRoomsPhotos(prevState => ({...prevState, [room]: []}));
    } else {
      const newFiles = [...roomsPhotos[room]];
      const img_idx = newFiles.indexOf(image);
      newFiles.splice(img_idx, 1);
      userActionsHandler({type: 'delete', field: `${room}[${img_idx}]`});
      setRoomsPhotos(prevState => ({...prevState, [room]: newFiles}));
      if((image.id + '_featured') === roomsFeaturedPhotos[room].id) {
        setRoomsFeaturedPhotos(prevState => ({...prevState, [room]: {}}));
      }
    }
  };

  //updating the photos when the user upload
  const updateRoomPhotos = (room, value) => {
    setRoomsPhotos(prevState => ({...prevState, [room]: [...prevState[room], value]}));
  };

  //updating the featured photos when the user upload
  const updateRoomFeaturedPhotos = (room, value) => {
    setRoomsFeaturedPhotos(prevState => ({...prevState, [room]: value}));
  };

  const [errorsReq, setErrorsReq] = useState(() => {
    let errors = {};
    roomNames.map(room => {if (!property.rooms?.[room]?.photos) errors[room] = true});
    return errors;
  });

  const setErrorArea = async (area, value) => {
    if (!value) {
      setErrorsReq(prevState => {
        delete prevState[area];
        return prevState;
      });
    } else setErrorsReq({...errorsReq, [area]: value});
  };

  //user actions history
  const [userActionsHistory, setUserActionHistory] = useState([]);

  const userActionsHandler = (value) => setUserActionHistory(prevState => [...prevState, value]);

  function onDragEnd(result) {
    const {source, destination} = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = source.droppableId?.split(' ')?.[0];
    const dInd = destination.droppableId?.split(' ')?.[0];
    let featuredSource = source.droppableId?.split(' ')?.[1] === 'featured';
    let featuredDestination = destination.droppableId?.split(' ')?.[1] === 'featured';

    //if the source is a featured container nothing will happen
    if (featuredSource) {
      return;
    } else if (featuredDestination) {
      if (sInd === dInd) {
        updateRoomFeaturedPhotos(destination.droppableId?.split(' ')?.[0], {
          ...roomsPhotos[sInd][source.index]
          , id: roomsPhotos[sInd][source.index].id + '_featured'
        });
      } else return;
    } else if (sInd === dInd) {
      if (source.index === destination.index) return;
      const items = reorder(roomsPhotos[sInd], source.index, destination.index);
      const newState = {...roomsPhotos};
      newState[sInd] = items;
      setRoomsPhotos(newState);
    } else {
      const result = move(roomsPhotos[sInd], roomsPhotos[dInd], source, destination);
      const newState = {...roomsPhotos};
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      if (newState[sInd].length < 11) {
        setRoomsPhotos(newState);
      } else {
        return;
      }
    }

    userActionsHandler({
      type: (sInd === dInd && !featuredDestination) ? 'swap' : 'move',
      field: [`${sInd}[${source.index}]`, `${dInd}[${featuredDestination ? 'featured' : destination.index}]`]
    })

  }

  //sent from toBeDefined to rooms
  const sendToOtherRoom = ({source, index,area, roomNumber}) => {
    if (roomNumber) {
      let destinationRoom = 'room_' + roomNumber;
      const photoMoved = movePhoto(roomsPhotos, setRoomsPhotos, {droppableId: source, index}, {
        droppableId: destinationRoom,
        index: roomsPhotos[destinationRoom].length
      });
      if (!photoMoved) return;
      userActionsHandler({
        type: 'move',
        field: [`${source}[${index}]`, `room_${roomNumber}[${roomsPhotos[roomNumber.length]}]`]
      })
    }else if(area){
      setRoomsPhotos(prevState => {
        const sourceClone = [...prevState[source]];
        sourceClone.splice(index, 1);
        return {...prevState, [source]: sourceClone}
      });
      userActionsHandler({
        type: 'move',
        field: [`${source}[${index}]`, `${area}[0]`]
      })
    }
  };


  const setAsFeaturedPhoto = ({source, index}) => {
    if(roomsPhotos?.[source]) {
      updateRoomFeaturedPhotos(source, {
        ...roomsPhotos[source][index]
        , id: roomsPhotos[source][index]?.id + '_featured'
      });
      userActionsHandler({
        type: 'move',
        field: [`${source}[${index}]`, `${source}[featured]`]
      })
    }
  };

  useEffect(() => defineUpdateFunc(async () => {
    if(userActionsHistory.length === 0) return;
    await onSubmit();
    if(Object.keys(errorsReq).length > 0) return false;
  }),[errorsReq, roomsPhotos, roomsFeaturedPhotos, userActionsHistory]);

  const onSubmit = async (values) => {
    setSubmitted(true);

    if (Object.keys(errorsReq).length === 0 && checkAllFeaturedPhotosUploaded()) {
      let sendingObject = {actions: {}};

      Object.keys(roomsPhotos).map(area => {
        let roomsPhotosObject = {};
        roomsPhotos[area].map((areaPhoto, index) => roomsPhotosObject[index] = areaPhoto.file);

        roomsPhotosObject.featured = roomsFeaturedPhotos[area].file;
        sendingObject[area] = roomsPhotosObject;
      });

      sendingObject.actions = JSON.stringify(userActionsHistory);

      let formData = serialize(sendingObject, {indices: true});

      if(values?.saveAndExit) formData.saveAndExit = true;

      await updateProperty(formData, currentPageLevel, 1, setLoadingPercentage);

      setUserActionHistory([]);
      setLoadingPercentage(0);
    } else {
      //Focus error
      let errors;
      if (!checkAllFeaturedPhotosUploaded()) {
        errors = {"featured_0": "Required"};
        let divToFocus = Object.keys(roomsFeaturedPhotos).find(room => (Object.keys(roomsFeaturedPhotos[room]).length === 3));
        const chooseImagesDivs = [...document.querySelectorAll('.ChooseOnePhoto')];
        //Find the div to focus
        chooseImagesDivs.find(div => div.id === `${divToFocus + '_featured'}`)?.focus();
      } else {
        errors = errorsReq;
        const errorsKeys = Object.keys(errorsReq);
        const chooseImagesDivs = [...document.querySelectorAll('.ChooseImages')];
        //Find the div to focus
        const divToFocus=chooseImagesDivs.find(div => errorsKeys.indexOf(div.id) > -1);
        chooseImagesDivs.find(div => errorsKeys.indexOf(div.id) > -1).focus();
      }
      return errors;
    }
  };

  return (roomNames.length > 0 && Object.keys(roomsPhotos).length > 0 ? <DragDropContext onDragEnd={onDragEnd}>
      <div className={'RoomPhotos section_content'}>
        <div className={'scrollable'}>
          <RoomPhotosHeader t={t}/>
          <div className={'RoomPhotos__content'}>
            {roomNames.map((room, index) => (
              <div className={'RoomPhotos__choose-photos'} key={room}>
                <ChooseImages key={room} images={roomsPhotos[room]}
                              uploadImages={(value) => updateRoomPhotos(room, value)}
                              deleteImages={(value) => deleteImages(room, value)}
                              setErrorArea={(value) => setErrorArea(room, value)}
                              fieldName={room}
                              areaProps={{
                                title: (property.accommodation === 'residence' && index >= property.bedroom_types) ? `${t('studio')} ${index - property.bedroom_types + 1}` : `${t('room')} ${index + 1}`,
                                minPhotos: 1,
                                roomsNumber: Object.keys(property.rooms).length,
                                sendToOtherArea: (value) => sendToOtherRoom({source: room, ...value}),
                                sendFeatured: (value) => setAsFeaturedPhoto({source: room, ...value}),
                                currentRoomIdx: index,
                                propertyAreasToSend: getPropertyAreasToSend(propertyAreasCharacteristics,property),
                              }}
                              displayError={submitted} userActionsHandler={userActionsHandler}/>
                <ChooseOnePhoto id={room + ' featured'}
                                key={room + ' featured'}
                                isRoomsSection={true}
                                image={roomsFeaturedPhotos[room]}
                                displayError={submitted && Object.keys(roomsFeaturedPhotos[room]).length === 0}
                                errorMessage={t('dropzone.featured_error')}
                                title={t(pageLabel + '.choose_featured_photo')}
                                userActionsHandler={userActionsHandler}
                />
              </div>
            ))}
          </div>


          {loadingPercentage > 1 && <ProgressBarComponent percentage={loadingPercentage}/>}
        </div>
        <Buttons disableUpdate={false} updateProperty={onSubmit} saveAndExit={v=>onSubmit({...v, saveAndExit: true})} changePage={changePage}/>
      </div>
    </DragDropContext>  : <Loading/>
  );
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(RoomPhotos);

