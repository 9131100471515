import React from "react";

const ExpandLess = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Icon" clipPath="url(#clip0_1_6670)">
            <path
                id="Vector"
                d="M12 8L6 14L7.41 15.41L12 10.83L16.59 15.41L18 14L12 8Z"
                fill="#284F83"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_6670">
                <rect width={24} height={24} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default ExpandLess;
