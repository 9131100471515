import React from "react";

const Timer = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="timer" clipPath="url(#clip0_2405_3431)">
            <path
                id="Vector"
                d="M10.0469 0.672852H6.04688V2.00618H10.0469V0.672852ZM7.38021 9.33952H8.71354V5.33952H7.38021V9.33952ZM12.7335 4.92618L13.6802 3.97952C13.3935 3.63952 13.0802 3.31952 12.7402 3.03952L11.7935 3.98618C10.7602 3.15952 9.46021 2.66618 8.04688 2.66618C4.73354 2.66618 2.04688 5.35285 2.04688 8.66618C2.04688 11.9795 4.72688 14.6662 8.04688 14.6662C11.3669 14.6662 14.0469 11.9795 14.0469 8.66618C14.0469 7.25952 13.5535 5.95952 12.7335 4.92618ZM8.04688 13.3395C5.46688 13.3395 3.38021 11.2529 3.38021 8.67285C3.38021 6.09285 5.46688 4.00618 8.04688 4.00618C10.6269 4.00618 12.7135 6.09285 12.7135 8.67285C12.7135 11.2529 10.6269 13.3395 8.04688 13.3395Z"
                fill="#434A5F"
            />
        </g>
        <defs>
            <clipPath id="clip0_2405_3431">
                <rect width={16} height={16} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Timer;
