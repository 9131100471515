import {propertyConstants} from '../constants';

const stateInital = {};

export function updatePropertyFunction(state = stateInital, action) {
  switch (action.type) {
    case propertyConstants.UPDATE_FUNCTION:
      return {
        updateProperty: action.payload.updateProperty,
      };
    default:
      return state;
  }
}
