import React, {useMemo} from 'react';
import "./style.less";
import {clampText} from "../../utils/stringFunctions";
import moment from "moment";
import avatarFemale from "../VisitCard/images/avatar_female.png";
import avatarMale from "../VisitCard/images/avatar_male.png";
import {useTranslation} from "react-i18next";
import lightBulb from "../../images/lightbulb.svg";
import {useHistory} from "react-router-dom";
import {reservationServices} from "../../services/reservationServices";
import {useAuth} from "../../services/authServices";
import {getExpirationDate} from "../../utils/dateFunctions";
import ControlPointDuplicateIcon from "../svg/ControlPointDuplicate";
import ExpirationCountdown from '../../pages/MainPage/containers/Reservations/components/ExpirationCountdown';
import StopwatchIcon from "../svg/Stopwatch";
import CompareArrowsIcon from "../svg/CompareArrows";
import PlaceIcon from "../svg/Place";
import MeetingRoomIcon from "../svg/MeetingRoom";
import EuroIcon from "../svg/Euro";
import InfoIcon from "../svg/InfoCircle";
import Tooltip from "../Tooltip";
import { isMobile } from '../../utils/responsive';

const ReservationCard = ({reservation, filter, updateReservations}) => {

    const {t} = useTranslation();
    const history = useHistory();
    const auth = useAuth();

    let property = reservation.property;

    let property_image = property.accommodation !== "apartment" ? property.room?.photos?.["featured"]?.md : property.photos?.["featured_0"][0].md;
    //let property_image = reservation.photo;

    let roomNumber = reservation.room_id && property.rooms ? Object.keys(property.rooms)
        .find(rn => property.rooms[rn].id === reservation.room_id)
        .split("_")[1] : 1;

    let roomName = reservation.property.room.internalName;


    let monthlyRent = useMemo(() => {
        if (reservation) return reservationServices.getMonthlyRent(reservation);
    }, [reservation]);

    let tenantImage = reservation.user.photo || (reservation.user.gender === "female" ? avatarFemale : avatarMale);
    let tenantAge = (new Date().getFullYear() - ((reservation.user.personalDetails?.birthdate||reservation.user.birthdate)?.split("-")[1] || 2004));
    let tenant = {
        name: reservation.user.personalDetails?.name || reservation.user.name,
        photo: reservation.user.photo,
        nationality: reservation.user.personalDetails?.nationality || reservation.user.nationality,
        gender: reservation.user.personalDetails?.gender || reservation.user.gender,
        university: reservation.user.personalDetails?.university || reservation.user.university
    }

    let expires = (reservation.currentStatus === "request" || reservation.currentStatus === "payment" || (reservation.needsStudentAcceptance && reservation.currentStatus !== "expired" && reservation.currentStatus !== "rejected")) ?
        (reservation.currentStatus === "request" ? getExpirationDate(reservation.requestDate) : moment(reservation.acceptedDate).add(1, 'd')) : null;

    let room = property && property.rooms ? (reservation.room_id ? Object.values(property.rooms).find(r => r.id === reservation.room_id) : property.rooms["room_1"]) : null;

    const openReservationDetails = (e) => {
        e.preventDefault();
        history.push("/reservations/" + reservation.id + "/details/" + (filter ? filter : reservation.currentStatus));
    }

    return (
        <div className={"Card"}>
            <div className={"Card__image"} style={{backgroundImage: `url('${property_image}')`}}>
                {reservation.landlord.id !== auth.user?.id && <div className={"Card__image__overlay"} style={{backgroundImage: `url(${reservation.landlord.photo || avatarMale})`}}/>}
                {/* <div className="card__overlay">

                    {expires && <div className="expiration">
                        {t("main_page.reservations.reservation_details.expires_in")}
                        <ExpirationCountdown targetTimestamp={new Date(getExpirationDate(reservation.requestDate))} />
                    </div>}
                </div> */}
                {expires && <div className="card__overlay">
                    <div className="expiration-timestamp">
                        <StopwatchIcon />
                        <ExpirationCountdown targetTimestamp={new Date(getExpirationDate(reservation.requestDate))} />
                    </div>
                </div>}
            </div>
            <div className={"Card__body"}>
                <div className={"Card__property-info"}>
                    <h5>{clampText((property.internal_name ? property.internal_name : property.title?.[localStorage.getItem('i18nextLng').split('-')[0]]), 85)}</h5>
                    <div className={"property-details"}>
                        <span>
                            <PlaceIcon />
                            {property.address}
                        </span>
                        <span>
                            <MeetingRoomIcon width={17} />
                            ID {property.id?.split("-")[0]} {reservation.room_id && ('| ' + (roomName ? roomName : t('main_page.my_visits.visit_card.room_n') + roomNumber))} {room && '| ' + (room.numPeople ? (t('up_to').toLowerCase() + " " + room.numPeople + " " + t(`person${room.numPeople > 1 ? "_plural" : ""}`)) : "")}
                        </span>
                        <span>
                            <EuroIcon />
                            {t('main_page.my_visits.visit_card.monthly_rent')}
                            <strong>{monthlyRent}</strong>
                        </span>
                        {reservation.suggestionType && <span><img style={{width: "10px"}} alt={"icon"}
                            src={lightBulb}/>{t('main_page.my_visits.visit_card.suggested_on')}: {moment(reservation.acceptedDate).format("DD/MM/YYYY")}</span>}
                    </div>
                </div>
                <div className={"compareArrowsContainer"}>
                    <CompareArrowsIcon />
                </div>
                <div className={"Card__tenant-info"}>
                    <div>
                        <div className={'avatar-container'} style={{backgroundImage: `url(${tenantImage})`}}>
                            <div className={'RoundedCircleFlag'}>
                                <div className={'CountryFlagCircle'}
                                     style={{backgroundImage: `url(https://github.com/madebybowtie/FlagKit/raw/master/Assets/PNG/${tenant.nationality?.toUpperCase()}@2x.png?raw=true)`}}/>
                            </div>
                        </div>
                    </div>
                    <div className={"tenant-details"}>
                        <div>
                            <h5>{filter === "confirmed" ? (reservation.user_info?.name || reservation.user?.name) : (reservation.user_info?.name || reservation.user?.name)?.split(" ")[0]}</h5>
                            <span>{t('country.' + (reservation.user_info?.nationality || reservation.user?.personalDetails?.nationality || reservation.user.nationality))} - {t(tenant.gender)}, {tenantAge} {t('years')}</span>
                            <span>{t('main_page.my_visits.visit_card.occupation.' + (reservation.user?.personalDetails?.occupation || reservation.user.occupation))} {t('at')} {(reservation.user?.personalDetails?.occupation || reservation.user.occupation) === "student" ? (tenant.university?.split(" - ")[0] || "N/A") : (reservation.user?.personalDetails?.occupation_detail || reservation.user.occupation_detail)}</span>
                        </div>
                        <div className={"tenant-dates-info"}>
                            <div className={"dates-info"}>
                                <p>
                                    <strong>{t('main_page.my_visits.visit_card.from')}:&nbsp;</strong>
                                    {moment(reservation.moveIn).format("DD/MM/YYYY")}
                                    <strong>&nbsp;|&nbsp;</strong>
                                </p>
                               <p>
                                   <strong>{t('main_page.my_visits.visit_card.to')}:&nbsp;</strong>
                                   {moment(reservation.moveOut).format("DD/MM/YYYY")}
                               </p>


                            </div>
                            <span><strong>{t('main_page.my_visits.visit_card.totalStay')}:&nbsp;</strong>
                                {t('main_page.my_visits.visit_card.month', { count: Math.round(moment(reservation.moveOut).diff(moment(reservation.moveIn), "month", true)) })}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"tenant-dates-info-mobile"}>
                    <span className={"dates-info"}>{t('main_page.my_visits.visit_card.from')}: <strong>{moment(reservation.moveIn).format("DD/MM/YYYY")}</strong></span>
                    <span>{t('main_page.my_visits.visit_card.to')}: <strong>{moment(reservation.moveOut).format("DD/MM/YYYY")}</strong></span>
                </div>
                <div className={"Card__actions Actions"}>
                    {/* {expires && <div className="expiration">
                        {t("main_page.reservations.reservation_details.expires_in")}
                        <ExpirationCountdown targetTimestamp={new Date(getExpirationDate(reservation.requestDate))} />
                    </div>} */}
                    {reservation.currentStatus === "request_cancellation" && <div className={"Card__actions-cancellation-request"}>
                        <Tooltip position={isMobile ? "top" : "left"}>
                            <Tooltip.Icon><InfoIcon height={16} width={16}/></Tooltip.Icon>
                            <Tooltip.Content>{t("main_page.reservations.reservation_card.cancellation_requested_message")}</Tooltip.Content>
                        </Tooltip>
                        {t("main_page.reservations.reservation_card.cancellation_requested")}
                    </div>}
                    <div className={'Actions__buttons'}>
                        <a href={"/reservations/" + reservation.id + "/details"} className={`btn ${(["expired", "rejected", "paid"].includes(reservation.currentStatus) || reservation.cancellation) ? "btn-secondary" : "btn-primary"}`} onClick={openReservationDetails}>
                            <ControlPointDuplicateIcon />
                            {t(`dashboard_list_item.${["expired", "rejected", "cancelled", "paid", "request_cancellation", "payment"].includes(reservation.currentStatus) ? "see_details": "seeAndReply"}`)}</a>
                    </div>
                    {/* <p className={"Card__actions-status"}>
                        {t('main_page.reservations.status.' + (reservation.needsStudentAcceptance && !reservation.rejectedDetails ? "suggestion_answer" : reservation.currentStatus))}
                    </p> */}
                </div>
            </div>
        </div>
    )
}


export default ReservationCard;
