import * as React from "react";

function SvgHk(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#FF4B55"
        />
        <g fill="#F5F5F5">
          <path d="M224.477 180.922c-11.128.115-23.624-3.449-34.573-15.518-.755-.832-.649-2.143.209-2.87l.003-.003c.832-.705 2.062-.596 2.797.211 16.69 18.327 37.376 15.118 49.735 10.69-4.566.203-10.303-.14-13.993-2.565-15.415-10.133-8.433-20.913-18.671-33.944-10.173-12.948-19.327-12.631-33.741-10.446-14.27 2.162-18.816-8.305-20.231-14.114-.253-1.041-1.705-1.106-2.077-.103-17.786 48.054 35.694 107.514 90.601 64.761-5.418 1.995-12.396 3.823-20.059 3.901zm-40.067-16.307c-.312.858-1.537.824-1.802-.05l-2.048-6.749-7.049-.247c-.913-.032-1.259-1.207-.51-1.729l5.786-4.033-1.943-6.781c-.252-.877.759-1.57 1.487-1.02l5.624 4.256 5.848-3.944c.757-.511 1.729.236 1.429 1.099l-2.31 6.664 5.558 4.343c.719.563.309 1.717-.604 1.699l-7.052-.138-2.414 6.63zm79.459-15.62c8.836-6.765 20.972-11.407 36.972-8.317 1.103.213 1.805 1.325 1.555 2.421l-.001.004c-.243 1.064-1.293 1.714-2.365 1.508-24.346-4.657-38.976 10.317-46.209 21.272 3.532-2.901 8.329-6.067 12.736-6.338 18.412-1.137 19.289 11.678 35.298 15.965 15.905 4.259 23.04-1.483 33.266-11.876 10.122-10.289 20.038-4.639 24.653-.839.826.681 2.027-.137 1.723-1.163-14.586-49.12-93.042-64.627-111.339 2.513 3.137-4.843 7.626-10.491 13.711-15.15zm41.842-10.979c-.265-.874.736-1.582 1.472-1.042l5.686 4.172 5.789-4.03c.749-.522 1.731.211 1.446 1.078l-2.211 6.698 5.621 4.26c.728.552.334 1.713-.578 1.708l-7.053-.033-2.314 6.663c-.3.863-1.525.847-1.803-.022l-2.148-6.718-7.052-.142c-.913-.019-1.277-1.188-.535-1.721l5.726-4.119-2.046-6.752z" />
          <path d="M234.399 153.626c-4.34-10.248-5.8-23.159 1.195-37.877.482-1.015 1.735-1.416 2.733-.899l.004.002c.969.501 1.335 1.681.87 2.667-10.599 22.408.241 40.318 9.038 50.061-1.926-4.146-3.79-9.581-2.952-13.917 3.505-18.111 16.131-15.756 24.286-30.184 8.103-14.335 4.327-22.679-3.178-35.178-7.43-12.374.521-20.561 5.355-24.079.865-.63.374-1.997-.695-1.959-51.207 1.837-85.845 73.92-25.416 108.428-3.905-4.25-8.251-10.008-11.24-17.065zm-.165-43.258c-.913.038-1.347-1.108-.64-1.685l5.462-4.462-2.454-6.613c-.318-.856.638-1.624 1.405-1.13l5.932 3.816 5.53-4.377c.716-.567 1.741.104 1.508.988l-1.796 6.82 5.871 3.907c.76.506.439 1.688-.472 1.74l-7.042.399-1.901 6.792c-.246.879-1.47.939-1.801.088l-2.556-6.573-7.046.29zm40.063 66.511c9.198 6.264 17.418 16.328 19.508 32.489.145 1.115-.692 2.13-1.811 2.237h-.006c-1.086.104-2.033-.69-2.175-1.771-3.225-24.577-22.037-33.763-34.71-37.19 3.863 2.442 8.375 6.002 10.018 10.101 6.862 17.123-5.028 21.982-4.071 38.527.951 16.438 8.646 21.408 21.724 27.852 12.947 6.379 10.698 17.566 8.538 23.143-.386.999.767 1.881 1.645 1.27 42.052-29.276 32.134-108.631-37.355-104.915 5.591 1.457 12.362 3.944 18.695 8.257zm23.567 36.276c.747-.526 1.732.202 1.451 1.07l-2.175 6.709 5.644 4.231c.73.547.344 1.71-.569 1.711l-7.053.004-2.279 6.675c-.295.864-1.521.855-1.803-.013l-2.184-6.707-7.052-.105c-.914-.013-1.283-1.182-.545-1.719l5.704-4.15-2.08-6.74c-.269-.873.727-1.586 1.466-1.049l5.709 4.142 5.766-4.059z" />
          <path d="M251.292 196.822c-3.168 10.668-10.254 21.56-25.013 28.469-1.018.477-2.239-.011-2.681-1.044l-.002-.004c-.429-1.003.039-2.146 1.025-2.611 22.43-10.551 25.457-31.265 24.865-44.38-1.151 4.424-3.169 9.804-6.573 12.617-14.223 11.747-22.47 1.899-37.939 7.846-15.37 5.908-17.763 14.75-19.922 29.169-2.136 14.274-13.479 15.535-19.447 15.176-1.068-.064-1.559 1.303-.71 1.954 40.684 31.15 113.258-2.444 88.574-67.507.314 5.762.005 12.968-2.177 20.315zm-27.386 33.486c.728.552.335 1.711-.578 1.708l-7.052-.031-2.312 6.664c-.299.863-1.525.847-1.803-.022l-2.151-6.718-7.052-.14c-.913-.018-1.278-1.188-.536-1.721l5.724-4.121-2.046-6.75c-.265-.874.735-1.582 1.471-1.042l5.688 4.17 5.788-4.032c.749-.522 1.731.211 1.446 1.078l-2.208 6.698 5.621 4.259z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgHk;
