import * as React from "react";

function SvgTr(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#FF4B55"
        />
        <path
          d="M253.474 137.752l13.837 18.101 21.606-7.232c1.208-.404 2.236.962 1.512 2.01l-12.939 18.753 13.555 18.314c.758 1.024-.224 2.423-1.444 2.059l-21.834-6.511-13.228 18.55c-.739 1.037-2.375.536-2.406-.737l-.555-22.777-21.73-6.849c-1.215-.383-1.244-2.092-.042-2.515l21.491-7.566-.202-22.783c-.012-1.274 1.606-1.829 2.379-.817zm-76.518 100.909c-38.995 0-70.627-31.633-70.627-70.663 0-38.958 31.633-70.662 70.627-70.662 14.508 0 27.887 4.462 39.037 12.014 1.707 1.156 3.656-1.087 2.227-2.573-16.664-17.325-40.248-27.894-66.398-27.001-44.926 1.533-82.118 37.553-84.989 82.413-3.287 51.383 37.399 94.086 88.055 94.086 24.953 0 47.379-10.432 63.393-27.112 1.415-1.473-.538-3.683-2.229-2.537-11.162 7.569-24.563 12.035-39.096 12.035z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgTr;
