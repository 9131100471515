import React from "react";

const EmptyCalendar = (props) => (
    <svg
        width={26}
        height={28}
        viewBox="0 0 26 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M23 2.75H21.75V0.25H19.25V2.75H6.75V0.25H4.25V2.75H3C1.625 2.75 0.5 3.875 0.5 5.25V25.25C0.5 26.625 1.625 27.75 3 27.75H23C24.375 27.75 25.5 26.625 25.5 25.25V5.25C25.5 3.875 24.375 2.75 23 2.75ZM23 25.25H3V11.5H23V25.25ZM23 9H3V5.25H23V9Z"
            fill="#1D9AFA"
        />
    </svg>
);

export default EmptyCalendar;
