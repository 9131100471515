import * as React from "react";

function SvgSi(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M512.001 112.092H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v102.989z"
          fill="#F5F5F5"
        />
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V223.908h512v102.988a8.828 8.828 0 01-8.828 8.828z"
          fill="#FF4B55"
        />
        <g fill="#41479B">
          <path d="M0 112.088h512V223.9H0z" />
          <path d="M172.014 69.37s-1.778 56.628-9.724 72.53c-9.836 19.545-19.725 25.247-34.438 30.515-14.711-5.267-24.6-10.965-34.438-30.515-7.945-15.903-9.725-72.524-9.724-72.53a107.317 107.317 0 0188.324 0z" />
        </g>
        <path
          d="M140.64 147.897c-1.076-.69-1.852-1.184-3.618-1.184-1.761 0-2.537.493-3.608 1.184-1.227.781-2.753 1.759-5.59 1.759-2.839 0-4.365-.978-5.592-1.763-1.071-.685-1.845-1.178-3.606-1.178s-2.535.493-3.606 1.178c-1.159.743-2.624 1.628-5.171 1.727 5.362 6.538 10.983 9.64 17.998 12.333 7.012-2.694 12.632-5.793 17.994-12.331-2.565-.093-4.038-.984-5.201-1.725zm-34.808-10.46c1.071.685 1.843 1.178 3.598 1.178 1.761 0 2.535-.493 3.606-1.178 1.227-.786 2.753-1.763 5.59-1.763 2.837 0 4.363.978 5.59 1.759 1.071.69 1.847 1.184 3.608 1.184 1.761 0 2.535-.493 3.606-1.178 1.227-.786 2.753-1.763 5.592-1.763 2.841 0 4.37.978 5.597 1.759 1.076.69 1.849 1.184 3.616 1.184 1.767 0 2.542-.493 3.618-1.184 1.025-.653 2.312-1.409 4.35-1.651a41.937 41.937 0 001.536-4.9l-13.17-21.95-4.907 9.814-9.814-19.627-9.814 19.627-4.907-9.814-13.162 21.937a42.32 42.32 0 001.534 4.915c2.03.239 3.311.995 4.333 1.651zm45.999 3.1c-1.227.781-2.755 1.759-5.597 1.759-2.839 0-4.367-.978-5.594-1.759-1.076-.69-1.852-1.184-3.618-1.184-1.761 0-2.537.493-3.608 1.184-1.227.781-2.753 1.759-5.59 1.759-2.839 0-4.365-.978-5.592-1.763-1.071-.685-1.845-1.178-3.606-1.178s-2.535.493-3.606 1.178c-1.227.786-2.753 1.763-5.59 1.763-2.835 0-4.359-.978-5.582-1.763l-.017-.011a71.188 71.188 0 002.881 4.803c.716.393 1.465.651 2.718.651 1.761 0 2.535-.493 3.606-1.178 1.227-.786 2.753-1.763 5.59-1.763 2.837 0 4.363.978 5.59 1.759 1.071.69 1.847 1.184 3.608 1.184 1.761 0 2.535-.493 3.606-1.178 1.227-.786 2.753-1.763 5.592-1.763 2.841 0 4.37.978 5.597 1.759 1.076.69 1.849 1.184 3.616 1.184 1.272 0 2.027-.262 2.754-.663a71.067 71.067 0 002.879-4.799c-.013.005-.024.012-.037.019z"
          fill="#F5F5F5"
        />
        <path
          d="M128.046 82.222l.861 2.814 2.867-.661c.194-.045.33.19.194.335l-2.006 2.153 2.006 2.153c.136.146.001.381-.194.335l-2.867-.661-.861 2.814c-.058.191-.329.191-.387 0l-.861-2.814-2.867.661c-.194.045-.33-.19-.194-.335l2.006-2.153-2.006-2.153c-.136-.146-.001-.381.194-.335l2.867.661.861-2.814c.059-.191.329-.191.387 0zm-14.107-13.52l.861 2.814 2.867-.661c.194-.045.33.19.194.335l-2.006 2.153 2.006 2.153c.136.146.001.381-.194.335l-2.867-.661-.861 2.814c-.058.191-.329.191-.387 0l-.861-2.814-2.867.661c-.194.045-.33-.19-.194-.335l2.006-2.153-2.006-2.153c-.136-.146-.001-.381.194-.335l2.867.661.861-2.814c.058-.191.328-.191.387 0zm28.316 0l.861 2.814 2.867-.661c.194-.045.33.19.194.335l-2.006 2.153 2.006 2.153c.136.146.001.381-.194.335l-2.867-.661-.861 2.814c-.058.191-.329.191-.387 0l-.861-2.814-2.867.661c-.194.045-.33-.19-.194-.335l2.006-2.153-2.006-2.153c-.136-.146-.001-.381.194-.335l2.867.661.861-2.814c.059-.191.329-.191.387 0z"
          fill="#FFE15A"
        />
        <path
          d="M166.934 67.238c.03 1.565.055 3.144.055 4.849-.708 17.704-3.342 56.258-9.082 67.87-8.463 16.996-16.277 22.614-30.055 27.791-13.778-5.176-21.591-10.793-30.048-27.778-5.761-11.656-8.414-50.866-9.091-67.882 0-1.707.028-3.268.052-4.846a107.812 107.812 0 00-5.058 2.123c.118 3.438.974 25.992 3.545 45.823 1.45 11.071 3.446 21.274 6.163 26.713 9.837 19.55 19.726 25.248 34.438 30.515 14.713-5.269 24.601-10.969 34.439-30.515 2.718-5.439 4.713-15.642 6.164-26.713 2.57-19.831 3.425-42.385 3.544-45.823a105.632 105.632 0 00-5.066-2.127z"
          fill="#FF1722"
        />
      </g>
    </svg>
  );
}

export default SvgSi;
