import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './style.less';
import RichTextEditor from "../../../../../../../../../../../components/RichTextEditor";
import {useForm} from "react-hook-form";
import Buttons from "../../../../../components/Buttons";
import {propertyServices} from "../../../../../../../../../../../services"
import moment from "moment";
import {useAuth} from "../../../../../../../../../../../services/authServices";
import {updatePropertyActions} from "../../../../../../../../../../../redux/actions/updatePropertyActions";
import {connect} from "react-redux";
import {withReact} from "slate-react";
import {createEditor} from "slate";

const pageLabel = 'main_page.my_ads.create_ad.house_details.more_info';

const PropertyDetails5 = ({
                              updateProperty,
                              currentLevel,
                              property,
                              changePage,
                              defineUpdateFunc,
                              currentRoomNumber
                          }) => {

    const {t} = useTranslation();
    const [value, setValue] = useState();
    const {user} = useAuth();
    const [editor] = useState(() => withReact(createEditor()));
    const language = user?.language;
    const editorRef = useRef(null);

    const moreInfo = currentRoomNumber ? property.rooms["room_" + currentRoomNumber]?.moreInfo : property?.moreInfo;

    const validationDate = moreInfo?.validation?.validationDate;

    const originalValue = ((!moreInfo?.original || moment(validationDate).isSameOrAfter(moment(moreInfo?.original?.modificationDate))) && moreInfo?.validation?.[language]) || moreInfo?.original;

    const paragraph = t("main_page.my_ads.create_ad.house_details.more_info.paragraph");

    const defaultValue = [{
        type: 'paragraph', children: [{text: paragraph}],
    },];

    const usedValue = useMemo(() => originalValue && originalValue.length > 0 ? [...originalValue] : [...defaultValue], []);

    const handleOnChange = (value) => {
        setValue(value);
    }

    const onSubmit = async (values) => {
        if (editorRef.current) {
            editorRef.current.blur(); // Trigger blur event on the editor
        }
        if (values.moreInfo && JSON.stringify(values.moreInfo) !== JSON.stringify(defaultValue) && JSON.stringify(values.moreInfo) !== JSON.stringify(originalValue)) {
            if (currentRoomNumber) {
                await propertyServices.updateRoomDetails(values, property.id, "room_" + currentRoomNumber);
            } else {
                await updateProperty(values, currentLevel);
            }
        }
    }

    const {handleSubmit, control, register} = useForm({shouldFocusError: true});


    useEffect(() => {
        defineUpdateFunc(async () => new Promise(async (resolve) => {
            setTimeout(async () => {
                await handleSubmit(async () => {
                    await onSubmit({moreInfo: editor.children});
                    resolve()
                }, resolve)()
            }, 0);
        }))
    }, []);

    return (<div className={"PropertyDetails5"}>
        <h6>{t(pageLabel + (".title"))}</h6>
        {validationDate &&
            <p className={"warning-text"}>{t(pageLabel + ".warning", {"validationDate": moment(validationDate).format("DD/MM/YYYY hh:mm")})}</p>}
        <form className={'PropertyDetails5'}>
            <RichTextEditor ref={editorRef} handleOnChange={handleOnChange} control={control} register={register}
                            defaultValue={usedValue} editor={editor}/>
            <Buttons changePage={changePage}/>
        </form>
    </div>);
};

const actionCreator = {
    defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(PropertyDetails5);
