import * as React from "react";

function SvgEt(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M512.001 112.092H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v102.989z"
          fill="#73AF00"
        />
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V223.908h512v102.988a8.828 8.828 0 01-8.828 8.828z"
          fill="#FF4B55"
        />
        <path fill="#FFE15A" d="M0 112.088h512V223.9H0z" />
        <path
          d="M361.945 168.098c0 58.426-47.435 105.945-105.945 105.945-58.51 0-105.945-47.519-105.945-105.945 0-58.594 47.435-106.029 105.945-106.029 58.51 0 105.945 47.435 105.945 106.029z"
          fill="#4173CD"
        />
        <g fill="#F2DD30">
          <path d="M175.42 141.463h61.556l13.929-42.867 3.699 11.498-20.228 62.316-6.026-4.396 6.301-19.362h-49.506l-9.725-7.189zm30.756 94.785l19.025-58.51-36.462-26.551h12.026l52.995 38.473-6.024 4.396-16.509-12.007-15.325 47.181-9.726 7.018zm99.646 0L256 200.061l-36.442 26.464 3.721-11.329 52.952-38.558 2.262 7.102-16.468 11.923 40.057 29.172 3.74 11.413zm30.756-94.785l-49.781 36.274 13.931 42.869-9.724-7.104-20.209-62.316h7.399l6.298 19.448 40.079-29.172h12.006v.001h.001zM256 82.953l19.025 58.51h45.088l-9.745 7.189h-65.487l2.305-7.189h20.418l-15.325-47.096L256 82.953z" />
          <path d="M257.775 252.822h-3.53V206.91h3.53v45.912zm78.276-56.991l-43.651-14.12 1.1-3.297 43.672 14.12-1.121 3.297zm-160.123 0l-1.1-3.297 43.692-14.12 1.08 3.297-43.672 14.12zm104.361-58.173l-2.877-2.028 26.995-37.12 2.812 2.03-26.93 37.118zm-48.513 0l-27.038-37.118 2.855-2.03 26.973 37.12-2.79 2.028z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgEt;
