import {useTranslation} from "react-i18next";
import React, { useEffect, useMemo, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {reservationServices} from "../../../../../../../../services/reservationServices";
import {propertyServices, visitServices} from "../../../../../../../../services";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../../../../../../components/MUIDialogComponents";
import Loading from "../../../../../../../../components/Loading";
import {DialogContent} from "@material-ui/core";
import moment from "moment";
import {clampText, grind} from "../../../../../../../../utils/stringFunctions";
import {OutdatedAdInputs} from "../../index";
import Select from "react-select";
import DatePicker from "react-datepicker";
import calendarIcon from "../../../../../../../../components/CustomDatePicker/images/calendarIcon.svg";
import editPencil from "../../../../../../../../components/ResumeItem/images/pencilIconGrey.svg";
import {BiInfoCircle} from "react-icons/bi";
import SearchFilter from "../../../../../../../../components/SearchFilter";
import closeGrey from "../../../../../../../MainPage/containers/MakeOffers/components/PotentialTenantsList/components/OfferPopOver/images/closeGrey.svg";
import {useAuth} from "../../../../../../../../services/authServices.js";
import PaginationLegacy from "../../../../../../../../components/PaginationLegacy/index.js";

const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

const SuggestPropertyModal = ({open, setOpen, room, room_id, request, currentRent, type, updateVisits}) => {

    const {t} = useTranslation()
    const history = useHistory();

    const {user} = useAuth();

    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false)

    const dialogRef = useRef();

    const [motive, setMotive] = useState(null);
    const [newRents, setNewRents] = useState(false);
    const [newPeriodMin, setNewPeriodMin] = useState(false);
    const [newAvailableFrom, setNewAvailableFrom] = useState(false);
    const [unavailable, setUnavailable] = useState(false);
    const [availableFrom, setAvailableFrom] = useState(null);
    const [newDate, setNewDate] = useState(null);
    const _defaultMonths = months.reduce((acc, m) => ({...acc, [m]: room.rent || 0}), {});
    const [rentMonths, setRentMonths] = useState(room.rentMonths && Object.values(room.rentMonths).length === 12 ? room.rentMonths : _defaultMonths);
    const [rent, setRent] = useState(null);
    const [periodMin, setPeriodMin] = useState(null);
    const [fixedRent, setFixedRent] = useState(room.fixedRent)
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [properties, setProperties] = useState(null);
    const [integrations, setIntegrations] = useState(null);
    const [totalListings, setTotalListings] = useState(null);
    const [page, setPage] = useState(0);

    const [searchKey, setSearchKey] = useState("");
    const [editingRent, setEditingRent] = useState("");
    const [editAvailFrom, setEditAvailFrom] = useState("");
    const [changedRents, setChangedRents] = useState();
    const [error, setError] = useState(false);

    const rentInput = useRef();

    const translationDirc = "main_page.reservations.reject_reservation."
    const translPath = "main_page.reservations.reservation_details."

    const resetFields = () => {
        setMotive(null);
        setNewRents(false)
        setNewPeriodMin(false)
        setRentMonths(room.rentMonths && Object.values(room.rentMonths).length === 12 ? room.rentMonths : _defaultMonths);
        setNewAvailableFrom(false);
        setUnavailable(false);
        setFixedRent(room.fixedRent);
        setRent(null);
        setCurrentPage(1);
        setSelectedProperty(null);
    }

    const handleClose = () => {
        if(loading) return;
        setOpen(false);
        resetFields()
    }
    let motiveTranslationDirect = translationDirc + "motive.";
    const motives = [
        {value: 'occupied_dates', label: t(motiveTranslationDirect + 'occupied_dates')},
        {value: 'outdated_ad', label: t(motiveTranslationDirect + 'outdated_ad')},
        {value: 'new_moveIn', label: t(motiveTranslationDirect + 'new_moveIn')},
        {value: 'new_moveOut', label: t(motiveTranslationDirect + 'new_moveOut')}
    ];

    const handleGoBack = () => {
        if (currentPage === 1) handleClose();
        else {
            if(currentPage === 2) setSelectedProperty(null)
            if(currentPage === 3) setSelectedRoom(null)
            setCurrentPage((prevState => --prevState))
        }
    }

    const handleAdvance = () => {
        setCurrentPage((prevState => {
            if(prevState === 1 && user.integration_landlord) return 3;
            return ++prevState;
        }))
        dialogRef.current.scrollTo(0,0);
    }

    const handleSubmit = async () => {
        setLoading(true);
        let fields = {
            motive: motive.value
        }
        if(selectedProperty) {
            fields["property_id"] = selectedProperty.id;
        }
        fields[type + "_id"] = request.id;
        switch (motive.value) {
            case "occupied_dates":
                newAvailableFrom ? (fields.availableFrom = moment(availableFrom).toISOString()) :
                    fields.unavailable= true
                break;
            case "outdated_ad":
                if(newPeriodMin) fields.periodStayMin = periodMin.toString();
                if(newRents) fixedRent ? fields.rent = rent.toString() :  fields.rentMonths = rentMonths;
                if(fixedRent !== room.fixedRent) fields.fixedRent = fixedRent;
                break;
            case "new_moveIn":
                fields.newDate = moment(newDate).toISOString()
                break;
            case "new_moveOut":
                fields.newDate = moment(newDate).toISOString()
                break;
            default:
                break;
        }
        if(user.integration_landlord) {
            fields["integration_id"] = selectedIntegration.id + "_" + selectedIntegration.room_id;
        } else {
            fields["room_id"] = selectedProperty.accommodation === "apartment" ? selectedProperty.rooms["room_1"].id : selectedRoom.id;
        }
        let newRequest;
        try {
            if (type === "reservation") {
                newRequest = await reservationServices.suggestNewProperty(fields);
                return history.push("/reservations/" + newRequest.id + "/details");

            } else {
                await visitServices.suggestNewProperty(fields);
                await updateVisits();
            }
        } catch (e) {
            console.log(e);
        }
        setOpen(false);
        setLoading(false)
    }


    const disableButton = () => {
        if (currentPage === 1) {
            return !motive || (motive.value === "outdated_ad" && ((!newPeriodMin || !periodMin) && fixedRent === room.fixedRent && !rent && !newRents)) || motive.value === "occupied_dates" && (!availableFrom && !unavailable ) || (["new_moveIn", "new_moveOut"].includes(motive.value) && !newDate)
        }
        if (currentPage === 2) return !selectedProperty;
        if( currentPage === 3) {
            if (user.integration_landlord) {
                return !selectedIntegration;
            }
            return !selectedRoom;
        }
    }

    let selectedPropertyRooms = useMemo(() => {
        if(selectedProperty) {
            return (selectedProperty.accommodation === "apartment" ? selectedProperty.rooms : (Object.values(selectedProperty.rooms)).filter(room => !room.disabled));
        }
    }, [selectedProperty])

    useEffect(() => {
        (async () => {
            if(user?.integration_landlord) {
                let filters = {};
                if(searchKey) filters = {"searchKey": searchKey};
                let _integrations = await propertyServices.getLandlordIntegrations(page, filters, 20);
                setIntegrations(_integrations.integrations);
                setTotalListings(_integrations.total);
            } else {
                let _properties = (await propertyServices.getCachedPropertiesList()).filter(property => property.finished && !property.disabled);
                let filteredProperties = _properties.filter((property) => {
                    let rentsInterval = property.accommodation === 'apartment' ? propertyServices.getPricesInterval(property.rooms['room_1'], property) : propertyServices.getRentsInterval(property);
                    let minRent = rentsInterval.includes("-") ? rentsInterval.split("-")[0] : rentsInterval.slice(0, -1);
                    property.rentsInterval = rentsInterval;
                    return !(parseFloat(minRent) - currentRent > 100) && Object.values(property.rooms).filter(room => !room.disabled && (room.rent || room.rentMonths) && room.roomLevel === "2.4").length > 0;
                })
                setProperties(filteredProperties);
            }
        })();
    }, [searchKey, page])

    let propertiesList = useMemo(()=> {
        if(properties) {
            if(searchKey) {
                return properties.filter(property => {
                    if (searchKey) {
                        let sanitizedSearch = grind(searchKey).trim();
                        let propertyFieldsSearch = [grind(property.internal_name),
                            grind(property.address),
                            grind(property.floor),
                            grind(property.region),
                            ...Object.values(property?.rooms || {}).map(room => grind(room.internalName))
                        ].join(' ');
                        return sanitizedSearch.split(' ').every(searchWord => propertyFieldsSearch.includes(searchWord))
                    } else return true;
                });
            }else{
                return properties;
            }
        }
    }, [properties, searchKey])

    const getRoomNumber = (room) => {
        return selectedProperty.accommodation === "apartment" ? "1" :  Object.keys(selectedProperty.rooms).find((k)=> selectedProperty.rooms[k].id === room.id).split("_")[1];
    }

    const getExcludedDates = ((room) => {
        let allUnavailabilities = []
        if (room?.unavailability)
            allUnavailabilities = room.unavailability.filter(unavailability => unavailability.added === 2)
        if (room?.importedUnavailability)
            allUnavailabilities.push(...room.importedUnavailability);

        return allUnavailabilities.reduce((acc, unavailability) => {
            let dateArray = [];
            let currentDate = moment(unavailability.start);
            let stopDate = moment(unavailability.end);
            while (currentDate <= stopDate) {
                if(currentDate.isAfter(moment())) dateArray.push(currentDate.toDate())
                currentDate.add(1, 'days');
            }
            return [...acc, ...dateArray];
        }, [])
    })();

    const handleRentUpdate = async(rent, roomNumber) => {
        if(!isNaN(rent)) {
            let response = await propertyServices.updateRent(selectedProperty.id, roomNumber, rent);
            if(response.status === "success") selectedProperty.rooms["room_"+roomNumber].rent = rent;
            setSelectedProperty(selectedProperty)
        }
         setEditingRent("");
    }

    const handleRentMonthsUpdate = async(roomToUpdate, roomNumber) => {
        if(!changedRents) return;
        setLoading(true)
        let previousRents = roomToUpdate.rentMonths;
        let hasChanged = Object.keys(changedRents).some((m) => changedRents[m] !== previousRents[m])
        if(hasChanged) {
            let response = await propertyServices.updateRentMonths(selectedProperty.id, roomNumber, rentMonths);
            if(response.status === "success") selectedProperty.rooms["room_"+roomNumber].rentMonths = changedRents;
        }
        setLoading(false)
        setEditingRent("");
    }


    useEffect(() => {
        if (editingRent && room.fixedRent) {
            rentInput.current.select();
        }
    }, [editingRent]);

    const handleUpdateAvailableFrom = async(date, roomNumber) => {
        let response = await propertyServices.updateAvailableFrom(selectedProperty.id, roomNumber, date.toISOString())
        selectedProperty.rooms["room_"+roomNumber].availableFrom = date.toISOString();
        if(response.status === "success") selectedProperty.rooms["room_"+roomNumber].firstAvailability = response.firstAvailability;
        setSelectedProperty(selectedProperty)
        setEditAvailFrom("");
    }

    const searchTimeout = useRef(null);
    const handleSearchKeyChange = (searchedKey) => {
        //use deboucer to set searchFilter
        window.clearTimeout(searchTimeout.current);
        if (searchedKey == null || (searchedKey.length > 0 && searchedKey.length < 2)) return;
        setLoading(true)
        searchTimeout.current = window.setTimeout(() => {
            setSearchKey(searchedKey);
            setLoading(false)
        }, 250);
    }

    return (
        <Dialog open={open} onClose={handleClose} className={`SuggestNewProperty`} maxWidth={"md"}>
            <DialogTitle className="DialogHeader" onClose={handleClose}/>
            {loading && <Loading cover={0.3}/>}
            <DialogContent className={"SuggestNewProperty__content"} ref={dialogRef}>
                {currentPage === 1 &&
                    <>
                    <div className={"SuggestNewProperty__content-label"}>
                        <span><strong>{t(translPath+"newProperty_modal.suggest_property")}</strong></span>
                        <span>{t(translPath + "newProperty_modal.indicate_motive", { name: request.user?.name?.trim().split(" ")[0] || request.user_info?.name?.trim().split(" ")[0] })}</span>
                    </div>
                    <div className={"SuggestNewProperty__content-select-motive"}>
                        <label>1. {t(translPath+"newProperty_modal.motive")}</label>
                        <Select className={"select-motive"}
                                placeholder={t(translationDirc + "motive.please_choose")}
                                options={motives}
                                value={motive}
                                onChange={(value => {
                                    setMotive(value);
                                })}
                        />
                    </div>
                    <div className={"horizontal_line w-100"}/>
                    {motive?.value === "outdated_ad" &&
                    <OutdatedAdInputs roomId={room_id} room={room} fixedRent={fixedRent}
                                      setFixedRent={setFixedRent} newPeriodMin={newPeriodMin} newRents={newRents}
                                      rentMonths={rentMonths} setNewPeriodMin={setNewPeriodMin}
                                      setNewRents={setNewRents} setPeriodMin={setPeriodMin} periodMin={periodMin} rent={rent} setRent={setRent}
                                      setRentMonths={setRentMonths}/>

                    }
                    {motive?.value === "occupied_dates" &&
                    <div className={"details_outdated"}>
                        <div className={"double-input"}>
                            <div>
                                <input type={"radio"} id={"availableFrom"} name={"availability"}
                                       checked={newAvailableFrom}
                                       onChange={() => {
                                           setNewAvailableFrom(true)
                                           setUnavailable(false)
                                       }}
                                />
                                <label htmlFor={"availableFrom"}>{t(translationDirc + "availableFrom_label")}</label>
                            </div>
                            <div className={"CustomDatePicker"} style={{width: "fit-content"}}>
                                <DatePicker className={"input_field basic_container"}
                                            id={"availableFrom"} name={"availableFrom"}
                                            onChange={(value) => setAvailableFrom(value)}
                                            selected={availableFrom}
                                            disabled={!newAvailableFrom}
                                            showPreviousMonths={false}
                                            locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                            dateFormat={"dd/MM/yyyy"}
                                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                                            popperPlacement='auto'
                                            minDate={new Date(moment().add(1, "day"))}
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                },
                                                hide: {
                                                    enabled: false // turn off since needs preventOverflow to be enabled
                                                }
                                            }}/>
                                <img src={calendarIcon} />
                            </div>
                        </div>
                        <div className={"double-input"}>
                            <div>
                                <input type={"radio"} id={"unavailable"} name={"option"}
                                       checked={unavailable}
                                       onChange={() => {
                                           setNewAvailableFrom(false)
                                           setUnavailable(true)
                                       }}/>
                                <label htmlFor={"unavailable"}>{t(translationDirc + "unavailable_label")}</label>
                            </div>
                            <div>
                                <span>{t(translationDirc + "unavailable_info")}</span>
                            </div>
                        </div>
                    </div>
                    }
                    {["new_moveIn", "new_moveOut"].includes(motive?.value) &&
                        <div className={"new-date"}>
                            <label htmlFor={"newDate"}>{t("main_page.radar.offers_filters." + (motive.value === "new_moveIn" ? "moveIn" : "moveOut"))}</label>
                            <div className={"CustomDatePicker"} style={{width: "fit-content"}}>
                                <DatePicker className={"input_field basic_container"}
                                            id={"newDate"} name={"newDate"}
                                            onChange={(value) => setNewDate(value)}
                                            selected={newDate}
                                            showPreviousMonths={false}
                                            locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                            dateFormat={"dd/MM/yyyy"}
                                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                                            popperPlacement='auto'
                                            minDate={new Date(moment().add(1, "day"))}
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                },
                                                hide: {
                                                    enabled: false // turn off since needs preventOverflow to be enabled
                                                }
                                            }}/>
                                <img src={calendarIcon} alt={"calendar-icon"}/>
                            </div>
                            <div className={"current-dates"}>
                               {t("main_page.reservations.reservation_details.newDates_modal.current_dates")}: {moment(request.moveIn).format("DD/MM/YYYY")} - {moment(request.moveOut).format("DD/MM/YYYY")}
                            </div>
                        </div>
                    }
                </>}
                {currentPage === 2 &&
                <div className={"SuggestNewProperty__content-choose-ad"}>
                    <div className={"select-top"}>
                        <div className={"title"}>{t(translPath+"newProperty_modal.choose_apartment")}</div>
                            <SearchFilter placeholder={"property_placeholder"} onChange={setSearchKey} />
                    </div>
                    <div className={"list"}>
                        {propertiesList ? Object.keys(propertiesList).map((key) => {
                            let property = propertiesList[key];
                            let propertyImage = property.photos?.["featured_0"]?.[0]?.md || "";
                            return (
                                <div
                                    className={`property-card ${selectedProperty?.id === property.id ? "selected" : ""}`}
                                    key={"property-card-" + key}
                                    onClick={() => setSelectedProperty(property)}>
                                    <div className={"property-section"}>
                                        <div className={"property-image"}
                                             style={{backgroundImage: `url(${propertyImage})`}}/>
                                        <div className={"property-info"}>
                                            <span><strong>{property.address}</strong></span>
                                            <span>{property.region}</span>
                                            <span>T{property.bedrooms}</span>
                                        </div>
                                    </div>
                                    <div className={"property-prices"}>
                                        {property.rentsInterval}
                                    </div>
                                </div>
                            )
                        }) : <Loading /> }
                    </div>
                </div>
                }
                {currentPage === 3 &&
                <div className={"SuggestNewProperty__content-choose-ad"} style={{minHeight: "300px"}}>
                    <div className={"select-top"}>
                        {selectedProperty ? <>
                            <div className={"title"}>{t(translPath+"newProperty_modal.choose_room")}</div>
                            <div className={"select-house"}>
                                <span>Casa</span>
                                <Select
                                    className='react-select-container' classNamePrefix="react-select"
                                    defaultValue={{value: selectedProperty, label: selectedProperty.address}}
                                    options={propertiesList.map(p => ({value: p, label: p.address}))}
                                    onChange={(property)=> {
                                        setSelectedProperty(property.value)
                                    }}
                                />
                            </div>
                        </> : <>
                            <div className={"title"}>{t(translPath+"newProperty_modal.choose_listing")}</div>
                            <div className={"select-house"}>
                                <SearchFilter placeholder={'integrations_placeholder'} onChange={handleSearchKeyChange} />

                                <PaginationLegacy total={totalListings} setPage={setPage} page={page}
                                                  updateList={()=>{}} filter={""}
                                                  setLoading={setLoading} searchKey={searchKey} isListings={false}/>
                            </div>
                        </>}
                    </div>
                    {editAvailFrom && editAvailFrom.id &&
                    <div className={'CustomDatePicker'} style={{width: "fit-content", position: "absolute"}}>
                        <DatePicker
                            inline
                            excludeDates={getExcludedDates}
                            onChange={async (date) => {
                                await handleUpdateAvailableFrom(date, getRoomNumber(editAvailFrom));
                            }}
                            selected={editAvailFrom?.firstAvailability && new Date(editAvailFrom?.firstAvailability) > Date.now() ? moment(editAvailFrom?.firstAvailability).toDate() : new Date()}
                            open={editAvailFrom?.id}
                            showPreviousMonths={false}
                            locale={localStorage.getItem('i18nextLng').split('-')[0]}
                            dateFormat={'dd MMM yyyy'}
                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                            popperPlacement='auto'
                            dayClassName={date =>
                                moment(date).isBefore(moment(editAvailFrom?.firstAvailability), 'day') ? 'passed_day' : undefined
                            }
                            minDate={new Date()}
                            onClickOutside={() => setEditAvailFrom("")}
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                },
                                hide: {
                                    enabled: false // turn off since needs preventOverflow to be enabled
                                }
                            }}
                        />
                    </div>}
                    <div className={"list"+(user?.integration_landlord ? " list-compact":"")}>
                        {user?.integration_landlord ? (integrations.map(integration => {
                            if(integration.disabled || integration.deleted) return;
                            let isAvailable = moment(integration.firstAvailability).isSameOrBefore(moment(request.moveIn), 'day');
                            let selected = selectedIntegration?.id === integration.id && selectedIntegration?.room_id === integration.room_id;
                            let integrationImage = [...(integration.room_photos||[]), ...(integration.photos||[])][0];
                            return (<div className={`room-card ${selected ? "selected" : ""} ${!isAvailable ? "not-available" : ""}`} key={integration.id + "_" + integration.room_id}>
                                <div className={"room-section"} style={isAvailable ? {cursor: "pointer"} : {}} onClick={()=>{
                                    if(isAvailable) setSelectedIntegration(integration)
                                }}>
                                    <div className={"room-image"} style={{backgroundImage: `url('${integrationImage}')`}} />
                                    <div className={"room-info"}>
                                        <span><strong>{clampText(integration.internal_name, 30)}</strong></span>
                                        <span>ID {room.id.split("-")[0]} | Integration {integration.integration_id}</span>
                                        <span>{integration.address} {integration.floor && ", "+ integration.region}</span>
                                    </div>
                                </div>
                            </div>)
                        })) : (selectedPropertyRooms && Object.values(selectedPropertyRooms).map((room, index) => {
                            if(room.disabled || room.roomLevel !== "2.4" || (!room.rent && !room.rentMonths)) return;
                            if(selectedProperty.accommodation === "apartment" && index!== 0) return;
                            let roomImage = room.photos?.featured?.md;
                            let firstAvailability = room?.firstAvailability || utils.firstAvailability(room);
                            let isAvailable = moment(firstAvailability).isSameOrBefore(moment(request.moveIn), 'day');
                            let price = propertyServices.getPricesInterval(room, selectedProperty);
                            let roomNumber =  getRoomNumber(room);
                            const handleSelect = () => {
                                if(isAvailable && (!selectedRoom || selectedRoom.id !== room.id)) setSelectedRoom(room);
                            }
                            return (
                                <div className={`room-card ${(selectedRoom?.id === room.id) ? "selected" : ""} ${!isAvailable ? "not-available" : ""}`} key={room.id}>
                                    <div className={"room-section"} style={isAvailable ? {cursor: "pointer"} : {}} onClick={handleSelect}>
                                        <div className={"room-image"} style={{backgroundImage: `url(${roomImage})`}} />
                                        <div className={"room-info"}>
                                            <span><strong>{clampText(selectedProperty.accommodation === "apartment" ? selectedProperty.title['pt'] : room.title['pt'], 30)}</strong></span>
                                            <span>ID {room.id.split("-")[0]} | Quarto nº {index +1}</span>
                                            <span>{selectedProperty.address} {selectedProperty.floor && ", "+ selectedProperty.floor}</span>
                                        </div>
                                    </div>
                                    {editingRent === room.id ? (room.fixedRent ? <input ref={rentInput} className={"rent-input"} type={"number"} placeholder={price} onBlur={ (e) => handleRentUpdate(e.target.valueAsNumber, roomNumber)}/> :
                                            <Dialog open={editingRent === room.id && !room.fixedRent} className={"RentMonths_Dialog"} maxWidth={"xs"}>
                                                <DialogTitle className="DialogHeader" onClose={() => setEditingRent("")}/>
                                                {loading && <Loading cover={0.3}/>}
                                                <DialogContent>
                                                    <div className={"title"}>{t(translPath+"newProperty_modal.monthRents")}</div>
                                                    <div className={"rents-input"}>
                                                        {Object.keys(changedRents).length > 0 && Object.keys(changedRents).map(month => {
                                                            return (
                                                                <div className={"rent"} key={room.id + "rent-" + month}>
                                                                    <label>{t("main_page.my_ads.create_ad.room_details.room_prices.rentMonths." + month)}</label>
                                                                    <input type="number"
                                                                           placeholder={`€400`}
                                                                           className={"input_field"}
                                                                           key={"input-" + room.id + "rent-" + month}
                                                                           id={month + "-rent"}
                                                                           name={month}
                                                                           value={changedRents[month]}
                                                                           onClick={(e) => e.target.select()}
                                                                           onBlur={() => {
                                                                               if(new Set(Object.values(changedRents)).size > 3) {
                                                                                   setError(true)
                                                                               }else{
                                                                                   setError(false);
                                                                               }
                                                                           }}
                                                                           onChange={(e) => {
                                                                               if(e.target.valueAsNumber > 9999 || e.target.valueAsNumber < 0) return;
                                                                               setChangedRents(prevState => ({
                                                                                   ...prevState,
                                                                                   [e.target.name]: e.target.valueAsNumber || 0
                                                                               }))
                                                                           }}/>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <span className={"info"} style={{color: (error ? "#C1381A" : "")}}><BiInfoCircle/>{t("main_page.reservations.reject_reservation.info_rentMonths")}</span>
                                                    <div className={"dialog-actions"}>
                                                        <button className={"btn btn_grey"} onClick={()=> setEditingRent("")}>{t(translationDirc + "goBack")}</button>
                                                        <button className={"btn btn_blue"}
                                                                disabled={error}
                                                                onClick={()=>handleRentMonthsUpdate(room, roomNumber)}>{t("buttons.save")}
                                                        </button>
                                                    </div>
                                                </DialogContent>
                                            </Dialog>
                                        ):
                                        <div className={"room-prices"}>{price} <img src={editPencil} onClick={()=> {
                                            setChangedRents(room.rentMonths)
                                            setEditingRent(room.id)
                                        }}/> </div>}
                                    <div className={"room-date"}>{moment(firstAvailability).format('D MMM yyyy')} <img src={editPencil} onClick={()=> setEditAvailFrom(room)}/></div>
                                    <div className={"room-availability"} style={isAvailable ? {cursor: "pointer"} : {}} onClick={handleSelect}>
                                        <span className={isAvailable ? "available" : "unavailable"}><strong>{t(translPath+"newProperty_modal." + (isAvailable ? "available" : "unavailable"))}</strong></span>
                                        <span>{t(translPath+"entry_date")}: {moment(request.moveIn).format('D MMM yyyy')}</span>
                                        <span>{t(translPath+"newDates_modal.moveOut")}: {moment(request.moveOut).format('D MMM yyyy')}</span>
                                    </div>
                                    <input style={{appearance: "auto", width: "20px", height:"40px", cursor:(isAvailable ? "pointer" : "not-allowed")}} type={"radio"} id={"room"+room.id} name={"selectedRoom"} disabled={!isAvailable} checked={selectedRoom?.id === room.id} onChange={handleSelect}/>
                                </div>
                            )
                        }))}
                    </div>
                </div>
                }
                <div className={"SuggestNewProperty__content-actions"}>
                    <button className={"btn btn_grey_soft"} onClick={handleGoBack}>{t(translationDirc + "goBack")}</button>
                    <button className={"btn btn_blue"}
                            disabled={disableButton()}
                            onClick={currentPage === 3 ? handleSubmit : handleAdvance}>{t("buttons." + (currentPage === 3 ? "send" : "go_forward"))}
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    )
}


export default SuggestPropertyModal;
