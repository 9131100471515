const getAreasNamesFromNums = (property, req_areas) => {
  let areasNames = [];

  let areasNumNames = ['numKitchen', 'numLivingRoom', 'numBathroom'];

  areasNumNames.forEach(propertyArea => {
    // if(parseInt(property[propertyArea])>1) {
    for (let i = 0; i < parseInt(property[propertyArea]); i++) {
      let areaName = propertyArea.replace('num', '');
      areaName = areaName.charAt(0).toLowerCase() + areaName.slice(1);
      areasNames.push(`${areaName}_${i}`);
    }
  });

  if(!req_areas) {
    areasNames.push('otherSpaces_0');
    areasNames.push('exteriorSpaces_0');
  }

  return areasNames;
};

export default getAreasNamesFromNums;
