import React from "react";

const CircleDownArrow = (props) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9 2.334A6.676 6.676 0 0 1 15.666 9 6.676 6.676 0 0 1 9 15.667 6.676 6.676 0 0 1 2.333 9 6.676 6.676 0 0 1 9 2.334ZM9 .667A8.336 8.336 0 0 0 .667 9c0 4.6 3.733 8.334 8.333 8.334S17.333 13.6 17.333 9 13.6.667 9 .667ZM9 11.5 5.667 8.167h6.666L9 11.5Z"
            fill="#57B4F2"
        />
    </svg>
);

export default CircleDownArrow;
