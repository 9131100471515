import * as React from "react";

function SvgNi(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M512.001 112.092H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v102.989zm-8.829 223.632H8.828A8.829 8.829 0 010 326.896V223.908h512v102.988a8.828 8.828 0 01-8.828 8.828z"
          fill="#41479B"
        />
        <path fill="#F5F5F5" d="M0 112.088h512V223.9H0z" />
        <path fill="#AFEBFF" d="M256 135.677l-26.927 39.599h53.854z" />
        <path
          fill="#557DE6"
          d="M229.073 175.276l-17.424 25.344h88.702l-17.424-25.344z"
        />
        <g fill="#73AF00">
          <path d="M262.891 173.063c.107-.221.22-.438.317-.664-.096.224-.21.443-.317.664zm.742-1.825c.066-.199.146-.392.203-.594-.058.201-.138.394-.203.594zm-1.658 3.486c.136-.217.27-.433.397-.655-.126.223-.262.439-.397.655zm4.116-7.377zm-1.261.418a1.58 1.58 0 00-.617 1.043v.002-.002c.053-.416.281-.782.617-1.043zm-.817 11.819c.377.358.751.698 1.127 1.024 2.01-1.74 4.108-3.951 5.58-6.538-.106.185-.22.365-.332.547-1.375-2.528-2.101-4.715-2.453-6.012-.201-.74-.967-1.256-1.842-1.257.337 2.954 1.061 8.383-2.08 12.236zm7.967-8.346c.066-.199.146-.392.203-.594-.057.201-.137.394-.203.594zm1.198-3.473a1.58 1.58 0 00-.617 1.043v.002-.002c.053-.416.281-.782.617-1.043zm-1.938 5.298c.107-.221.22-.438.317-.664-.098.224-.21.443-.317.664z" />
          <path d="M288.339 181.612c-3.053 0-5.942-1.465-7.706-3.957-2.657-3.754-3.862-7.254-4.349-9.049-.201-.742-.969-1.258-1.845-1.258l.003.001c.402 3.526 1.358 10.575-4.371 14.264h-3.73c-.153-.121-.302-.237-.459-.365l.01.009c-1.044 1.363-3.132 1.745-4.751.91-6.778-3.492-5.726-11.109-5.303-14.818-.875.001-1.641.516-1.842 1.257-.352 1.297-1.078 3.484-2.453 6.012-.111-.182-.226-.361-.332-.547 1.656 2.91 4.113 5.366 6.333 7.186-1.044 1.363-3.132 1.745-4.751.91-6.778-3.492-5.726-11.11-5.303-14.818-.875.001-1.641.516-1.842 1.257-.352 1.297-1.078 3.484-2.453 6.012-.111-.182-.226-.361-.332-.547 1.656 2.91 4.113 5.366 6.333 7.186-1.044 1.363-3.132 1.745-4.751.91-6.778-3.492-5.726-11.109-5.303-14.818l.003-.001c-.876 0-1.644.515-1.845 1.258-.487 1.795-1.692 5.295-4.349 9.049-1.763 2.493-4.652 3.957-7.706 3.957h-2.508v3.168h66.526v-3.168h-.924z" />
          <path d="M258.724 172.398c.097.226.21.444.317.664-.107-.22-.221-.439-.317-.664zm-.626-1.755c.056.202.137.395.203.594-.067-.199-.147-.392-.203-.594zm-.379-1.832v-.002.002zm1.841 5.259c.127.223.262.438.397.655-.135-.217-.27-.433-.397-.655zm1.455 2.196c-.017-.021-.031-.043-.047-.065.136-.181.266-.363.397-.548-.13.184-.264.366-.399.546a17.692 17.692 0 01.049.067zm.696.876c.154.185.308.37.466.548-.158-.178-.312-.363-.466-.548zm1.692 1.858c-.148-.148-.292-.303-.438-.456.147.154.29.308.438.456z" />
        </g>
        <path
          d="M274.442 167.348l-.003-.001c-.481 0-.925.157-1.261.418a1.58 1.58 0 00-.617 1.043v.002a12.651 12.651 0 01-.378 1.833c-.056.202-.137.395-.203.594-.129.392-.262.782-.424 1.161-.097.226-.21.444-.317.664-.164.34-.332.679-.519 1.007-1.472 2.586-3.57 4.798-5.58 6.538a32.356 32.356 0 01-1.127-1.024c3.14-3.853 2.417-9.282 2.081-12.236l-.003-.001c-.241 0-.472.04-.685.111a1.976 1.976 0 00-.576.307 1.58 1.58 0 00-.617 1.043v.002a12.521 12.521 0 01-.379 1.833c-.056.202-.137.395-.203.594-.129.392-.262.782-.424 1.161-.097.226-.21.444-.317.664-.164.34-.332.679-.519 1.007-.127.223-.262.438-.397.655a19.74 19.74 0 01-1.007 1.475 19.233 19.233 0 01-1.011-1.475c-.136-.217-.27-.433-.397-.655a17.1 17.1 0 01-.519-1.007c-.107-.221-.22-.438-.317-.664a14.231 14.231 0 01-.424-1.161c-.066-.199-.146-.392-.203-.594-.168-.598-.3-1.208-.378-1.833v-.002a1.573 1.573 0 00-.617-1.043 2.047 2.047 0 00-1.26-.418l-.003.001c-.422 3.709-1.475 11.326 5.303 14.818a4.013 4.013 0 003.591.024c.687-.346 1.425-.578 2.194-.578h3.146c5.728-3.688 4.772-10.738 4.37-14.263z"
          fill="#FFE15A"
        />
        <path
          d="M249.749 170.643c.056.202.137.395.203.594-.066-.199-.146-.392-.203-.594zm-.379-1.832v-.002.002zm1.006 3.587c.097.226.21.444.317.664-.108-.22-.22-.439-.317-.664zm-2.882-5.051z"
          fill="#73AF00"
        />
        <path
          d="M252.793 182.166c1.62.834 3.708.452 4.751-.91-2.22-1.82-4.676-4.275-6.333-7.186a17.1 17.1 0 01-.519-1.007c-.107-.221-.22-.438-.317-.664a14.231 14.231 0 01-.424-1.161c-.066-.199-.146-.392-.203-.594-.168-.598-.3-1.208-.378-1.833v-.002a1.573 1.573 0 00-.617-1.043 1.976 1.976 0 00-.576-.307 2.138 2.138 0 00-.685-.111l-.003.001c-.421 3.708-1.474 11.324 5.304 14.817z"
          fill="#FFE15A"
        />
        <path
          d="M241.401 170.643c.056.202.137.395.203.594-.066-.199-.146-.392-.203-.594zm.627 1.755c.097.226.21.444.317.664-.107-.22-.221-.439-.317-.664zm-1.005-3.587v-.002.002z"
          fill="#73AF00"
        />
        <path
          d="M244.446 182.166c1.62.834 3.708.452 4.751-.91-2.22-1.82-4.676-4.275-6.333-7.186a17.1 17.1 0 01-.519-1.007c-.107-.221-.22-.438-.317-.664a14.231 14.231 0 01-.424-1.161c-.066-.199-.146-.392-.203-.594-.168-.598-.3-1.208-.378-1.833v-.002a1.573 1.573 0 00-.617-1.043 2.048 2.048 0 00-1.261-.418l-.003.001c-.422 3.708-1.476 11.324 5.304 14.817z"
          fill="#FFE15A"
        />
        <path
          d="M242.655 154.752l-.395 1.516a49.518 49.518 0 0114.274-2.076c5.178.003 9.95.776 14.274 2.076l-.395-1.516a55.262 55.262 0 00-13.879-1.746 55.32 55.32 0 00-13.879 1.746z"
          fill="#41479B"
        />
        <path
          d="M242.827 154.093l-.395 1.516c4.276-1.3 8.99-2.08 14.102-2.082a48.34 48.34 0 0114.102 2.082l-.395-1.516a53.764 53.764 0 00-13.707-1.752 53.892 53.892 0 00-13.707 1.752z"
          fill="#5AA0E6"
        />
        <path
          d="M242.986 153.484l-.395 1.516a47.119 47.119 0 0113.943-2.088c5.053 0 9.71.783 13.943 2.088l-.395-1.516a52.546 52.546 0 00-13.548-1.759 52.508 52.508 0 00-13.548 1.759z"
          fill="#9BC800"
        />
        <path
          d="M243.167 152.788l-.395 1.516a45.895 45.895 0 0113.762-2.095c4.982.002 9.578.786 13.762 2.095l-.395-1.516a51.16 51.16 0 00-13.367-1.766c-4.758.001-9.21.644-13.367 1.766z"
          fill="#FFE14E"
        />
        <path
          d="M243.346 152.104l-.395 1.516a44.735 44.735 0 0113.583-2.102 44.73 44.73 0 0113.583 2.102l-.395-1.516a49.713 49.713 0 00-13.188-1.772 49.678 49.678 0 00-13.188 1.772z"
          fill="#FFB14E"
        />
        <path
          d="M243.421 151.812l-.277 1.062a43.45 43.45 0 0113.389-2.11 43.317 43.317 0 0113.389 2.11l-.277-1.062a49.106 49.106 0 00-13.113-1.775 48.907 48.907 0 00-13.111 1.775z"
          fill="#FF4B55"
        />
        <path
          d="M300.352 202.157h-88.703a1.587 1.587 0 01-1.397-.835 1.589 1.589 0 01.079-1.628l44.352-66.527c.588-.878 2.048-.878 2.636 0l44.352 66.527a1.585 1.585 0 11-1.319 2.463zm-85.745-3.168h82.785L256 136.902l-41.393 62.087z"
          fill="#FFE15A"
        />
        <path
          d="M259.029 160.932c0-1.394-1.412-3.512-3.673-3.512-1.321 0-2.392.808-2.392 1.803 0 .498.267.947.698 1.273l-.004.055c-.54.331-.892.829-.892 1.394 0 .95.979 1.72 2.216 1.79l1.018 2.036.919-1.838c1.207-.307 2.11-1.531 2.11-3.001z"
          fill="#FF4B55"
        />
      </g>
    </svg>
  );
}

export default SvgNi;
