import React from "react";

const DateRangeCalendar = (props) => (
    <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="date_range" clipPath="url(#clip0_2344_9650)">
            <path
                id="Vector"
                d="M14 22H18V26H14V22ZM42 12V40C42 42.2 40.2 44 38 44H10C7.78 44 6 42.2 6 40L6.02 12C6.02 9.8 7.78 8 10 8H12V4H16V8H32V4H36V8H38C40.2 8 42 9.8 42 12ZM10 16H38V12H10V16ZM38 40V20H10V40H38ZM30 26H34V22H30V26ZM22 26H26V22H22V26Z"
                fill="#434A5F"
            />
        </g>
        <defs>
            <clipPath id="clip0_2344_9650">
                <rect width={48} height={48} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default DateRangeCalendar;
