import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { login } from "./loginReducer";
import { signup } from "./signupReducer";
import { footerDisplay } from "./displayFooterReducer";
import {propertyAdHistory} from './propertyAdHistoryReducer';
import {updatePropertyFunction} from './updatePropertyReducer';

const middleware = [thunk];

const rootReducer = combineReducers({
  login,
  signup,
  footerDisplay,
  propertyAdHistory,
  updatePropertyFunction
});

const persistConfig = {
  key: "inlife_landlord",
  storage: storage,
  blacklist: ["signup", "login", "footerDisplay", "updatePropertyFunction"],
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
export const persistor = persistStore(store);
