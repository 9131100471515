import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../../../../../../components/MUIDialogComponents";
import Loading from "../../../../../../../../components/Loading";
import {DialogContent} from "@material-ui/core";
import {OutdatedAdInputs} from "../../index";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import {reservationServices} from "../../../../../../../../services/reservationServices";

const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

const RejectReservationModal = ({open, setOpen, reservation, room, rejectReservation}) => {

    const {t} = useTranslation();
    const translationDirc = "main_page.reservations.reject_reservation."


    const [loading, setLoading] = useState(false);
    const [motive, setMotive] = useState(null)
    const [reason, setReason] = useState(null);
    const [newRents, setNewRents] = useState(false);
    const [newPeriodMin, setNewPeriodMin] = useState(false);
    const [newAvailableFrom, setNewAvailableFrom] = useState(false);
    const [unavailable, setUnavailable] = useState(false);
    const [availableFrom, setAvailableFrom] = useState(null);
    const _defaultMonths = months.reduce((acc, m) => ({...acc, [m]: room.rent || 0}), {});
    const [rentMonths, setRentMonths] = useState(room.rentMonths && Object.values(room.rentMonths).length === 12 ? room.rentMonths : _defaultMonths);
    const [rent, setRent] = useState(null);
    const [periodMin, setPeriodMin] = useState(null);
    const [periodMax, setPeriodMax] = useState(null);
    const [fixedRent, setFixedRent] = useState(room.fixedRent)

    const [errors, setErrors] = useState([]);

    //not passed on submit
    const [newMoveIn, setNewMoveIn] = useState(new Date(moment().add(1, "day")))


    let motiveTranslationDirect = translationDirc + "motive.";
    let reasonTranslationDirect = translationDirc + "reason.";
    const cancellationMotives = [
        {value: 'occupied_dates', label: t(motiveTranslationDirect + 'occupied_dates')},
        {value: 'other_dates', label: t(motiveTranslationDirect + 'other_dates')},
        {value: 'outdated_ad', label: t(motiveTranslationDirect + 'outdated_ad')},
        {value: 'disable_ad', label: t(motiveTranslationDirect + 'disable_ad')}];

    const reasons = [
        {id: 'reason', value: 'short_stay', label: t(reasonTranslationDirect + 'short_stay')},
        {id: 'reason', value: 'long_stay', label: t(reasonTranslationDirect + 'long_stay')},
        {id: 'reason', value: 'late_check_in', label: t(reasonTranslationDirect + 'late_check_in')},
        {id: 'reason', value: 'antecedence', label: t(reasonTranslationDirect + 'antecedence')},
    ];

    const resetFields = () => {
        setMotive(null);
        setReason(null)
        setNewRents(false)
        setNewPeriodMin(false)
        setRentMonths(room.rentMonths && Object.values(room.rentMonths).length === 12 ? room.rentMonths : _defaultMonths);
        setNewAvailableFrom(false);
        setFixedRent(room.fixedRent);
        setRent(room.rent)
    }


    const handleClose = () => {
        if(loading) return;
        setOpen(false);
        resetFields();
    }

    const disableSubmitButton = () => {
        if (!motive) return true
        switch (motive.value) {
            case "occupied_dates":
                return (!newAvailableFrom || !availableFrom) && !unavailable
            case "other_dates":
                return !reason || (reason === "short_stay" && !periodMin) || (reason === "long_stay" && !periodMax);
            case "outdated_ad":
                return !newPeriodMin && fixedRent === room.fixedRent && !newRents || (newRents && !fixedRent && new Set(Object.values(rentMonths)).size > 3);
            case "disable_ad":
                return false;
            default:
                return true;
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        let fields = {
            motive: motive.value,
            reservation_id: reservation.id
        }
        switch (motive.value) {
            case "occupied_dates":
                newAvailableFrom ? (fields.availableFrom = availableFrom) :
                    (fields.unavailable= true)
                break;
            case "other_dates":
                fields.reason = reason;
                if(reason === "short_stay") fields.periodStayMin = periodMin.toString();
                if(reason === "long_stay") fields.periodStayMax = periodMax.toString();
                if(reason === "late_check_in") fields.newMoveIn = newMoveIn;
                break;
            case "outdated_ad":
                if (newPeriodMin) fields.periodStayMin = periodMin.toString();
                if (newRents) fixedRent ? fields.rent = rent.toString() : fields.rentMonths = rentMonths;
                if (fixedRent !== room.fixedRent) fields.fixedRent = fixedRent;
                break;
            case "disable_ad":
                break;
            default:
                break;
        }
        await reservationServices.rejectReservation(fields);
        rejectReservation(motive.value);
        setLoading(false);
        handleClose();

    }


    return (
        <Dialog open={open} onClose={handleClose} className={`RejectReservationModal`} maxWidth={"xs"} >
            <DialogTitle className="DialogHeader" onClose={handleClose} />
            {loading && <Loading cover={0.3}/>}
            <DialogContent className={"RejectReservation__content"}>
                <div className={"RejectReservation__content-label"}>
                    <span><strong>{t(translationDirc+"reject_title")}</strong></span>
                    <span>{t(translationDirc + "title_info", { name: reservation.user?.name?.trim().split(" ")[0] || reservation.user_info?.name?.trim().split(" ")[0] })}</span>
                </div>
                <div className={"RejectReservation__content-select-motive"}>
                    <label>{t(translationDirc+"motive_title")}</label>
                    <Select className={"select-motive"}
                            placeholder={t(translationDirc+"motive.please_choose")}
                            options={cancellationMotives}
                            value={motive}
                            onChange={(value => {
                                resetFields();
                                setMotive(value);
                            })}
                    />
                </div>
                <div className={"horizontal_line w-100"}/>
                <div className={"RejectReservation__content-details"}>
                    {motive?.value === 'other_dates' &&
                    <div className={"details_other-dates"} >
                        <span><strong>{t(translationDirc + "more_details")}</strong></span>
                        {reasons.map((button, index) => (
                            <div key={"reason-" + index} onChange={(e) => {
                                setReason(e.target.value)
                                setPeriodMax(null)
                                setPeriodMin(null)
                                setErrors([]);
                            }}>
                                <input type={"radio"} id={button.value} name={"reason"}
                                       value={button.value}/>
                                <label htmlFor={button.value}>{button.label}</label>
                            </div>

                        ))}
                        {reason === "short_stay" && <div className={"align-items-center"}>
                            <input className={"input_field"} type={"number"} id={"rent"} name={"periodStayMin"}
                                   onChange={(e) => {
                                       let value = e.target.valueAsNumber
                                       if(value < room.periodStayMax || value < 0) {
                                           setErrors(prev => ([...prev, "invalid_minStay"]))
                                           return;
                                       }
                                       if(errors.includes("invalid_minStay")) setErrors(errors.filter(er => er !== "invalid_minStay"))
                                       setPeriodMin(value)
                                   }}/>
                            <span>{t(translationDirc + "currentMinStay", {
                                count: room.periodStayMin === 1 ? 1 : 2,
                                period: room.periodStayMin
                            })}</span>
                        </div>}
                        {reason === "short_stay" && errors.includes("invalid_minStay") && <span  style={{color: "#C1381A"}}>{t(translationDirc+"invalid_minStay", {period: room.periodStayMax})}</span>}
                        {reason === "long_stay" && <div className={"align-items-center"}>
                            <input className={"input_field"} type={"number"} id={"rent"} name={"periodStayMax"}
                                   onChange={(e) => {
                                       let value = e.target.valueAsNumber
                                       if(value < room.periodStayMin || value < 0) {
                                           setErrors(prev => ([...prev, "invalid_maxStay"]))
                                           return;
                                       }
                                       if(errors.includes("invalid_maxStay")) setErrors(errors.filter(er => er !== "invalid_maxStay"))
                                       setPeriodMax(value)
                                   }}/>
                            <span>{t(translationDirc + "currentMaxStay", {
                                count: room.periodStayMax === 1 ? 1 : 2,
                                period: room.periodStayMax
                            })}</span>
                        </div>}
                        {reason === "long_stay" && errors.includes("invalid_maxStay") && <span style={{color: "#C1381A"}}>{t(translationDirc+"invalid_maxStay", {period: room.periodStayMin})}</span>}
                        {reason === "late_check_in" &&
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label htmlFor={"moveInDate"}>{t(translationDirc+"desired_moveIn")}:</label>
                            <div className={"CustomDatePicker"} style={{width: "fit-content"}}>
                                <DatePicker className={"input_field basic_container"}
                                            onChange={ (date) => {setNewMoveIn(date)}}
                                            selected={newMoveIn}
                                            showPreviousMonths={false}
                                            locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                            dateFormat={"dd/MM/yyyy"}
                                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                                            popperPlacement='bottom-start'
                                            minDate={new Date(moment().add(1, "day"))}
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                },
                                                hide: {
                                                    enabled: false // turn off since needs preventOverflow to be enabled
                                                }
                                            }}/>
                            </div>
                        </div>}
                        <span>{t(translationDirc + "other_dates_reminder", {name: reservation.user.name.split(" ")[0]})}</span>
                    </div>}
                    {motive?.value === "disable_ad" &&
                    <span>{t(translationDirc+"disable_info")}</span>
                    }

                    {motive?.value === "outdated_ad" &&
                    <OutdatedAdInputs roomId={reservation.room_id} room={room} fixedRent={fixedRent}
                                      setFixedRent={setFixedRent} newPeriodMin={newPeriodMin} newRents={newRents}
                                      rentMonths={rentMonths} setNewPeriodMin={setNewPeriodMin}
                                      setNewRents={setNewRents} setPeriodMin={setPeriodMin} periodMin={periodMin} rent={rent} setRent={setRent}
                                      setRentMonths={setRentMonths}/>

                    }
                    {motive?.value === "occupied_dates" &&
                    <div className={"details_outdated"}>
                        <div className={"double-input"}>
                            <div>
                                <input type={"radio"} id={"newAvailableFrom"} name={"availability"}
                                       onChange={() => {
                                           setNewAvailableFrom(true)
                                           setUnavailable(false)
                                       }}
                                />
                                <label htmlFor={"newAvailableFrom"}>{t(translationDirc+"availableFrom_label")}</label>
                            </div>
                            <div className={"CustomDatePicker"} style={{width: "fit-content"}}>
                                <DatePicker className={"input_field basic_container"}
                                            id={"rent"} name={"periodStayMin"}
                                            disabled={!newAvailableFrom}
                                            onChange={(date)=> setAvailableFrom(date)}
                                            selected={availableFrom}
                                            showPreviousMonths={false}
                                            locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                            dateFormat={"dd/MM/yyyy"}
                                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                                            popperPlacement='bottom-start'
                                            minDate={new Date()}
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                },
                                                hide: {
                                                    enabled: false // turn off since needs preventOverflow to be enabled
                                                }
                                            }}/>
                            </div>

                        </div>
                        <div className={"double-input"}>
                            <div>
                                <input type={"radio"} id={"unavailable"} name={"availability"}
                                       onChange={() => {
                                           setNewAvailableFrom(false)
                                           setUnavailable(true)
                                       }}/>
                                <label htmlFor={"unavailable"}>{t(translationDirc+"unavailable_label")}</label>
                            </div>
                            <div>
                                <span style={{marginLeft: "0px"}}>{t(translationDirc + "unavailable_info")}</span>
                            </div>
                        </div>
                    </div>
                    }

                </div>
                <div className={"RejectReservation__content-actions"}>
                    <button className={"btn btn_grey"} onClick={handleClose}>{t(translationDirc + "goBack")}</button>
                    <button className={"btn btn_blue"} onClick={handleSubmit}
                            disabled={disableSubmitButton()}>{t(translationDirc + "submit")}
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default RejectReservationModal;
