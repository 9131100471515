import * as React from "react";

function SvgSz(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M0 273.931h512v52.966a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.897v-52.966zM8.828.276h494.345a8.829 8.829 0 018.828 8.828V62.07H0V9.103A8.828 8.828 0 018.828.276z"
          fill="#4173CD"
        />
        <path fill="#FF4B55" d="M0 62.069h512v211.862H0z" />
        <path
          d="M0 53.241h512v17.655H0zm0 211.862h512v17.655H0zm448.897-88.584H62.734a4.744 4.744 0 01-4.745-4.745v-1.501a4.744 4.744 0 014.745-4.745h386.163a4.744 4.744 0 014.745 4.745v1.501a4.745 4.745 0 01-4.745 4.745z"
          fill="#FFE15A"
        />
        <path
          fill="#F5F5F5"
          d="M373.962 154.538l19.233-8.243 19.233 8.243-19.233 8.243z"
        />
        <path
          d="M378.695 151.027H96.456a3.51 3.51 0 100 7.022h282.238a3.511 3.511 0 00.001-7.022z"
          fill="#FFE15A"
        />
        <path
          fill="#F5F5F5"
          d="M393.959 135.305l19.233-8.243 19.233 8.243-19.233 8.243z"
        />
        <path
          d="M398.69 131.794H116.452a3.51 3.51 0 100 7.022H398.69a3.511 3.511 0 000-7.022z"
          fill="#FFE15A"
        />
        <path
          d="M255.816 99.586c-48.808 0-91.39 27.639-114.197 68.69 22.806 41.05 65.389 68.69 114.197 68.69 48.808 0 91.39-27.639 114.197-68.69-22.806-41.051-65.39-68.69-114.197-68.69z"
          fill="#F5F5F5"
        />
        <path
          d="M141.62 168.276c22.806 41.05 65.389 68.69 114.197 68.69 8.15 0 16.106-.836 23.846-2.312-5.909-12.971-26.691-12.949-27.967-17.837-1.374-5.266 16.943-4.121 20.149-8.472 3.206-4.35-17.401-6.182-18.317-10.532-.916-4.351 8.243-7.327 9.846-14.196 1.603-6.869 5.266-8.929 8.013-13.05 2.748-4.121-4.579-5.495-6.411-19.233-1.832-13.738-12.594-15.57-17.859-26.788-5.267-11.219 3.206-19.462 8.701-24.957-48.809-.003-91.392 27.636-114.198 68.687z"
          fill="#464655"
        />
        <path
          d="M184.375 143.548h5.495v21.981h-5.495zm10.991 0h5.495v21.981h-5.495zm10.99 0h5.495v21.981h-5.495zm10.99 0h5.495v21.981h-5.495zm10.991 0h5.495v21.981h-5.495zm10.99 0h5.495v21.981h-5.495zm-54.952 32.971h5.495V198.5h-5.495zm10.991 0h5.495V198.5h-5.495zm10.99 0h5.495V198.5h-5.495zm10.99 0h5.495V198.5h-5.495zm10.991 0h5.495V198.5h-5.495zm10.99 0h5.495V198.5h-5.495z"
          fill="#F5F5F5"
        />
        <path
          d="M266.803 143.548h5.495v21.981h-5.495zm10.99 0h5.495v21.981h-5.495zm10.99 0h5.495v21.981h-5.495zm10.991 0h5.495v21.981h-5.495zm10.99 0h5.495v21.981h-5.495zm10.99 0h5.495v21.981h-5.495zm-54.951 32.971h5.495V198.5h-5.495zm10.99 0h5.495V198.5h-5.495zm10.99 0h5.495V198.5h-5.495zm10.991 0h5.495V198.5h-5.495zm10.99 0h5.495V198.5h-5.495zm10.99 0h5.495V198.5h-5.495z"
          fill="#464655"
        />
        <path
          d="M425.386 164.67c-4.401 10.223-16.925 11.426-9.985 37.486l3.554-3.608s3.723 9.823 12.693 16.438c0 0 11-9.222 11.847-23.854l4.569 2.005c0-.003-2.2-26.663-22.678-28.467z"
          fill="#245DBD"
        />
        <path
          d="M441.633 185.618c-.352 0-.701-.163-.966-.482-4.31-5.199-9.8-6.866-9.853-6.884-.725-.213-1.169-1.084-.989-1.942.18-.852.915-1.385 1.636-1.172.254.075 6.27 1.886 11.136 7.755.524.627.515 1.647-.015 2.268a1.244 1.244 0 01-.949.457zm-3.102 12.128c-.405 0-.807-.213-1.074-.627-3.197-4.924-6.823-7.605-6.86-7.63-.638-.463-.833-1.453-.441-2.211.394-.751 1.227-.983 1.865-.52.167.119 4.088 3.026 7.584 8.407.455.702.344 1.71-.248 2.249a1.218 1.218 0 01-.826.332zm-7.105 13.499a1.05 1.05 0 01-.233-.025c-.735-.15-1.233-.978-1.106-1.848.534-3.671.087-9.691-.646-12.798-.201-.852.22-1.736.939-1.98.727-.232 1.468.263 1.668 1.116.828 3.508 1.312 10.055.708 14.201-.112.783-.685 1.334-1.33 1.334zm-9.523-14.809c-.386 0-.769-.194-1.036-.57-.481-.676-.409-1.692.161-2.261.034-.031 3.49-3.54 4.393-8.394.161-.864.878-1.409 1.613-1.221.729.188 1.193 1.046 1.032 1.911-1.106 5.939-5.122 9.992-5.294 10.161a1.242 1.242 0 01-.869.374zm-3.76-10.261c-.455 0-.899-.269-1.155-.765-.392-.751-.193-1.741.445-2.205.051-.038 4.961-3.652 6.484-7.598.309-.814 1.119-1.172 1.795-.796.68.37.979 1.322.669 2.124-1.869 4.842-7.302 8.833-7.531 9.002a1.19 1.19 0 01-.707.238z"
          fill="#FF4B55"
        />
        <path
          d="M82.285 168.11c-3.679 8.548-14.152 9.555-8.35 31.345l2.972-3.017s3.113 8.213 10.614 13.745c0 0 9.198-7.711 9.906-19.947l3.821 1.676c0 .001-1.839-22.292-18.963-23.802z"
          fill="#245DBD"
        />
        <path
          d="M95.871 185.628c-.295 0-.586-.136-.807-.404-3.604-4.348-8.194-5.741-8.239-5.757-.606-.178-.978-.906-.826-1.624.15-.713.765-1.158 1.368-.98.212.063 5.242 1.577 9.311 6.485.438.524.431 1.377-.013 1.896a1.04 1.04 0 01-.794.384zm-2.594 10.14c-.339 0-.674-.178-.898-.524-2.674-4.117-5.705-6.359-5.736-6.38-.533-.387-.696-1.215-.37-1.849.33-.629 1.026-.822 1.559-.435.139.099 3.418 2.53 6.342 7.03.381.587.288 1.43-.207 1.88-.206.189-.45.278-.69.278zm-5.942 11.289a.866.866 0 01-.194-.021c-.615-.126-1.031-.818-.925-1.545.447-3.07.073-8.104-.54-10.701-.168-.713.183-1.451.785-1.655.608-.194 1.227.22 1.395.932.692 2.933 1.097 8.407.593 11.875-.095.653-.575 1.115-1.114 1.115zm-7.963-12.383c-.323 0-.643-.162-.867-.477-.403-.566-.343-1.415.135-1.891.029-.026 2.919-2.959 3.672-7.019.135-.723.734-1.178 1.348-1.022.61.157.998.875.863 1.598-.925 4.966-4.284 8.355-4.427 8.497a1.026 1.026 0 01-.724.314zm-3.144-8.581c-.381 0-.751-.225-.967-.639-.328-.629-.161-1.457.372-1.844.042-.031 4.148-3.054 5.422-6.354.258-.681.936-.98 1.502-.665.568.309.818 1.106.559 1.775-1.564 4.049-6.105 7.385-6.297 7.527a.988.988 0 01-.591.2z"
          fill="#FF4B55"
        />
      </g>
    </svg>
  );
}

export default SvgSz;
