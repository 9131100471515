import * as React from "react";

function SvgLs(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M0 223.908h512v102.988a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.896V223.908z"
          fill="#73AF00"
        />
        <path
          d="M8.828.276h494.345a8.829 8.829 0 018.828 8.828v102.988H0V9.103A8.828 8.828 0 018.828.276z"
          fill="#41479B"
        />
        <path fill="#F5F5F5" d="M0 112.088h512V223.9H0z" />
        <path
          d="M286.477 187.913l-2.052 1.045c-.279.142-.58.265-.865.403l-18.127-22.898c3.726-8.099 5.934-19.153 5.934-24.178 0-8.257-5.418-15.136-12.547-16.591a3.068 3.068 0 00-2.805-1.831 3.068 3.068 0 00-2.805 1.831c-7.128 1.455-12.547 8.335-12.547 16.591 0 5.025 2.209 16.078 5.934 24.178l-18.126 22.898c-.282-.137-.583-.259-.861-.401l-2.052-1.045-7.658 15.054 2.052 1.044c10.451 5.316 22.921 8.126 36.063 8.126 13.143 0 25.617-2.812 36.07-8.127l2.052-1.044-7.66-15.055zm-18.181-45.628c0 3.735-1.387 11.586-3.957 18.783a55.305 55.305 0 01-1.042 2.699l-2.677-3.382v-11.959h4.606l-6.141-6.141v-13.363c5.288 1.54 9.211 6.938 9.211 13.363zm-20.606 18.782c-2.57-7.197-3.957-15.048-3.957-18.783 0-6.424 3.923-11.822 9.212-13.363v13.363l-6.141 6.141h4.606v11.959l-2.677 3.382a53.908 53.908 0 01-1.043-2.699zm-20.648 34.084l.627 2.579-2.251.614 1.624-3.193zm-2.679 5.869l3.851-1.05.707 2.91a65.819 65.819 0 01-4.558-1.86zm5.839-5.867a61.565 61.565 0 005.449 1.849l.554 1.698-4.849 1.196-1.154-4.743zm2.164 8.901l-.467-1.919 5.022-1.239 1.557 4.771a72.81 72.81 0 01-6.112-1.613zm6.816-6.127c1.927.449 3.889.836 5.892 1.129l.834 1.492-5.33 1.661-1.396-4.282zm2.742 8.405l-.631-1.933 5.763-1.796 2.612 4.671a82.299 82.299 0 01-7.744-.942zm6.923-6.823c2.366.224 4.759.348 7.167.348.513 0 1.023-.028 1.535-.039l.641.983-7.084 2.748-2.259-4.04zm7.167 8.023c-.913 0-1.814-.039-2.719-.067l-1.055-1.887 7.235-2.806 2.933 4.497a85.946 85.946 0 01-6.394.263zm5.098-7.869a74.54 74.54 0 007.199-.831l.691.883-5.608 3.446-2.282-3.498zm4.748 7.28l-1.208-1.853 5.778-3.55 3.212 4.107a78.92 78.92 0 01-7.782 1.296zm5.846-8.754a67.251 67.251 0 005.878-1.536l.715.926-4.077 3.827-2.516-3.217zm5.22 6.673l-1.28-1.636 4.067-3.817 2.835 3.671c-1.833.66-3.7 1.268-5.622 1.782zm3.726-9.26a57.885 57.885 0 003.786-1.54l.6 1.181-1.962 3.5-2.424-3.141zm4.886 6.327l-.931-1.206 1.688-3.011 1.628 3.2c-.779.36-1.585.685-2.385 1.017z"
          fill="#464655"
        />
      </g>
    </svg>
  );
}

export default SvgLs;
