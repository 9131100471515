import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next'
import {DragDropContext} from 'react-beautiful-dnd';
import {serialize} from 'object-to-formdata';

import './style.less';
import Buttons from '../../../components/Buttons';
import ChooseOnePhoto from '../../../components/ChooseOnePhoto';

import ChooseImages from '../../../components/ChooseImages';
import getAreasNamesFromNums from '../../../functions/getAreasNamesFromNums';
import ProgressBarComponent from '../../../../../../../../../components/ProgressBarComponent';
import isAreaNotUnique from '../../../functions/isAreaNotUnique';
import {movePhoto, reorder} from '../../../functions/beautifulDndFunctions';
import getPropertyAreasCharacteristics from '../../../functions/getPropertyAreasCharacteristics';
import getPropertyAreasToSend from '../../../functions/getPropertyAreasToSend';
import {updatePropertyActions} from "../../../../../../../../../redux/actions/updatePropertyActions";

const pageLabel = 'main_page.my_ads.create_ad.house_details.photographs';

const getInitialFeaturedPhoto = (property) => (
  property.photos?.['featured_0'] ?
    {
      id: property.photos['featured_0'][0]?.original + '_featured',
      file: property.photos['featured_0'][0]?.original,
      src: property.photos['featured_0'][0]?.sm
    } : {});

const getInitialAreaPhotos = (property) => {
  let propertyAreasFinal = {};

  propertyAreasFinal.toBeDefined = [];

  let commonAreasNames = getAreasNamesFromNums(property);
  commonAreasNames.forEach(area => propertyAreasFinal[area] = []);

  // set the default photos
  Object.keys(propertyAreasFinal).map(area => {
    let areaName = area.split('_').length === 1 && area !== 'toBeDefined' ? `${area}_0` : area;
    propertyAreasFinal[areaName] = property.photos?.[areaName] ? Object.keys(property.photos[areaName]).map((i) => ({
      id: property.photos[areaName][i].original,
      file: property.photos[areaName][i].original,
      src: property.photos[areaName][i].sm
    })) : [];
  });

  return propertyAreasFinal;
};

const PhotographsHeader = ({}) => {

  const {t} = useTranslation();

  return (
    <div>
      <h4 className={'title'} style={{width: '100%'}}>{t(pageLabel + '.add.title')}</h4>
      <div className={'textbox'}>
        <p>{t(pageLabel + '.add.text1')}</p>
      </div>

      <div className={'textbox'}>
        <p>{t(pageLabel + '.add.tips.text1')}</p>
        <p>{t(pageLabel + '.add.tips.text2')}</p>
        <p>{t(pageLabel + '.add.tips.text3')}</p>
        <p>{t(pageLabel + '.add.tips.text4')}</p>
        <p>{t(pageLabel + '.add.tips.text5')}</p>
      </div>

      <div className={'textbox'}>
        <p>{t(pageLabel + '.add.notes')}</p>
      </div>
    </div>
  )
};

const Photographs = ({updateProperty, currentPageLevel, property, changePage, defineUpdateFunc}) => {

  const {t} = useTranslation();

  const [featuredPhoto, setFeaturedPhoto] = useState(getInitialFeaturedPhoto(property));
  const [propertyAreasPhotos, setPropertyAreasPhotos] = useState(getInitialAreaPhotos(property));

  useEffect(()=> {
    setFeaturedPhoto(getInitialFeaturedPhoto(property));
    setPropertyAreasPhotos(getInitialAreaPhotos(property));
    if (featuredPhoto && Object.values(featuredPhoto).length <= 0 && property.photos){
      const newPhoto = Object.entries(property.photos).slice(0,1);
      if (newPhoto[0]?.[0])
      setAsFeaturedPhoto({source: newPhoto[0]?.[0], index: 0});
    }
  }, [property.photos]);

  const [submitted, setSubmitted] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const propertyAreasCharacteristics = useMemo(() =>{return getPropertyAreasCharacteristics(t)},[localStorage.getItem('i18nextLng').split('-')[0]]);

  const deleteImages = (area, image) => {
    if(!image) {
      for(let i = propertyAreasPhotos[area].length - 1; i >= 0; i--) {
        userActionsHandler({type: 'delete', field: `${area}[${i}]`});
        if((propertyAreasPhotos[area][i].id + '_featured') === featuredPhoto.id) {
          setFeaturedPhoto({});
        }
      }
      setPropertyAreasPhotos(prevState => ({...prevState, [area]: []}));
    } else {
      const newFiles = [...propertyAreasPhotos[area]];
      const img_idx = newFiles.indexOf(image);
      newFiles.splice(img_idx, 1);
      userActionsHandler({type: 'delete', field: `${area}[${img_idx}]`});
      setPropertyAreasPhotos(prevState => ({...prevState, [area]: newFiles}));
      if((image.id + '_featured') === featuredPhoto.id) {
        setFeaturedPhoto({});
      }
    }
  };

  const uploadImages = (area, image) => {
    setPropertyAreasPhotos(prevState => ({...prevState, [area]: [...prevState[area], image]}));
  };

  const setAsFeaturedPhoto = ({source, index}) => {
    setFeaturedPhoto(propertyAreasPhotos[source]?.[index]);
    userActionsHandler({
      type: 'move',
      field: [`${source}[${index}]`, 'featured_0[0]']
    });
  };

  //sent from toBeDefined to rooms
  const sendToOtherArea = ({source, index, roomNumber, area}) => {
    if (roomNumber) {
      setPropertyAreasPhotos(prevState => {
        const sourceClone = [...prevState[source]];
        sourceClone.splice(index, 1);
        return {...prevState, [source]: sourceClone}
      });
    } else {
      const photoMoved = movePhoto(propertyAreasPhotos,setPropertyAreasPhotos, {droppableId: source, index}, {
        droppableId: area,
        index: propertyAreasPhotos[area].length
      });
      if (!photoMoved) return;
    }
    let photo_index = userActionsHistory.filter(action => action.field[1]?.startsWith(`room_${roomNumber}`)).length;
    userActionsHandler({
      type: 'move',
      field: [`${source}[${index}]`, `${roomNumber ? `room_${roomNumber}[${photo_index}]` : `${area}[${propertyAreasPhotos[area].length}]`}`]
    })
  };

  //Request error/errors in image dropzones
  const [errorsReq, setErrorsReq] = useState(() => {

    let errors = {};
    Object.keys(propertyAreasPhotos).map(area => errors[area] = true);
    if (propertyAreasPhotos.toBeDefined.length === 0) delete errors.toBeDefined;
    return errors;
  });

  const setErrorArea = async (area, value) => {
    if (!value) {
      setErrorsReq(prevState => {
        delete prevState[area];
        return prevState;
      });
    } else setErrorsReq({...errorsReq, [area]: true});
  };

  //user actions history
  const [userActionsHistory, setUserActionHistory] = useState([]);

  const userActionsHandler = (value) => setUserActionHistory(prevState => [...prevState, value]);

  function onDragEnd(result) {
    const {source, destination} = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    if (dInd === 'featured_0') {
      setFeaturedPhoto({
        ...propertyAreasPhotos[sInd][source.index]
        , id: propertyAreasPhotos[sInd][source.index].id + '_featured'
      });
    } else if (sInd === 'featured_0') return;
    else if (sInd === dInd) {
      if (source.index === destination.index) return;
      const items = reorder(propertyAreasPhotos[sInd], source.index, destination.index);
      const newState = {...propertyAreasPhotos};
      newState[sInd] = items;
      setPropertyAreasPhotos(newState);
    } else {
      if (dInd === 'toBeDefined') return;
      const photoMoved = movePhoto(propertyAreasPhotos,setPropertyAreasPhotos, source, destination);
      if (!photoMoved) return;
    }
    userActionsHandler({
      type: sInd === dInd?'swap':'move',
      field: [`${source.droppableId}[${source.index}]`, `${destination.droppableId}[${destination.index}]`]
    })
  }

  const onSubmit = async (values) => {
    setSubmitted(true);

    if (Object.keys(errorsReq).length === 0 && Object.keys(featuredPhoto).length !== 0) {
      let sendingObject = {actions: {}};

      Object.keys(propertyAreasPhotos).map(area => {
        let areaPhotosObject = {};
        propertyAreasPhotos[area].map((areaPhoto, index) => areaPhotosObject[index] = areaPhoto.file);
        sendingObject[area] = areaPhotosObject;
      });

      sendingObject.featured = featuredPhoto.file;
      sendingObject.actions = JSON.stringify(userActionsHistory);

      let formData = serialize(sendingObject, {indices: true});

      if(values?.saveAndExit) formData.saveAndExit = true;

      await updateProperty(formData, currentPageLevel, 1, setLoadingPercentage);

      setUserActionHistory([]);
      setLoadingPercentage(0);
    } else {
      //Focus error
      let errors;
      if (Object.keys(featuredPhoto).length === 0) {
        errors = {"featured_0": "Required"};
        document.querySelector(`#featured_0`).focus();
      } else {
        errors = errorsReq;
        const errorsKeys = Object.keys(errorsReq);
        const chooseImagesDivs = [...document.querySelectorAll('.ChooseImages')];
        chooseImagesDivs.find(div => errorsKeys.indexOf(div.id) > -1).focus();
      }
      return errors;
    }
  };

  useEffect(() => defineUpdateFunc(async () => {
    if(userActionsHistory.length === 0) return;
    await onSubmit();
    if(Object.keys(errorsReq).length > 0) return false;
  }),[errorsReq, featuredPhoto, propertyAreasPhotos, userActionsHistory]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={'Photographs section_content'}>
        <div className={'scrollable'}>
          <div className={'Photographs__header'}>
            <PhotographsHeader t={t}/>
            <div className={'Photographs__header-choose'}>
              <ChooseOnePhoto id={'featured_0'} image={featuredPhoto} type={'blue'}
                              displayError={submitted && Object.keys(featuredPhoto).length === 0}
                              errorMessage={t('dropzone.featured_error')}
                              title={t(pageLabel + '.areas.main.title').toUpperCase()}
                              userActionsHandler={userActionsHandler}
              />
            </div>
          </div>


          <div className="Photographs__content">
            {/*to be defined photos*/}
            {propertyAreasPhotos.toBeDefined.length > 0 &&
             <ChooseImages key={'toBeDefined'}
                           images={propertyAreasPhotos.toBeDefined}
                           deleteImages={(value) => deleteImages('toBeDefined', value)}
                           setErrorArea={(value) => setErrorArea('toBeDefined', value)}
                           fieldName={'toBeDefined'}
                           areaProps={{
                             ...propertyAreasCharacteristics['toBeDefined'],
                             isAreaNotUnique: false,
                             maxPhotos: 0,
                             sendToOtherArea: (value) => sendToOtherArea({source: 'toBeDefined', ...value}),
                             propertyAreasToSend: getPropertyAreasToSend(propertyAreasCharacteristics,property),
                             roomsNumber: Object.keys(property.rooms).length
                           }}
                           displayError={submitted} userActionsHandler={userActionsHandler}/>}
            {Object.keys(propertyAreasPhotos).map(area => area !== 'toBeDefined' ?
              (<ChooseImages key={area} images={propertyAreasPhotos[area]}
                             uploadImages={(value) => uploadImages(area, value)}
                             deleteImages={(value) => deleteImages(area, value)}
                             setErrorArea={(value) => setErrorArea(area, value)}
                             fieldName={area}
                             areaProps={{
                               ...propertyAreasCharacteristics[area.split('_')[0]],
                               divNum: area.split('_')[1],
                               sendToOtherArea: (value) => sendToOtherArea({source: area, ...value}),
                               sendFeatured: (value) => setAsFeaturedPhoto({source: area, ...value}),
                               propertyAreasToSend: getPropertyAreasToSend(propertyAreasCharacteristics,property,area),
                               isAreaNotUnique: isAreaNotUnique(area, property),
                               roomsNumber: Object.keys(property.rooms).length
                             }}
                             displayError={submitted} hasPlaceholder={true} userActionsHandler={userActionsHandler}/>)
              : null)}
          </div>
        </div>

        {loadingPercentage > 0 && <ProgressBarComponent percentage={loadingPercentage}/>}
        <Buttons saveAndExit={v=>onSubmit({...v, saveAndExit: true})} disableUpdate={false} updateProperty={onSubmit} changePage={changePage}/>
      </div>
    </DragDropContext>
  );
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(Photographs);
