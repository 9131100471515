import React, {useMemo} from 'react';

import './style.less'
import Buttons from '../../../../../components/Buttons';
import PropertyResume from '../../../../../../../../../../../components/ResumeItem/PropertyResume';
import {useAuth} from "../../../../../../../../../../../services/authServices";

const pageLabel = 'main_page.my_ads.create_ad.publish.resume1';

const Resume1 = ({currentLevel, updateProperty, t, property, changePage}) => {
    const {user} = useAuth();

    return (
        <div className={'Resume1'}>
            <div className={'scrollable'}>
                <div className={'Resume__header'}>
                    <h4>{`${user ? user.name : ""} ${t(pageLabel + '.label1')} ${property.internal_name}.`}</h4>
                    <h4>{t(pageLabel + '.label2')}</h4>
                </div>

                <PropertyResume property={property} changePage={changePage} type={'Resume'}/>
            </div>

            <Buttons updateProperty={() => updateProperty({}, currentLevel)} clickAdvance={{}} changePage={changePage}/>
        </div>
    )
}


export default Resume1;
