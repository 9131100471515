import React, { useRef, useEffect } from 'react';
import './style.less';

import { createPortal } from 'react-dom';
import useMountTransition from '../../hooks/useMountTransition'

function createPortalRoot() {
    const drawerRoot = document.createElement('div');
    drawerRoot.setAttribute('id', 'drawer-root');

    return drawerRoot;
}

const Drawer = ({
    isOpen,
    children,
    onClose,
    position = "bottom",
    removeWhenClosed = true,
}) => {
    const isTransitioning = useMountTransition(isOpen, 300);
    const bodyRef = useRef(document.querySelector('body'));
    const portalRootRef = useRef(document.getElementById('drawer-root') || createPortalRoot());

    // Append portal root on mount
    useEffect(() => {
        bodyRef.current.appendChild(portalRootRef.current);
        const portal = portalRootRef.current;
        const bodyEl = bodyRef.current;

        return () => {
            // Clean up the portal when drawer component unmounts
            portal.remove();
            // Ensure scroll overflow is removed
            bodyEl.style.overflow = '';
        }
    }, []);

    useEffect(() => {
        const updatePageScroll = () => {
            if (isOpen) {
                bodyRef.current.style.overflow = 'hidden';
            } else {
                bodyRef.current.style.overflow = '';
            }
        };

        updatePageScroll();
    }, [isOpen]);

    if (!isTransitioning && removeWhenClosed && !isOpen) {
        return null;
    }

    return createPortal(
        <div
            aria-hidden={isOpen ? "false" : "true"}
            className={`drawer-container ${isOpen ? "open" : null} ${isTransitioning ? "in" : null}`}
        >
            <div
                className={`drawer ${position}`}
                role='dialog'
                open={isOpen}
            >
                <div className='drawer-handler' onClick={onClose} />
                {children}
                <button className="btn btn-primary"
                    onClick={onClose}
                >
                    OK
                </button>
            </div>
            <div className="backdrop" onClick={onClose} />
        </div>,
        portalRootRef.current,
    );
}

export default Drawer;
