import React, { useEffect, useState, useMemo } from 'react';
import "./style.less";

import { useTranslation, Trans } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { dashboardServices } from "../../../../../../services/index.js";
import offerSentImage from "../../../../../../components/ActionDialog/images/2offers_sent.svg";
import NavBarGoBack from '../../../../../../components/NavBarGoBack';
import Loading from '../../../../../../components/Loading';
import { calculateTenantAge, calculateTenantAgeGroup } from "../../../../../../utils/tenantAge";
import ClampedText from '../../../../../../components/ClampedText';
import SendArrowIcon from '../../../../../../components/svg/SendArrow';
import ArrowUpwardIcon from "../../../../../../components/svg/Navigation/ArrowUpward";
import { usePopupDialog } from "../../../../../../components/PopupDialog";
import OfferPopOver from '../../components/PotentialTenantsList/components/OfferPopOver';
import InfoCircleIcon from "../../../../../../components/svg/InfoCircle";

const TenantDetails = () => {

    const transPath = "main_page.radar.tenantDetails."
    const { t, i18n } = useTranslation();

    const [tenantDetails, setTenantDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isOfferDialogOpen, setIsOfferDialogOpen] = useState(false);
    const [dialogStart, setDialogStart] = useState(false);
    const { user_id } = useParams();
    const history = useHistory();

    const popupDialog = usePopupDialog();

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                let _tenant = await dashboardServices.getPotentialTenantsDetails(user_id);
                setTenantDetails(_tenant);
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                history.push("/radar/potential")
            }
        })();
    }, [user_id])

    const handleMakeOffer = () => {
        setIsOfferDialogOpen(!isOfferDialogOpen);
    };

    const handleArchive = async (tenant) => {
        popupDialog.openPopupDialog({
            title: t('main_page.radar.offers_details.archive_potential_tenant'),
            message: t('main_page.radar.offers_details.archive_potential_tenant_message'),
            request: async () => {
                await dashboardServices.archivePotentialTenant(tenant.id);
                history.push("/radar");
            },
            request_done: async () => {
                let temp = [...currentTenants.filter((t) => { return t.id !== tenant.id })];
                setCurrentTenants(temp);
                setPotentialTenants(temp);
                if (archivedTenants && archivedTenants.length > 0) {
                    setArchivedTenants([...archivedTenants, tenant]);
                } else {
                    setArchivedTenants([tenant]);
                }
                history.push("/radar");
            }
        });


    };

    const handleUnarchive = async (tenant) => {
        popupDialog.openPopupDialog({
            title: t('main_page.radar.offers_details.unarchive_potential_tenant'),
            message: t('main_page.radar.offers_details.unarchive_potential_tenant_message'),
            request: async () => {
                await dashboardServices.unarchivePotentialTenant(tenant.id);
            },
            request_done: async () => {
                let temp = [...currentTenants.filter((t) => { return t.id !== tenant.id })];
                setArchivedTenants(temp);
                setPotentialTenants([...potentialTenants, tenant]);
                setCurrentTenants(temp);
            }
        });
    };

    const sendOffer = (offers, tenant) => {
        popupDialog.openPopupDialog({
            title: t("main_page.radar.offers_details.sent_offer_title"),
            message: t("main_page.radar.offers_details.sent_offer_message", { num: tenant.offers }),
            customSuccessDialog: {
                image: offerSentImage,
                message: t("main_page.radar.offers_details.offer_sent_success", { count: offers.length })
            },
            request: async () => {
                await dashboardServices.sendOffers(offers, tenant.id).then((e) => {
                }).catch(e => console.log(e));
            }/* ,
            request_done: async () => {
                let tenants = await dashboardServices.getPotentialTenants(getFilters(filters));
                setPotentialTenants(tenants.potentialTenants);
                setCurrentTenants(tenants.potentialTenants);
                setDialogStart(false);
                setTenantDetails(false);
                setIsInfoDialogOpen(!infoPopOver);
            } */
        });
    }

    const totalStay = useMemo(() => {
        if (!tenantDetails?.preferences) return;

        const moveIn = new Date(tenantDetails.preferences.moveIn);
        const moveOut = new Date(tenantDetails.preferences.moveOut);

        const yearDiff = moveOut.getUTCFullYear() - moveIn.getUTCFullYear();
        const monthDiff = moveOut.getUTCMonth() - moveIn.getUTCMonth();

        const totalMonths = yearDiff * 12 + monthDiff + 1;

        return totalMonths > 1 ? `${totalMonths} ${t('months')}` : `${totalMonths} ${t('month')}`;
    }, [tenantDetails?.preferences?.moveIn, tenantDetails?.preferences?.moveOut]);

    return (
        <main className="tenant-details">
            <div className="tenant-details__header">
                <NavBarGoBack />
            </div>
            <div className="tenant-details__container">
                {!tenantDetails || isLoading ? <Loading /> : <>
                    <div className="tenant-details__container__header">
                        <h3>
                            {tenantDetails && !isLoading ? (
                                <Trans i18nKey={transPath + "profile"} values={{ name: tenantDetails.name }} />
                            ) : null}
                        </h3>
                    </div>

                    <section className="tenant-details__container__main-content">
                        <div className="tenant-details__tenant-info card">
                            {tenantDetails?.photo && (
                                <div
                                    className="avatar-container"
                                    style={{ backgroundImage: `url(${tenantDetails?.photo})` }}>
                                    <div className={"RoundedCircleFlag"}>
                                        <div
                                            className={"CountryFlagCircle"}
                                            style={{
                                                backgroundImage: `url(https://github.com/madebybowtie/FlagKit/raw/master/Assets/PNG/${tenantDetails?.personalDetails.nationality.toUpperCase()}@2x.png?raw=true)`
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="tenant-info">
                                <div className="info">
                                    {/* <h5>{tenantDetails.name?.trim().split(" ")[0] || tenantDetails.user.name?.trim().split(" ")[0]}</h5> */}
                                    <h2>{tenantDetails?.name}</h2>
                                    <p>{`${t("gender." + tenantDetails?.personalDetails?.gender)} · ${tenantDetails?.personalDetails?.age
                                        ? calculateTenantAgeGroup(tenantDetails?.personalDetails?.age, t)
                                        : tenantDetails?.personalDetails?.birthdate
                                            ? calculateTenantAge(tenantDetails?.personalDetails?.birthdate, t)
                                            : ""
                                        }${(tenantDetails?.personalDetails?.occupation && tenantDetails?.personalDetails?.occupation !== "other")
                                            ? ` · ${t(transPath + tenantDetails?.personalDetails?.occupation)}` : ""} 
                                    `}</p>
                                </div>
                            </div>
                        </div>

                        <div className="tenant-details__tenant-actions card">
                            <div className={"tenant-actions__message" + (!tenantDetails?.inactive ? "" : " warning")}>
                                {tenantDetails?.inactive && <div className={"warning-icon"}><InfoCircleIcon  /></div>}
                                <span>
                                    <Trans i18nKey={`${transPath}${!tenantDetails?.inactive ? "makeOffer" : "warning"}Message`}
                                        components={<strong />}
                                        values={{ name: tenantDetails?.name }}
                                    />
                                </span>
                            </div>
                            {!tenantDetails?.inactive && <div className="tenant-actions__buttons">
                                {tenantDetails.archived ? <button className="btn btn-tertiary" onClick={() => handleUnarchive(tenantDetails)}>
                                    {t(`${transPath}unarchive`)}
                                </button> : <>
                                    <button className="btn btn-tertiary" onClick={() => handleArchive(tenantDetails)}>
                                        {t(`${transPath}archive`)}
                                    </button>
                                    <button className="btn btn-primary" onClick={() => handleMakeOffer(tenantDetails)}>
                                        <SendArrowIcon />
                                        {t(`${transPath}makeOffer`)}
                                    </button>
                                </>}
                                {/* <button className="btn btn-secondary">{t(`${transPath}proposeVideoCall`)}</button> */}
                            </div>}
                        </div>
                    </section>

                    <div className="tenant-details__wrapper">

                        <section className="tenant-details__content">
                            {(tenantDetails?.personalDetails.extraInfo ||
                                tenantDetails?.tenantVideo) && <div className='tenant-details__card'>
                                    <h3><Trans i18nKey={`${transPath}meet`} values={{ name: tenantDetails?.name }} /></h3>
                                    <div className="tenant-details__meet card">
                                        {tenantDetails?.personalDetails.extraInfo && <div className="tenant-details__about">
                                            <h5>{t(`${transPath}about`)}</h5>
                                            <ClampedText text={tenantDetails?.personalDetails?.extraInfo} maxLength={500} />
                                        </div>}
                                        {tenantDetails?.tenantVideo && <div className="tenant-details__video">
                                            <h5>{t(`${transPath}videoBio`)}</h5>
                                            <video controls>
                                                <source src={tenantDetails?.tenantVideo} type="video/mp4" />
                                                <p>Your browser doesn't support HTML video.</p>
                                            </video>
                                        </div>}
                                    </div>
                                </div>}

                            {!(tenantDetails?.personalDetails.extraInfo || tenantDetails?.tenantVideo) && <div className='tenant-details__card'>
                                <h3>{t(`${transPath}preferredStay`)}</h3>
                                <div className="tenant-details__stay card">
                                    <div className="stay__details">
                                        <p>{t(`${transPath}moveIn`)}</p>
                                        <span>{new Date(tenantDetails?.preferences.moveIn).toLocaleDateString(i18n.language)}</span>
                                    </div>

                                    <div className="stay__details">
                                        <p>{t(`${transPath}moveOut`)}</p>
                                        <span>{new Date(tenantDetails?.preferences.moveOut).toLocaleDateString(i18n.language)}</span>
                                    </div>

                                    <div className="stay__details">
                                        <p>{t(`${transPath}totalStay`)}</p>
                                        <span>{tenantDetails?.preferences && totalStay}</span>
                                    </div>

                                    <div className="separator" />

                                    <div className="stay__details">
                                        <p>{t(`${transPath}lookingFor`)}</p>
                                        <span>{t(`${transPath}${tenantDetails?.preferences.accommodation}`)}</span>
                                    </div>

                                    <div className="stay__details">
                                        <p>{t(`${transPath}numberRooms`)}</p>
                                        <span>{tenantDetails?.preferences.numRooms}</span>
                                    </div>

                                    <div className="stay__details">
                                        <p>{t(`${transPath}area`)}</p>
                                        <span>{tenantDetails?.preferences.city}</span>
                                    </div>

                                    <div className="separator" />

                                    <div className="stay__details budget">
                                        <p>{t(`${transPath}budget`)}</p>
                                        <span>{`€${tenantDetails?.preferences.budgetMin} - €${tenantDetails?.preferences.budgetMax}`}</span>
                                    </div>
                                </div>
                            </div>}
                        </section>

                        <section className="tenant-details__content">

                            {(tenantDetails?.personalDetails.extraInfo || tenantDetails?.tenantVideo) && <div className='tenant-details__card'>
                                <h3>{t(`${transPath}preferredStay`)}</h3>
                                <div className="tenant-details__stay card">
                                    <div className="stay__details">
                                        <p>{t(`${transPath}moveIn`)}</p>
                                        <span>{new Date(tenantDetails?.preferences.moveIn).toLocaleDateString(i18n.language)}</span>
                                    </div>

                                    <div className="stay__details">
                                        <p>{t(`${transPath}moveOut`)}</p>
                                        <span>{new Date(tenantDetails?.preferences.moveOut).toLocaleDateString(i18n.language)}</span>
                                    </div>

                                    <div className="stay__details">
                                        <p>{t(`${transPath}totalStay`)}</p>
                                        <span>{tenantDetails?.preferences.stayLength}</span>
                                    </div>

                                    <div className="separator" />

                                    <div className="stay__details">
                                        <p>{t(`${transPath}lookingFor`)}</p>
                                        <span>{t(`${transPath}${tenantDetails?.preferences.accommodation}`)}</span>
                                    </div>

                                    <div className="stay__details">
                                        <p>{t(`${transPath}numberRooms`)}</p>
                                        <span>{tenantDetails?.preferences.numRooms}</span>
                                    </div>

                                    <div className="stay__details">
                                        <p>{t(`${transPath}area`)}</p>
                                        <span>{tenantDetails?.preferences.city}</span>
                                    </div>

                                    <div className="separator" />

                                    <div className="stay__details budget">
                                        <p>{t(`${transPath}budget`)}</p>
                                        <span>{`€${tenantDetails?.preferences.budgetMin} - €${tenantDetails?.preferences.budgetMax}`}</span>
                                    </div>
                                </div>
                            </div>}

                            <div className='tenant-details__card'>
                                <h3>{t(`${transPath}statistics`)}</h3>
                                <div className="tenant-details__statistics card">

                                    <div className="statistic__details">
                                        <p>{t(`${transPath}bookingsMade`)}</p>
                                        <span>{tenantDetails?.reservations}</span>
                                    </div>

                                    <div className="statistic__details">
                                        <p>{t(`${transPath}visitsMade`)}</p>
                                        <span>{tenantDetails?.visits}</span>
                                    </div>

                                    <div className="separator" />

                                    <div className="statistic__details registration">
                                        <p>{t(`${transPath}registrationDate`)}</p>
                                        <span>{new Date(tenantDetails?.registrationDate).toLocaleDateString(i18n.language)}</span>
                                    </div>

                                </div>
                            </div>

                        </section>

                    </div>
                </>}

                {!isLoading && tenantDetails && <div className="tenant-details__footer">
                    <button type="button" className="btn btn-ghost" onClick={() => {
                        document.getElementById("horizontalbar_content").scrollIntoView({ behavior: "smooth" })
                    }}>
                        <ArrowUpwardIcon />
                        {t("backToTop")}
                    </button>
                </div>}

            </div>
            {(isOfferDialogOpen && tenantDetails) && <OfferPopOver setStart={setDialogStart}
                setIsInfoDialogOpen={setIsOfferDialogOpen}
                handleClick={handleMakeOffer}
                tenant={tenantDetails}
                start={dialogStart}
                /* userProperties={userProperties} */
                sendOffer={(offer) => sendOffer(offer, tenantDetails)}
            />}
            {((isOfferDialogOpen || dialogStart) && tenantDetails) && <div className={"offer-dialog__opacity"} />}
        </main>
    );
}

export default TenantDetails;
