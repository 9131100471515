import React, {useState} from 'react';

import CustomDatePicker from '../../../../../../../../../../../../../components/CustomDatePicker';

import './style.less'
import addCircleIcon from './images/addCircleIcon.svg';
import {ReactSelect} from '../../../../../../../../../../../../../components/ReactSelect';
import {useTranslation} from 'react-i18next';

const UnavailabilityPeriodTable = ({
                                     value,
                                     errors,
                                     control,
                                     pageLabel,
                                     clearErrors,
                                     getValues,
                                     setValue,
                                     register,
                                     setError,
                                     setForcedSave,
                                     integrationKey
                                   }) => {

  const{t}=useTranslation();

  const options = [
    {value: 'Busy by booking another platform', label: t(pageLabel+'.unavailability.reason.busy_by_another_platform')},
    {value: 'Occupied by direct booking', label: t(pageLabel+'.unavailability.reason.busy_by_direct_booking')},
    {value: 'Works and maintenance', label: t(pageLabel+'.unavailability.reason.maintenance')},
    {value: 'Other', label: t(pageLabel+'.unavailability.reason.other')}
  ]

  const [unavailabilityAddSuccess, setUnavailabilityAddSuccess] = useState(false);

  //added == 1 means that the element wasn't added yet
  //added == 2 means that the element has already been added

  const [unavailability, setUnavailability] = useState(() => {
    if (!value || value.length === 0) {
      let id = Date.now();
      return [{id, start: '', end: '', reason: '', added: 1}]
    } else {
      return value.map((item, index) => {
        let id = Date.now() + index;

        // setValue(`unavailability[${index}][added]`,true)
        let optionReason = options.find(reason => reason.value === item.reason);
        if(!optionReason) {
          optionReason = {value: item.reason, label: item.reason};
        }
        return {
          id,
          start: item.start,
          end: item.end,
          reason: optionReason,
          added: 2
        }
      })
    }
  })

  const addUnavailability = () => {
    setUnavailability(prevState => {
      let id = Date.now() + prevState.length;
      return [...prevState, {id, start: '', end: '', reason: '', added: 1}]
    })
  }

  const addTheRow = (index) => {
    setUnavailability((prevState) => {
      let unavailabitilyArray = [...prevState]
      let unavailabilityItem = {...unavailabitilyArray[index]}
      unavailabilityItem.added = 2;
      unavailabitilyArray[index] = unavailabilityItem
      return unavailabitilyArray
    })
  }

  const addButtonHandler = async (index) => {
    const unavailabilityVariable = `unavailability[${index}]`

    if (!getValues(unavailabilityVariable + '[start]'))
      setError(unavailabilityVariable + '[start]', {
        type: 'required',
        message: 'start'
      })
    else clearErrors(unavailabilityVariable + '[start]')

    if (!getValues(unavailabilityVariable + '[end]'))
      setError(unavailabilityVariable + '[end]', {
        type: 'required',
        message: 'end'
      })
    else clearErrors(unavailabilityVariable + '[end]')

    if (!getValues(unavailabilityVariable + '[reason]'))
      setError(unavailabilityVariable + '[reason]', {
        type: 'required',
        message: 'reason'
      })
    else clearErrors(unavailabilityVariable + '[reason]')

    if (!errors?.unavailability?.[index]) {
      addTheRow(index)
      setValue(`unavailability[${index}][added]`, 2, {shouldValidate: true})
      setUnavailabilityAddSuccess(true);
      setTimeout(()=>setUnavailabilityAddSuccess(false), 5000);
    }
  }

  const removeTheRow = (index, id) => {
    setForcedSave(true);
    let unavailabilityNewArray = [...unavailability]
    unavailabilityNewArray = unavailabilityNewArray.filter(item => item.id !== id)
    if (unavailabilityNewArray.length === 0) {
      let id = Date.now()
      unavailabilityNewArray = [{id, start: '', end: '', reason: '', added: 1}];
    }
    setUnavailability(unavailabilityNewArray);
  }

  return (
    <div className={'UnavailabilityPeriodTable basic_container'}>
      <div className={'UnavailabilityPeriodTable__selectors'}>

        {unavailability.map((unavailability, index) => (
          <div key={unavailability.id} className={'UnavailabilityPeriodTable__selector'}>
            <div className={'UnavailabilityPeriodTable__selector__container--datepicker'}>
              <p>{t(pageLabel + '.unavailability.start.label')}</p>
              <CustomDatePicker name={`unavailability[${index}][start]`}
                                control={control}
                                selected={typeof unavailability.start === 'string' && unavailability.start ? new Date(unavailability.start) : unavailability.start}
                                disabled={unavailability.added===2 || integrationKey}
                                defaultValue={unavailability.start}
                                minDate={new Date()}
                                dateFormat={'dd/MM/yy'}
                                maxDate={getValues(`unavailability[${index}][end]`)}
                                onChange={() => clearErrors(`unavailability[${index}][start]`)}
                                className={`${errors.unavailability?.[index]?.start ? 'input_error' : null} input_field basic_container`}
              />
            </div>

            <div className={'UnavailabilityPeriodTable__selector__container--datepicker'}>
              <p>{t(pageLabel + '.unavailability.end.label')}</p>
              <CustomDatePicker name={`unavailability[${index}][end]`}
                                control={control}
                                selected={typeof unavailability.end === 'string' && unavailability.end ? new Date(unavailability.end) : unavailability.end }
                                disabled={unavailability.added===2 || integrationKey}
                                defaultValue={unavailability.end}
                                minDate={getValues(`unavailability[${index}][start]`)
                                  ? getValues(`unavailability[${index}][start]`)
                                  : new Date()}
                                dateFormat={'dd/MM/yy'}
                                onChange={() => clearErrors(`unavailability[${index}][end]`)}
                                className={`${errors.unavailability?.[index]?.end ? 'input_error' : null} input_field basic_container`}
              />
            </div>

            <div className={'UnavailabilityPeriodTable__selector__container--reactselect'}>
              <p>{t(pageLabel + '.unavailability.reason.label')}</p>
              <ReactSelect
                options={options}
                disabled={unavailability.added===2 || integrationKey}
                defaultValue={unavailability.reason}
                placeholder={t(pageLabel + '.unavailability.reason.label')}
                name={`unavailability[${index}][reason]`}
                className={`${errors.unavailability?.[index]?.reason ? 'input_error' : null}`}
                control={control}
              />
            </div>

            {/*Simply serves to check if the add button has already been clicked*/}
            <input style={{display: 'none'}} onWheel={(e) => e.target.blur()} type={'number'} name={`unavailability[${index}][added]`}
                   ref={register()} defaultValue={unavailability.added}/>

            {unavailability.added==1 &&
             <button type={'button'} className={'btn btn_blue'}
                     onClick={() => addButtonHandler(index)} disabled={integrationKey}>{t('buttons.add')}</button>}

            {unavailability.added==2 &&
             <button type={'button'} className={'btn btn_grey'}
                     onClick={() => removeTheRow(index, unavailability.id)} disabled={integrationKey}>
               {t('buttons.remove')}
             </button>}

          </div>))}
      </div>
      {errors.unavailability && <p className={'error_message'}>{t(pageLabel + '.unavailability.error')}</p>}
      {unavailabilityAddSuccess && <p className={'success_message'}>{t(pageLabel + '.unavailability.success')}</p>}
      <div className={'UnavailabilityPeriodTable__add-unavailability'} onClick={() => { if (!integrationKey) addUnavailability() }}>
        <img src={addCircleIcon} alt={"circle icon"} />
        <p>{t(pageLabel + '.unavailability.add')}</p>
      </div>
    </div>
  )
}

export default UnavailabilityPeriodTable
