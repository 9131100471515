import React from "react";

const FilterAscending = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_1658_92)">
            <path d="M22 7H13V9H22V7Z" />
            <path d="M22 15H13V17H22V15Z" />
            <path d="M22 11H16V13H22V11Z" />
            <path
                d="M7.5 6.5L2.5 11.5H6.5V17.5H8.5V11.5H12.5L7.5 6.5Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_1658_92">
                <rect width={24} height={24} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default FilterAscending;
