import React, {useMemo, useState} from 'react';
import './style.less'
import { useTranslation } from 'react-i18next';
import {propertyServices} from "../../../../../../../../../services";
import CheckIcon from '../../../../../../../../../components/svg/Check';
import Tooltip from "../../../../../../../../../components/Tooltip";
import InfoCircleIcon from "../../../../../../../../../components/svg/InfoCircle";
import {getPropertyUnavailabilityReason} from "../../../../../../../../../utils/unavailabilityReason";

const PropertyResumePopOver = ({ handleDeselectProperty, offers, handleSelectProperty, property, setPropertySelected, tenant, isSelected }) => {

    const {t} = useTranslation();
    const [prices, setPrices] = useState();
    const [blockedReason, setBlockedReason] = useState();

    const toggleSelectedProperty = () => {
        if (isSelected) {
            handleDeselectProperty(property);
        } else {
            handleSelectProperty(property);
        }
    }

    useMemo(() => {
        if (property) {
            if (property.integration_version) {
                setPrices({
                    min: property.minRent,
                    max: property.maxRent
                })
            } else {
                let rent = propertyServices.getRentsInterval(property);
                let split = rent.split(" - ");
                setPrices({
                    min: split[0].split("€")[0],
                    max: split[1] ? split[1].split("€")[0] : split[0].split("€")[0]
                })
            }
        }
    }, [property]);

    let prices_value = (prices?.min && prices?.max && prices.min === prices.max) ?
        `${prices.min}€` : `${prices?.min ? prices.min + "€" : "TBD"} - ${prices?.max ? prices.max + "€" : "TBD"}`;

    const label = "main_page.radar.offers_details";

    const isBlocked = useMemo(() => {
        if (!tenant?.availableProperties || tenant?.availableProperties.length === 0) return true;
        if (tenant?.availableProperties) {
            const isBlocked = !tenant.availableProperties.find((p) => { return p === property.id })
            if (isBlocked) {
                const {periodStayMin, periodStayMax} = property?.room || {};
                const {periodStay: tenantPeriodStay} = tenant || {};
                let reasonIsBlocked = null;
                if (periodStayMin || periodStayMax) {
                    if (tenantPeriodStay > periodStayMax && periodStayMax !== 0) {
                        reasonIsBlocked = "It is not possible to meet the minimum stay period of " + periodStayMax + " month."
                    } else if (tenantPeriodStay < periodStayMin) {
                        reasonIsBlocked = t(label + ".period_min_unavailability", { count: periodStayMin })
                    }
                }
                if (!reasonIsBlocked) {
                    reasonIsBlocked = getPropertyUnavailabilityReason(property, tenant);
                    if (reasonIsBlocked) reasonIsBlocked = t(label + "." + reasonIsBlocked + "_unavailability");
                }
                setBlockedReason(reasonIsBlocked);
            }
            return isBlocked
        }
    }, [tenant]);

    /* const isSelected = useMemo(() => {
        if (offers) {
            if (offers.find((o) => {
                return o.property === property.id
            })) return true;
        }
    }, [offers]); */

    const totalRooms = useMemo(() => {
        if (property) {
            if (property.bedrooms) return property.bedrooms;
            if (property.rooms && property.rooms.length > 0) return property.rooms.length;
            return 1;
        }
    }, [property]);

    const availableRooms = useMemo(() => {
        if (totalRooms !== undefined && isBlocked !== undefined) {
            if (isBlocked) {
                return 0;
            } else {
                return totalRooms;
            }
        }
    }, [totalRooms, isBlocked]);

    let photo = property.integration_version ? [...(property.room_photos||[]), ...(property.photos||[])][0] : property.photos?.featured_0?.[0]?.md;

    return (
        <div className={"propertyResume" + (isBlocked ? " blocked" : "") + (isSelected ? " selected" : "")}>
            <div className={"propertyResume--photo"}
                style={{ "backgroundImage": `url(${photo})` }}
            >
                {isBlocked && <div className="property__blocked">
                    <div className="status">
                        {t(label + ".unavailable")}
                        {blockedReason && <Tooltip position='right'>
                            <Tooltip.Icon>
                                <InfoCircleIcon width={16} height={16} />
                            </Tooltip.Icon>
                            <Tooltip.Content>
                                <p>{blockedReason}</p>
                            </Tooltip.Content>
                        </Tooltip>}
                    </div>
                </div>}

            </div>
            <div className="property-resume__info">
                <div className={"propertyResume--title"}>
                    <h5>{property.internal_name}</h5>
                    <h5>{property.address}</h5>
                    <p>{!!property.has_rooms && `T${totalRooms} · `}
                        {t("accommodation_type." + (!property.has_rooms ? "studio" : property.accommodation))}
                        {property.floor && <> · {property.floor}</>}
                        {property.neighborhood && <> · {property.neighborhood}</>}
                    </p>
                </div>
                <div className={"propertyResume--prices"}>
                    <div>
                        <p>{t(label + ".prices")}:</p>
                        <h5>{prices_value}</h5>
                    </div>
                </div>

                {isSelected ? <button className="btn btn-ghost"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleSelectedProperty;
                    }}
                >
                    <CheckIcon />
                    {t(label + ".selected")}
                </button> : <button
                    className="btn btn-primary"
                    onClick={toggleSelectedProperty}
                    disabled={isBlocked}
                    >
                    {t(label + ".select")}
                </button>
            }
            </div>

        </div>
    )
}

export default PropertyResumePopOver;
