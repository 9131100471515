import * as React from "react";

function SvgNr(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path fill="#FFE15A" d="M0 150.345h511.999v35.31H0z" />
        <path
          fill="#F5F5F5"
          d="M119.172 220.965l5.141 20.54 14.721-15.218-5.817 20.358 20.357-5.818-15.216 14.723 20.538 5.139-20.538 5.141 15.216 14.721-20.357-5.817 5.817 20.358-14.721-15.217-5.141 20.539-5.141-20.539-14.721 15.217 5.818-20.358-20.358 5.817 15.217-14.721-20.539-5.141 20.539-5.139-15.217-14.723 20.358 5.818-5.818-20.358 14.721 15.218z"
        />
      </g>
    </svg>
  );
}

export default SvgNr;
