import React from 'react'

import './style.less'
import ProgressBar from '@ramonak/react-progress-bar';
import {useTranslation} from 'react-i18next';

const ProgressBarComponent = ({percentage}) => {
  const {t, i18n} = useTranslation();

  return (
    <div className={'ProgressBarComponent'}>
      <ProgressBar completed={percentage} bgColor={'#1D9AFA'} height={"5px"} isLabelVisible={false}/>
      <label>{percentage} % {t("progress-bar.loaded")}</label>
    </div>
  )
}

export default ProgressBarComponent
