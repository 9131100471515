import * as React from "react";

function SvgTn(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#FF4B55"
        />
        <path
          d="M257.002 79.724c48.718 0 88.205 39.594 88.205 88.276 0 48.823-39.487 88.276-88.205 88.276-48.823 0-88.346-39.453-88.346-88.276-.001-48.682 39.523-88.276 88.346-88.276"
          fill="#F5F5F5"
        />
        <g fill="#FF4B55">
          <path d="M273.043 217.466c-27.327 0-49.493-22.165-49.493-49.461 0-27.23 22.166-49.395 49.493-49.395a48.86 48.86 0 0125.405 7.102c1.112.674 2.282-.782 1.355-1.695-11.138-10.974-26.389-17.771-43.235-17.771-34.2 0-61.892 27.692-61.892 61.761 0 34.135 27.691 61.825 61.892 61.825 16.838 0 32.083-6.792 43.219-17.763.927-.913-.243-2.369-1.355-1.696a48.85 48.85 0 01-25.389 7.093z" />
          <path d="M268.52 133.387l13.644 18.526 21.945-6.912c2.841-.895 5.186 2.342 3.452 4.762l-13.402 18.701 13.355 18.735c1.729 2.425-.625 5.656-3.464 4.754l-21.928-6.968-13.692 18.49c-1.772 2.395-5.572 1.154-5.591-1.825l-.149-23.007-21.816-7.307c-2.825-.946-2.819-4.943.008-5.881l21.835-7.252.207-23.007c.027-2.978 3.83-4.208 5.596-1.809z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgTn;
