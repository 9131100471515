import React, {useState} from 'react';
import {components} from 'react-select'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import './style.less';

const AddressAutocomplete = (props) => {
  const [value, setValue] = useState(props.value ? {label: props.value, value: props.value} : undefined);

  let chosen = false;
  let selectRef = React.createRef();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgba(29, 154, 250, 0.1)' : undefined
    }),
    menu: provided => ({...provided, minWidth: 'max-content'}),
    menuList: provided => ({...provided, paddingRight: '10px'})
  };

  const chooseAddress = (input) => {
    const value = typeof input === 'string' ? input : input.value.description; //input.value.structured_formatting.main_text
    chosen = true;
    if (!value) {
      setValue('');
      return;
    }
    setValue({label: value, value});
    props.setValue(value.replace(", Portugal", ""));
    props.onSelect();
  };

  const region_country = {
    "Lisboa": "PT",
    "Porto": "PT",
    "Évora": "PT",
    "Coimbra": "PT",
    "Braga": "PT",
    "Aveiro": "PT",
    "Barcelona": "ES",
    "Madrid": "ES",
    "Milan": "IT",
    "Roma": "IT",
    "Sevilla": "ES",
    "Valencia": "ES"
  }

  return (
    <div className="AddressAutocomplete" onKeyDown={(e) => {e.key === "Enter" && props.getCoords()}}>
      {props.region && <GooglePlacesAutocomplete
        apiKey={process.env.GOOGLE_MAPS_API}
        customStyles
        apiOptions={{region: ["PT", "ES", "IT"]}}
        autocompletionRequest={{
          componentRestrictions: {
            country: [(region_country[props.region])?.toLowerCase()]
          },
          strictBounds: true,
          location: {
            lat: parseInt(props.regionCoords?.coords?.split(',')[0]),
            lng: parseInt(props.regionCoords?.coords?.split(',')[1])
          },
          radius: props.regionCoords?.radius,
          types: ['address']
        }}
        selectProps={{
          className: `Select basic_container ${props.className}`,
          classNamePrefix: 'Select',
          styles: customStyles,
          placeholder: props.placeholder,
          value: value,
          ref: (r) => selectRef = r,
          onFocus: () => {
            chosen = false;
            if (value) {
              selectRef.select.state.inputValue = value.label;
              let inputRef = selectRef.select.select.inputRef;
              inputRef._valueTracker.setValue(value.label + ' ')
              setTimeout(() => {
                let event = new Event('input', {bubbles: true});
                event.simulated = true;
                inputRef._valueTracker.setValue(value.label.slice(0, -1))
                inputRef.dispatchEvent(event);
              }, 50);
            }
          },
          cacheOptions: true,
          blurInputOnSelect: true,
          components: {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            NoOptionsMessage: () => <p style={{padding: '5px 12px'}}>{props.noOptionMessage}</p>,
            Menu: (menuProps) => {
              let inputRef = selectRef.select?.select?.inputRef;
              return inputRef?.value ? (<components.Menu {...menuProps} children={menuProps.children}/>) : null;
            }
          },
          onChange: chooseAddress,
          /*onInputChange: (inputValue, action) => {
            if (value && !chosen && (action.action === 'input-blur' || action.action === 'menu-close')) {
              chooseAddress(selectRef.select.select.inputRef.value);
            }
          }*/
        }}
      />}
    </div>
  );
};

export default AddressAutocomplete;
