import React from "react";

const DoneAll = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="done_all" clipPath="url(#clip0_2927_32971)">
            <path
                id="Vector"
                d="M18.0002 7.00003L16.5902 5.59003L10.2502 11.93L11.6602 13.34L18.0002 7.00003ZM22.2402 5.59003L11.6602 16.17L7.48016 12L6.07016 13.41L11.6602 19L23.6602 7.00003L22.2402 5.59003ZM0.410156 13.41L6.00016 19L7.41016 17.59L1.83016 12L0.410156 13.41Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_2927_32971">
                <rect width={24} height={24} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default DoneAll;
