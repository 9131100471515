import React, {useCallback, useEffect, useMemo, useState} from 'react';

import './style.less'

import {AiOutlineClose, AiOutlinePlus} from 'react-icons/ai';
import {useTranslation} from 'react-i18next';
import {ReactSelect} from '../../../../../../../../../../../components/ReactSelect';

const DoubleColumnTimePickers = ({visitTimesStart, visitTimesEnd, errors, control, weekday,setValue}) => {
  const {t} = useTranslation();

  const options = useCallback(() => {
    let options = [];
    for (let i = 0; i < 48; i++) {
      options.push({
        id: i,
        value: `${Math.floor(i / 2)}:${i % 2 === 1 ? '30' : '00'}`,
        label: `${Math.floor(i / 2)}:${i % 2 === 1 ? '30' : '00'}`
      })
    }
    return options;
  }, [])()

  const defaultStartTimeValue = useMemo(() => options.find((time) => time.value === '9:00'), []);
  const defaultEndTimeValue = useMemo(() => options.find((time) => time.value === '20:00'), []);

  const getStartValues = (amountTimes) => {
    let visitTimesDefaultValuesFinal = []
    if (visitTimesStart && visitTimesEnd) {
      for (let i = 0; i < amountTimes; i++) {
        let optionValueStart = options.find(option => visitTimesStart[i] === option.value)
        let optionValueEnd = options.find(option => visitTimesEnd[i] === option.value)
        visitTimesDefaultValuesFinal.push({
          id: Date.now() + i + weekday,
          valueStart: optionValueStart,
          valueEnd: optionValueEnd
        })
      }
    } else {
      visitTimesDefaultValuesFinal.push({
        id: Date.now() + weekday,
        valueStart: defaultStartTimeValue,
        valueEnd: defaultEndTimeValue
      })
    }
    return visitTimesDefaultValuesFinal
  }

  const [visitTimesValues, setVisitTimesValues] = useState(getStartValues(visitTimesStart?.length > 1 ? visitTimesStart.length : 1));

  const removeIndex = (id) => {
    setVisitTimesValues(prevState => prevState.filter(item => item.id !== id))
  }

  const addTimer = () => {
    let id = Date.now() + visitTimesValues.length + weekday;
    setVisitTimesValues(prevState => ([...prevState, {id, valueStart: defaultStartTimeValue, valueEnd: defaultEndTimeValue}]))
  }

  useEffect(() => {
    setVisitTimesValues(getStartValues(visitTimesEnd?.length > 1 ? visitTimesEnd.length : 1))
  }, [visitTimesStart, visitTimesEnd])

  return (
    <div className="TimesPickers">
      <div>
        {visitTimesValues.map((e, index) => (<div key={e.id}>
            <div>
              <div className="TimesPickers__start">
                <ReactSelect
                  key={`${weekday}[start][${e.id}]`}
                  placeholder={t(`main_page.my_ads.create_ad.house_details.landlord_rules.level1.visit_week_days.between_time`)}
                  options={options}
                  name={`${weekday}[start][${index}]`}
                  defaultValue={visitTimesValues[index].valueStart ? visitTimesValues[index].valueStart : defaultStartTimeValue}
                  control={control}
                  className={errors?.[weekday]?.['start']?.[index] ? 'input_error' : null}
                  onChange={(value) => {
                    if (value.id > visitTimesValues[index].valueEnd.id) {
                      setValue(`${weekday}[end][${index}]`, value);
                    }
                  }}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('main_page.my_ads.create_ad.house_details.landlord_rules.level1.visit_week_days.times_error')}`
                    }
                  }}/>
              </div>
              <div className="TimesPickers__end">
                <ReactSelect
                  key={`${weekday}[end][${e.id}]`}
                  placeholder={t(`main_page.my_ads.create_ad.house_details.landlord_rules.level1.visit_week_days.until_time`)}
                  options={options}
                  name={`${weekday}[end][${index}]`}
                  defaultValue={visitTimesValues[index].valueEnd ? visitTimesValues[index].valueEnd : defaultEndTimeValue}
                  control={control}
                  className={errors?.[weekday]?.['end']?.[index] ? 'input_error' : null}
                  isOptionDisabled={(option) => option.id <= visitTimesValues[index].valueStart.id}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('main_page.my_ads.create_ad.house_details.landlord_rules.level1.visit_week_days.times_error')}`
                    }
                  }}/>
              </div>
              {index > 0 && <button type="button" id={'close_button'} className={'btn center btn_grey_soft'}
                                    onClick={() => removeIndex(e.id)}><AiOutlineClose/></button>}
            </div>
            {(errors?.[weekday]?.['end']?.[index] || errors?.[weekday]?.['start']?.[index]) &&
             <p className={'error_message'}>{errors?.[weekday]?.['end']?.[index].message}</p>}
          </div>)
        )}
      </div>

      <button type="button" className={'btn btn_grey'} onClick={() => {if (visitTimesValues.length < 5) addTimer()}}>
        <AiOutlinePlus/>
        <p>{t('main_page.my_ads.create_ad.house_details.landlord_rules.level1.visit_week_days.add_more_times')}</p>
      </button>
    </div>
  )
}

export default DoubleColumnTimePickers;
