import React from "react";

const Videocam = (props) => (
    <svg
        width={10}
        height={10}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="videocam">
            <g clipPath="url(#clip0_3460_32375)">
                <path
                    id="Vector"
                    d="M6.25 3.33333V6.66667H2.08333V3.33333H6.25ZM6.66667 2.5H1.66667C1.4375 2.5 1.25 2.6875 1.25 2.91667V7.08333C1.25 7.3125 1.4375 7.5 1.66667 7.5H6.66667C6.89583 7.5 7.08333 7.3125 7.08333 7.08333V5.625L8.75 7.29167V2.70833L7.08333 4.375V2.91667C7.08333 2.6875 6.89583 2.5 6.66667 2.5Z"
                    fill="#284F83"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_3460_32375">
                <rect width={10} height={10} rx={5} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Videocam;
