import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import Loading from "../../../../../../components/Loading";
import VisitCard from "../../../../../../components/VisitCard";

import './style.less'
import EmptyState from "../../../../../../components/EmptyState";
import FiltersContainer from "../../../../../../components/FiltersContainer";
import PaginationLegacy from "../../../../../../components/PaginationLegacy";

const VisitsList = ({visits, filter, updateVisits, totalResults, ...props}) => {

    const {t} = useTranslation();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await updateVisits(page, {"currentStatus": filter});
            setLoading(false)
        })();
    }, [])

    let pages = props.pages;


    return (
        <div className={"VisitsList"}>
            <div className="page__header">
                <div className="page__header__title">
                    <h3>{t(props.header)}</h3>
                {pages && <FiltersContainer pages={pages} type={"my_visits"}/>}
            </div>
            <PaginationLegacy page={page} setPage={setPage} total={totalResults} setLoading={setLoading} filter={filter} updateList={updateVisits}/>
            </div>
            {visits === null || loading ? <Loading/> : (visits?.length > 0 ? visits.map(visit => (
                <VisitCard key={visit.id + "card"} visit={visit} filter={filter} updateVisits={updateVisits}/>
            )) : <EmptyState type={"visits"}/>)}
        </div>
    )
}

export default VisitsList
