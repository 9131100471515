import * as React from "react";

function SvgMn(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M341.333.276h161.839A8.829 8.829 0 01512 9.104v317.793a8.829 8.829 0 01-8.828 8.828H341.333V.276z"
          fill="#FF4B55"
        />
        <path fill="#41479B" d="M170.67.276h170.67v335.448H170.67z" />
        <path
          d="M8.828.276h161.839v335.448H8.828A8.829 8.829 0 010 326.896V9.103A8.828 8.828 0 018.828.276z"
          fill="#FF4B55"
        />
        <g transform="translate(25 41)" fill="#F9CF02">
          <path d="M60.689 98.028c-13.341 0-24.776-8.038-29.814-19.519-.003.175-.052.338-.052.513 0 16.494 13.372 29.866 29.866 29.866s29.865-13.372 29.865-29.866c0-.175-.049-.338-.052-.512-5.038 11.48-16.473 19.518-29.813 19.518z" />
          <circle cx={60.694} cy={70.879} r={21.72} />
          <path d="M47.114 30.153c0 7.497 6.078 13.575 13.575 13.575 7.497 0 13.575-6.078 13.575-13.575 0-5.43-3.394-6.109-3.394-8.145 0-2.036 2.036-4.751-2.036-8.145 2.036 3.394-1.357 4.073-1.357 7.466s1.357 3.394 1.357 6.109a2.714 2.714 0 11-5.428 0c0-2.714 2.714-5.43 2.714-9.503s-.679-5.43-2.714-8.824c-2.036-3.394-5.43-6.109-2.714-8.824-4.073 1.357-2.036 6.787-2.036 10.182 0 3.394-2.714 5.43-2.714 9.503s2.036 4.751 2.036 7.466a2.714 2.714 0 11-5.428 0c0-2.714 1.357-2.714 1.357-6.109s-3.394-4.073-1.357-7.466c-4.073 3.394-2.036 6.109-2.036 8.145-.006 2.036-3.4 2.714-3.4 8.145zM.959 116.56v125.838a2.241 2.241 0 002.241 2.241h22.667a2.241 2.241 0 002.241-2.241V116.56a2.241 2.241 0 00-2.241-2.241H3.2a2.24 2.24 0 00-2.241 2.241zm92.311 0v125.838a2.241 2.241 0 002.241 2.241h22.667a2.241 2.241 0 002.241-2.241V116.56a2.241 2.241 0 00-2.241-2.241H95.511a2.24 2.24 0 00-2.241 2.241zm-59.731 21.719v6.377a2.241 2.241 0 002.241 2.241h49.817a2.241 2.241 0 002.241-2.241v-6.377a2.241 2.241 0 00-2.241-2.241H35.78a2.241 2.241 0 00-2.241 2.241zm0 76.021v6.377a2.241 2.241 0 002.241 2.241h49.817a2.241 2.241 0 002.241-2.241V214.3a2.241 2.241 0 00-2.241-2.241H35.78a2.24 2.24 0 00-2.241 2.241zm4.046-99.982h46.208c1.137 0 1.551 1.497.577 2.082l-21.95 13.171a3.36 3.36 0 01-3.459 0L37.009 116.4c-.975-.585-.56-2.082.576-2.082zm0 114.032h46.208c1.137 0 1.551 1.497.577 2.082l-21.952 13.17a3.36 3.36 0 01-3.459 0l-21.951-13.171c-.974-.585-.559-2.081.577-2.081zm25.756-75.81c-.856-.082-1.685-.259-2.562-.259-14.931 0-27.078 12.147-27.078 27.078 0 8.862 4.329 16.662 10.943 21.583.761.566 1.767-.252 1.397-1.127a15.656 15.656 0 01-1.239-6.106c0-8.825 7.193-16.002 16.026-15.975 4.643.014 9.094-2.279 11.149-6.443 4.251-8.611-.936-17.17-8.636-18.751zm-2.562 17.883a5.416 5.416 0 110-10.832 5.416 5.416 0 010 10.832z" />
          <path d="M76.912 157.775c-.761-.566-1.767.253-1.397 1.127a15.637 15.637 0 011.239 6.105c0 8.826-7.194 16.003-16.026 15.976-4.643-.014-9.095 2.279-11.149 6.443-4.249 8.612.938 17.172 8.638 18.751.856.082 1.685.259 2.562.259 14.93 0 27.078-12.147 27.078-27.078-.002-8.861-4.331-16.661-10.945-21.583zm-16.133 41.35a5.416 5.416 0 110-10.832 5.416 5.416 0 010 10.832z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgMn;
