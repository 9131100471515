import React, {useMemo} from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import './style.less';
import TimelineIcon from "../../../../../../components/svg/Timeline";
import Separator from "../../../../../../components/svg/Separator";
import AlertNotificationIcon from "../../../../../../components/svg/AlertNotification";

const CancellationTimeline = ({ reservation, className }) => {

    const {t} = useTranslation();

    let preWarningDays = useMemo(() => {
        let days;
        if (reservation) {
            let requestDate = reservation.cancellation?.requestDate || new Date()
            let startDate = moment(requestDate).isBefore(moment(reservation.moveIn)) ? reservation.contractStartDate : reservation.moveIn;
            days = moment(startDate).startOf("day").diff(moment(requestDate).startOf("day"), "days");
            return days < 0 ? 0 : days;
        }
    }, [reservation])

    let timelineContent = useMemo(() => {
        if (preWarningDays === null) return;

        return [
            {
                number: 1,
                text: "acceptedDate",
                date: moment(reservation.acceptedDate).format("DD MMM YYYY")
            },
            {
                number: 2,
                text: "cancellationDate",
                date: moment(reservation.cancellation?.requestDate || new Date()).format("DD MMM YYYY")
            },
            {
                number: 3,
                text: "begin_contract",
                date: moment(reservation.moveIn).format("DD MMM YYYY")
            }
        ];
    }, [reservation])

    return (
        <div className={`CancellationTimeline ${className && className}`}>
            <div className="CancellationTimeline__header">
                <TimelineIcon />
                <h5>{t("main_page.reservations.cancel_reservation.header")}</h5>
            </div>
            <div className="CancellationTimeline__content">
                <div className="CancellationTimeline__content__timeline">
                    <div className="timeline__top-section">
                        {timelineContent?.map((line) => {
                            if (line.number === 4) return;

                            return <div className="timeline-identifier">
                                {line.number !== 1 && <Separator className="separator" />}
                                <div className="timeline-identifier__number">
                                    <span>{line.number}</span>
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="timeline__bottom-section">
                        {timelineContent?.map((line) => {
                            if (line.number === 4) return;

                            return <div className="timeline__info">
                                <span>{t("main_page.reservations.cancel_reservation." + line.text)}</span>
                                <p>{line.date}</p>
                            </div>
                        })}
                    </div>
                </div>

                <div className="CancellationTimeline__content__warning">
                    <div className="warning__header">
                        <AlertNotificationIcon />
                        <p className="warning__title">{t("main_page.reservations.cancel_reservation.preWarning")}</p>
                    </div>
                    <p className="warning__value">
                        {preWarningDays < 0 ? 0 : preWarningDays} {t(preWarningDays === 1 ? "day" : "days")}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CancellationTimeline;
