import React from 'react';
import {Controller} from 'react-hook-form';

import './style.less'
import StyledSwitch from '../../../../../components/StyledSwitch';
import {toggleObjects} from '../../../../../../../../../../../utils/toogleObjects';
import DoubleColumnTimePickers from '../DoubleColumnTimePickers';
import {useTranslation} from 'react-i18next';

const VisitWeekDays = ({
                         weekdays,
                         weekdaysWithTimes,
                         setWeekDays,
                         pageLabel,
                         control,
                         errors,
                         setValue
                       }) => {

  const {t} = useTranslation();

  let visitWeekDaysOptions = [{option: 'sunday', label: t(pageLabel + '.visit_week_days.sunday')},
    {option: 'monday', label: t(pageLabel + '.visit_week_days.monday')},
    {option: 'tuesday', label: t(pageLabel + '.visit_week_days.tuesday')},
    {option: 'wednesday', label: t(pageLabel + '.visit_week_days.wednesday')},
    {option: 'thursday', label: t(pageLabel + '.visit_week_days.thursday')},
    {option: 'friday', label: t(pageLabel + '.visit_week_days.friday')},
    {option: 'saturday', label: t(pageLabel + '.visit_week_days.saturday')}]

  return (
    <Controller
      as={
        <div className="VisitWeekDays">
          {visitWeekDaysOptions.map((weekday, index) => (
            <div className="VisitWeekDay" key={weekday.option}>
              <div className={'SwitchContainer'}>
                <label>{weekday.label}</label>
                <StyledSwitch id={weekday.option} onChange={() => toggleObjects(weekday.option, weekdays, setWeekDays)}
                              checked={weekdays.find((value) => value === weekday.option) ? true : false}/>
              </div>
              {weekdays.find((value) => value === weekday.option) &&
               <DoubleColumnTimePickers weekday={weekday.option}
                                        visitTimesStart={weekdaysWithTimes?.[weekday.option]?.start}
                                        visitTimesEnd={weekdaysWithTimes?.[weekday.option]?.end}
                                        control={control} errors={errors} setValue={setValue}/>}
            </div>
          ))}
        </div>
      }
      defaultValue={'visit_weekdays'}
      name={'visit_weekdays'}
      control={control}
      rules={{validate: () => weekdays?.length > 0}}
      onFocus={() => {
        const anchor = document.querySelector(`#visit_week_days`)
        if (anchor) anchor.scrollIntoView({behavior: 'smooth', block: 'start'})
      }}
    />
  )
}

export default VisitWeekDays;