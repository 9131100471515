import React, {useState, useEffect, useMemo} from 'react';
import {dashboardServices} from '../../../../../../services';

import './style.less'
import Loading from "../../../../../../components/Loading";
import OfferPopOver from "./components/OfferPopOver";
import {useTranslation} from "react-i18next";
import {usePopupDialog} from "../../../../../../components/PopupDialog";
import OfferCard from "./components/OfferCard";
import RoomResumePopOver from "./components/OfferPopOver/RoomResume";
import PotentialTenant from "../../../../../../components/ListItem/types/PotentialTenants";
import moment from "moment";
import FiltersContainer from "../../../../../../components/FiltersContainer";
import EmptyState from "../../../../../../components/EmptyState";
import offerSentImage from "../../../../../../components/ActionDialog/images/2offers_sent.svg";
import Filters from "../../../../../../components/Filters";
import {grind} from "../../../../../../utils/stringFunctions";
import PaginationLegacy from "../../../../../../components/PaginationLegacy";

const PotentialTenantsList = ({
                                  filters,
                                  updatePotentialTenantsList,
                                  totalPotentialTenants,
                                  setFilters,
                                  potentialTenants,
                                  offers,
                                  archivedTenants,
                                  setArchivedTenants,
                                  setPotentialTenants,
                                  pages,
                                  filter,
                                  userProperties,
                                  dashboard,
                                  header,
                                  getOffers,
                                  loadingPotential
                              }) => {

    const {t} = useTranslation();

    const [currentOffers, setCurrentOffers] = useState(null);
    const [currentTenants, setCurrentTenants] = useState(potentialTenants);
    const [infoPopOver, setInfoPopover] = useState(false);
    const [tenant, setTenant] = useState(false);
    const [start, setStart] = useState(false);
    const [offerOpen, setOfferOpen] = useState();
    const [isByAsc, setIsByAsc] = useState(true);
    const [lastSort, setLastSort] = useState("");
    const [isFiltering, setIsFiltering] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [searchedTenants, setSearchedTenants] = useState();
    const [page, setPage] = useState(0);

    const popupDialog = usePopupDialog();

    useEffect(async () => {
        switch (filter) {
            case "potential":
                setCurrentTenants(potentialTenants);
                return;
            case "sent":
                setCurrentOffers(await getOffers(filter));
                return;
            case "declined":
                setCurrentOffers(await getOffers(filter));
                return;
            case "accepted":
                setCurrentOffers(await getOffers(filter));
                return;
            case "archived":
                setCurrentTenants(archivedTenants);
        }
    }, [filter, offers, potentialTenants, archivedTenants]);

    const handleClick = (event, data) => {
        setInfoPopover(!infoPopOver);
        setTenant(data);
    };

    const getFilters = (filters) => {
        let filtersNew = {}
        Object.entries(filters).forEach(([key, value]) => {
            filtersNew[key] = value;
        })
        return filtersNew
    }

    const archive = async (tenant) => {
        popupDialog.openPopupDialog({
            title: t('main_page.radar.offers_details.archive_potential_tenant'),
            message: t('main_page.radar.offers_details.archive_potential_tenant_message'),
            request: async () => {
                await dashboardServices.archivePotentialTenant(tenant.id);
            },
            request_done: async () => {
                let temp = [...currentTenants.filter((t) => {return t.id !== tenant.id})];
                setCurrentTenants(temp);
                setPotentialTenants(temp);
                if (archivedTenants && archivedTenants.length > 0){
                    setArchivedTenants([...archivedTenants, tenant]);
                }else{
                    setArchivedTenants([tenant]);
                }
            }
        });
    }

    const unarchive = async (tenant) => {
        popupDialog.openPopupDialog({
            title: t('main_page.radar.offers_details.unarchive_potential_tenant'),
            message: t('main_page.radar.offers_details.unarchive_potential_tenant_message'),
            request: async () => {
                await dashboardServices.unarchivePotentialTenant(tenant.id);
            },
            request_done: async () => {
                let temp = [...currentTenants.filter((t) => {return t.id !== tenant.id})];
                setArchivedTenants(temp);
                setPotentialTenants([...potentialTenants, tenant]);
                setCurrentTenants(temp);
            }
        });
    }

    const sendOffer = (offers, tenant) => {
        popupDialog.openPopupDialog({
            title: t("main_page.radar.offers_details.sent_offer_title"),
            message: t("main_page.radar.offers_details.sent_offer_message", { num: tenant.offers.length }),
            customSuccessDialog: {
                image: offerSentImage,
                message: t("main_page.radar.offers_details.offer_sent_success", { count: 1 })
            },
            request: async () => {
                await dashboardServices.sendOffers(offers, tenant.id).then((e) => {
                }).catch(e => console.log(e));
            },
            request_done: async () => {
                let tenants = await dashboardServices.getPotentialTenants(getFilters(filters));
                setPotentialTenants(tenants.potentialTenants);
                setCurrentTenants(tenants.potentialTenants);
                setStart(false);
                setTenant(false);
                setInfoPopover(!infoPopOver);
            }
        });
    }

    const handleOpenOffer = (offerID) => {
        if (offerOpen){
            if (offerOpen === offerID){
                setOfferOpen(null);
            }else{
                setOfferOpen(offerID);
            }
        }else{
            setOfferOpen(offerID);
        }
    }

    const sortTenants = (filterField, isByAsc) => {
        setIsFiltering(true);
        let tenants = [...currentTenants];
        let filter = filterField || lastSort;
        setLastSort(filter)
        if (filter === "offers") {
            tenants.sort((a, b) => isByAsc ? a.offers - b.offers : b.offers - a.offers);
        } else if (filter === "registrationDate") {
            tenants.sort((a, b) => isByAsc ? moment(a.registrationDate) - moment(b.registrationDate) : moment(b.registrationDate) - moment(a.registrationDate));
        } else if (filter === "stayDates") {
            tenants.sort((a, b) => isByAsc ?
                (moment(a.preferences.moveOut).diff(moment(a.preferences.moveIn))) - (moment(b.preferences.moveOut).diff(moment(b.preferences.moveIn)))
                : (moment(b.preferences.moveOut).diff(moment(b.preferences.moveIn))) - (moment(a.preferences.moveOut).diff(moment(a.preferences.moveIn))));
        } else if (filter === "moveIn") {
            tenants.sort((a, b) => isByAsc ? moment(a.preferences.moveIn) - moment(b.preferences.moveIn) : moment(b.preferences.moveIn) - moment(a.preferences.moveIn));
        } else {
            tenants.sort((a, b) => isByAsc ? a.preferences[filter] - b.preferences[filter] : b.preferences[filter] - a.preferences[filter]);
        }
        setCurrentTenants(tenants);
        setFilters(prevState => ({...prevState, sort: filter}));
        setIsFiltering(false);
    }

    const handleFilter = async (filter2, value) => {
        setIsFiltering(true);
        setFilters(prevState => ({...prevState, [filter2]: value}));
        await updatePotentialTenantsList(null, filter, filter2, value);
        setIsFiltering(false);
    }

    const getFilterOptions = (byPreference, type, translation) => {
        if (currentTenants && currentTenants.length) {
            let tenants = currentTenants.filter((tenant) => byPreference ? tenant.preferences?.[type] : tenant.personalDetails?.[type]);
            let typeList = tenants.map((tenant) => byPreference ? tenant.preferences?.[type] : tenant.personalDetails?.[type]);
            let options = (Array.from(new Set(typeList))?.map(option => ({
                value: option,
                label: translation ? t(translation + option) : option
            })));
            options.sort(function (a, b) {
                if (a["label"] < b["label"]) {
                    return -1;
                }
                if (a["label"] > b["label"]) {
                    return 1;
                }
                return 0;
            });
            options.push({ value: null, label: t("main_page.radar.offers_filters.none") })
            return options;
        }
    }

    const optionsByUniversity = useMemo(() => {
        return getFilterOptions(true, "university");
    }, [currentTenants]);

    const optionsByNationality = useMemo(() => {
        return getFilterOptions(false, "nationality", "country.");
    }, [currentTenants]);

    const optionsByNeighborhood = useMemo(() => {
        return getFilterOptions(true, "neighborhood");
    }, [currentTenants]);

    const applyFilter = () => {
        if (!searchFilter || searchFilter === "") {
            return null
        } else if (currentTenants && currentTenants.length)
            return currentTenants
                .filter(tenant => {
                    return tenant.name && grind(tenant.name).includes(grind(searchFilter).trim());
                });
    }

    useEffect(() => {
        setSearchedTenants(applyFilter());
    }, [searchFilter]);

    return (
        <div className={"PotentialTenantsList"}>
            <div className="page__header">
                {!dashboard && filter === "potential" && currentTenants && <>
                    <div>
                        <div className="page__header__title">
                            <h3>{t("main_page.dashboard.potential_tenants.title")}</h3>
                            {pages && <FiltersContainer pages={pages} type={"radar"} />}
                        </div>
                        <p>{t("showing") + " " + currentTenants?.length + " " + t("of") + " " + potentialTenants.length + " " + t("results")}</p>
                    </div>
                    <Filters filters={filters} setFilters={setFilters} setSearchFilter={setSearchFilter}
                         isByAsc={isByAsc} setIsByAsc={setIsByAsc} lastSort={lastSort}
                             resetFilters={async () => {setIsFiltering(true); await updatePotentialTenantsList(null, filter, null, null, true); setIsFiltering(false)}}
                         handleFilter={handleFilter} sort={sortTenants} optionsByUniversity={optionsByUniversity}
                        optionsByNationality={optionsByNationality} optionsByNeighborhood={optionsByNeighborhood}
                    />
                </>}
                {!dashboard && filter !== "potential" &&
                    <div className="page__header__title">
                        <h3>{t(header)}</h3>
                        {pages && <FiltersContainer pages={pages} type={"radar"} />}
                    </div>}
            </div>
            {(infoPopOver && tenant) &&
            <OfferPopOver setStart={setStart} setInfoPopover={setInfoPopover} handleClick={handleClick} tenant={tenant}
                          start={start} userProperties={userProperties} sendOffer={(offer) => {
                sendOffer(offer, tenant)
            }}/>}
            {((infoPopOver || start) && tenant) && <div className={"PotentialTenantsList__darkPage"}/>}
            <PaginationLegacy setPage={setPage} page={page} total={totalPotentialTenants} updateList={updatePotentialTenantsList}
                              setLoading={() => {}} filter={filter} filters={filters} searchKey={searchFilter}/>
            {(filter === "potential" || filter === "archived") ? (
                    (((filter === "potential" && currentTenants) || (filter === "archived" && archivedTenants)) && !loadingPotential) ?
                        (currentTenants && currentTenants.length > 0) ?
                        (searchedTenants ? searchedTenants : currentTenants)?.map((tenant) => {
                                return (<PotentialTenant filter={filter} tenant={tenant} key={tenant.id}
                                                         handleClick={handleClick}
                                                         type={"PotentialTenants"} archive={() => archive(tenant)}
                                                         unarchive={() => unarchive(tenant)}/>)
                            })
                            : <EmptyState type={"potentials"}/>
                        : <Loading/>) :
                (currentOffers ? ((currentOffers && currentOffers.length > 0) ? currentOffers?.map((offer) => {
                                return (<div className={"offer_Card"} key={offer.id}>
                                    <OfferCard offerID={offer.id}
                                               tenant={offer.user} property={offer.property} handleOpenOffer={handleOpenOffer}
                                               rooms={offer.rooms}/>
                                    {offer.rooms && offer.rooms.map((room) => {
                                       const parsedRoom = offer.property?.rooms ? offer.property.rooms.filter((r) => {return r.id === room.id})[0] : null;
                                        return (
                                            parsedRoom &&
                                            <RoomResumePopOver date={offer?.offerDate} property={offer.property} key={offer.id + room.id}
                                                               room={parsedRoom} offerRoom={room}
                                                               address={offer?.property?.address} tenant={offer.user}
                                                               offerOpen={offerOpen} type={"offer"} offerID={offer.id}/>
                                        )
                                    })}
                                </div>)
                            }) :
                            <EmptyState type={"offers"}/>)
                        :
                        <Loading/>
                )}
            {(isFiltering || loadingPotential) && <Loading/>}
        </div>
    )
}

export default PotentialTenantsList
