import React from 'react';

import './style.less'
import pencilIconBlack from "../../pages/MainPage/containers/MyAccount/containers/images/penciIconBlack.svg";
import {useTranslation} from "react-i18next";
import {userServices} from "../../services";
import {usePopupDialog} from "../PopupDialog";


const DeleteAccount = () => {

  const {t} = useTranslation();

  const popupDialog = usePopupDialog();

  const deleteAccount = async () => {
    popupDialog.openPopupDialog({
      title: t('my_account.delete_account.popup_title'),
      message: t('my_account.delete_account.popup_content'),
      request: async () => {
        await userServices.deleteAccount();
      },
      request_done: async () => {
        await userServices.logout();
        window.location.href = "/start/login";
      }
    });
  }

  return (
      <div className={"AccountDetails__erase_account"}>
        <h4>{t('my_account.delete_account.title')}</h4>
        <p>{t('my_account.delete_account.content_1')}</p>
        <p>{t('my_account.delete_account.content_2')}</p>
        <p>{t('my_account.delete_account.content_3')}</p>
        <p>{t('my_account.delete_account.content_4')}</p>
        <p className={"erase_account"} onClick={() => deleteAccount()}>
          {t('my_account.delete_account.button')}
          <img src={pencilIconBlack} alt={"Pencil Icon"}/>
        </p>
      </div>
  )
}

export default DeleteAccount;

