import {useTranslation} from "react-i18next";
import React, { useState } from "react";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {reservationServices} from "../../../../../../../../services/reservationServices";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../../../../../../../components/MUIDialogComponents";
import Loading from "../../../../../../../../components/Loading";
import {DialogContent} from "@material-ui/core";
import DatePicker from "react-datepicker";

const SuggestNewDateModal = ({open, setOpen, reservation, rejectReservation, setShowActionDialog}) => {

    const {t} = useTranslation()
    const history = useHistory();
    const translPath = "main_page.reservations.reservation_details."

    const [moveIn, setMoveIn] = useState(null);
    const [moveOut, setMoveOut] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectingStart, setSelectingStart] = useState(null);
    const [changingDates, setChangingDates] = useState(false);
    const [newDatesApplied, setNewDatesApplied] = useState(false);

    const handleClose = () => {
        if (loading) return;
        setOpen(false);
        setMoveIn(null);
        setMoveOut(null);
        setError(false)
    }

    const submitHandler = async () => {
        let moveInDate = moveIn ? moment(moveIn).toISOString() : reservation.moveIn;
        let moveOutDate = moveOut ? moment(moveOut).toISOString() : reservation.moveOut;
        if(moment(moveOutDate).diff(moment(moveInDate), "month") < 1) {
            setError(true);
            return;
        }
        setLoading(true);
        let fields = {
            reservation_id: reservation.id,
            moveIn: moveInDate,
            moveOut: moveOutDate
        }
        let response = await reservationServices.suggestNewDates(fields);
        if (response.id){
            setLoading(false);
            return history.push("/reservations/"+response.id+"/details?showActionDialog=dates_suggested")
        } else{
            rejectReservation(null, moveIn, moveOut);
        }
        setLoading(false);
        handleClose();
        setShowActionDialog("dates_suggested");
    }

    return (
        <Dialog open={open} onClose={handleClose} className={`SuggestNewDatesModal`} maxWidth={"xs"}>
            <DialogTitle className="DialogHeader" onClose={handleClose}/>
            {loading && <Loading cover={0.3}/>}
            <DialogContent className={"SuggestNewDates__content"}>
                <div className="dialog__content">
                <div className={"header_container"}>
                    <span><strong>{t(translPath+"newDates_modal.suggest_dates")}</strong></span>
                    <span>{t(translPath + "newDates_modal.extra_info", { name: reservation.user?.name?.trim().split(" ")[0] || reservation.user_info?.name?.trim().split(" ")[0] })}</span>
                </div>
                    <div className="dates">
                    <button
                        className="btn btn-secondary"
                        onClick={() => {
                            setSelectingStart(true);
                            setChangingDates(true);
                            setNewDatesApplied(false);
                        }}
                    >
                            {`${t(translPath + "newDates_modal.currentMoveIn")}: ${moment(moveIn ? moveIn : reservation.moveIn).format("DD/MM/YYYY")}`}
                            {/* {moveIn ? t(translPath + "move_in") + ": " + moment(moveIn).format("DD/MM/YYYY") : t(translPath + "newDates_modal.currentMoveIn")} */}
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={() => {
                            setSelectingStart(false);
                            setChangingDates(true)
                        }}
                    >
                            {`${t(translPath + "newDates_modal.currentMoveOut")}: ${moment(moveOut ? moveOut : reservation.moveOut).format("DD/MM/YYYY")}`}
                            {/* {moveOut ? t(translPath + "move_out") + ": " + moment(moveOut).format("DD/MM/YYYY") : t(translPath + "newDates_modal.currentMoveOut")} */}
                    </button>
                </div>

                <div className={"dates-input"}>
                    <div className={"CustomDatePicker"} style={{ width: "fit-content" }}>

                            {changingDates && <DatePicker inline
                            className={"input_field basic_container"}
                            name={selectingStart ? "moveIn" : "moveOut"}
                            id={selectingStart ? "moveIn" : "moveOut"}
                                        onChange={(e) => {
                                            if (selectingStart) {
                                                setSelectingStart(false);
                                                setChangingDates(false);
                                                setMoveIn(e);
                                            } else {
                                                setChangingDates(false);
                                                setMoveOut(e);
                                            }
                                            setError(false);
                                        }}
                            selectsStart={selectingStart}
                            selectsEnd={!selectingStart}
                            selected={selectingStart ? (moveIn || new Date(reservation.moveIn)) : (moveOut || new Date(reservation.moveOut))}
                            disabledKeyboardNavigation
                            showPreviousMonths={false}
                                        locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                        dateFormat={"dd/MM/yyyy"}
                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                            minDate={new Date(moment().add(1, "day"))}
                        />}
                    </div>
                </div>

                {(moveIn?.getTime() === new Date(reservation.moveIn).getTime() && moveOut?.getTime() === new Date(reservation.moveOut).getTime()) && <div className={"dates-error"}>{t(translPath + "newDates_modal.different_dates")}</div>}

                {error &&
                <div className={"dates-error"}>{t(translPath+"newDates_modal.minimum_stay")}</div>}
                </div>
                <div className={"actions-container"}>
                    <button className={"btn btn-secondary"} onClick={handleClose}>{t("buttons.back")}</button>
                    <button className={"btn btn-primary"} disabled={((moveIn?.getTime() === new Date(reservation.moveIn).getTime() || !moveIn) && (moveOut?.getTime() === new Date(reservation.moveOut).getTime() || !moveOut))} onClick={submitHandler}>{t(translPath + "newDates_modal.submit")}
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    )
}


export default SuggestNewDateModal;
