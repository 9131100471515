import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import './style.less';
import Buttons from '../../../../../components/Buttons';
import {useForm} from 'react-hook-form';
import RadioButtons from '../../../../../components/RadioButtons';
import PriceComponent from '../../../../../components/PriceComponent';
import {updatePropertyActions} from "../../../../../../../../../../../redux/actions/updatePropertyActions";

const pageLabel = 'main_page.my_ads.create_ad.house_details.landlord_rules.level4';

const securityDepositInitialValue = (property, buttons) => {
  if (property?.securityDeposit) {
    let option = buttons.find(option => option.buttonValue === property.securityDeposit);
    if (option) return option.buttonValue;
    else return 'specific_value';
  }
};

const LandlordRules4 = ({updateProperty, t, currentLevel, property, changePage, defineUpdateFunc}) => {

  const SpecificValuePriceComponent = () => (
    <PriceComponent
      name={'securityDepositAmount'}
      disabled={currentProperty.securityDeposit !== 'specific_value'}
      value={!isNaN(Number(property.securityDeposit)) ? parseInt(property.securityDeposit) : ''}
      error={errors.securityDepositAmount}
      watchValue={watchFields.securityDepositAmount}
      register={register}
      rules={currentProperty.securityDeposit === 'specific_value' ? {
        required: {value: currentProperty.securityDeposit === 'specific_value', message: t(pageLabel + '.securityDepositAmount.error')},
        validate: value => {
          if(currentProperty.securityDeposit === 'specific_value')
            return (value > 0 || t(pageLabel + '.securityDepositAmount.validation'));
          else return true;
        }
      } : undefined}/>);

  const radioButtons = {
    securityDepositSwitch: [{id: 'SecurityDepositSwitch', buttonValue: 'true', label: 'yes'},
      {id: 'no_SecurityDepositSwitch', buttonValue: 'false', label: 'no'}],

    securityDeposit: [{id: '1/2month', buttonValue: '1/2month', label: pageLabel + '.securityDeposit.1/2month'},
      {id: '1month', buttonValue: '1month', label: pageLabel + '.securityDeposit.1month'},
      {id: '3/2months', buttonValue: '3/2months', label: pageLabel + '.securityDeposit.3/2months'},
      {id: '2months', buttonValue: '2months', label: pageLabel + '.securityDeposit.2months'},
      {id: '5/2months', buttonValue: '5/2months', label: pageLabel + '.securityDeposit.5/2months'},
      {id: '3months', buttonValue: '3months', label: pageLabel + '.securityDeposit.3months'},
      {
        id: 'specific_value', buttonValue: 'specific_value', label: pageLabel + '.securityDeposit.specific_value',
        component: SpecificValuePriceComponent, className: 'inline'
      }],

    paidAtMovinDate: [{id: 'security', buttonValue: 'Security Deposit', label: pageLabel + '.paidAtMovinDate.security'},
      {
        id: 'securityAndRent',
        buttonValue: 'Security Deposit + Last Rent',
        label: pageLabel + '.paidAtMovinDate.securityAndRent'
      }],

    incompleteRentType: [{
      id: 'daily',
      buttonValue: 'daily',
      label: pageLabel + '.incompleteRentType.daily.label',
      info: pageLabel + '.incompleteRentType.daily.info',
      styleLabel: {flex: 0.2}
    },
      {
        id: 'biweekly',
        buttonValue: 'biweekly',
        label: pageLabel + '.incompleteRentType.biweekly.label',
        info: pageLabel + '.incompleteRentType.biweekly.info',
        styleLabel: {flex: 0.2}
      },
      {
        id: 'monthly',
        buttonValue: 'monthly',
        label: pageLabel + '.incompleteRentType.monthly.label',
        info: pageLabel + '.incompleteRentType.monthly.info',
        styleLabel: {flex: 0.2}
      }],

    refundPolicy: [{
      id: '15',
      buttonValue: '15',
      label: pageLabel + '.refundPolicy.15.label',
      info: pageLabel + '.refundPolicy.15.info',
      styleLabel: {flex: 0.2}
    },
      {
        id: '30',
        buttonValue: '30',
        label: pageLabel + '.refundPolicy.30.label',
        info: pageLabel + '.refundPolicy.30.info',
        styleLabel: {flex: 0.2}
      },
      {
        id: '60',
        buttonValue: '60',
        label: pageLabel + '.refundPolicy.60.label',
        info: pageLabel + '.refundPolicy.60.info',
        styleLabel: {flex: 0.2}
      },
      {id: '0', buttonValue: '0', label: pageLabel + '.refundPolicy.0.label'}]
  };

  const [currentProperty, setCurrentProperty] = useState(() => {
    return {
      ...property,
      paidAtMovinDate: property?.paidAtMovinDate ? property.paidAtMovinDate : 'Security Deposit',
      incompleteRentType: property?.incompleteRentType ? property.incompleteRentType : 'biweekly',
      refundPolicy: property?.hasOwnProperty("refundPolicy") ? property.refundPolicy : '15',
      securityDepositSwitch: property.securityDeposit ? (!!property.securityDeposit).toString() : 'false',
      securityDeposit: securityDepositInitialValue(property, radioButtons.securityDeposit)
    }
  });

  const handlePropertyFieldChange = (field, value) => {
    setCurrentProperty(prevState => ({...prevState, [field]: value}))
  };

  const onSubmit = async (values) => {

    if(values.securityDepositSwitch==='false'){
      values.securityDeposit=null;
    }

    values.securityDeposit = values.securityDeposit === 'specific_value'
      ? values.securityDepositAmount
      : values.securityDeposit;

    await updateProperty(values, currentLevel);
  };

  const {handleSubmit, register, errors, control, watch, formState} = useForm({shouldFocusError: true});

  const watchFields = watch(['securityDepositSwitch', 'securityDeposit', 'securityDepositAmount']);

  useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
    if(!formState.isDirty) return resolve();
    await handleSubmit(async (values) => {
      await onSubmit(values);
      resolve()
    }, resolve)()
  })), [currentProperty, formState.isDirty]);

  return (
    <div className={'LandlordRules4'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'scrollable'}>
          <div className="LandlordRules_container">
            <div className={'input_container'}>
              <label htmlFor={'securityDepositSwitch'}>{`${t(pageLabel + '.securityDeposit.label')}`}</label>
              <RadioButtons radioButtons={radioButtons.securityDepositSwitch} name={'securityDepositSwitch'}
                            value={currentProperty.securityDepositSwitch}
                            defaultValue={currentProperty.securityDepositSwitch}
                            setValue={handlePropertyFieldChange} t={t} type={'double'}
                            control={control} rules={{required: true}}/>
              {currentProperty?.securityDepositSwitch === 'true' &&
               <RadioButtons id={'securityDeposit'} radioButtons={radioButtons.securityDeposit} name={'securityDeposit'}
                             value={currentProperty.securityDeposit} t={t} className={'double second_input_container'}
                             watchValue={watchFields.securityDeposit}
                             control={control} rules={{required: true}} setValue={handlePropertyFieldChange}/>}
            </div>
          </div>

          <div className="LandlordRules_container">
            <div className={'input_container'}>
              <label htmlFor={'paidAtMovinDate'}>{`${t(pageLabel + '.paidAtMovinDate.label')}`}</label>
              <RadioButtons radioButtons={radioButtons.paidAtMovinDate} name={'paidAtMovinDate'}
                            value={currentProperty.paidAtMovinDate}
                            t={t} className={'double margin_bottom'}
                            control={control} rules={{required: true}}/>
            </div>
          </div>

          <div className="LandlordRules_container">
            <div className={'input_container'}>
              <label htmlFor={'incompleteRentType'}>{`${t(pageLabel + '.incompleteRentType.label')}`}</label>
              <RadioButtons radioButtons={radioButtons.incompleteRentType} name={'incompleteRentType'}
                            value={currentProperty.incompleteRentType}
                            t={t} className={'margin_bottom'}
                            control={control} rules={{required: true}} type={'info'}/>
            </div>
          </div>

          <div className="LandlordRules_container LandlordRules_container_last">
            <div className={'input_container'}>
              <label htmlFor={'refundPolicy'}>{`${t(pageLabel + '.refundPolicy.label')}`}</label>
              <RadioButtons radioButtons={radioButtons.refundPolicy} name={'refundPolicy'}
                            value={currentProperty.refundPolicy}
                            s t={t} className={'margin_bottom'}
                            control={control} rules={{required: true}} type={'info'}/>
            </div>
          </div>
        </div>
        <Buttons saveAndExit={handleSubmit(v=>onSubmit({...v, saveAndExit: true}))} changePage={changePage}/>
      </form>
    </div>
  );
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(LandlordRules4);
