import React, { useState, useMemo, useEffect } from "react";
import "./style.less";

import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../../../../../../../../../../../components/DatePickerRange";
import CirclePlusIcon from "../../../../../../../../../../../../../components/svg/CirclePlus";
import infoIcon from "../OverviewCalendar/images/infoIcon.svg";
import Tooltip from "../../../../../../../../../../../../../components/Tooltip/index.js";

const AvailabilitySlots = ({
  value,
  watch,
  errors,
  control,
  pageLabel,
  clearErrors,
  getValues,
  setValue,
  register,
  setError,
  setForcedSave,
  integrationKey
}) => {
  const { t, i18n } = useTranslation();
  const NOT_ADDED = 1;
  const ADDED = 2;

  const slots = watch("availability_periods");

  const initialAvailabilities = useMemo(() => {
    const id = Date.now();

    if (!value || value.length === 0) {
      return [{
        id,
        move_in: {
          start: { day: null, month: null },
          end: { day: null, month: null }
        },
        move_out: {
          start: { day: null, month: null },
          end: { day: null, month: null }
        },
        added: NOT_ADDED
      }]
    } else {
      return value.map((item, index) => {

        return {
          id: id + index,
          move_in: {
            start: {
              day: item.move_in.start?.day,
              month: item.move_in.start?.month,
            },
            end: {
              day: item.move_in.end?.day,
              month: item.move_in.end?.month,
            }
          },
          move_out: {
            start: {
              day: item.move_out.start?.day,
              month: item.move_out.start?.month,
            },
            end: {
              day: item.move_out.end?.day,
              month: item.move_out.end?.month,
            }
          },
          added: ADDED,
          temporary_year: item.temporary_year
        };
      });
    }
  }, [value]);

  const [availabilitySlotAdded, setAvailabilitySlotAdded] = useState(false);
  const [availabilities, setAvailabilities] = useState(initialAvailabilities);
  const [exceededLimit, setExceededLimit] = useState(false);

/*  useEffect(() => {
    if (availabilities.length >= 5) {
      if (!exceededLimit) {
        setExceededLimit(true);
      }
    } else {
      if (exceededLimit) {
        setExceededLimit(false);
      }
    }
  }, [availabilities.length, exceededLimit])*/

  let canInsertAvailabilities = availabilities.filter(a => !a.temporary_year).length < 5;

  const addAvailability = () => {
    if (!canInsertAvailabilities) {
      setExceededLimit(true);
      return;
    }
    setExceededLimit(false);

    setAvailabilities([...availabilities, {
      id: Date.now() + 1,
      move_in: {
        start: { day: null, month: null },
        end: { day: null, month: null }
      },
      move_out: {
        start: { day: null, month: null },
        end: { day: null, month: null }
      },
      added: NOT_ADDED
    }]);
  };

  const removeAvailability = (index, id) => {
    setForcedSave(true);
    const availabilityNewArray = availabilities.filter((item, idx) => {
      return idx !== index || item.id !== id;
    });

    if (availabilityNewArray.length === 0) {
      const newId = Date.now();
      const newAvailability = [{
        id: newId,
        move_in: {
          start: { day: null, month: null },
          end: { day: null, month: null }
        },
        move_out: {
          start: { day: null, month: null },
          end: { day: null, month: null }
        },
        added: NOT_ADDED
      }];
      setAvailabilities(newAvailability);
    } else {
      setAvailabilities(availabilityNewArray);
    }
  };

  const handleAddAvailability = async (index) => {
    const availabilityVariable = `availability_periods[${index}]`;

    const startValue = getValues(`${availabilityVariable}[move_in]`);
    let hasErrors = false;

    if (!startValue || !startValue[0] || !startValue[1]) {
      setError(`${availabilityVariable}[move_in]`, {
        type: "required",
        message: "move_in"
      });
      hasErrors = true;
    } else {
      clearErrors(`${availabilityVariable}[move_in]`);
    }

    const endValue = getValues(`${availabilityVariable}[move_out]`);
    if (!endValue || !endValue[0] || !endValue[1]) {
      setError(`${availabilityVariable}[move_out]`, {
        type: "required",
        message: "move_out"
      });
      hasErrors = true;
    } else {
      clearErrors(`${availabilityVariable}[move_out]`);
    }

    let moveOutStart = new Date(endValue[0]);
    moveOutStart.setFullYear(startValue[0].getFullYear());

    if (moveOutStart >= startValue[0] && moveOutStart <= startValue[1]) {
      setError(`invalid_availability`, {
        type: "invalid",
        message: "overlap"
      });
      hasErrors = true;
    } else {
      clearErrors(`invalid_availability`);
    }

    if (!hasErrors) {
      const updatedSlots = availabilities.map((slot, i) => {

        return { ...slot, added: ADDED };
      });

      setAvailabilities(updatedSlots);
      setValue(`${availabilityVariable}[added]`, 2, { shouldValidate: true });
      setAvailabilitySlotAdded(true);
      setTimeout(() => setAvailabilitySlotAdded(false), 5000);
    }
  };

  const formatDateToISOString = (day, month) => {
    if (!(day && month)) {
      return;
    }

    const year = new Date().getFullYear();
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");

    const formattedDateString = `${year}-${formattedMonth}-${formattedDay}`;
    const formattedDate = new Date(formattedDateString);

    return formattedDate.toISOString();
  };

  const currentlyInserting = availabilities.some(availability => availability.added !== ADDED);

  return (
    <div className={"AvailabilitySlots basic_container"}>
      <div className={"AvailabilitySlots__selectors"}>

        {availabilities.map((availability, index) => {
          const formattedStart = [
            formatDateToISOString(availability.move_in.start.day, availability.move_in.start.month),
            formatDateToISOString(availability.move_in.end.day, availability.move_in.end.month)
          ]

          const formattedEnd = [
            formatDateToISOString(availability.move_out.start.day, availability.move_out.start.month),
            formatDateToISOString(availability.move_out.end.day, availability.move_out.end.month)
          ]

          return (
            <div key={availability.id} className={"AvailabilitySlots__selector"}>
              <div className={"AvailabilitySlots__selector__container--datepicker"}>
                <p>{t(pageLabel + ".availabilitySlots.fixedMoveIn")}</p>

                <DatePickerRange
                  name={`availability_periods[${index}][move_in]`}
                  control={control}
                  dateFormat="dd/MM"
                  placeholder="dd/mm - dd/mm"
                  selected={formattedStart}
                  selectedStart={formattedStart && Date.parse(formattedStart[0])}
                  selectedEnd={formattedStart && Date.parse(formattedStart[1])}
                  maxMonthLimit={6}
                  /*excludeDateIntervals={excludedDates}*/
                  disabled={availability.added === 2 || integrationKey}
                  onChange={() => clearErrors(`availability_periods[${index}][move_in]`)}
                  className={`input_field basic_container 
                            ${(errors.availability_periods?.[index]?.move_in || errors.invalid_availability) ? "input_error" : null}
                          `}
                  isClearable={availability.added === 2 || integrationKey ? false : true}
                  showIcon={true}
                  showMonthDropdown={true}
                />
              </div>

              <div className={"AvailabilitySlots__selector__container--datepicker"}>
                <p>{t(pageLabel + ".availabilitySlots.fixedMoveOut")}</p>

                <DatePickerRange
                  name={`availability_periods[${index}][move_out]`}
                  control={control}
                  dateFormat="dd/MM"
                  placeholder="dd/mm - dd/mm"
                  selected={formattedEnd}
                  selectedStart={formattedEnd && Date.parse(formattedEnd[0])}
                  selectedEnd={formattedEnd && Date.parse(formattedEnd[1])}
                  maxMonthLimit={6}
                  /*excludeDateIntervals={excludedDates}*/
                  disabled={availability.added === 2 || integrationKey}
                  onChange={() => clearErrors(`availability_periods[${index}][move_out]`)}
                  className={`input_field basic_container 
                            ${(errors.availability_periods?.[index]?.move_out || errors.invalid_availability) ? "input_error" : null}
                          `}
                  isClearable={availability.added === 2 || integrationKey ? false : true}
                  showIcon={true}
                  showMonthDropdown={true}
                />
              </div>

              {/*Simply serves to check if the add button has already been clicked*/}
              <input
                style={{ display: "none" }}
                onWheel={(e) => e.target.blur()}
                type={"number"}
                name={`availability_periods[${index}][added]`}
                ref={register()}
                defaultValue={availability.added}
              />
              <input
                  style={{ display: "none" }}
                  onWheel={(e) => e.target.blur()}
                  type={"number"}
                  name={`availability_periods[${index}][temporary_year]`}
                  ref={register()}
                  defaultValue={availability.temporary_year}
              />
              <div className={"AvailabilitySlots__selector__container--datepicker"}>
                {availability.temporary_year &&
                    <Tooltip position="left" delay={200}>
                      <Tooltip.Icon>
                        <p>{t(pageLabel + ".availabilitySlots.temporary")} <img src={infoIcon} className="infoIcon" alt={"info icon"} /></p>
                      </Tooltip.Icon>
                      <Tooltip.Content>
                        {t(pageLabel + ".availabilitySlots.temporaryDesc", {year: availability.temporary_year})}
                      </Tooltip.Content>
                    </Tooltip>}
                {availability.added == 1 && (
                  <button
                    type={"button"}
                    className={"button add"}
                    onClick={() => handleAddAvailability(index)}
                    disabled={integrationKey}>
                    {t("buttons.add")}
                  </button>
                )}

                {availability.added == 2 && (
                  <button
                    type={"button"}
                    className={"button remove"}
                    onClick={() => removeAvailability(index, availability.id)}
                    disabled={integrationKey}>
                    {t("buttons.remove")}
                  </button>
                )}
              </div>
            </div>
          );
        })}

        <div
          className={`AvailabilitySlots__add-availability ${((!canInsertAvailabilities)||currentlyInserting) ? "disabled" : ""}`}
          onClick={() => {
            if (!integrationKey && !currentlyInserting) addAvailability();
          }}>
          <CirclePlusIcon />
          <p>{t(pageLabel + ".availabilitySlots.addMore")}</p>
        </div>
      </div>

      {errors.invalid_availability && (
        <p className={"error_message"}>{t(pageLabel + ".availabilitySlots.invalid." + errors.invalid_availability.message)}</p>
      )}

      {errors.availability && (
        <p className={"error_message"}>{t(pageLabel + ".availabilitySlots.error")}</p>
      )}

      {availabilitySlotAdded && (
        <p className={"success_message"}>{t(pageLabel + ".availabilitySlots.success")}</p>
      )}

      {exceededLimit && (
        <p className={"error_message"}>{t(pageLabel + ".availabilitySlots.exceededLimit")}</p>
      )}
    </div>
  );
};

export default AvailabilitySlots;
