import React, {useEffect, useMemo, useState} from 'react';
import './style.less';
import {useTranslation} from 'react-i18next';
import logo from '../../images/logo.svg';
import {useHistory, useLocation} from "react-router-dom";
import {dashboardServices, userServices} from "../../services";
import DropdownInput from "../DropdownInput";
import LandlordNotification from "../LandlordNotification";
import endSessionIconRed from "../../pages/MainPage/containers/MyAccount/containers/images/endSessionIconRed.svg";
import notificationBell from "./images/notifications_black.svg";
import notificationBellBlue from "./images/notifications_blue_24px.svg";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import avatarMale from "../VisitCard/images/avatar_male.png";
import {isSm} from "../../utils/responsive";
import defaultAvatar from "../MainNavBar/images/avatar_placeholder.svg";
import {useAuth} from "../../services/authServices";
import cogoToast from 'cogo-toast';
const { io } = require("socket.io-client");
const socket = io("/");

const MainNavBar = () => {
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng').split('-')[0])
    const auth = useAuth();

  const [videoOpen, setVideoOpen] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const {t, i18n} = useTranslation();

  const isActive = (path, match) => !!(match || path === location.pathname);

  const [notifications, setNotifications] = useState([]);

  useEffect(()=>{
      if(auth.user?.notificationToken) {
          socket.emit("subscribe", {token: auth.user.notificationToken});
      }
  },[auth.user?.notificationToken])


    useEffect(() => {
        socket.on("connect", () => {
            //console.log("connected to socket io:", socket.connected);
        });
        socket.on("connect_error", (err) => {
            //console.log("error connecting to socket", err)
            setTimeout(() => {
                socket.connect();
            }, 1000);
        });
        socket.on("notification", (newNotification) => {
            if((!newNotification.visibleFrom || moment(newNotification.visibleFrom).isBefore(moment())) && (!newNotification.timeout || moment(newNotification.timeout).isAfter(moment()))) {
                setNotifications(prev => [newNotification, ...prev])
                const {hide} = cogoToast.info(t("notifications." + newNotification.type + ".title"), {position: 'top-right', onClick: () => {hide();setOpenNotifications(true)}, hideAfter: 5})
            }
        });
    }, []);



  const isSmallScreen = useMemo(() => {
      return isSm();
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang).then(() => {
      localStorage.setItem('i18nextLng', lang);
      setLanguage(lang);
    });
  }

    useEffect(() => {
        setNotifications(auth.user?.notifications?.filter(n => {
            return (!n.visibleFrom || moment(n.visibleFrom).isBefore(moment())) && (!n.timeout || moment(n.timeout).isAfter(moment()))
        }).sort((a, b) => {
            return moment(b.date) - moment(a.date)
        }))
    }, [auth?.user])


    let isStatic = location.pathname === '/' || location.pathname === '/how-to';

    let isMyAccount = location.pathname.split("/")?.[1] === 'my_account';


    const navActive = (nav) => {
        if (nav.toLowerCase() === "/dashboard" && window.location.pathname.toLowerCase() === nav.toLowerCase()) return "active";
        if (nav.toLowerCase() !== "/dashboard" && window.location.pathname.toLowerCase().includes(nav.toLowerCase())) return "active"
    };


  const handleLogout = async () => {
    await userServices.logout();
    window.location.href = "/start/login";
  }

  const hrefWrapper = (e) => {
    e.preventDefault();
    let next = (e.target.href || e.target.parentElement.href).replace(window.location.origin, '');
    history.push(next);
  };


  const handleOpen = (urlId) => {
    setVideoOpen(urlId);
  };

  const handleClose = () => {
    setVideoOpen(null);
  };

  const VideoDialog = () => {
    return (videoOpen && <Dialog
        id="video-dialog"
        open={!!videoOpen}
        keepMounted
        className={'VideoDialog'}
        onClose={handleClose}
    >
      <iframe width="850" height="550" src={'https://www.youtube.com/embed/' + videoOpen + "?autoplay=1"}
              allowFullScreen={true}
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
    </Dialog>)
  }
  const NavBarMenu = ({desktop, close = ()=>{}}) => {
    return <div className={"NavBar__menu-container desktop-menu"} onClick={() => {if(!isSmallScreen) close()}}>
        <div className={"NavBar__menu-body__photo"}>
            <div className={"NavBar__menu-body__photo-avatar"} style={{"backgroundImage": `url("${auth.user?.photo || avatarMale}")`}}/>
            <LanguageDropDown/>
        </div>
      <div className={"NavBar__menu-body desktop"}>
        <a href={'/dashboard'} onClick={hrefWrapper} className={navActive('/dashboard')} >{t('navbar.reservedArea')}</a>
        <a href={'/my_account/account_details'} onClick={hrefWrapper} className={navActive('/my_account/account_details')}>{t('navbar.accountSettings')}</a>
        <a href={'/how-to'} onClick={hrefWrapper} className={navActive('/how-to')} >{t('navbar.how_it_works')}</a>
        <div className={"separator"}/>
        <a onClick={handleLogout} className={"logout-button"}><img src={endSessionIconRed} alt={"icon"}/> {t('end_session')}</a>
      </div>
        <div className={"NavBar__menu-body mobile"}>
            {!isMyAccount ?
                <p>{t('navbar.reservedArea')}</p>
                :
                <p className={(isMyAccount ? "isMyAccount" : "")}> <a href={'/dashboard'} onClick={hrefWrapper} className={navActive('/dashboard')} >{t('navbar.reservedArea')}</a></p>
            }
            {isMyAccount &&
                <p>{t('navbar.accountSettings')}</p>
            }
            {isMyAccount ?
                <>
                    <a href={'/my_account/account_details'} onClick={hrefWrapper} className={navActive('/my_account/account_details')} >{t('navbar.account_details')}</a>
            {/* <a href={'/my_account/users'} onClick={hrefWrapper} className={navActive('/my_account/users')}>{t('navbar.users')}</a> */}
                    <a href={'/my_account/notifications'} onClick={hrefWrapper} className={navActive('/my_account/notifications')} >{t('navbar.notifications')}</a>
                    <a href={'/my_account/verifications'} onClick={hrefWrapper} className={navActive('/my_account/verifications')} >{t('navbar.verifications')}</a>
                    <a href={'/my_account/billing_details'} onClick={hrefWrapper} className={navActive('/my_account/billing_details')}>{t('navbar.billing_details')}</a>
                    <a href={'/my_account/billing_history'} onClick={hrefWrapper} className={navActive('/my_account/billing_history')} >{t('navbar.billing_history')}</a>
                </>
                :
                <>
                    <a href={'/dashboard'} onClick={hrefWrapper} className={navActive('/dashboard')} >{t('navbar.dashboard')}</a>
            <a href={'/radar'} onClick={hrefWrapper} className={navActive('/radar')}>{t('navbar.radar')}</a>
                    <a href={'/reservations'} onClick={hrefWrapper} className={navActive('/reservations')} >{t('navbar.reservations')}</a>
                    <a href={'/my_ads'} onClick={hrefWrapper} className={navActive('/my_ads')} >{t('navbar.ads')}</a>
                    <a href={'/my_visits'} onClick={hrefWrapper} className={navActive('/my_visits')}>{t('navbar.visits')}</a>
                 {/*   <a href={'/my_calendar'} onClick={hrefWrapper} className={navActive('/my_calendar')} >{t('navbar.calendar')}</a>*/}
                </>
            }
            <div className={"separator"}/>
            {!isMyAccount && <a href={'/my_account/account_details'} onClick={hrefWrapper} className={navActive('/my_account/account_details')}>{t('navbar.accountSettings')}</a>}
            <a href={'/how-to'} onClick={hrefWrapper} className={navActive('/how-to')} >{t('navbar.how_it_works')}</a>
            <a onClick={handleLogout} className={"logout-button"}><img src={endSessionIconRed} alt={"icon"}/> {t('end_session')}</a>
        </div>
    </div>
  }

    const [notificationsIcon, setNotificationsIcon] = useState(notificationBell);
    const [openNotifications, setOpenNotifications] = useState(false);


    const NotificationsList = ({close = ()=>{}}) => {
    return <div className={"Notifications"}>
      {notifications?.length ? notifications.map(notification => {
        return <LandlordNotification notification={notification} setNotifications={setNotifications} key={notification.id} close={close}/>}) :
          <div className={"no-notifications"}>
            <div className={"bell-wrapper"}><img src={notificationBell} /></div>
            <div className={"text"}>
              <span>{t("notifications.noNotifications.title")}</span>
              <span>{t("notifications.noNotifications.subtitle")}</span>
            </div>
          </div>
      }
    </div>
  }


    const LanguageDropDown = () => {
        return (
            <DropdownInput
                usePortal
                dropdownClassName={"language-dropdown"}
                closeOnChange triggerStyle={"simple"}
                onOpen={() => {
                    let element = document.getElementById("dropdown-language");
                    element.classList.add("active")
                }}
                onClose={() => {
                    let element = document.getElementById("dropdown-language");
                    element.classList.remove("active")
                }}
                onChange={async lang => {
                    changeLanguage(lang);
                }}
                value={language}
                renderDropdown={(val, onChange) => <div className="nav-language">
                    <h5 onClick={() => onChange(i18n.language)}>{t("lang.title")}</h5>
                    {["en", "pt", "it", "es"].map(lang => {
                        return <div key={lang} onClick={() => onChange(lang)}>
                            <div className={"selected-bar" + (language === lang ? " active" : "")}/>
                            <div
                                style={{backgroundImage: `url(https://github.com/madebybowtie/FlagKit/raw/master/Assets/PNG/${(lang === "en" ? "gb" : lang).toUpperCase()}@2x.png?raw=true)`}}
                                className={"flag"}/>
                            <span
                                className={`country-name ${language === lang ? "active" : ""}`}>{t("lang." + lang)}</span>
                        </div>
                    })}
                </div>} valueRenderer={val => <span className={"language-value"}
                                                    id={"dropdown-language"}>{val.toUpperCase()}</span>}/>
        )
    }

    //TODO menu stay under the language menu
    const MenuDropDown = () => {
        return (
            <DropdownInput
                dropdownClassName={"dropdown-menu"}
                onOpen={() => {
                    let element = document.getElementById("dropdown-avatar");
                    element.classList.add("nav_link")
                    element.classList.add("active")
                }}
                onClose={() => {
                    let element = document.getElementById("dropdown-avatar");
                    element.classList.remove("nav_link")
                    element.classList.remove("active")
                }}
                valueRenderer={(val, open) => <div className={"dropdown-menu"}>
                    <div className={'logged_user'}>
                        <a id={"dropdown-avatar"}>
                            <div className={'avatar'} style={{backgroundImage: `url(${auth.user.photo || defaultAvatar})`}}/>
                        </a>
                    </div>
                </div>}
                wrapperClassName={'nav-auth-container'}
                triggerStyle={"simple"} usePortal strippedDropdown
                renderDropdown={(val, onChange, close) =>
                    <NavBarMenu close={close} desktop/>}/>
        )
    }

    return (
        <nav className={`MainNavBar${isStatic ? ' hide-border' : ''}`}>
            <div className="container">
                <div className={'navbar__title-container navbar__container'}>
          <span className={`navbar__brand nav_link navbar__title`}
              onClick={()=>{history.push(auth.user ? '/dashboard' : '/')}}>
            <img alt={'Inlife Landlord'} className={'hide_mobile'} src={logo}/>
            <p>{t('navbar.for_landlords')}</p>
          </span>
        </div>

        <div className="navbar__aux navbar__container">
          <div className="navbar__nav navbar__container">
            {(!auth.user) && <span
                className={`nav_link ${location.pathname==='/'?'is-active':''}`}
                onClick={()=>{history.push('/')}}>
              {t('navbar.be_a_landlord')}
            </span>}

            {auth.user ? <>
              <span className={"new-features"} onClick={() => handleOpen("jYv3e-jAlYo")}>{t("navbar.newFeatures")}</span>
              <VideoDialog />
            </> : <span
                className={`nav_link ${location.pathname==='/how-to'?'is-active':''}`}
                onClick={()=>{
                  if(isStatic || !auth.user) {
                    history.push('/how-to')
                  } else {
                    window.open('/how-to', '_blank');
                  }
                }}>
              {t('navbar.how_it_works')}
            </span>}

            {/*<NavLink to={'/my_account'} className="nav_link" id="feedback">
              <BsBookmark/> Feedback
            </NavLink>*/}
          </div>


                {auth.user ? (<>
                    <DropdownInput
                        placement={"bottom-end"}
                        offset={[0, 31]}
                        dropdownClassName={"notifications-dropdown"}
                        hideChevron
                        openDropdown={openNotifications}
                        onOpen={async () => {
                            setNotificationsIcon(notificationBellBlue)
                            if (auth.user?.notifications?.some(n => !n.read)) {
                                await dashboardServices.readNotifications();
                            }
                        }}
                        onClose={() => {
                            setNotificationsIcon(notificationBell)
                            setNotifications(prev => {
                                return prev.map(n => {
                                    n.read = true
                                    return n;
                                });
                            })
                        }}
                        valueRenderer={(val, open) => <div className={"notifications-bell"}>
                            <img src={notificationsIcon}/>
                            {notifications?.filter(n => !n.read).length > 0 && <div
                                className={"notifications-number"}>{notifications?.filter(n => !n.read).length > 99 ? "+99" :  notifications?.filter(n => !n.read).length}</div>}
                        </div>}
                        wrapperClassName={'nav-auth-container'}
                        triggerStyle={"simple"}
                        usePortal
                        strippedDropdown
                        renderDropdown={(val, onChange, close) => <>
                            <NotificationsList close={close} desktop/>
                            {!!notifications?.length &&
                            <div className={"clear-button link link_blue"} onClick={async () => {
                                setNotifications([]);
                                await dashboardServices.clearNotifications();
                            }
                            }>{t("notifications.clearAll")}</div>}
                        </>}/>

                    <MenuDropDown/>
                </>) : (!location.pathname.startsWith("/start") && <div className={'login_button'}>
                    <button type="button" className="btn btn_blue" onClick={() => {
                        history.push('/start/login');
                        //document.getElementById("metaviewport").content = "width=1280";
                    }}>
                        <p>{t('start.login.nav')}</p>
                    </button>
                </div>)
                }
            {auth.user ?
                <div className={"language-dropdown_Container"}>
                    <LanguageDropDown/>
                </div>
                :  <LanguageDropDown/>
            }


          {/*<div className="notifications container">*/}
          {/*  <IoIosNotificationsOutline />*/}
          {/*</div>*/}

          {/*{loginState.loggedIn ?*/}
          {/*  <NavLink to={"/my_account"} className="nav_link" isActive={isActive.bind(this, '/my_account')}>*/}
          {/*    <div*/}
          {/*      className={"avatar"}*/}
          {/*    />*/}
          {/*  </NavLink> :*/}
          {/*  <div*/}
          {/*    className={"avatar"}*/}
          {/*  />*/}
          {/*}*/}
        </div>
      </div>
    </nav>
  );
};


export default MainNavBar;
