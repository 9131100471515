import moment from "moment/moment";

const calcExpiration = (expirationDate) => {
    let vExp = "";
    let currentDate = moment();
    if (expirationDate < currentDate) return "00h00m";
    let diff = expirationDate - currentDate;
    let hours = Math.max(Math.floor(diff / 3600000), 0);
    let minutes = Math.max(Math.floor((diff - (hours * 3600000)) / 60000), 0);
    if (hours > 0) vExp += ((hours < 10 ? (hours < 1 ? "00" : "0") : "") + hours) + "h";
    if (minutes > 0) vExp += ((minutes < 10 ? (minutes < 1 ? "00" : "0") : "") + minutes) + "m";
    return vExp;
};

const getExpirationDate = (date) => {
    let currentDate = date ? moment(date) : moment();
    let businessHours = 0;
    while (businessHours < 24) {
        if (currentDate.day() >= 1 && currentDate.day() <= 5 && currentDate.hour() >= 8 && currentDate.hour() < 20)
            businessHours++;
        currentDate.add(1, 'hour');
    }
    return currentDate;
}


export {calcExpiration, getExpirationDate};
