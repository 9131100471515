import React from "react";

const PhoneValidated = (props) => (
    <svg
        width={200}
        height={114}
        viewBox="0 0 200 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={200} height={113.733} rx={30} fill="#F0F3F6" />
        <g opacity={0.4} clipPath="url(#clip0_2941_4670)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.872 31.457L27.3096 29.9895C28.8329 29.9895 30.08 31.2366 30.08 32.7599C30.08 36.2248 30.6326 39.5515 31.6593 42.6579C31.9655 43.6286 31.7415 44.7077 30.9649 45.4843L25.842 50.5353C29.8333 58.3798 37.0758 64.1248 44.9203 68.146L48.5 62.8286C49.0526 62.3021 49.747 62.0258 50.4676 62.0258C50.7439 62.0258 51.0501 62.052 51.3264 62.164C54.4328 63.1907 57.7857 63.7433 61.2244 63.7433C62.7477 63.7433 63.9948 64.9904 63.9948 66.5137V78.5798C63.9948 80.1032 62.7477 81.3502 61.2244 81.3502C35.1969 81.354 14.1016 60.2587 14.1016 34.2274C14.1016 32.7041 15.3486 31.457 16.872 31.457Z"
                fill="#A1BACA"
            />
        </g>
        <g opacity={0.9}>
            <rect
                x={141.867}
                y={56.1339}
                width={40.4633}
                height={40.4633}
                rx={20.2316}
                fill="#3EC28F"
            />
        </g>
        <g opacity={0.3}>
            <rect
                x={136.703}
                y={51.5797}
                width={50.9817}
                height={49.8969}
                rx={24.9485}
                fill="#3EC28F"
            />
        </g>
        <path
            d="M170.597 70.0767C170.207 69.6839 169.571 69.6827 169.18 70.0742L159.485 79.7694C159.095 80.1594 158.463 80.16 158.072 79.7708L155.119 76.8297C154.729 76.4405 154.097 76.4411 153.707 76.8311L152.742 77.7955C152.352 78.186 152.352 78.8192 152.742 79.2097L158.072 84.5394C158.462 84.9299 159.096 84.9299 159.486 84.5394L171.562 72.4633C171.952 72.0738 171.953 71.4425 171.565 71.0516L170.597 70.0767Z"
            fill="#FEFEFE"
        />
        <rect
            x={75.9297}
            y={37.9653}
            width={53.5043}
            height={13.867}
            rx={4}
            fill="#9DB5C6"
        />
        <rect
            opacity={0.4}
            x={75.9297}
            y={61.9223}
            width={39.7554}
            height={13.867}
            rx={4}
            fill="#9DB5C6"
        />
        <defs>
            <clipPath id="clip0_2941_4670">
                <rect
                    width={49.8969}
                    height={49.8969}
                    fill="white"
                    transform="translate(14.1016 31.457)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default PhoneValidated;
