import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import './style.less'
import {displayFooterActions} from "../../redux/actions";
import { connect } from "react-redux";
import {useHistory} from "react-router-dom";
import Loading from "../../components/Loading";
import MetaTags from "react-meta-tags";

const Static = ({displayFooter, page = "landing-landlords"}) => {

    const history = useHistory();
    const {i18n} = useTranslation();

    const [showBorder, setShowBorder] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [clearLoading, setClearLoading] = useState(false);
    const [lang, setLang] = useState(localStorage.getItem('i18nextLng')?.split('-')[0]);

    useEffect(()=>{
        displayFooter(false);

        window.onmessage = (e) => {
            if(typeof e.data === 'string') {
                if(e.data.startsWith("goto:")) {
                    let goto = e.data.split("goto:")[1];
                    history.push(goto);
                    if(goto !== '/how-to' && goto !== "/") {
                        // document.getElementById("metaviewport").content="width=1280";
                    }
                } else if (e.data.startsWith("border:")) {
                    setShowBorder(e.data.split("border:")[1] === "show");
                }
            }
        }

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            displayFooter(true);
            i18n.off('languageChanged', handleLanguageChange);
            window.onmessage = null;
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
    },[lang, page]);

    const handleLanguageChange = (lang) => {
        setLang(lang);
    };

    let iframeSrc = "https://internal-static.inlifehousing.com/" + page + "-" + lang.split("-")[0].toLowerCase();

    return <div className={`Static ${showBorder?'show-border':''}`}>
        {isLoading && <Loading timeout={2000} cover={0.3} clear={clearLoading}/>}
        {(page === "/how-to" || page === "/como-funciona-landlords") && <MetaTags>
            <title>Como funciona a Inlife</title>
            <meta name="og:title" content="Como funciona a Inlife"/>
            <meta name="og:description" content="Pretende arrendar a sua propriedade? Saiba como garantir um rendimento estável com a Inlife."/>
        </MetaTags>}
        <iframe src={iframeSrc} onLoad={()=>{
            setClearLoading(true);
            setTimeout(()=>{
                setIsLoading(false);
                setClearLoading(false);
            }, 400);
        }}/>
    </div>
}

function mapStateToProps(state) {
    return { displayFooter: state.footerDisplay };
}

const actionCreator = {
    displayFooter: displayFooterActions.display
};

export default connect(mapStateToProps, actionCreator)(Static);
