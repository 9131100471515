import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {userServices} from '../../../../../../services'
import {dashboardServices} from '../../../../../../services'

import "./style.less";
import saveIconWhite from "../../../../../../components/ResumeItem/images/saveIconWhite.svg";
import pencilIconGrey from "../images/penciIconGrey.svg";
import pencilIconBlue from "../images/penciIconBlue.svg";
import moment from "moment";
import avatarMale from "../../../../../../components/VisitCard/images/avatar_male.png";
import Loading from "../../../../../../components/Loading";
import Select from "react-select";
import MyAccountNav from "../MyAccountNav";
import ActionDialog from "../../../../../../components/ActionDialog";
import PhoneInput from "../../../../../../components/PhoneInput";
import {FlagControl, FlagOption} from "../../../../../../components/FlagControl";
import {dataServices, dataServices as dataService} from "../../../../../../services/dataServices";
import { Dialog, DialogContent } from "@material-ui/core";
import DeleteAccount from "../../../../../../components/DeleteAccount";
import useQuery from "../../../../../../utils/query";
import verifyPhone from "../../../../../Start/images/verify_phone.svg";
import verifySuccess from "../../../../../Start/images/verify_success.svg";
import close from "../../../MakeOffers/components/PotentialTenantsList/components/OfferPopOver/images/closeGrey.svg";

import {useAuth} from "../../../../../../services/authServices";
import ApiKeys from "../../../../../../components/ApiKeys";
import Tooltip from "../../../../../../components/Tooltip";
import InfoCircleIcon from "../../../../../../components/svg/InfoCircle";

const NewEmailDialog = ({open, onClose}) => {

    const {t} = useTranslation();
    const [newEmail, setNewEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const requestNewEmail = async () => {
        setLoading(true);
        try {
            await userServices.changeEmail(newEmail);
            setShowSuccess(true);
            setTimeout(() => {
                handleClose();
            }, 4000);
        } catch (e) {
            console.log(e)
            console.log(e.error?.message)
            setError(e.error?.message || "invalid_email");
        }
        setLoading(false);
    }

    const handleClose = () => {
        setNewEmail(null);
        setShowSuccess(false);
        setError(null);
        onClose();
    }


    return (
        <Dialog className={"NewEmailDialog"} open={open} onClose={onClose}>
            <DialogContent className={"NewEmailDialog__content"}>
                {loading ? <Loading/> : <>
                    <div className={"title"}>{t("account_details.new_email.title")}</div>
                    <input className={"input_field"} type={"email"} onChange={(e) => {
                        setNewEmail(e.target.value);
                        if (error) setError(null);
                    }}
                           value={newEmail} placeholder={"new-email@gmail.com"}/>
                    {error && <span className={"error_message"}>{t("account_details.errors." + error)}</span>}
                    {showSuccess &&
                        <span className={"success_message"}>{t("account_details.new_email.success_message")}</span>}
                </>}
                <div className={"buttons_container"}>
                    <button className={"btn btn_grey"} disabled={loading} onClick={handleClose}>{t("buttons.cancel")}</button>
                    <button className={"btn btn_blue"} disabled={loading} onClick={requestNewEmail}>{t("buttons.confirm")}</button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

const PhoneVerificationDialog = ({open, onClose, phone}) => {

    const {t} = useTranslation();
    const transDir = "account_details.phone_verification."
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [code, setCode] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const handleTokenDigit = (e, index) => {
        let input = e.target;
        let next = input.nextElementSibling;
        if (next && input.value) {
            next.focus();
        }
        setCode(prev => {
            let arr = prev.split("");
            arr[index] = input.value;
            return arr.join("");
        })
    }

    const handleBackSpace = (e) => {
        let input = e.target;
        let prev = input.previousElementSibling;
        if (prev && !input.value) {
            input.value = "";
            prev.focus();
        }
    }

    const handleArrowMove = (e) => {
        let input = e.target;
        let prev = input.previousElementSibling;
        let next = input.nextElementSibling;
        if (next && e.key === "ArrowRight") {
            next.focus();
        } else if (prev && e.key === "ArrowLeft") {
            prev.focus();
        }
    }

    const handlePaste = (e) => {
        e.preventDefault();
        let inputs = Array.from(document.getElementsByClassName("digit_input"));
        let value = e.clipboardData.getData("text");
        inputs.forEach((input, i) => {
            input.value = value[i] || ''
        })
    }

    const handleRequestCode = async () => {
        setLoading(true);
        try {
            await userServices.sendPhoneVerification();
            setError(null);
        } catch (e) {
            setError(e.error?.message || "invalid_phone");
        }
        setLoading(false);
    }
    const verifyCode = async () => {
        setLoading(true);
        try {
            await userServices.verifyPhone(code);
            setShowSuccess(true);
        } catch (e) {
            setError(e.error?.message || "invalid_code");
            setCode("");
        }
        setLoading(false);
    }

    return (
        <Dialog className={"PhoneVerificationDialog"} open={open} onClose={onClose}>
            <DialogContent className={"PhoneVerificationDialog__content"}>
                {loading ? <Loading/> : <>
                    <img className={"close_icon"} src={close} onClick={onClose}/>
                    <img src={showSuccess ? verifySuccess : verifyPhone} alt={"verify_phone"}/>
                    <div className={"title"}>{t(transDir + (showSuccess ? "success" : "title"))}</div>
                    {showSuccess ? <div className={"info"}>{t(transDir + "success_message")}</div> : <>
                    <p><Trans i18nKey={transDir + "insert_code"} components={{s: <b/>}} values={{phone}}/> </p>
                    <div className={"code_container"}>
                        <label className={"label"}>{t(transDir + "validation_code")}</label>
                        <div className={"input__wrapper"}>
                            {[0, 1, 2, 3, 4, 5].map((item) => <input id={"input_" + item} className={"digit_input"} onPaste={(e) => handlePaste(e)} onKeyDown={e => {
                                if (e.key === "Backspace") {
                                    handleBackSpace(e);
                                } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
                                    handleArrowMove(e);
                                }
                            }} onInput={(e) => handleTokenDigit(e, item)} type={"tel"} pattern={"[0-9]"} key={"input_" + item} maxLength={1}/>)}
                            <button className={"btn btn_blue"} disabled={code.length < 6} onClick={verifyCode}>{t(transDir + "validate")}</button>
                        </div>
                    </div>
                    {error && <span className={"error_message"}>{t("account_details.errors." + error)}</span>}
                    <div className={"info"}>
                        <Trans i18nKey={transDir + "info"} components={{a: <a className={"link link_blue"} onClick={handleRequestCode}/>}}/></div>
                    </>}
                </>}
            </DialogContent>
        </Dialog>
    )
}


const AccountDetails = (props) => {

    const {t} = useTranslation();


    const {handleSubmit, register, errors, formState, reset, setError, getValues} = useForm({shouldFocusError: true});

    const [dashboardInfo, setDashboardInfo] = useState(false);
    const [activeAds, setActiveAds] = useState();
    const [imageLoader, setImageLoader] = useState(false);
    const [changingType, setChangingType] = useState(false);
    const {user, setUser} = useAuth();
    const [initialDialCode, setInitialDialCode] = useState(user?.dialCode);
    const [userPhoto, setUserPhoto] = useState(user?.photo || avatarMale);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [openNewEmailDialog, setOpenNewEmailDialog] = useState(false);
    const [countries, setCountries] = useState([]);
    const [showNewEmailValidation, setShowNewEmailValidation] = useState(false);
    const [showPhoneVerification, setShowPhoneVerification] = useState(false);

    let query = useQuery();

    const languageOptions = ["en", "pt", "it", "es"].map(lang => ({value: lang, label: t("lang." + lang)}))

    const [timezoneOptions, setTimezoneOptions] = useState([]);

    useEffect(() => {
        (async () => {
            let _timezones = await dataServices.getTimezones();

            let timezoneLabels = Object.keys(_timezones).map(t => {
                let absOffset = Math.abs(_timezones[t].offset);
                let hoursOffset = Math.floor(absOffset);
                let minutesOffset = (absOffset - hoursOffset) * 60;
                let offsetLabel = "UTC" + (_timezones[t].offset >= 0 ? "+" : "-") + (hoursOffset > 9 ? hoursOffset : "0" + hoursOffset) + ":" + (minutesOffset > 9 ? minutesOffset : "0" + minutesOffset);
                return {value: t, label: offsetLabel + " • " + t + " (" + _timezones[t].desc + ")"}
            });
            setTimezoneOptions(timezoneLabels);
        })();
    }, []);

    useEffect(() => {
        if (query?.validateNewEmail) {
            setShowNewEmailValidation(true);
        }
    }, []);

    const submit = async (values) => {
        setImageLoader(true);
        let val = {};
        let password;
        Object.keys(formState.dirtyFields).filter(a => formState.dirtyFields[a]).forEach(field => {
            if (field === "password") {
                password = values.password;
            } else {
                val[field] = values[field];
            }
        });
        if (user.nationality) val = {...val, nationality: user.nationality}
        if (user.language) val = {...val, language: user.language}
        if (user.dialCode) val = {...val, dialCode: user.dialCode}
        if (user.timezone) val = {...val, timezone: user.timezone}
        try {
            if (password) {
                reset(await userServices.changePassword({"password": password}));
                setShowSuccessMessage(true);
            }
            let phoneChanged = (val.phone && user.phone !== val.phone) || (val.dialCode && val.dialCode !== initialDialCode);
            if (val) reset(await userServices.saveAccountDetails(val));
            if (phoneChanged) {
                setUser({...user, ...val, phone_verified: false})
                setShowPhoneVerification(true);
            } else {
                setUser({...user, ...val})
            }
        } catch (e) {
            if (e.validationErrors) {
                e.validationErrors.forEach(err => {
                    setError(err.param, {type: "string", message: t("error." + err.msg)});
                })
            }
        }
        setImageLoader(false);
    };

    useEffect(async () => {
        let _countries = countries;
        if (!countries.length) {
            _countries = (await dataService.getCountries()).map(c => ({value: c.iso2, label: t('country.' + c.iso2)}));
        } else {
            _countries = _countries.map(c => ({value: c.value, label: t('country.' + c.value)}))
        }
        setCountries(_countries);
    }, [localStorage?.getItem('i18nextLng')])


    useMemo(() => {
        dashboardServices.getIndicatorsInfo().then((r) => {
            setDashboardInfo(r);
        });
    }, [user])

    useMemo(async () => {
        if (user?.properties)
            setActiveAds(user?.properties.filter((p) => {
                return !p.disabled && !p.deleted
            }).length)
    }, [user]);

    const uploadProfilePicture = async (e) => {
        const file = e.target.files;
        if (!file || !file[0]) return;
        setImageLoader(true);
        if (file[0].length !== 0 && /^image/.test(file[0].type)) {
            const formData = new FormData();
            formData.append('photo', file[0], file[0].name);
            setUserPhoto((await userServices.saveProfilePicture(formData)).url);
        }
        setImageLoader(false);
    }


    const options = [
        {value: 'business', label: t("my_account.business")},
        {value: 'independent', label: t("my_account.independent")}
    ]

    const changeType = async (values) => {
        setImageLoader(true);
        try {
            setUser(await userServices.saveAccountDetails({"type": values.value}));
        } catch (e) {
            console.log(e);
        }
        setImageLoader(false);
        setChangingType(false);
    };

    const handleDialCodeChange = (o) => {
        if (!formState.isDirty) formState.isDirty = true;
        setUser(prev => ({...prev, "dialCode": "+" + o.dial}))
    }

    return (
        <div className={'AccountDetails'}>
            <div className={"AccountDetails__content"}>
                <MyAccountNav pages={props.pages}/>
                <div className="AccountDetails__profile-container">
                <div className={"AccountDetails__container"}>

                        <form onSubmit={handleSubmit(submit)}>
                            <div className="name-photo-container">
                                <div className={'input_container'}>
                                    <label htmlFor={'name'}>{`${t('account_details.name.label')}`}</label>
                                    <input name="name" type="text" defaultValue={user.name}
                                        className={`basic_container input_field ${errors.name ? 'input_error' : ''}`}
                                        placeholder={`${t('account_details.name.label')}`}
                                        ref={register({ required: true })} />
                                    {errors.name && <p className={"error_message"}>{errors.name.message}</p>}
                                    <img src={pencilIconGrey} alt={"Pencil Icon"} />
                                </div>
                                <div>
                        <label htmlFor={'profile-picture-input'}>
                            <div className="gradient-avatar gradient-avatar__upload-photo"
                                 style={{backgroundImage: `url("${userPhoto}")`, backgroundSize: "cover"}}>
                                <img src={pencilIconBlue} alt={"Pencil Icon"} className={"edit"}/>
                                {imageLoader && <Loading/>}
                            </div>
                        </label>
                        <input name="avatar" type="file" id="profile-picture-input" onChange={uploadProfilePicture}/>
                                </div>
                        </div>
                        <div className={'input_container'} onClick={() => setOpenNewEmailDialog(true)}>
                            <label htmlFor={'email'}>{`${t('account_details.email.label')}`}</label>
                            <input name="email" type="email" defaultValue={user.email}
                                   className={'basic_container input_field'}
                                   placeholder={`${t('account_details.email.label')}`}
                                   readOnly={true}
                                   style={{cursor: "pointer"}}
                            />
                            <img src={pencilIconGrey} alt={"Pencil Icon"}/>
                        </div>
                        <label className={"label"} htmlFor={"phone"}>Phone number</label>
                        <PhoneInput defaultPhone={user?.phone} errors={errors} defaultDialCode={user?.dialCode ? parseInt(user.dialCode.split("+")[1]) : undefined} register={register} onChange={handleDialCodeChange} getValues={getValues} phone_key={user.id}/>
                        <div className="input-field">
                            <label htmlFor={'nationality'}
                                   className={"label"}>{`${t('account_details.nationality.label')}`}</label>
                            <Select searchable={true}
                                    name={"nationality"}
                                    defaultValue={countries.filter(c => c.value === user["nationality"])}
                                    value={countries.filter(c => c.value === user["nationality"])}
                                    onChange={(o) => {
                                        if (!formState.isDirty) formState.isDirty = true;
                                        setUser(prev => ({...prev, "nationality": o.value}))
                                    }}
                                    options={countries}
                                    placeholder={t("billing_details.country")}
                                    searchPlaceholder={t("billing_details.country")}
                                    className={'input-select'} classNamePrefix={'input-select'}
                                    selectedSize={14} components={{Option: FlagOption, Control: FlagControl}}
                            />
                            {errors.nationality && <p className={"error_message"}>{errors.nationality.message}</p>}
                        </div>
                        <div className="input-field">
                            <label htmlFor={'language'}
                                   className={"label"}>{`${t('account_details.language.label')}`}</label>
                            <Select searchable={true}
                                    name={"language"}
                                    defaultValue={languageOptions.filter(c => c.value === user.language)}
                                    value={languageOptions.filter(c => c.value === user.language)}
                                    onChange={(o) => {
                                        if (!formState.isDirty) formState.isDirty = true;
                                        setUser(prev => ({...prev, "language": o.value}))
                                    }}
                                    options={languageOptions}
                                    placeholder={t("billing_details.language")}
                                    searchPlaceholder={t("billing_details.language")}
                                    className={'input-select'} classNamePrefix={'input-select'}
                                    selectedSize={14} components={{Option: FlagOption, Control: FlagControl}}
                            />
                            {errors.language && <p className={"error_message"}>{errors.language.message}</p>}
                        </div>
                        <div className="input-field">
                            <label htmlFor={'timezone'}
                                   className={"label"}>{`${t('timezone')}`}</label>
                            <Select searchable={true}
                                    clearable={false}
                                    name={"timezone"}
                                    defaultValue={timezoneOptions.find(c => c.value === user.timezone)}
                                    value={timezoneOptions.find(c => c.value === user.timezone)}
                                    onChange={(o) => {
                                        if (!formState.isDirty) formState.isDirty = true;
                                        setUser(prev => ({...prev, "timezone": o.value}))
                                    }}
                                    options={timezoneOptions}
                                    className={'input-select'} classNamePrefix={'input-select'}
                                    selectedSize={14}
                            />
                        </div>
                        <div className={'input_container'}>
                            <label htmlFor={'password'}>{`${t('account_details.password.label')}`}</label>
                            <input name="password" type="password"
                                   className={`basic_container input_field ${errors.password ? 'input_error' : ''}`}
                                   placeholder={t('account_details.password.label')}
                                   autoComplete={'new-password'}
                                   ref={register({required: false})}/>
                            {errors.password && <p className={"error_message"}>{errors.password.message}</p>}
                            <img src={pencilIconGrey} alt={"Pencil Icon"}/>
                        </div>
                        <div className={'buttons_container'}>
                            <button type={'submit'} disabled={!formState.isDirty || formState.isSubmitting}
                                    className={'btn btn_blue'}><p>{t('buttons.save')}</p><img alt={"save icon"}
                                                                                              src={saveIconWhite}/>
                            </button>
                        </div>
                    </form>
                </div>
                <div className={"AccountDetails__container"}>
                    <h5>{t('my_account.about_me')}</h5>
                    <div className={"AccountDetails__details"}>
                        <p>{t("my_account.type")}</p>
                        {changingType ?
                            <Select options={options} onChange={(e) => changeType(e)}/>
                            :
                            <p className={"grey-text"} onClick={() => setChangingType(true)}>
                                {user?.type ?
                                    (user?.type === "business" ?
                                        t("my_account.business") : t("my_account.independent"))
                                    : (t("my_account.business") + " / " + t("my_account.independent"))}
                                <img src={pencilIconGrey} alt={"Pencil Icon"}/>
                            </p>}
                    </div>
                    {dashboardInfo?.indicator_global &&
                        <div className={"AccountDetails__details"}>
                            <p>{t('my_account.level')}</p>
                            <p className={"grey-text"}>
                                {t('my_account.' + dashboardInfo.indicator_global)}
                            </p>
                        </div>}
                    {user?.registrationDate &&
                        <div className={"AccountDetails__details"}>
                            <p>{t('my_account.started_inlife')}</p>
                            <p className={"grey-text"}>
                                {moment.unix(user.registrationDate / 1000).format("DD MMM YYYY") ? moment.unix(user.registrationDate / 1000).format("DD MMM YYYY")
                                    : moment(user.registrationDate).format("DD MMM YYYY")}
                            </p>
                        </div>}
                    {activeAds &&
                        <div className={"AccountDetails__details"}>
                            <p>{t('my_account.total_ads')}</p>
                            <p className={"grey-text"}>
                                {activeAds}
                            </p>
                        </div>}
                    {(user?.commission || user?.noCommission) &&
                        <div className={"AccountDetails__details"}>
                            <p>{t('my_account.landlord_commission')}</p>
                            <p className={"grey-text commission-text"}>
                                {user.noCommission ? 0 : user.commission}%
                            </p>
                                {!user?.noCommission && <Tooltip position="left">
                                    <Tooltip.Icon>
                                        <InfoCircleIcon />
                                    </Tooltip.Icon>
                                    <Tooltip.Content>
                                        {t("my_account.commission_model." + (user.firstRentCommission ? "firstRent" : "default"))}
                                    </Tooltip.Content>
                                </Tooltip>} 
                        </div>}
                    {(user?.promotion?.commission && moment(user?.promotion?.endDate).isAfter(moment())) &&
                        <div className={"AccountDetails__details"}>
                            <p>{t('my_account.promotion')}</p>
                            <p className={"grey-text commission-text"}>
                                {t('my_account.promotion_commission', {
                                    commission: user.promotion.commission,
                                    date: moment(user.promotion.endDate).format("DD-MM-YYYY")
                                })}
                            </p>
                        </div>}
                    {!dashboardInfo && <Loading relative={true}/>}
                    {
                        dashboardInfo?.indicators && dashboardInfo?.indicators.map((i) => {
                            let type = i.type === "cancelled_bookings" ? "rejected_bookings" : i.type;
                            return (
                                <div className={"AccountDetails__details"} key={i.type}>
                                    <p>{t('my_account.' + type)}</p>
                                    <p className={"grey-text"}>
                                        {i.current}{i.objective_type === "percentage" && "%"}{i.objective_type === "time" && "h"}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
                </div>
            </div>
            <ApiKeys/>
            <ActionDialog type={"changed_password"} open={showSuccessMessage} setOpen={setShowSuccessMessage}
                          title={t("action_dialog.changed_password")}/>
            <ActionDialog type={"success"} open={showNewEmailValidation} setOpen={setShowNewEmailValidation}
                          title={t("action_dialog.new_email_confirmation")}
                          message={t("action_dialog.new_email_confirmation_message", {email: user.newEmail})}/>
            <DeleteAccount/>
            <PhoneVerificationDialog open={showPhoneVerification} onClose={() => setShowPhoneVerification(false)} phone={user.phone}/>
            <NewEmailDialog open={openNewEmailDialog} setOpen={setOpenNewEmailDialog}
                            onClose={() => {
                                setOpenNewEmailDialog(false)
                            }}/>
        </div>
    );
};



export default AccountDetails;
