import * as React from "react";

function SvgMm(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M0 223.908h512v102.988a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.896V223.908z"
          fill="#FF4B55"
        />
        <path
          d="M8.828.276h494.345a8.829 8.829 0 018.828 8.828v102.988H0V9.103A8.828 8.828 0 018.828.276z"
          fill="#FFE15A"
        />
        <path fill="#73AF00" d="M0 112.088h512V223.9H0z" />
        <path
          d="M261.032 56.959l26.435 79.263 83.552.648c5.097.04 7.21 6.543 3.111 9.571l-67.214 49.635 25.203 79.663c1.537 4.86-3.996 8.879-8.142 5.916L256 233.068l-67.976 48.587c-4.147 2.964-9.679-1.056-8.142-5.916l25.203-79.663-67.214-49.635c-4.1-3.028-1.987-9.533 3.111-9.571l83.552-.648 26.435-79.263c1.611-4.835 8.451-4.835 10.063 0z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgMm;
