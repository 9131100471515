import React, {useCallback, useEffect, useRef, useState} from 'react';
import cuid from 'cuid';
import update from 'immutability-helper';

import Dropzone from './Dropzone';
import ImageList from './ImageList';

import './styles.less';
import {useTranslation} from 'react-i18next';

import removeButtonIcon from './images/removeButtonIcon.svg';
import {Droppable} from 'react-beautiful-dnd';
import Dialog from "@material-ui/core/Dialog";
import resizeImage from "../../../../../../../../utils/resizeImage";

const ChangeImageFull = ({areaProps, images, onDrop, hasPlaceholder, fieldName, deleteImages}) => {

  const {t, i18n} = useTranslation();

  const [openLightbox, setOpenLightbox] = useState(null);
  const closeLightbox = () => setOpenLightbox(null);

  return (<div className="ChooseImages__change-image ChooseImages__change-image-full">
    <Dialog
        onClose={closeLightbox}
        className="ChooseImages__Lightbox"
        onClick={closeLightbox}
        open={!!openLightbox}>
      {openLightbox && <img className="ChooseImages__Lightbox-image" src={typeof openLightbox.file === "string" ? openLightbox.file : openLightbox.src}/>}
    </Dialog>
    <div className="ChooseImages__change-image-full__title">
      <label>{`${areaProps.title}${areaProps.isAreaNotUnique ? ' ' + (+areaProps.divNum + 1) : ''}`}</label>
      <button className={'btn btn_white'} onClick={()=>{deleteImages()}}>
        <img src={removeButtonIcon}/>
        <label>{t('delete_all')}</label>
      </button>
    </div>

    <div className={`ChooseImages__change-image-full__center ${images.length === 0 ? 'empty' : ''}`}>
      <ImageList areaProps={areaProps} setOpenLightbox={setOpenLightbox}
                 images={images} fieldName={fieldName} deleteImage={deleteImages}/>
      {!areaProps.hideDropzone && <Dropzone onDrop={onDrop} accept={'image/*'} images={images} areaProps={areaProps}
                                            hasPlaceholder={hasPlaceholder}/>}
    </div>

    <div className="ChooseImages__change-image-full__info">
      <p>{`${Object.keys(images).length} ${t('dropzone.added_photo', {count: Object.keys(images).length})} `}</p>
      {!areaProps.hideDropzone &&
       <p>{`(${areaProps.minPhotos ? t('minimum').toLowerCase() + ' ' + t('of') + ' ' + areaProps.minPhotos + ', ' : ''}${t('maximum').toLowerCase()} ${t('of')} 10)`}</p>}
    </div>
  </div>)
}

const ChooseImages = ({
                        images,
                        deleteImages,
                        uploadImages,
                        areaProps,
                        setErrorArea,
                        displayError,
                        hasPlaceholder,
                        fieldName,
                        userActionsHandler
                      }) => {
  const {t} = useTranslation();

  const uploadedImagesCount = useRef(images.length);

  useEffect(() => {
    uploadedImagesCount.current = images.length;
  }, [images.length]);

  useEffect(() => {
    if (areaProps.minPhotos && images.length < areaProps.minPhotos) {
      setErrorArea(true);
    }else if(areaProps.maxPhotos !== undefined && images.length > areaProps.maxPhotos){
      setErrorArea(true);
    } else {
      setErrorArea(false)
    }

    return(() => setErrorArea(false));
    // Execute the created function directly
  }, [images])

  //in order to use this inside the useCallback function to limit the images to 10
  const uploadedImagesCountLastCount = useRef(0);

  const onDrop = useCallback((acceptedFiles) => {
    uploadedImagesCountLastCount.current = uploadedImagesCount.current;
    acceptedFiles.map(async (file, index) => {
      let resizedImage = await resizeImage({file, maxSize: 1920});
      resizedImage.lastModifiedDate = new Date();
      resizedImage.name = file.name;
      const reader = new FileReader();
      reader.onload = function (e) {
        // A limit of 10
        if (uploadedImagesCountLastCount.current + index < 10) {
          uploadImages({id: cuid(), src: e.target.result, file: resizedImage});
          // uploadImages(prevState => [...prevState, {id: cuid(), src: e.target.result, file: file}]);
          userActionsHandler({
            type: 'new',
            field: `${fieldName}[${uploadedImagesCountLastCount.current + index}]`
          });
        }
      };
      reader.readAsDataURL(resizedImage);
      return file;
    });
  }, []);

  const isError = () => {
    if (areaProps.minPhotos) {
      if (images.length < areaProps.minPhotos) return true;
    } else if (areaProps.maxPhotos !== undefined) {
      if (images.length > areaProps.maxPhotos) return true;
    } else return false;
  }

  return (
    <Droppable droppableId={fieldName} id={fieldName} direction={'horizontal'} type={'sub'}>
      {(provided, snapshot) => (
        <div id={fieldName} tabIndex={'-1'}
             className={`ChooseImages ${images.length === 0 && hasPlaceholder ? 'no-images' : null} ${(displayError && isError()) ? 'error_container' : ''}`}
             ref={provided.innerRef}
             {...provided.droppableProps}>
          {(images.length === 0 && hasPlaceholder) &&
           <Dropzone onDrop={onDrop} accept={'image/*'} images={images}
                     areaProps={areaProps} hasPlaceholder={hasPlaceholder}/>}

          {(images.length !== 0 || !hasPlaceholder) &&
           <ChangeImageFull images={images} deleteImages={deleteImages} areaProps={areaProps} onDrop={onDrop}
                            hasPlaceholder={hasPlaceholder} fieldName={fieldName}/>}

          {(displayError && isError()) &&
           <p className={'error_message'}>{areaProps.maxPhotos !== undefined?t('dropzone.distribute_all'):`${t('dropzone.insert_at_minimum')} ${areaProps.minPhotos} ${t('photos').toLowerCase()}`}</p>}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ChooseImages;
