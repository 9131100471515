import React, {useState} from 'react';
import './style.less';
import bookingAccepted from "./images/booking_accepted.svg";
import visitAccepted from "./images/visit_accepted.svg";
import visitRejected from "./images/visit_rejected.svg";
import offerSent from "./images/offer_sent.svg";
import changedPassword from "./images/changed_password.svg";
import datesSuggested from "./images/dates_suggested.svg";
import visitDates from "./images/visit_dates.svg";
import propertyUpdated from "./images/property_updated.svg";
import availabilityUpdated from "./images/availability_updated.svg";
import emailNotFound from "./images/email_not_found.svg";
import emailSent from "./images/email_sent.svg";
import newPassword from "./images/new_password.svg";
import successIcon from "./images/success.svg";
import {Dialog} from "@material-ui/core";
import {DialogContent} from "@material-ui/core";


//possible types :
// booking_accepted
// offer_sent

const ActionDialog = ({open, setOpen, type, title, message, undo}) => {

    const icons = {
        booking_accepted: bookingAccepted,
        visit_accepted: visitAccepted,
        offer_sent: offerSent,
        visit_rejected: visitRejected,
        booking_rejected: visitRejected,
        changed_password: changedPassword,
        dates_suggested: datesSuggested,
        visit_dates: visitDates,
        property_updated: propertyUpdated,
        availability_updated: availabilityUpdated,
        email_not_found: emailNotFound,
        email_sent: emailSent,
        new_password: newPassword,
        success: successIcon
    }


    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog open={open} onClose={handleClose} className={"ActionDialog"}>
            <DialogContent className={"ActionDialog__content"}>
                {icons[type] && <img src={icons[type]}/>}
                <div className={"title"}>{title}</div>
                {message && <div className={"message"}>{message}</div>}
                <div className={"close-button"} onClick={handleClose}>OK</div>
                {/*<div className={"input-notification"}>*/}
                {/*    <input type={"checkbox"} className={"input-field input-checkbox"} id={"stopShowing"} onChange={() => setStopShowing(!stopShowing)}/>*/}
                {/*    <label htmlFor={"stopShowing"}>{t("action_dialog.inputMessage")}</label>*/}
                {/*</div>*/}
                {undo && <div className={"undo-button"}>Undo</div>}
            </DialogContent>
        </Dialog>
    )
}

export default ActionDialog;