import {useTranslation} from 'react-i18next';
import pencilIconWhite from '../images/pencilIconWhite.svg';
import React, {useState, useEffect} from 'react';

import './style.less';
import {propertyServices} from "../../../services";
import PencilIcon from "../../../components/svg/Pencil";

const ResumeButtons = ({property, changePage, onDelete, onEnable, roomNumber, setOpen, filter, currentPage}) => {
  const {t} = useTranslation();

  const [confirmedAvailability, setConfirmedAvailability] = useState(property.rooms?.["room_" + roomNumber]?.confirmedAvailability);
  useEffect(()=>{
    if(property.rooms?.["room_" + roomNumber]?.confirmedAvailability) {
      setConfirmedAvailability(property.rooms?.["room_" + roomNumber]?.confirmedAvailability);
    }
  }, [property.rooms])

  return (
    <div className={`ResumeButtons ${filter==='disabled' ? 'align-right' : ''}`}>
      {/*Not Finished Property*/}
      {!property.finished &&
        <button className={'btn btn-primary'} onClick={() => {
         changePage(property.level)
         localStorage.setItem(`prevPage_${property.id}`, currentPage)
       }}>
          <PencilIcon />
         <p>{t(`property_resume.continue_editing`)}</p>
       </button>}
      {/*Disabled Property*/}
      {filter==='disabled' &&
        <button className={'btn btn-primary'} onClick={() => onEnable(roomNumber)}>
         {t(`property_resume.enable`)}
       </button>}
      {/*Finished Property*/}
      {(property.finished && filter!=='disabled') &&
        <button className={'btn btn-primary'}
               onClick={() => setOpen(property.accommodation !== 'apartment' ? roomNumber : undefined)}>
          <PencilIcon />
         <p>{t(`property_resume.edit_ad`)}</p>
       </button>
      }
      {(!property.finished || filter==='disabled') &&
       <p className={'link link_grey'} onClick={() => onDelete(property.accommodation !== 'apartment' && property.renting > 1 ? roomNumber : undefined)}>{t(`property_resume.delete`)}</p>}
      {property.finished && !(property.accommodation === 'residence' && property.rooms?.["room_" + roomNumber]?.openResidenceCalendar) && filter!=='disabled' && ((confirmedAvailability && new Date(confirmedAvailability).getTime() > (Date.now() - 86400000)) ?
      <p>{t('property_resume.updated_today')}</p>    :
      <p className={'link link_blue confirm-availability'}
          onClick={async () => {
            await propertyServices.confirmAvailability(property.id, property.accommodation !== "apartment" && roomNumber)
            setConfirmedAvailability(Date.now());
          }}>{t('property_resume.confirm_availability')}</p>)}
    </div>)

}

export default ResumeButtons;
