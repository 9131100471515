import React, {useEffect, useMemo, useState} from "react";
import "./style.less";
import calendar from "../../../../components/ListItem/images/calendarIconGray.svg";
import listIcon from "./images/list_alt.svg";
import DatePicker from 'react-datepicker';
import moment from "moment";
import {dashboardServices, visitServices} from "../../../../services";
import {useTranslation} from "react-i18next";
import place from "../../../../components/VisitCard/images/place.svg";
import meetingRoom from "../../../../components/VisitCard/images/meeting_room.svg";
import money from "../../../../components/VisitCard/images/money.svg";
import avatarFemale from "../../../../components/VisitCard/images/avatar_female.png";
import avatarMale from "../../../../components/VisitCard/images/avatar_male.png";
import close from "../../../MainPage/containers/MakeOffers/components/PotentialTenantsList/components/OfferPopOver/images/closeGrey.svg";
import Loading from "../../../../components/Loading";
import Dialog from "@material-ui/core/Dialog";
import ActionDialog from "../../../../components/ActionDialog";
import RejectVisitModal from "../../../../components/VisitCard/RejectVisitModal";
import {DialogContent} from "@material-ui/core";
import ScheduleDialog from "../../../../components/VisitCard/ScheduleDialog";
import {useHistory} from "react-router-dom";

moment().locale(localStorage.getItem('i18nextLng')?.split('-')[0])


const MyCalendar = (props) => {
    const {t} = useTranslation();
    const transDir = "main_page.my_calendar."
    const history = useHistory();


    const [filter, setFilter] = useState("all");

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const [newEvent, setNewEvent] = useState({});
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [showEventDialog, setShowEventDialog] = useState(false);
    const [showActionDialog, setShowActionDialog] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);


    useEffect(() => {
        (async () => {
            await handleUpdateEvents()
            //check_ins , confirmed_visits, visit_requests, user_events
        })();
    }, [])

    const handleDayClick = (date) => () => {
        let day = eventsList.find(event => event.day.isSame(moment(date).startOf("day")));
        if (!day) return;
        setSelectedEvent(day.day_events[0]);
        let dayElement = document.getElementById(day.day_events[0].id);
        if (dayElement) {
            dayElement.scrollIntoView({behavior: "smooth"});
        }
    }


    const renderDayContents = useMemo(() => {
        return ((day, date) => {
            let pending_visits = events?.visit_requests;
            let check_ins = events?.check_ins;
            let visits = events?.confirmed_visits;
            let user_events = events?.user_events;
            return (
                <span onClick={handleDayClick(date)}>
                    {day}
                    <div className={"custom-marks"}>
                        {pending_visits?.some(v => moment(v.requestDate).isSame(date, "day")) &&
                            <div className={"react-datepicker__day--highlighted-custom pending-visit"}></div>}
                        {check_ins?.some(r => moment(r.moveIn).isSame(date, "day")) &&
                            <div className={"react-datepicker__day--highlighted-custom check-in"}></div>}
                        {visits?.some(v => moment(v.tenantSelectedDate || v.selectedDate).isSame(date, "day")) &&
                            <div className={"react-datepicker__day--highlighted-custom visit-appointment"}></div>}
                        {user_events?.some(e => moment(e.date).isSame(date, "day")) &&
                            <div className={"react-datepicker__day--highlighted-custom user-event"}></div>}
                </div>
            </span>
            )
        })
    }, [events])

    let eventsList = useMemo(() => {
        if (events?.length < 1) return [];
        let eventsToConsider = [];
        switch (filter) {
            case "all":
                eventsToConsider = [events.confirmed_visits, events.check_ins, events.visit_requests, events.user_events];
                break
            case "pending":
                eventsToConsider = events.visit_requests;
                break;
            case "confirmed":
                eventsToConsider = events.confirmed_visits;
                break;
            case "check-ins":
                eventsToConsider = events.check_ins;
                break;
            default:
                break;
        }
        let sortedEvents = eventsToConsider?.flat()?.sort((e1, e2) => {
            return moment(e2?.sortDate) - moment(e1?.sortDate)
        })
        let daysToConsider = [...new Set(sortedEvents.map(e => moment(e?.sortDate).format("DD/MM/YYYY")))]
        let list = []
        daysToConsider.map(day => {
            let day_moment = moment(day, "DD/MM/YYYY");
            let day_events = [];
            sortedEvents.forEach(e => {
                if (moment(e?.sortDate).isSame(day_moment, "day")) day_events.push(e);
            })
            list.unshift({day: day_moment, day_events})
        })
        setSelectedEvent(list[0]?.day_events[0] || null);
        return list;
    }, [events, filter])

    const handleCreateEvent = async () => {
        setLoading(true);
        let res = await dashboardServices.createEvent(newEvent);
        newEvent.eventType = "user_event";
        newEvent.sortDate = newEvent.date;
        newEvent.id = res.id;
        setEvents(prevEvents => ({...prevEvents, user_events: [...prevEvents.user_events, newEvent]}));
        setLoading(false);
        setShowEventDialog(false);
    }

    const handleRemoveEvent = async () => {
        if (loading) return;
        setLoading(true);
        await dashboardServices.deleteEvent(selectedEvent.id);
        setEvents(prevEvents => ({...prevEvents, user_events: prevEvents.user_events.filter(e => e.id !== selectedEvent.id)}));
        setLoading(false);
    }

    const handleUpdateEvents = async () => {
        setLoading(true);
        let _events = await dashboardServices.getCalendarInfo();
        setEvents(_events);
        setLoading(false)
    }

    const closeEventDialog = () => {
        setNewEvent({});
        setShowEventDialog(false);
    }

    return (
        <div className={"MyCalendar container"}>
            <div className={"MyCalendar__calendar"}>
                <div className={"MyCalendar__calendar-body"}>
                    <div className={"calendar"}>
                        <div className={"calendar__header"}>
                            <img src={calendar} alt={"calendar"}/>{t(transDir + "calendar")}
                        </div>
                        <DatePicker
                            className={"date-picker"}
                            showMonthDropdown
                            inline
                            formatWeekDay={(name) => name.slice(0, 1)}
                            renderDayContents={renderDayContents}
                        />
                    </div>
                    <div className={"calendar-list"}>
                        <div className={"calendar-list__header"}>
                            <img src={listIcon}/>{t(transDir + "agenda")}
                            <button className={"btn btn_white"} onClick={() => {
                                setShowEventDialog(true)
                            }}>{t(transDir + "add_event")} +
                            </button>
                        </div>
                        <div className={"list-filters"}>
                            {["all", "pending", "confirmed", "check-ins"].map((item, index) => (
                                <div key={index} className={"list-filter" + (filter === item ? " selected" : "")}
                                     onClick={() => setFilter(item)}>{t(transDir + "filters." + item)}</div>))}
                        </div>
                        {loading ? <Loading relative cover={0.3}/> : <div className={"events-list"}>
                            {eventsList?.map((eventsDay, index) => {
                                return (
                                    <div className={"day-events"} id={eventsDay.day_events[0]?.id}
                                         key={"eventsDay_" + index}>
                                        <div className={"day-name"}>{eventsDay.day?.format("DD MMMM")}</div>
                                        {eventsDay.day_events?.map(e => {
                                            return (<div className={`event-info ${selectedEvent?.id === e?.id ? "selected" : ""}`}
                                                         onClick={() => setSelectedEvent(e)} key={"event_" + e?.id}>
                                                <div className={"hour"}>{moment(e?.sortDate).format("HH:mm")}</div>
                                                <div className={"type-icon " + e?.eventType}/>
                                                <div className={"info"}>
                                                    <div className={"type-icon " + e?.eventType}/>
                                                    <div className={"description"}>
                                                        <span>{e?.eventType === "user_event" ? e?.title : t(transDir + "event_types." + e?.eventType)}</span>
                                                        <span>{e?.eventType === "user_event" ? e?.description : t(transDir + "event_description." + e?.eventType + (e?.eventType === "confirmed_visit" ? "_" + e?.type : ""), {name: (e.user ? e.user.name.split(" ")?.[1] : "")})}</span>
                                                    </div>

                                                </div>
                                            </div>)
                                        })}
                                        {index + 1 !== eventsList.length && <div className={"dashed-separator"}/>}
                                    </div>
                                )
                            })}
                        </div>}
                    </div>
                </div>


            </div>
            <div className={"MyCalendar__selected"}>
                {selectedEvent?.eventType === "user_event" && <div>
                    <div className={"label"}>{selectedEvent.title}</div>
                    <div>{selectedEvent.description}</div>
                    <div className={"small-text"}>{moment(selectedEvent.date).format("DD/MM/YY   HH:mm")}</div>
                    <div className={"link link_grey"} onClick={handleRemoveEvent}>{t(transDir + "remove_event")}</div>
                </div>}
                {selectedEvent?.eventType !== "user_event" && <div className={"property-info"}>
                    <div className={"label"}>{t('property_resume.not_finished_ad')}</div>
                    {selectedEvent &&
                        <div className={"info"}>
                            <div className={"round-image"}
                                 style={{backgroundImage: `url(${selectedEvent.photo || selectedEvent.property?.photo || ""})`}}/>
                            <div className={"description"}>
                                <span>{selectedEvent.property.internal_name}</span>
                                <span><img alt={"localization"} src={place}/>{selectedEvent.property.address + (selectedEvent.property.floor && (", " + selectedEvent.property.floor) || "")}</span>
                                <span><img alt={"property-room"} src={meetingRoom}/>{selectedEvent.property.id?.split("-")[0]} | {t("dashboard_list_item.room_number") + selectedEvent.room_id && selectedEvent.property.rooms ? Object.keys(selectedEvent.property.rooms).find(rn => selectedEvent.property.rooms[rn].id === selectedEvent.room_id)?.split("_")[1] : 1}</span>
                                <span><img alt={"euro-symbol"} src={money}/>{t('main_page.my_visits.visit_card.monthly_rent')} €{selectedEvent.property.room?.rent}</span>
                            </div>
                        </div>}
                </div>}
                {selectedEvent?.property && <div className={"dashed-separator"}/>}
                {selectedEvent && selectedEvent?.eventType !== "user_event" && <div className={"user-details"}>
                    <div className={"label"}>{t(transDir + "event_description.tenant_details")}</div>
                    {selectedEvent && <div className={"user-info"}>
                        <div className={"user-photo"}>
                            <div className={"round-image"}
                                 style={{backgroundImage: `url(${selectedEvent?.user?.photo || (selectedEvent.user.gender === "female" ? avatarFemale : avatarMale)})`}}/>
                            <span>{selectedEvent.user.name.split(" ")[0]} <img alt={"flag-" + selectedEvent.user.nationality} src={`https://github.com/madebybowtie/FlagKit/raw/master/Assets/PNG/${selectedEvent.user.nationality?.toUpperCase()}@2x.png?raw=true)`}/></span>
                        </div>

                        <div className={"details"}>
                            <span className={"small-text"}>{t('country.' + selectedEvent?.user.nationality)}</span>
                            <span className={"small-text"}>{t(selectedEvent?.user.gender)} | {new Date().getFullYear() - selectedEvent?.user.birthdate?.split("-")[1]} {t('years')}</span>
                            <span className={"small-text"}>{t('main_page.my_visits.visit_card.occupation.' + selectedEvent.user.occupation)} {t('at')} {selectedEvent.user?.occupation === "student" ? selectedEvent.user.university?.split(" - ")[0] : selectedEvent?.user.occupation_detail}</span>
                            <span className={"small-text"}>{t("dashboard_list_item.entry_date")}: {moment(selectedEvent.moveIn).format("DD/MM/YYYY")}</span>
                            <span className={"small-text"}>{t("dashboard_list_item.exit_date")}: {moment(selectedEvent.moveOut).format("DD/MM/YYYY")}</span>
                        </div>
                    </div>}
                </div>}
                {selectedEvent?.user && <div className={"dashed-separator"}/>}
                {selectedEvent?.eventType === "visit_request" && <div className={"event-details"}>
                    <div className={"label"}>{t('main_page.my_visits.visit_card.dates')}</div>
                    {selectedEvent.chosenDates.map((date, index) => {
                        return (
                            <span key={"chosenDate-" + index + "-" + date.date}
                                  className={"small-text"}>{moment(date.date, "YYYY-MM-DD").format("DD MMM YYYY")} período {date.start + " - " + date.end}</span>)
                    })}
                    <div className={"visit_buttons"}>
                        <button className={"btn btn_blue"} onClick={() => setOpenScheduleDialog(true)}>{t('main_page.my_visits.visit_card.schedule_visit')}</button>
                        <div className={"link link_grey"} onClick={() => setOpenRejectModal(true)}>{t('main_page.my_visits.visit_card.reject_visit')}</div>
                    </div>
                </div>}
                {selectedEvent?.eventType === "confirmed_visit" && <div className={"event-details"}>
                    <div className={"label"}>{t(transDir + "event_description.visit_details")}</div>
                    <span className={"small-text"}>{t("my_account.type")} {t('main_page.my_visits.visit_card.type.' + selectedEvent.type)}</span>
                    <span className={"small-text"}>Timezone: {selectedEvent.timezone || "Europe/Lisbon"}</span>
                    <span className={"small-text"}>{t("date")}: {moment(selectedEvent.selectedDate).format("DD/MM/YY HH:mm")}</span>
                    <div className={"link link_grey"} style={{marginTop: "10px"}} onClick={() => setOpenRejectModal(true)}>{t("main_page.reservations.reservation_details.cancellation_request")}</div>
                </div>}
                {selectedEvent?.eventType === "check_in" && <div className={"event-details"}>
                    <div className={"label"}>{t(transDir + "event_description.tenant_contacts")}</div>
                    <span className={"small-text"}>Tel.: {selectedEvent.user.phone}</span>
                    <span className={"small-text"}>Email: {selectedEvent.user.email}</span>
                    <div className={"link link_grey"} style={{marginTop: "30px"}} onClick={() => history.push("/reservations/" + selectedEvent.id + "/details")}>{t("main_page.my_ads.create_ad.house_details.advanced_edition.see_reservation")}</div>
                    {(moment(selectedEvent.moveIn).endOf("day").isAfter(moment()) && <div className={"link link_grey"} style={{marginTop: "10px"}} onClick={() => history.push("/reservations/" + selectedEvent.id + "/cancel")}>{t("main_page.reservations.reservation_details.cancellation_request")}</div>)}
                </div>}
            </div>
            <Dialog open={!!showEventDialog} onClose={closeEventDialog} className={"NewEventDialog"}>
                <DialogContent className={"NewEventDialog__content"}>
                    {loading && <Loading cover={0.3}/>}
                    <div className={"title"}>{t(transDir + "new_event.page_title")}<img src={close} alt={"close"} onClick={closeEventDialog}/></div>
                    <div className="input_container">
                        <label htmlFor={"title"}>{t(transDir + "new_event.title")}</label>
                        <input className={"basic_container input_field"} type="text" id={"title"}
                               value={newEvent?.title}
                               onChange={(e) => setNewEvent(prev => ({...prev, title: e.target.value}))}/>
                    </div>
                    <div className="input_container">
                        <label htmlFor={"description"}>{t(transDir + "new_event.description")}</label>
                        <textarea className={"basic_container input_field"} id={"description"}
                                  value={newEvent?.description}
                                  onChange={(e) => setNewEvent(prev => ({...prev, description: e.target.value}))}/>
                    </div>
                    <div className="input_container">
                        <label htmlFor={"title"}>{t(transDir + "new_event.start_date")}</label>
                        <DatePicker
                            className={'basic_container input_field'}
                            selected={newEvent?.date}
                            dateFormat={"dd/MM/yyyy HH:mm"}
                            onChange={(date) => setNewEvent(prev => ({...prev, date: date}))}
                            minDate={new Date()}
                            showTimeSelect
                            timeFormat="HH:mm"
                            popperPlacement={"top"}
                            showPreviousMonths={false}
                        />
                    </div>
                    <button className={"submit-button btn btn_blue"} onClick={handleCreateEvent}
                            disabled={!(newEvent.title && newEvent.description && newEvent.date)}>{t(transDir + "new_event.create")}</button>
                </DialogContent>
            </Dialog>

            {selectedEvent?.eventType === "visit_request" && <ScheduleDialog openDialog={!!openScheduleDialog} closeDialog={() => setOpenScheduleDialog(false)} visit={selectedEvent} setVisitMoment={setSelectedDate} setActionDialog={setShowActionDialog}/>}
            {["confirmed_visit", "visit_request"].includes(selectedEvent?.eventType) && <RejectVisitModal open={openRejectModal} setOpen={setOpenRejectModal}
                                                                                                          visit={selectedEvent} setActionDialog={setShowActionDialog} updateVisits={handleUpdateEvents}/>}
            <ActionDialog type={showActionDialog}
                          open={showActionDialog} setOpen={setShowActionDialog}
                          title={showActionDialog ? t(`action_dialog.${showActionDialog}`, {
                              visitMoment: showActionDialog === "visit_accepted" ? moment(selectedDate).locale(localStorage.getItem('i18nextLng').split('-')[0]).format(`DD MMM [${t("at_time")}] HH:mm`) : null,
                              tenantName: selectedEvent.user.name.split(" ")[0]
                          }) : ""}
                          message={showActionDialog ? t("action_dialog." + showActionDialog + "-message", {tenantName: selectedEvent.user.name.split(" ")[0]}) : ""}/>
        </div>
    );
};


export default MyCalendar;
