import React, {useEffect, useRef, useState} from 'react';
import getLevelsMaxRoom from '../functions/getLevelsMaxRoom';
import RoomDetailsHeader from '../components/RoomDetailsHeader';
import RoomDetails from './components/RoomDetails';
import RoomAmenities from './components/RoomAmenities';
import RoomPrices from './components/RoomPrices';

import {connect} from 'react-redux';
import {propertyAdHistoryActions} from '../../../../../../../../../redux/actions';

import './style.less'
import {useTranslation} from 'react-i18next';

const pageLabel = 'main_page.my_ads.create_ad.room_details';

const RoomsMain = ({
                       updateProperty,
                       currentPageLevel,
                       currentRoomNumber,
                       property,
                       changePage,
                       addHistoryPush,
                       addHistoryPop,
                       propertyAdHistory,
                       goToNextAfterUpdate
                   }) => {
    const {t} = useTranslation();

    const getCurrentRoom = () => {
        if (currentRoomNumber) {
            if ((property.accommodation === 'apartment' && currentPageLevel === '2.4')) {
                return {idx: 1, name: 'room_1'};
            } else {
                return {idx: parseInt(currentRoomNumber), name: 'room_' + currentRoomNumber};
            }
        }
        return {idx: 1, name: 'room_1'};
    };

    const [currentRoom, setCurrentRoom] = useState(getCurrentRoom);

    useEffect(()=>setCurrentRoom(getCurrentRoom()), [currentRoomNumber]);

    const [maxRoom, setMaxRoom] = useState(getLevelsMaxRoom(property.rooms, currentPageLevel));
    const numberPropertyRooms = Object.keys(property.rooms).length;

    const [cloneValue, setCloneValue] = useState();

    const changeInfo = async (value) => {
        //get from the server
        setCloneValue(value);
        cloneRoomHandler(value.value);
    };

    const cloneRoomHandler = (roomIdx) => {
        setCurrentRoom(prevState => ({...prevState, name: roomIdx}));
    };

    const setCurrentRoomIndx = value => {
        setCurrentRoom({idx: value, name: 'room_' + value});

        //add to the propertyAdHistory
        addHistoryPush({level: currentPageLevel, room: value}, propertyAdHistory);
        setCloneValue(null);
    };

    const updateRoomLevel = async (values, currentPageLevel, currentRoomIndx) => {
        if (numberPropertyRooms === currentRoom.idx) values.nextRoomLevel = true;
        if(await updateProperty(values, currentPageLevel, currentRoomIndx)) return true;
        //add to the propertyAdHistory
        addHistoryPush({level: currentPageLevel, room: currentRoomIndx}, propertyAdHistory);
        setCloneValue(null);
    };

    const goToNextRoom = () => {
        if (currentRoom.idx < property.renting && goToNextAfterUpdate) {
            let newIdx = currentRoom.idx + 1;
            setCurrentRoom({idx: newIdx, name: 'room_' + newIdx});

            //add to the propertyAdHistory
            addHistoryPush({level: currentPageLevel, room: newIdx}, propertyAdHistory);

            if (maxRoom < newIdx) {
                setMaxRoom(newIdx)
            }
        }
        setCloneValue(null);
    };

    const goToPreviousRoom = () => {
        if (currentRoom.idx > 1) {
            setCurrentRoom(prevState => {
                const newRoomLevel = prevState.idx - 1;
                return {idx: newRoomLevel, name: 'room_' + newRoomLevel}
            });
            addHistoryPop(1)
        } else {
            const previousPage = propertyAdHistory.history[propertyAdHistory.history.length - 2];
            addHistoryPop(2);
            if (previousPage) {
                changePage(previousPage.level, previousPage.room);
            }
        }
        setCloneValue(null);
    };

    const components = {
        2.2: {name: 'room_details', component: RoomDetails},
        2.3: {name: 'room_amenities', component: RoomAmenities},
        2.4: {name: 'room_prices', component: RoomPrices}
    };

    let CurrentComponent = components[currentPageLevel].component;

    return (
        <div className={'RoomsMain  section_content'} key={currentPageLevel + currentRoom.name}>
            {/*{(property.accommodation === 'apartment' && currentPageLevel === '2.4')*/}
            {/*  ? <h4 className={'title'} style={{width: '100%'}}>{t(pageLabel + '.apartment_prices_label')}</h4>*/}
            {/*  : <RoomDetailsHeader currentRoom={currentRoom} setCurrentRoomIndx={setCurrentRoomIndx}*/}
            {/*                       cloneRoomHandler={cloneRoomHandler} t={t}*/}
            {/*                       maxRoom={maxRoom} setMaxRoom={setMaxRoom} rooms={property.rooms}*/}
            {/*                       currentPageLevel={currentPageLevel}*/}
            {/*                       title={t(pageLabel + '.' + components[currentPageLevel].name + '.title')}/>}*/}

            <CurrentComponent
                key={currentRoom.name}
                currentRoomHeader={currentRoom}
                currentRoomName={currentRoom.name}
                currentRoomIndx={currentRoom.idx}
                updateRoomLevel={updateRoomLevel}
                currentPageLevel={currentPageLevel}
                goToNextRoom={goToNextRoom}
                setCurrentRoomIndx={setCurrentRoomIndx}
                cloneRoomHandler={cloneRoomHandler}
                changeInfo={changeInfo}
                cloneValue={cloneValue}
                property={property}
                maxRoom={maxRoom}
                setMaxRoom={setMaxRoom}
                rooms={property.rooms}
                title={property.accommodation === 'apartment' && currentPageLevel === '2.4' ? t(pageLabel + '.apartment_prices_label') : t(pageLabel + '.' + components[currentPageLevel].name + '.title')}
                goToPreviousRoom={goToPreviousRoom}/>
        </div>
    );
};

function mapStateToProps(state) {
    return {propertyAdHistory: state.propertyAdHistory};
}

const actionCreator =
    {
        addHistoryPush: propertyAdHistoryActions.push,
        addHistoryPop: propertyAdHistoryActions.pop
    };

export default connect(mapStateToProps, actionCreator)(RoomsMain);
