import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./style.less";

import Verifications from "./containers/Verifications";
import BillingHistory from "./containers/BillingHistory";
import BillingDetails from "./containers/BillingDetails";
import AccountDetails from "./containers/AccountDetails";
import Notifications from "./containers/Notifications";
import PagesSwitch from "../../../../components/PagesSwitch";

import InlifeIcon from "../../../../components/svg/Account/Inlife";
import IntegrationsIcon from "../../../../components/svg/Account/Integrations";
import NotificationsIcon from "../../../../components/svg/Account/Notifications";
import VerificationsIcon from "../../../../components/svg/Account/Verifications";
import BankBillingIcon from "../../../../components/svg/Account/BankBilling";
import InvoicingHistoryIcon from "../../../../components/svg/Account/InvoicingHistory";

import {useAuth} from "../../../../services/authServices";

const pages = [
    {
        label: "my_account.account_details",
        path: "account_details",
        component: AccountDetails,
    icon: InlifeIcon
    },
    /* {
      label: "my_account.integrations",
      path: "integrations",
      component: Integrations,
      icon: IntegrationsIcon
    }, */
    /* {
        label: "my_account.users",
        path: "users",
        component: Users,
        icon: UsersIconBlack,
        iconActive: UsersIconBlue
    }, */
    {
        label: "my_account.notifications",
        path: "notifications",
        component: Notifications,
      icon: NotificationsIcon
    },
    {
        label: "my_account.verifications",
        path: "verifications",
        component: Verifications,
      icon: VerificationsIcon
    },
    {
        label: "my_account.billing_details",
        path: "billing_details",
        component: BillingDetails,
      icon: BankBillingIcon
    },
    {
        label: "my_account.billing_history",
        path: "billing_history",
        component: BillingHistory,
      icon: InvoicingHistoryIcon
    }
];

const MyAccount= ({path}) => {

  const {t} = useTranslation();
  const auth = useAuth();

  const history = useHistory();

  useEffect(() => {
    if(!auth.user && !auth.loading) {
      history.push('/start');
    }
  },[auth.user, auth.loading])


  const link = (<span className="link"
    onClick={async () => {
      await auth.logout();
    }}
  >
    {t("my_account.logout")}
  </span>)

  return (<div className="MyAccount">
      <PagesSwitch path={path}
                   pages={pages}
                   containerClass={true}
                   boxShadow={true}
                   navBarButton={true}
                   type={"secondary"}
                   renderLinks={link}
      />
  </div>);
};

export default MyAccount;
