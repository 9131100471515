import * as React from "react";

function SvgCy(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 336"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#F5F5F5"
        />
        <g fill="#73AF00">
          <path d="M244.426 272.546c4.884-3.648 9.128-4.841 15.694-6.034 6.569-1.193 13.506-3.228 17.144-4.21 3.67-1.016-12.328 4.841-16.403 5.999-4.007 1.227-16.435 4.245-16.435 4.245zm26.439-10.272c-1.314-8.252 1.952-16.941 9.429-20.41 1.752 5.996-3.604 16.166-9.429 20.41zm12.764-4.277c-4.008-6.904 2.728-15.763 7.746-20.074 2.324 4.143-1.752 16.503-7.746 20.074zm11.754-5.322c-4.244-5.895.809-16.201 6.198-20.276 2.088 5.828-.001 16.267-6.198 20.276zm11.788-7.409c-3.906-5.558-1.177-14.18 4.446-19.805 4.009 4.649.572 14.686-4.446 19.805zm9.466-7.41c-3.267-5.255.739-17.816 5.994-21.287 2.256 4.648.572 17.785-5.994 21.287zm7.509-1.986c-.943-7.511 12.192-15.83 17.548-15.594-.573 6.128-6.197 12.864-17.548 15.594zm-7.343 8.485c6.198-6.33 18.155-6.33 22.397-5.018-1.11 3.706-15.255 11.756-22.397 5.018z" />
          <path d="M308.316 250.722c8.69-2.325 14.314-4.211 19.501 1.177-3.671 4.649-17.749 3.269-19.501-1.177zm-12.393 5.626c8.319-4.447 18.389 2.121 19.905 5.927-9.262 3.872-20.074-4.952-19.905-5.927zm-11.385 6.364c7.578-2.897 16.268.943 19.703 5.187-4.042 1.954-14.618 4.075-19.703-5.187zm-10.372 3.266c8.69-.977 17.007 5.625 18.827 9.364-11.049 1.077-15.325-2.256-18.827-9.364zm-2.796 6.568c-4.817-3.648-9.06-4.841-15.628-6.034-6.569-1.193-13.506-3.228-17.209-4.21-3.604-1.016 12.394 4.841 16.468 5.999 4.041 1.227 16.369 4.245 16.369 4.245zm-26.439-10.272c1.379-8.252-1.887-16.941-9.431-20.41-1.684 5.996 3.672 16.166 9.431 20.41zm-12.698-4.277c4.076-6.904-2.728-15.763-7.746-20.074-2.324 4.143 1.752 16.503 7.746 20.074zm-11.821-5.322c4.311-5.895-.742-16.201-6.131-20.276-2.12 5.828.001 16.267 6.131 20.276zm-11.755-7.409c3.873-5.558 1.18-14.18-4.412-19.805-4.007 4.649-.572 14.686 4.412 19.805zm-9.429-7.41c3.334-5.255-.742-17.816-5.996-21.287-2.324 4.648-.573 17.785 5.996 21.287zm-7.579-1.986c1.011-7.511-12.125-15.83-17.481-15.594.573 6.128 6.098 12.864 17.481 15.594zm7.41 8.485c-6.198-6.33-18.153-6.33-22.397-5.018 1.177 3.706 15.255 11.756 22.397 5.018z" />
          <path d="M207.546 250.722c-8.69-2.325-14.314-4.211-19.501 1.177 3.604 4.649 17.749 3.269 19.501-1.177zm12.326 5.626c-8.284-4.447-18.322 2.121-19.838 5.927 9.263 3.872 20.007-4.952 19.838-5.927zm11.35 6.364c-7.544-2.897-16.2.943-19.635 5.187 4.076 1.954 14.652 4.075 19.635-5.187zm10.509 3.266c-8.757-.977-17.075 5.625-18.86 9.364 11.114 1.077 15.358-2.256 18.86-9.364z" />
        </g>
        <path
          d="M147.966 141.194s-2.412 3.146 1.685 11.114c4.097 7.968 1.154 17.164 5.568 20.106s.185 8.778 5.242 12.664c5.056 3.887 18.345 12.831 27.517 13.877 9.172 1.045 18.082-8.222 23.374-1.382 5.292 6.84 4.136 11.661 9.128 12.193s4.992-1.675 4.625-4.985c-.367-3.31 3.309-12.505 11.769-12.505s17.422-1.007 31.7-6.389c14.278-5.382 21.266-7.956 23.105-16.416 1.839-8.46 7.068-18.462 17.143-13.828 10.076 4.633 9.398 2.46 12.495.236 3.097-2.223 13.483 4.209 15.83.809 2.348-3.401-18.377-20.224-17.209-29.1 1.166-8.875 3.961-16.247 12.495-16.975 8.533-.728 6.326-10.291 15.154-15.073 8.828-4.781 12.894-5.329 19.872-12.412 6.978-7.083 25.329-21.421 25.329-21.421s-35.701 17.097-42.841 18.66c-7.14 1.564-10.084 11.863-29.947 17.38-19.863 5.517-20.966 7.724-36.046 11.034-13.581 2.982-44.26 1.981-62.408-3.843a3.649 3.649 0 00-4.768 3.793c.747 8.821.537 24.681-10.176 27.142 0 0-14.603-10.906-23.798-6.125-9.195 4.781-15.735 21.834-22.58 19.192-6.845-2.643-7.741-13.587-12.258-7.746z"
          fill="#FFC850"
        />
      </g>
    </svg>
  );
}

export default SvgCy;
