import React from 'react';
import {useTranslation} from 'react-i18next';

import TenantInfo from '../../components/TenantInfo';
import MoveInInfo from '../../components/MoveInInfo';

import './style.less'
import '../../style.less'

import { useHistory } from "react-router-dom";
import SendArrowIcon from "../../../svg/SendArrow";

const componentLabel = 'dashboard_list_item'

const PotentialTenantsDetails =({user}) => {
  const {t} = useTranslation();

  return(
    <div className={"PotentialTenantsDetails"}>
    {user.preferences?.accommodation &&
        <div>
          <p className={'small'}><span>{t(componentLabel + '.looking_for')}</span> {t(`accommodation_type.${user.preferences.accommodation}`)}</p>
    </div>}
    {user.preferences?.numRooms &&
        <div>
          <p className={'small'}><span>{t(componentLabel + '.number_of_rooms')}</span> {`${user.preferences.numRooms} ${t('room', { count: user.preferences.numRooms })}`}</p>
    </div>}
    {user.preferences?.location &&
        <div>
      <p className={'small'}>
            <span>{t(componentLabel + '.near_by')} </span>
          {user.preferences.location === "none" ? t(componentLabel+'.locationNone') : (
              user.preferences.location === "university" ? user.preferences?.university?.split('-')?.[0] : (
                  user.preferences.location === "metro" ? t(componentLabel + '.metro_lines.' + user.preferences.metro) : (
                      user.preferences.neighborhood
                  )
              )
          )}
      </p>
    </div>}
      {user.preferences?.city &&
        <div>
          <p className={'small'}><span>{t(componentLabel + '.city')}</span> {user.preferences?.city}</p>
      </div>}
  </div>)
}

const PotentialTenantsBudget = ({budget}) => {
  const {t} = useTranslation();

  return (
    <div className={"PotentialTenantsBudget"}>
      <p>{t(componentLabel + '.total_budget_label')}</p>
      {budget && <h4>{`${budget.from}€ - ${budget.to}€`}</h4>}
    </div>
  )
}

const Actions = (props) => {
  const {t} = useTranslation();
  const { tenant } = props;

  const history = useHistory();

  const openTenantDetails = (e) => {
    e.preventDefault();
    history.push(`/radar/${tenant.id}/details/`);
  }

  return (
    <div className={'Actions'}>
      <button className="btn btn-primary" onClick={(e) => props.handleClick(e, props.tenant)}>
        <SendArrowIcon />
          {t(componentLabel + '.make_offer')}
        </button>
      <a href={`/radar/${tenant.id}/details/`}
        className="btn btn-secondary" onClick={openTenantDetails}>
        {t(componentLabel + '.viewMoreDetails')}
      </a>
    </div>
  )
}

const PotentialTenant = ({tenant, ...props}) => {

    const {t} = useTranslation();

    return (
      <div className={"PotentialTenantContainer " + (tenant.preferences ? ' ListItem basic_container' : "") + ((tenant.availableProperties && tenant.availableProperties.length > 0) ? "" : " potentialTenant__noProperties")}>
            {tenant.preferences &&
            <div className={'ListItem__content'}>
                <TenantInfo listType={props.type} tenant={tenant} type={'fullIcon'} move_details={{
                    "move_in": tenant.preferences?.moveIn,
                    "move_out": tenant.preferences?.moveOut
            }} />
                    <PotentialTenantsDetails user={tenant}/>
            <MoveInInfo listType={props.type}
              move_in={tenant.preferences?.moveIn}
              move_out={tenant.preferences?.moveOut}
              stayLength={tenant.preferences?.stayLength}
            />
            <PotentialTenantsBudget budget={{ from: tenant.preferences?.budgetMin, to: tenant.preferences?.budgetMax }} />
                <Actions filter={props.filter} expires={tenant.preferences?.expires} tenant={tenant}
                         handleClick={props.handleClick} archive={props.archive} unarchive={props.unarchive}/>
            </div>}
        </div>
    )

}

export default PotentialTenant;
