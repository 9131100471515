import React from "react";

const Euro = (props) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="euro_symbol" clipPath="url(#clip0_3549_5822)">
      <path
        id="Vector"
        d="M10.2002 12.8333C8.52686 12.8333 7.0802 11.8867 6.3602 10.5H10.2002V9.16667H5.9202C5.88686 8.94667 5.86686 8.72667 5.86686 8.5C5.86686 8.27333 5.88686 8.05333 5.9202 7.83333H10.2002V6.5H6.3602C7.0802 5.11333 8.53353 4.16667 10.2002 4.16667C11.2735 4.16667 12.2602 4.56 13.0202 5.21333L14.2002 4.03333C13.1402 3.08 11.7335 2.5 10.2002 2.5C7.58686 2.5 5.37353 4.17333 4.54686 6.5H2.2002V7.83333H4.2402C4.21353 8.05333 4.2002 8.27333 4.2002 8.5C4.2002 8.72667 4.21353 8.94667 4.2402 9.16667H2.2002V10.5H4.54686C5.37353 12.8267 7.58686 14.5 10.2002 14.5C11.7402 14.5 13.1402 13.92 14.2002 12.9667L13.0135 11.7867C12.2602 12.44 11.2802 12.8333 10.2002 12.8333Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_3549_5822">
        <rect
          width={16}
          height={16}
          fill="white"
          transform="translate(0.200195 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Euro;
