import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import './style.less';

import MeetingRoomIcon from '../../../../../../components/svg/MeetingRoom';
import NoMeetingRoomIcon from '../../../../../../components/svg/NoMeetingRoom';
import SmallEllipseIcon from '../../../../../../components/svg/SmallEllipse';
import BlueEyeIcon from '../../../../../../components/svg/BlueEye';
import { isMobile } from '../../../../../../utils/responsive';

const Timeline = ({ rents }) => {
    const { t, i18n } = useTranslation();
    const MAX_ITEMS = 6;

    const [showAllMonths, setShowAllMonthsMonths] = useState(false);
    /* const firstItems = rents.slice(0, MAX_ITEMS / 2);
    const lastItems = rents.slice(-MAX_ITEMS / 2);
    const middleEllipsis = [{ ellipsis: true }];
    const rentsDisplayed = showAllMonths ? rents : [...firstItems, ...middleEllipsis, ...lastItems]; */

    const formatDate = (dateString) => {
        const parts = dateString.split('/');
        const date = new Date(parts[2], parts[1] - 1, parts[0]);
        const month = date.toLocaleString(i18n.language, { month: isMobile ? 'short' : 'long' });
        const year = date.getFullYear();

        return `${month} ${year}`;
    }

    let rentsDisplayed = rents;

    if (rents.length >= 6 && !showAllMonths) {
        const firstItems = rents.slice(0, MAX_ITEMS / 2);
        const lastItems = rents.slice(-MAX_ITEMS / 2);
        const middleEllipsis = [{ ellipsis: true }];
        rentsDisplayed = [...firstItems, ...middleEllipsis, ...lastItems];
    }

    return (
        <Fragment>
            {rents.length > 0 && (
                <div className="timeline-container">
                    <div className="timeline-icon">
                        {rentsDisplayed.map((item, idx) => {
                            if (item.ellipsis) {
                                return (
                                    <div className="timeline-item-icon padding" key={idx}>
                                        <span className="timeline-icon-double-line"></span>
                                    </div>
                                )
                            }

                            return (
                                <div className={`timeline-item-icon ${!(idx === 0 || idx === rentsDisplayed.length - 1) && 'padding'}`} key={idx}>
                                    {idx === 0 ? <MeetingRoomIcon className="door-icon" /> : idx === rentsDisplayed.length - 1 ? <NoMeetingRoomIcon className="door-icon" /> : <SmallEllipseIcon />}
                                    {idx !== rentsDisplayed.length - 1 || item.ellipsis ? <span className="timeline-icon-line"></span> : null}
                                </div>
                            )
                        })}
                    </div>
                    <div className="timeline-item">
                        {rentsDisplayed.map((item, idx) => (
                            <Fragment key={idx}>
                                {item.ellipsis ? (
                                    <div className="timeline-item__content ellipsis">
                                        <span>...</span>
                                    </div>
                                ) : (
                                    <div className="timeline-item__content">
                                        <div className="timeline-item__info">
                                            <span>{formatDate(item.date)}</span>
                                            {idx === 0 ? <p>{t("dashboard_list_item.entry_date")}</p> : idx === rentsDisplayed.length - 1 && <p>{t("dashboard_list_item.exit_date")}</p>}
                                        </div>
                                        <span>{item.rent}</span>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    </div>
                </div>
            )}

            {rents.length > MAX_ITEMS && !showAllMonths ? (
                <div className="btn-container">
                    <button className="btn btn-ghost" onClick={() => setShowAllMonthsMonths(true)}>
                        <BlueEyeIcon />
                        {t("main_page.reservations.reservation_details.showAllMonths")}
                    </button>
                </div>
            ) : rents.length > MAX_ITEMS && showAllMonths && (
                <div className="btn-container">
                    <button className="btn btn-ghost" onClick={() => setShowAllMonthsMonths(false)}>
                        <BlueEyeIcon />
                        {t("main_page.reservations.reservation_details.showLessMonths")}
                    </button>
                </div>
            )}
        </Fragment>
    );
};

export default Timeline;
